import {useEffect} from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardHeader, CardContent } from "@shadcn/components/ui/card";
import { Badge } from "@shadcn/components/ui/badge";
import { 
  
  
  User,
  Store,
  Clock,
  Truck,
  
  CheckCircle2
} from "lucide-react";

import { useTaskCard } from "@zustand/useTaskCard";

import { cva } from "class-variance-authority";

const statusTitle = {
    todo: 'To do',
    'in-progress': 'In Progress',
    complete: 'Complete',
  };

export function TaskCard({ task, isOverlay }) {
    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: task.id,
        data: { type: "Task", task },
    });

    const selectedCard = useTaskCard((state) => state.selectedCard);
    const taskCard = useTaskCard((state) => state.task);
    const selected = useTaskCard((state => state.selected))
    const setSelected = useTaskCard((state => state.setSelected))
    useEffect(() => {
        if(task.selected) {
            if(selected === undefined) {
                selectedCard({...task})
                setSelected(true)
            }
        }
    },[])
    

    const style = {
        transition,
        transform: CSS.Translate.toString(transform),
    };

    const cardClass = task?.id === taskCard?.id
        ? "border-2 border-black shadow-lg"
        : "bg-white border border-gray-300 shadow-sm";

    const variants = cva("", {
        variants: {
            dragging: {
                over: "ring-2 opacity-30",
                overlay: "ring-2 ring-primary",
            },
        },
    });

    return (
        <Card
            onClick={() => {
                selectedCard(task)
                setSelected(true)
            }}
            ref={setNodeRef}
            style={style}
            className={`${cardClass} cursor-pointer ${variants({
                dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
            })} transition-all duration-300 rounded-xl hover:shadow-lg`}
        >
            <CardHeader 
                
                className="px-4 py-3 space-y-3 cursor-grab"
            >
                {/* Order Info */}
                <div 
                {...attributes}
                {...listeners}
                className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                        {task?.order?.name && 
                            <Badge variant="outline" className="text-xs font-medium bg-blue-50 border-blue-200 text-blue-700">
                                {task?.order?.name}
                            </Badge>
                        }
                        {!task?.hasOrder && (
                            <>
                            {task?.orderNumber && <Badge variant="outline" className="text-xs bg-gray-100 text-gray-800">
                                {task?.orderNumber}
                            </Badge>}   
                            <Badge variant="outline" className="text-xs bg-gray-100 text-gray-800">
                                Pre 2020
                            </Badge>
                            </>
                        )}
                    </div>
                    <div className="flex items-center gap-2">
                        
                    {task.toBeShipped && <Truck className="h-4 w-4 text-blue-500" />}
                        {task?.order?.fulfillment_status === 'fulfilled' && <CheckCircle2 className="h-4 w-4 text-green-500" />}
                    </div>
                </div>

                {/* Customer & Store Info */}
                <div className="space-y-2">
                    <div className="flex items-center gap-2 text-sm">
                        <User className="h-4 w-4 text-gray-400" />
                        <span className="font-medium">{task.customerName}</span>
                    </div>
                    <div className="flex items-center gap-2 font-medium text-gray-600">
                        <Store className="h-4 w-4" />
                        <span>{task?.store?.name}</span>
                    </div>

                    {/* Delivery Method */}
                    {task.pickupLocation && (
                        <div className="flex items-center gap-1.5 bg-purple-50 py-1 rounded-full">
                            <Store className="h-4 w-4 text-purple-600" />
                            <span className="text-xs text-purple-700 font-medium">
                                Pickup: {task?.pickupLocation?.display_name} ({task?.pickupLocation?.code})
                            </span>
                        </div>
                    )}
                    
                    
                </div>
            </CardHeader>

            <CardContent className="px-4 pb-3 pt-0">
                {/* Footer Info */}
                <div className="flex items-center justify-between text-xs text-gray-500 mt-2">
                    <div className="flex items-center gap-3">
                        <div className="flex items-center gap-1">
                            <Clock className="w-3 h-3" />
                            <span>Due: {new Date(task.dayNeededBy).toLocaleDateString()}</span>
                        </div>
                        
                    </div>
                   
                </div>
            </CardContent>
        </Card>
    );
}
