import { WarrantyKanbanBoard } from '@components/WarrantyKanban/WarrantyKanbanBoard';
import { Dialog, DialogContent } from '@shadcn/components/ui/dialog';
import { useTaskCard } from '@zustand/useTaskCard';
import moment from 'moment';
import { Badge } from '@shadcn/components/ui/badge';
import { CalendarDays, User, AlertTriangle, PackageCheck, Truck, ArrowRight, History } from 'lucide-react';
import { InfoBox } from '@shadcn/components/ui/infobox';
import { states, FAULT_REASONS } from './warranty-claim';
import { Button } from '@shadcn/components/ui/button';
import { Link } from 'react-router-dom';
import { CloseTicketButton } from '@components/shared/CloseTicketButton';
import { useState, useEffect } from 'react';
import { useQueryParams } from '@helpers/JIFI/useQueryParams';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';

export const getLabel = (list, value) => {
  const item = list.find((item) => item.value === value);
  return item ? item.label : value;
};


export const HeicImage = ({ url, alt, className }) => {  
  return (
    <img
      src={url}
      alt={alt}
      className={className}
      
    />
  );
};

const WarrantyClaimPage = () => {
  const selected = useTaskCard((state) => state.selected);
  const card = useTaskCard((state) => state.task);
  const selectedCard = useTaskCard((state) => state.selectedCard);
  const setSelected = useTaskCard((state => state.setSelected))
  
  
  const queryParams = useQueryParams();
  const id = queryParams.get('id');
  
  
 
  
  useEffect(() => {
    if (id && !selected && !card ) {
      fetchCardById(id);
    }
  }, [id, selected, card]);

  
  const fetchCardById = async (cardId) => {
    try {
      
      const response = await superagent
        .get(`${backendUrl}/warranty-tickets/${cardId}`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('Accept', 'application/json');
      
      if (response.body) {
        const cardData = {
          id: response.body._id,
          ...response.body
        };
        
        
        selectedCard(cardData);
        setSelected(true)
      }
    } catch (err) {
      console.error('Error fetching card by ID:', err);
    } 
  };

  
  return (
    <div className="container mx-auto px-4 flex flex-col nguyen441">
      <div className="flex justify-end">
        <a href="/warranty-claim">
          <Button className="mb-4">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              className="mr-2"
            >
              <path d="M12 5v14M5 12h14" />
            </svg>
            New Warranty</Button>
        </a>
      </div>
      <div className="overflow-hidden">
        <WarrantyKanbanBoard/>
      </div>
      <Dialog open={selected} onOpenChange={() => {
        selectedCard({})
        setSelected(false)
      }}>
        <DialogContent hideClose className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
          <div className="grid grid-cols-1 gap-8">
            <div className="space-y-6">
              <div className="border-b pb-4 flex justify-between items-start">
                <div>
                  <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
                    <span>Warranty Claim {card?.order?.name && <Link to={`/orders/${card?.order?._id}`}>{card?.order?.name}</Link>}</span>
                    {!card?.hasOrder && (
                      <>
                      {card?.orderNumber && <Badge variant="outline" className="text-sm bg-gray-100 text-gray-800">
                        {card?.orderNumber}
                      </Badge>} 
                      <Badge variant="outline" className="text-sm bg-gray-100 text-gray-800">
                        Pre 2020
                      </Badge>
                     
                      </>
                    )}
                    <Badge variant="outline" className="text-sm bg-green-100 text-green-800">
                      {card?.fulfilled ? "Fulfilled" : "Processing"}
                    </Badge>
                  </h2>
                  <div className="mt-2 flex items-center gap-2 text-sm text-gray-500">
                    <CalendarDays className="h-4 w-4" />
                    <span>Created at: {moment(card?.createdAt).format('DD/MM/YYYY HH:mm')}</span>
                  </div>
                </div>
                <div className="flex gap-2">
      {!card?.closedAt && 
      <CloseTicketButton 
        ticketId={card?._id}
        ticketType="warranty"
        onSuccess={() => {
          selectedCard({})
          setSelected(false)
        }}
      />
}
      <Button 
        variant="ghost" 
        size="sm"
        onClick={() => {
          selectedCard({})
          setSelected(false)
        }}
        className="text-gray-500 hover:text-gray-700"
      >
        ✕
      </Button>
    </div>
              </div>

              
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                  <User className="h-5 w-5" />
                  Customer Information
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <InfoBox label="Customer Name" value={card?.customerName} />
                  <InfoBox
                    label="State"
                    value={getLabel(states, card?.state)}
                  />
                  <InfoBox label="Store Location" value={card?.storeLocation} />
                  <InfoBox label="Staff Member" value={card?.retailMemberName} />
                 
                </div>
              </div>

             
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5 text-red-500" />
                  Warranty Reason
                </h3>
                
                
                <div className="space-y-2">
                  <div className="p-4 bg-red-50 rounded-lg">
                    <div className="font-medium text-red-700">Customer Request:</div>
                    <p className="mt-2 text-sm text-red-600">
                      {card?.customerRequest || 'No special request'}
                    </p>
                  </div>
                  
                  <div className="p-4 bg-yellow-50 rounded-lg">
                    <div className="font-medium text-yellow-800">Fault Reason:</div>
                    <p className="mt-2 text-sm text-yellow-700">
                      {getLabel(FAULT_REASONS, card?.faultReason)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          
            <div className="space-y-6">
              {/* Replacement product list */}
              
              {card?.replacementSkus?.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                  <PackageCheck className="h-5 w-5 text-blue-500" />
                  Replacement Product
                </h3>
                <div className="space-y-3">
                  {card?.replacementSkus?.map((item, index) => (
                    <div key={index} className="p-4 bg-gray-50 rounded-lg">
                      <div className="flex items-center gap-2 text-sm">
                        <span className="line-through text-gray-500">{item?.originalSku}</span>
                        <ArrowRight className="h-4 w-4 text-gray-400" />
                        <span className="font-medium">{item.replacementSku}</span>
                      </div>
                      {item.monogram && item.monogram.type === 'text' && (
                        <div className="mt-2 space-y-2">
                          <div className="flex flex-wrap gap-2">
                            <Badge variant="outline" className="bg-purple-100 text-purple-800">
                              Text: {item.monogram.text}
                            </Badge>
                            <Badge variant="outline">
                              Font: {item.monogram.font}
                            </Badge>
                            <Badge variant="outline">
                              Color: {item.monogram.color}
                            </Badge>
                            <Badge variant="outline">
                              Location: {item.monogram.location}
                            </Badge>
                            <Badge variant="outline"> 
                              Shadow Color: {item.monogram.shadowColor}
                            </Badge>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              )}


              <div className="space-y-4">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                  <Truck className="h-5 w-5 text-green-500" />
                  Shipping Information
                </h3>
                <div className="grid grid-cols-2 gap-4">
                  <InfoBox 
                    label="Shipping Method" 
                    value={card?.toBeShipped ? "Shipping" : card?.pickupLocation?.display_name} 
                    icon={<Truck className="h-4 w-4" />}
                  />
                  {card?.replacementIssuedDate && (
                    <InfoBox 
                      label="Replacement Date" 
                      value={moment(card.replacementIssuedDate).format('DD/MM/YYYY')}
                      icon={<CalendarDays className="h-4 w-4" />}
                    />
                  )}
{card?.originalOrderDate && (
                  <InfoBox 
                  label="Original Order Date" 
                  value={card?.originalOrderDate ? moment(card.originalOrderDate).format('DD/MM/YYYY') : 'N/A'} 
                  icon={<History className="h-4 w-4" />}
                />
              )}
                </div>
              </div>

              {card?.attachments && card.attachments.length > 0 && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Warranty Images</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {card.attachments.map((url, index) => (
                      <HeicImage
                        key={index}
                        url={url}
                        alt={`Warranty image ${index + 1}`}
                        className="rounded-lg object-cover w-full h-48"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WarrantyClaimPage;
