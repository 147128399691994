import { KanbanBoard } from '@components/Kanban/KanbanBoard';
import { Dialog, DialogContent } from '@shadcn/components/ui/dialog';
import { useTaskCard } from '@zustand/useTaskCard';
import moment from 'moment';
import { Button } from '@shadcn/components/ui/button';
import { Card, CardContent, CardHeader } from '@shadcn/components/ui/card';
import { Label } from '@shadcn/components/ui/label';
import { Link } from "react-router-dom";
import { CloseTicketButton } from "@components/shared/CloseTicketButton";
import { Badge } from "@shadcn/components/ui/badge";
import { useEffect, useState } from 'react';
import { useQueryParams } from '@helpers/JIFI/useQueryParams';

import { useHistory } from 'react-router-dom';
import { HeicImage } from '../warranty-claim';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';

const OrderTicketPage = () => {
  const selected = useTaskCard((state) => state.selected);
  const card = useTaskCard((state) => state.task);
  const selectedCard = useTaskCard((state) => state.selectedCard);
  
  
  const history = useHistory();
  const queryParams = useQueryParams();
  const id = queryParams.get('id');
  
  const setSelected = useTaskCard((state => state.setSelected))
  
  
  
  
  useEffect(() => {
    if (id && !selected && !card) {
      fetchCardById(id);
    }
  }, [id, selected, card]);

  
  const fetchCardById = async (cardId) => {
    try {
      
      const response = await superagent
        .get(`${backendUrl}/order-tickets/${cardId}`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('Accept', 'application/json');
      
      if (response.body) {
        const cardData = {
          id: response.body._id,
          ...response.body
        };
        
        
        selectedCard(cardData);
        setSelected(true) 
      }
    } catch (err) {
      console.error('Error fetching order ticket by ID:', err);
    }
  };
  
  const handleCloseDialog = () => {
    selectedCard({});
    setSelected(false)
    
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete('id');
    
    const newSearch = currentParams.toString();
    const currentPath = window.location.pathname;
    const newUrl = newSearch ? `${currentPath}?${newSearch}` : currentPath;
    
    history.replace(newUrl);
  };

  return (
    <div className="container mx-auto px-4 flex flex-col">
      <div className="flex justify-end items-center mb-2">
        
        <a href="/feedback">
          <Button className="bg-primary mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              className="mr-2"
            >
              <path d="M12 5v14M5 12h14" />
            </svg>
            New Ticket
          </Button>
        </a>
      </div>

      <div className="flex-1 min-h-0 overflow-hidden">
        <KanbanBoard />
      </div>
{card  && 
(
      <Dialog open={selected} onOpenChange={handleCloseDialog}>
        <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
          <Card className="mt-4">
            <CardHeader className="pb-3">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <h2 className="text-xl font-semibold">Ticket Details</h2>
                  {!card?.hasOrder && (
                    <>
                    
                    <Badge variant="outline" className="text-sm bg-gray-100 text-gray-800">
                      Pre 2020
                    </Badge>

                   
                    </>
                  )}
                </div>
                {!card?.closedAt && (
                  <CloseTicketButton 
                    ticketId={card?._id}
                    ticketType="order"
                    onSuccess={handleCloseDialog}
                  />
                )}
              </div>
            </CardHeader>
            <CardContent className="space-y-6">
              {/* Due Date Section */}
              <div className="bg-gray-50 rounded-lg p-4 border">
                <div className="flex items-center gap-3 text-primary mb-2">
                  <svg
                    className="w-5 h-5 flex-shrink-0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 6v6l4 2" />
                  </svg>
                  <Label className="font-medium text-base mb-0">Due Date</Label>
                </div>
                <p className="text-gray-700 text-sm">
                  {moment(card?.dayNeededBy).format('DD MMM YYYY, h:mm A')}
                </p>
              </div>

                {/* Order Information */}
                <div className="bg-white rounded-lg border">
                  <div className="p-4 border-b bg-gray-50">
                    <div className="flex items-center gap-3 text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                        <path d="M15 2H9a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                      </svg>
                      <Label className="font-medium text-base mb-0">Order Information</Label>
                    </div>
                  </div>

                  <div className="divide-y">
                    <InfoRow 
                      label="Order Name" 
                      value={card?.order?._id ? (
                        <Link to={`/orders/${card.order._id}`}>
                          {card?.order?.name }
                        </Link>
                      ) : (
                        <span>{card?.orderNumber}</span>
                      )} 
                    />
                    <InfoRow label="Store" value={card?.store?.display_name} />
                    <InfoRow label="Department" value={card?.department} />
                    <InfoRow label="Category" value={card?.category?.name} />
                    <InfoRow label="Customer Name" value={card?.customerName} />
                    <InfoRow label="Pickup Location" value={card?.pickupLocation?.display_name} />
                  </div>
                </div>

                {/* Description Section */}
                <div className="bg-white rounded-lg border">
                  <div className="p-4 border-b bg-gray-50">
                    <div className="flex items-center gap-3 text-primary">
                      <svg
                        className="w-5 h-5 flex-shrink-0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                        <path d="M14 2v6h6" />
                        <path d="M16 13H8" />
                        <path d="M16 17H8" />
                        <path d="M10 9H8" />
                      </svg>
                      <Label className="font-medium text-base mb-0">Description</Label>
                    </div>
                  </div>
                  <div className="p-4">
                    <p className="text-gray-700 whitespace-pre-wrap">
                      {card?.actionDescription}
                    </p>
                  </div>
                </div>

                {/* Attachment Section */}
                {card?.attachments?.length > 0 && (
                  <div className="bg-white rounded-lg border">
                    <div className="p-4 border-b bg-gray-50">
                      <div className="flex items-center gap-3 text-primary">
                        <svg
                          className="w-5 h-5 flex-shrink-0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
                        </svg>
                        <Label className="font-medium text-base mb-0">Attachments</Label>
                      </div>
                    </div>
                    <div className="p-4">
                      <HeicImage

                      className="max-h-[300px] w-auto mx-auto rounded-lg object-contain"
                      url={card?.attachments[0]}
                      alt="Attachment"
                    />
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
      )}
    </div>
  );
};

// Helper component for info rows
const InfoRow = ({ label, value }) => (
  <div className="p-4 hover:bg-gray-50 transition-colors">
    <div className="flex justify-between items-center">
      <span className="text-gray-600 font-medium">{label}</span>
      <span className="text-gray-900">{value || '-'}</span>
    </div>
  </div>
);

export default OrderTicketPage;
