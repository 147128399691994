import { create } from 'zustand'

export const useTaskCard = create((set, get) => ({
  task: undefined,
  tasks: [],
  selected: undefined,
  
  setTasksList: (tasks) => {
    set((state) => ({
      tasks: tasks,
    
    }));
  },
  
  setSelected: (selected) => {
    set((state) => ({
      selected: selected
    }));
  },
  
  selectedCard: (task) => { 
    set((state) => ({
      task: task,
    }));
  },
  
}));
