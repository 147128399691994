import  { useState, useRef , useEffect} from 'react';

import { useReactToPrint } from 'react-to-print';


import { Card } from '@shadcn/components/ui/card';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
} from '@shadcn/components/ui/dialog';
import { backendUrl } from '@helpers/consts';
import { saveAs } from 'save-as';
import moment from 'moment';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import superagent from 'superagent'
import { Button } from '@shadcn/components/ui/button';
import SharedPrintPage from './PrintPage';



const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

const SharedBatchModal = ({
  isVisible,
  togglemodal,
  selectedIds,
  refresh,
  type,
  downloadPdfs,
  location
}) => {
  const [batchID, setBatchID] = useState();
  const [downloadPrintfiles, setDownloadPrintfiles] = useState(false)
  const [loading, setLoading] = useState(false);
  const [downloadLabel, setDownloadLabel] = useState(false)



  const downloadFiles = () => {
    handleDownloadPdfs(selectedIds, `${batchID}.zip`)
    setDownloadPrintfiles(true)
  }


  const handleBatchList = () => {
    handlePrintLabel()
    setDownloadLabel(true)
  }

  function handleDownloadPdfs(lineItems, zipFilename) {
    if(downloadPdfs) {
      return downloadPdfs(lineItems, zipFilename)
    }
    const urls = lineItems.map(lineItem => {
      
      if (!lineItem.pet) {
        return {
          url: `https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(lineItem.monogram_text)}-${lineItem.monogram_color}-${lineItem.monogram_shadow || ''}-undefined${lineItem.sku.startsWith('IPC') ? `-IPC-${lineItem.monogram_position}` : ''}${lineItem.sku.startsWith('BOT') && lineItem.monogram_position === 'Top' ? '-Lid' : ''}.pdf?avoidTheCaches=1`}`,
          filename: `${moment(new Date(lineItem.orderSent)).format('YYYY-MM-DD')}-${lineItem.monogramID}.pdf`,
          pet: false
        }
      } else {
        const petArray = JSON.parse(atob(lineItem.monogram_pets))

        return {
          petData: lineItem.monogram_airtable_line.map((val, key) => {
            return {
              id: val, 
              filename: `${moment(new Date(lineItem.orderSent)).format('YYYY-MM-DD')}-${lineItem.monogramID}-pet#${key}-${petArray[key].name}`
            }
          }),
          pet: true
        }
      }
    })
    const zip = new JSZip();
    let count = 0;
    urls.forEach(async function (url){
      if (url.pet === false) {
        const filename = url.filename
        try {
          const file = await JSZipUtils.getBinaryContent(url.url)
          zip.file(filename, file, { binary: true});
          count++;
          if(count === urls.length) {
            zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);
            });
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        let images = []
        try {
          for(let pet of url.petData) {
            const res = (await superagent.post(`${backendUrl}/orders/fetchPetAirtable`)
                                        .send({ 
                                          airtableId: pet.id
                                        })
                                        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
                                        .set('accept', 'json')).body.fields
            const completedIllustration = res['Completed illustration']
  
            images = [...completedIllustration.map((val, comKey) => {
              let filetype = val.filename.split('.')[1]
              let path = val.url
  
              return {
                url: path, 
                filename: `${pet.filename}-${comKey}.${filetype}`
              }
            })]
  
            await sleep(500)
            console.log(images)
          }
          images.forEach(async function (url){
            const filename = url.filename
            try {
              const file = await JSZipUtils.getBinaryContent(url.url)
              zip.file(filename, file, { binary: true});
              if(count === urls.length) {
                zip.generateAsync({type:'blob'}).then(function(content) {
                  saveAs(content, zipFilename);
                });
              }
            } catch (err) {
              console.log(err);
            }

          })
        } catch (err) {
          console.log(err);
        } 
        count++;
      }
    });
}
const packingLabelsRef = useRef();
const printLabels = useReactToPrint({
  content: () => packingLabelsRef.current,
});
const handlePrintLabel = () => printLabels();



const createBatch = () => {
  superagent.post(`${backendUrl}/orders/createPersonalisationBatch`)
    .send({ 
      lineItems: selectedIds,
      location: location
    })
    .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
    .set('accept', 'json')
    .on('error', (response) => {
      setLoading(false)
    })
    .then(response => {
      const code = response.status
      if (code > 400) {
      } else {
        togglemodal()
        refresh()
      }
    }).catch(err => {
      console.log(err)
      setLoading(false)
    });

}

useEffect(() => {
  if (isVisible === true) {
    setLoading(true)
    const url = getEndpoint()
    superagent.get(url)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.text)
          setBatchID(response.text)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }
}, [ isVisible ])

  const getEndpoint = () => {
    const endpoints = {
      pers: `${backendUrl}/orders/getPersonalisationBatchID`,
      airbox: `${backendUrl}/orders/getPersonalisationBatchIDAirbox`,
      ewe: `${backendUrl}/orders/getPersonalisationBatchIDEWE`,
      ewesyd: `${backendUrl}/orders/getPersonalisationBatchIDEWESYD`,
      launch: `${backendUrl}/orders/getPersonalisationBatchIDLaunch`,
    };
    return endpoints[type];
  };

  return (
    <Dialog open={isVisible} onOpenChange={togglemodal}>
      <DialogContent>
        <DialogTitle>Confirm Batch {batchID}</DialogTitle>

        <div>
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              
              }}
            >
              <div className="spinner-grow text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
            <Card className="py-4 mt-4" style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Button
                  variant={downloadPrintfiles ? 'secondary' : 'primary'}
                  className="m-2"
                  onClick={downloadFiles}
                >
                  {downloadPrintfiles && (
                    <i className="mdi mdi-sticker-check-outline mr-1" />
                  )}
                  Download Print Files
                </Button>
                <Button
                  variant={downloadLabel ? 'secondary' : 'primary'}
                  className="m-2"
                  onClick={handleBatchList}
                >
                  {downloadLabel && (
                    <i className="mdi mdi-sticker-check-outline mr-1" />
                  )}
                  Print Batch list
                </Button>
              </div>
            </Card>
            <DialogFooter>
              <div className='mt-3' style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '50%'}}>
                <Button type="button" variant={downloadPrintfiles && downloadLabel ? 'success' : 'secondary'} onClick={createBatch}>Create Batch</Button>
              </div>
          </DialogFooter>
            </div>
          )}
         
        </div>
        <div style={{display: 'none'}}>
        <SharedPrintPage 
          ref={packingLabelsRef}
          monogramRecord={{
            batchID: batchID, 
            lineItems: selectedIds
          }} 
          monogram={false}
        />
      </div>
      </DialogContent>
    </Dialog>
  );
};

export default SharedBatchModal;
