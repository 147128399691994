import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@shadcn/components/ui/dialog';
import { Button } from '@shadcn/components/ui/button';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import { toast } from 'react-toastify';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';
import ProductVariantMultiSelector from '@pages/JIFI/helpers/ProductVariantMultiSelector';
import useQuery from '@helpers/JIFI/useQuery';

const CreateMappingModal = ({ open, onClose, onSuccess, initialValues = [] }) => {
  const refreshRef = useRef(true);
  const [mappings, setMappings] = useState([]);
  const [productData, productState] = useQuery({
    url: `/product`,
    refresh: refreshRef.current
  });

  const isEditing = useMemo(() => {
    return initialValues && initialValues.length > 0;
  }, [initialValues]);

  const products = productData?.data || [];

  const getMappedData = useCallback((item) => {
    if (!item || !products.length) return null;

    const mainVariant = products.flatMap(p => p.variants)
      .find(v => v.sku === item.sku);
    
    const mapVariants = (skus) => {
      return (skus || []).map(sku => {
        const variant = products.flatMap(p => p.variants)
          .find(v => v.sku === sku);
        return variant ? {
          sku: sku,
          name: variant.name,
          product: variant.product?.name
        } : { sku: sku, name: sku };
      });
    };

    return {
      sku: item.sku,
      variant: mainVariant ? {
        sku: mainVariant.sku,
        name: mainVariant.name,
        product: mainVariant.product?.name
      } : { sku: item.sku, name: item.sku },
      setSkus: mapVariants(item.setSkus),
      doubleSkus: mapVariants(item.doubleSkus)
    };
  }, [products]);

  const initialMappings = useMemo(() => {
    const values = Array.isArray(initialValues) ? initialValues : [initialValues];
    
    if (values.length > 0 && products.length > 0) {
      return values.map(getMappedData).filter(Boolean);
    }
    
    return [{
      sku: '',
      variant: null,
      setSkus: [],
      doubleSkus: []
    }];
  }, [initialValues, products, getMappedData]);

  useEffect(() => {
    setMappings(initialMappings);
  }, [initialMappings]);

  const handleAddMapping = () => {
    setMappings([...mappings, {
      sku: '',
      variant: null,
      setSkus: [],
      doubleSkus: []
    }]);
  };

  const editRow = (index, field, value) => {
    const newMappings = [...mappings];
    
    if (field === 'variant') {
      
      newMappings[index].sku = value?.sku || '';
      newMappings[index].variant = value;
    } else if (field === 'setSkus' || field === 'doubleSkus') {
      if (!value) {
      
        newMappings[index][field] = [];
      } else if (!Array.isArray(value)) {
      
        newMappings[index][field] = [{
          sku: value.sku,
          name: value.name,
          product: value.product
        }];
      } else {
      
        newMappings[index][field] = value.map(v => ({
          sku: v.sku,
          name: v.name,
          product: v.product
        }));
      }
    }
    setMappings(newMappings);
  };

  const removeRow = (index) => {
    setMappings(mappings.filter((_, i) => i !== index));
  };

  const validateMappings = () => {
    const errors = [];
    
    mappings.forEach((mapping, index) => {
      if (!mapping.sku) {
        errors.push(`Row ${index + 1}: SKU is required`);
      }
      
      if (!mapping.setSkus?.length && !mapping.doubleSkus?.length) {
        errors.push(`Row ${index + 1}: At least one Set SKU or Double SKU is required`);
      }
    });

    return errors;
  };

  const handleSubmit = async () => {
    const validationErrors = validateMappings();
    
    if (validationErrors.length > 0) {
      validationErrors.forEach(error => {
        toast.error(error);
      });
      return;
    }

    try {
      const response = await superagent
        .post(`${backendUrl}/sku-mapping/bulk-create`)
        .send( 
           mappings.map(m => ({
            sku: m.sku,
            setSkus: m.setSkus.map(s => s.sku),
            doubleSkus: m.doubleSkus.map(s => s.sku)
          }))
        )
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`);

      toast.success(`SKU mappings ${isEditing ? 'updated' : 'created'} successfully`);
      onSuccess();
      onClose();
    } catch (err) {
      toast.error(`Failed to ${isEditing ? 'update' : 'create'} SKU mappings`);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="z-[1600] max-w-[95vw] sm:min-w-[60vw] lg:min-w-[50vw] xl:min-w-[40vw]">
        <DialogHeader>
          <DialogTitle className="text-lg sm:text-xl">
            {isEditing ? 'Edit SKU Mapping' : 'Create New SKU Mapping'}
          </DialogTitle>
        </DialogHeader>

        <Card className="max-h-[80vh]">
          <CardBody className="p-2 sm:p-4 space-y-4">
            <Row className="hidden sm:flex pt-4">
              <Col className="sm:w-4/12">Variant <span className="text-red-500">*</span></Col>
              <Col className="sm:w-3/12">Set SKUs <span className="text-red-500">*</span></Col>
              <Col className="sm:w-3/12">Double SKUs <span className="text-red-500">*</span></Col>
              <Col className="sm:w-2/12">Actions</Col>
            </Row>
            
            {mappings.map((mapping, index) => (
              <Row 
                key={index} 
                className="flex flex-col sm:flex-row gap-4 sm:gap-2 p-2 sm:p-0 border-b sm:border-none"
              >
                <Col className="w-full sm:w-4/12 mb-2 sm:mb-0">
                  <ProductVariantMultiSelector
                    id={`variant-${index}`}    
                    hidePersonalisationOptions
                    showSku={true}
                    products={products}
                    setSelectedVariant={(val) => editRow(index, 'variant', val)}
                    selectedVariant={mapping.variant}
                  />
                </Col>

                <Col className="w-full sm:w-3/12 mb-2 sm:mb-0">
                  <ProductVariantMultiSelector
                    id={`setSkus-${index}`}
                    hidePersonalisationOptions
                    products={products}
                    isMulti
                    showSku={true}
                    setSelectedVariant={(vals) => editRow(index, 'setSkus', vals)}
                    selectedVariant={mapping.setSkus} 
                  />
                </Col>

                <Col className="w-full sm:w-3/12 mb-2 sm:mb-0">
                  <ProductVariantMultiSelector
                    id={`doubleSkus-${index}`}
                    hidePersonalisationOptions
                    showSku={true}
                    products={products}
                    isMulti
                    setSelectedVariant={(vals) => editRow(index, 'doubleSkus', vals)}
                    selectedVariant={mapping.doubleSkus}
                  />
                </Col>

                <Col className="w-full sm:w-2/12 flex justify-center sm:justify-start">
                  <Button
                    variant="destructive"
                    onClick={() => removeRow(index)}
                    size="sm"
                    className="w-full sm:w-auto"
                  >
                    X
                  </Button>
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>

        <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between mt-4">
          <Button 
            onClick={handleAddMapping}
            className="w-full sm:w-auto"
          >
            Add New Row
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={productState === 'loading'}
            className="w-full sm:w-auto"
          >
            {isEditing ? 'Save Changes' : 'Create Mapping'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateMappingModal;
