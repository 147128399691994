import { RepairKanbanBoard } from '@components/RepairKanban/RepairKanbanBoard';
import { Dialog, DialogContent } from '@shadcn/components/ui/dialog';
import { useTaskCard } from '@zustand/useTaskCard';
import moment from 'moment';
import { Badge } from '@shadcn/components/ui/badge';
import { CalendarDays, User, History, Wrench, NotebookPen, AlertCircle, ImageIcon, Store } from 'lucide-react';

import { Button } from '@shadcn/components/ui/button';
import { Link, useHistory } from 'react-router-dom';
import { CloseTicketButton } from '@components/shared/CloseTicketButton';
import { HeicImage } from '../warranty-claim';
import {  useEffect } from 'react';
import { useQueryParams } from '@helpers/JIFI/useQueryParams';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';

export const getLabel = (list, value) => {
  const item = list.find((item) => item.value === value);
  return item ? item.label : value;
};

const RepairTicketPage = () => {
  const selected = useTaskCard((state) => state.selected);
  const card = useTaskCard((state) => state.task);
  const selectedCard = useTaskCard((state) => state.selectedCard);
  
  
  const history = useHistory();
  const setSelected = useTaskCard((state => state.setSelected))
  const queryParams = useQueryParams();
  const id = queryParams.get('id');
  
  

  
  useEffect(() => {
    if (id && !selected && !card) {
      fetchCardById(id);
    }
  }, [id, selected, card]);

  
  
  const fetchCardById = async (cardId) => {
    try {
      
      const response = await superagent
        .get(`${backendUrl}/repair-tickets/${cardId}`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('Accept', 'application/json');
      
      if (response.body) {
        const cardData = {
          id: response.body._id,
          ...response.body
        };
        
        
        selectedCard(cardData);
        setSelected(true)
      }
    } catch (err) {
      console.error('Error fetching repair ticket by ID:', err);
    }
  };
  
  const handleCloseDialog = () => {
    selectedCard({});
    setSelected(false)
    
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.delete('id');
    
    const newSearch = currentParams.toString();
    const currentPath = window.location.pathname;
    const newUrl = newSearch ? `${currentPath}?${newSearch}` : currentPath;
    
    history.replace(newUrl);
  };
  
  return (
    <div className="container mx-auto px-4 flex flex-col">
      <div className="flex justify-end">
        <a href="/repair-ticket">
          <Button className="mb-4">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              className="mr-2"
            >
              <path d="M12 5v14M5 12h14" />
            </svg>
            New Repair Ticket</Button>
        </a>
      </div>
      <div className="overflow-hidden">
        <RepairKanbanBoard />
      </div>
      <Dialog open={selected} onOpenChange={() => {
        selectedCard({})
        setSelected(false)
      }}>
        <DialogContent hideClose className="z-[1600] max-h-[80vh] overflow-y-auto w-full mx-2 min-w-[60vw]">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl md:text-2xl font-bold flex flex-wrap items-center gap-2">
              {(card?.order?.name || card?.orderNumber) 
              
              && (

                card?.order?._id ?
                <Link 
                  to={`/orders/${card?.order?._id}`}
                  className="hover:text-blue-600 transition-colors break-all"
                >
                  {card?.order?.name }
                </Link> :
                <span className="transition-colors break-all">
                  {card?.orderNumber}
                </span>
              )}
              {!card?.hasOrder && (
                <> 
                
                {!card?.orderNumber && 
                <Badge variant="outline" className="text-xs md:text-sm bg-gray-100 text-gray-800">
                  Pre 2020
                </Badge>}
               
                </>
              )}
              <Badge variant="outline" className="text-xs md:text-sm px-2 py-0.5 md:px-3 md:py-1 bg-green-100 text-green-800">
                {card?.completed ? "Completed" : "In Progress"}
              </Badge>
            </h2>
           <div className="flex gap-2">
            {!card?.closedAt &&
      <CloseTicketButton 
        ticketId={card?._id}
        ticketType="repair"
        onSuccess={handleCloseDialog}
      />
            }
      <Button 
        variant="ghost" 
        size="sm" 
        onClick={() => selectedCard({})}
        className="text-gray-500 hover:text-gray-700"
      >
        ✕
      </Button>
    </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 p-2 md:p-4 md:gap-6">
            
            <div className="space-y-4 md:space-y-6">
              <div className="bg-white p-3 md:p-6 rounded-lg md:rounded-xl shadow-sm border">
                <div className="flex flex-col md:flex-row md:items-start justify-between gap-3">
                  <div className="flex-1">
                    <div className="mt-2 flex flex-col sm:flex-row gap-2 text-xs md:text-sm">
                      <div className="flex items-center gap-2 bg-gray-100 px-2 py-1 rounded-full">
                        <Store className="h-3 w-3 md:h-4 md:w-4 text-gray-500" />
                        <span className="font-medium truncate">{card?.store}</span>
                      </div>
                      {/* Staff Info */}
                      {card?.staffName && (
                        <div className="flex items-center gap-2 bg-gray-100 px-2 py-1 rounded-full">
                          <User className="h-3 w-3 md:h-4 md:w-4 text-gray-500" />
                          <span className="truncate">
                            <span className="text-gray-400">Staff:</span> {card.staffName}
                          </span>
                        </div>
                      )}
                      {/* Customer Info */}
                      {card?.customerName && (
                        <div className="flex items-center gap-2 bg-blue-50 px-2 py-1 rounded-full border border-blue-100">
                          <User className="h-3 w-3 md:h-4 md:w-4 text-blue-600" />
                          <span className="truncate text-blue-800">
                            <span className="text-blue-600">Customer:</span> {card.customerName}
                          </span>
                        </div>
                      )}
                    </div>
                    
                  </div>
                
                </div>

                    {card?.order?.name && (
                    <div className="mt-4 md:mt-6 space-y-3 md:space-y-4">
                      <h3 className="text-base md:text-lg font-semibold flex items-center gap-2 text-blue-600">
                        <Wrench className="h-4 w-4 md:h-5 md:w-5" />
                        Repair Details
                      </h3>
                      
                      
                   

                      {card?.itemRepairs?.map((repair, index) => (
                        <div key={index} className="bg-gray-50 p-2 md:p-4 rounded-lg">
                          <div className="flex flex-col sm:flex-row sm:items-center gap-2 mb-2 md:mb-3">
                            <Badge className="text-xs md:text-sm bg-blue-100 text-blue-800">{repair.sku}</Badge>
                            <div className="flex flex-wrap gap-1 md:gap-2">
                              {repair.repairTypes.map((type, i) => (
                                <Badge key={i} className="text-xs md:text-sm bg-red-100 text-red-800">
                                  {type.replace(/_/g, ' ')}
                                </Badge>
                              ))}
                            </div>
                          </div>
                          
                          {repair.parts?.length > 0 && (
                            <div className="pt-2 md:pt-3 border-t border-gray-200">
                              <h4 className="text-xs md:text-sm font-medium text-gray-700 mb-1 md:mb-2">Required Parts:</h4>
                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 md:gap-2">
                                {repair.parts.map((part, i) => (
                                  <div key={i} className="bg-orange-50 p-1.5 md:p-2 rounded flex items-center justify-between">
                                    <span className="text-xs md:text-sm truncate">{part.name}</span>
                                    <Badge variant="outline" className="text-xs md:text-sm bg-orange-100 text-orange-800">
                                      x{part.quantity}
                                    </Badge>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>)}
                  </div>
                    

                  {/* Notes Section */}
                  {card?.additionalNotes && (
                    <div className="bg-white p-3 md:p-6 rounded-lg md:rounded-xl shadow-sm border">
                      <h3 className="text-base md:text-lg font-semibold flex items-center gap-2 text-gray-700 mb-2 md:mb-4">
                        <NotebookPen className="h-4 w-4 md:h-5 md:w-5" />
                        Additional Notes
                      </h3>
                      <div className="prose prose-sm max-w-none text-gray-600 whitespace-pre-wrap break-words">
                        {card.additionalNotes}
                      </div>
                    </div>
                  )}

                  {card?.pickupLocation && (
                    <div className="bg-white p-3 md:p-6 rounded-lg md:rounded-xl shadow-sm border">
                      <h3 className="text-base md:text-lg font-semibold flex items-center gap-2 text-gray-700 mb-2 md:mb-4">
                        <Store className="h-4 w-4 md:h-5 md:w-5" />
                        Pickup Location
                      </h3>
                      <div className="flex items-center gap-2 text-sm text-gray-600">
                        <Badge variant="outline" className="bg-blue-50">
                          {card.pickupLocation.display_name}
                        </Badge>
                        <span className="text-gray-400">•</span>
                        <span>{card.pickupLocation.code}</span>
                      </div>
                    </div>
                  )}
                </div>

                {/* Right Column */}
                <div className="space-y-4 md:space-y-6">
                  {/* Order Timeline */}
                  <div className="bg-white p-3 md:p-6 rounded-lg md:rounded-xl shadow-sm border">
                    <h3 className="text-base md:text-lg font-semibold flex items-center gap-2 text-gray-700 mb-3 md:mb-4">
                      <History className="h-4 w-4 md:h-5 md:w-5" />
                      Order Timeline
                    </h3>
                    <div className="space-y-3 md:space-y-4">
                      <div className="flex items-center gap-2 md:gap-3">
                        <div className="flex-shrink-0 w-6 md:w-8">
                          <CalendarDays className="h-4 w-4 md:h-5 md:w-5 text-gray-400" />
                        </div>
                        <div>
                          <p className="text-xs md:text-sm font-medium">Original Order Date</p>
                          <p className="text-xs md:text-sm text-gray-500">
                            {moment(card?.order?.orderCreated).format('DD MMM YYYY')}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 md:gap-3">
                        <div className="flex-shrink-0 w-6 md:w-8">
                          <AlertCircle className="h-4 w-4 md:h-5 md:w-5 text-orange-400" />
                        </div>
                        <div>
                          <p className="text-xs md:text-sm font-medium">Repair Requested</p>
                          <p className="text-xs md:text-sm text-gray-500">
                            {moment(card?.repairDate).format('DD MMM YYYY')}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 md:gap-3">
                        <div className="flex-shrink-0 w-6 md:w-8">
                          <CalendarDays className="h-4 w-4 md:h-5 md:w-5 text-blue-400" />
                        </div>
                        <div>
                          <p className="text-xs md:text-sm font-medium">Date Needed</p>
                          <p className="text-xs md:text-sm text-gray-500">
                            {moment(card?.dateNeeded).format('DD MMM YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

              {/* Attachments */}
              {card?.attachments?.length > 0 && (
                <div className="bg-white p-3 md:p-6 rounded-lg md:rounded-xl shadow-sm border">
                  <h3 className="text-base md:text-lg font-semibold flex items-center gap-2 text-gray-700 mb-3 md:mb-4">
                    <ImageIcon className="h-4 w-4 md:h-5 md:w-5" />
                    Attachments
                  </h3>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 md:gap-3">
                    {card.attachments.map((url, index) => (
                      <a 
                        key={index} 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="group relative block"
                      >
                        <HeicImage
                          key={index}
                          url={url}
                          alt={`Attachment ${index + 1}`}
                          className="rounded-md md:rounded-lg object-cover w-full h-20 md:h-28 border transition-transform group-hover:scale-105"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 transition-all rounded-md md:rounded-lg" />
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RepairTicketPage;
