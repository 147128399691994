import { useState } from 'react';



// Thêm import Dialog components
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@shadcn/components/ui/dialog';
import  WarrantyClaim from './warranty-claim';
import { ToastContainer } from 'react-toastify';


export default function WarrantyClaimForm({order}) {
  const [open, setOpen] = useState(false);
  return (
   
      <>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <p className='px-2 py-[6px] font-[14px]'>
              Create Warranty Claim
            </p>
          </DialogTrigger>
          <DialogContent className="z-[1500] max-h-[80vh] overflow-y-auto min-w-[60vw]">
            <DialogHeader>
              <DialogTitle>Create Warranty Claim</DialogTitle>
            </DialogHeader>
          <WarrantyClaim
          initialOrder={order}
          onModalChange={() => setOpen(false)}
          isForm={true}></WarrantyClaim>
          </DialogContent>
        </Dialog>
        <ToastContainer />
      </>
    
  );
}
