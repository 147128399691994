import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const OrderViewersFab = () => {
  const [ws, setWs] = useState(null);
  const [viewers, setViewers] = useState([]);
  const { orderID } = useParams();

  
  const viewerColors = useMemo(() => {
    const colors = {};
    viewers.forEach((viewer) => {
      if (!colors[viewer.email]) {
        colors[viewer.email] = '#' + Math.floor(Math.random()*16777215).toString(16);
      }
    });
    return colors;
  }, [viewers.map(v => v.email).join(',')]); 

  useEffect(() => {
    const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
    
    const socket = new WebSocket('wss://36z739qzy4.execute-api.ap-southeast-2.amazonaws.com/dev');

    socket.onopen = () => {
      const payload = {
        action: "viewOrder",
        orderId: orderID,
        userInfo: {
          username: authUser.userName,
          email: authUser.email,
          avatar: authUser.profile_image
        }
      };
      socket.send(JSON.stringify(payload));
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "VIEWER_UPDATE") {
        setViewers(data.viewers);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    setWs(socket);

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [orderID]);

  return (
    <div className="flex items-center gap-2">
      {viewers.map((viewer) => (
        <div
          key={viewer.email}
          className="relative group -ml-1 first:ml-0"
        >
          <div
            className="w-[30px] h-[30px] rounded-full border-2 border-white overflow-hidden"
            title={viewer.username}
          >
            {viewer.avatar ? (
              <img 
                src={viewer.avatar}
                alt={viewer.username}
                className="w-full h-full object-cover"
              />
            ) : (
              <div
                className="w-full h-full flex items-center justify-center text-white font-bold"
                style={{
                  backgroundColor: viewerColors[viewer.email],
                  fontSize: '12px'
                }}
              >
                {viewer.username.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div 
            className="absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs px-2 py-1 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-50"
          >
            {viewer.username}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderViewersFab;
