import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardHeader, CardContent } from "@shadcn/components/ui/card";
import { Button } from "@shadcn/components/ui/button";
import { cva } from "class-variance-authority"; 
import {  CalendarDays,  Truck, CheckCircle2, Store ,User} from "lucide-react"; 
import { Badge } from "@shadcn/components/ui/badge";


import { useTaskCard } from "@zustand/useTaskCard";
import { useEffect } from "react";




const statusColors = {
    todo: 'red',
    'in-progress': 'yellow',
    complete: 'green'
};

const priorityColors = {
    high: 'bg-red-100 text-red-800',
    medium: 'bg-yellow-100 text-yellow-800',
    low: 'bg-gray-100 text-gray-800'
};

export function TaskCard({ task, isOverlay }) {
    
    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: task.id, 
        data: {
            type: "Task",
            task, 
        },
        attributes: {
            roleDescription: "Task",
        },
    });

    const selectedCard = useTaskCard ((state) => state.selectedCard);
    const selected = useTaskCard((state => state.selected))
    const taskCard  = useTaskCard((state => state.task))
    const setSelected = useTaskCard((state => state.setSelected))
    useEffect(() => {
        if(task.selected) {
            if(selected === undefined) {
                selectedCard({...task})
                setSelected(true)
            }
        }
    },[])
    
    const style = {
        transition, 
        transform: CSS.Translate.toString(transform),
     
      };

      const cardClass = task?.id === taskCard?.id
        ? "border-2 border-black shadow-lg"
        : "bg-white border border-gray-300 shadow-sm";

   
    const variants = cva("", {
        variants: {
            dragging: {
                over: "ring-2 opacity-30", 
                overlay: "ring-2 ring-primary",
            },
        },
    });
    const formattedRepairDate = task.repairDate ? new Date(task.repairDate).toLocaleDateString('en-AU', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    }) : '';

    const formattedDateNeeded = task.dateNeeded ? new Date(task.dateNeeded).toLocaleDateString('en-AU', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    }) : '';

    return (
        <Card
            onClick={() => {
                selectedCard(task)
                setSelected(true)
            }}
            ref={setNodeRef}
            style={style}
            className={`${cardClass} cursor-pointer ${variants({
                dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
            })} transition-all duration-300 rounded-lg hover:shadow-md border-l-4 border-${statusColors[task.status]}-500 relative max-w-[320px]`}
        >
            <CardHeader 
                {...attributes}
                {...listeners}
                className="px-3 py-2 flex flex-row items-center justify-between border-b cursor-grab"
            >
                <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2 px-2">
                        <Store className="h-3.5 w-3.5 text-blue-500" />
                        <span className="font-medium text-sm text-blue-700">
                            {task.store}
                        </span>
                        <span className="text-xs text-gray-400">•</span>
                        <span className="text-xs text-gray-600">{task.staffName}</span>
                    </div>
                    
                    { task.pickupLocation && (
                        <div className="flex items-center gap-1.5 bg-purple-50 px-2 py-1 rounded-full">
                            <Store className="h-3.5 w-3.5 text-purple-600" />
                            <span className="text-xs text-purple-700 font-medium">
                                Pickup: {task?.pickupLocation?.display_name} ({task?.pickupLocation?.code})
                            </span>
                        </div>
                    )}


                    {task.customerName && (
                        <div className="flex items-center gap-2 text-sm px-2">
                            <User className="h-4 w-4 text-gray-400" />
                            <span className="font-medium text-gray-700">{task.customerName}</span>
                        </div>
                    )}

                  

                    <div className="flex items-center gap-2">
                        {task?.order?.name && 
                        <Badge variant="outline" className="text-xs py-0 px-2 border-blue-200 bg-blue-50 text-blue-600">
                            {task?.order?.name}
                        </Badge>
}
                        {!task?.hasOrder && (
                            <>
                            {task?.orderNumber && <Badge variant="outline" className="text-xs bg-gray-100 text-gray-800">
                                {task?.orderNumber}
                            </Badge>}
                            <Badge variant="outline" className="text-xs bg-gray-100 text-gray-800">
                                Pre 2020
                            </Badge>
                            </>
                        )}
                       
                         
                        {task.completed && (
                            
                            <CheckCircle2 className="h-5 w-5 text-green-500 bg-white rounded-full" />
                            
                        )}
                    </div>

                    
                </div>
                <div className="flex items-center gap-2">
                    {task.toBeShipped && <Truck className="h-4 w-4 text-blue-500" />}
                    {task.fulfilled && <CheckCircle2 className="h-4 w-4 text-green-500" />}
                </div>
            </CardHeader>

            <CardContent className="p-3 space-y-2">
                {task.itemRepairs?.length > 0 && (
                    <div className="flex flex-wrap gap-1.5">
                        {task.itemRepairs.map((repair, index) => (
                            <div key={index} className="bg-gray-50 px-2 py-1 rounded-md">
                                <div className="flex items-center gap-1.5">
                                    <Badge variant="outline" className="text-[11px] py-0 px-1.5 bg-white">
                                        {repair.sku}
                                    </Badge>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className="flex items-center justify-between text-[11px] text-gray-600">
                    <div className="flex flex-col items-center gap-1.5">
                      
                        {task.dateNeeded && (
                            <div className="mt-1 flex items-center gap-1 bg-gray-100 px-1.5 py-0.5 rounded">
                                <CalendarDays className="h-3.5 w-3.5 text-gray-500" />
                                <span>Needed:</span>
                                <span className="font-medium">{formattedDateNeeded}</span>
                            </div>
                        )}
                    </div>
                    {task.additionalNotes && (
                        <div 
                            className="relative group max-w-[120px] truncate text-gray-500 hover:text-gray-700 cursor-help"
                            onClick={(e) => {
                                e.stopPropagation();
                                
                            }}
                        >
                            <span className="border-b border-dashed border-gray-300">
                                {task.additionalNotes.length > 20 
                                    ? `${task.additionalNotes.substring(0, 20)}...`
                                    : task.additionalNotes}
                            </span>
                            <div className="hidden group-hover:block absolute bottom-full left-0 bg-white p-2 text-xs rounded shadow-lg border border-gray-200 max-w-[200px] break-words">
                                {task.additionalNotes}
                            </div>
                        </div>
                    )}
                </div>
            </CardContent>

           
        </Card>
    );
}
