import { useForm } from 'react-hook-form';
import { Input } from '@shadcn/components/ui/input';
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select';
import useQuery from '../../../helpers/JIFI/useQuery';

import { Button } from '@shadcn/components/ui/button';
import { Label } from '@shadcn/components/ui/label';
import { Card, CardContent, CardHeader } from '@shadcn/components/ui/card';
import { Checkbox } from "@shadcn/components/ui/checkbox";
import { useState, useEffect, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';
import { CommandSearch } from '../order-ticket/feedback';
import ProductVariantSelector from '../helpers/ProductVariantSelector';
import useProductData from '../orders/useProductData';
import SelectItemsModal from './SelectItemsModal';
import { Textarea } from '@shadcn/components/ui/textarea';
import { uploadFileS3Bucket } from '@utilities/uploadFileS3Bucket';
import moment from 'moment';

import WizardNav from '@pages/JIFI/orders/Wizard/WizardNav';
import PetSection from './components/PetSection';


export const states = [
  { value: 'VIC', label: 'Victoria' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'WA', label: 'Western Australia' },

];


const monogramTypes = {
  text: 'Text',
  pets: 'Pets'
};

export const fonts = {
  heritage: 'Heritage',
  'bold-new': 'Bold (new)', 
  bubble: 'Bubble',
  'retro-serif': 'Retro Serif',
  graphic: 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',
  'love-letter': 'Love Letter',
  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif'
};

export const newColors = {
  Lavender: '#90759B',
  Lilac: '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  Grenaine: '#9E5360',
  'Blush-Pink': '#E77472',
  Magenta: '#B12D52',
  Maroon: '#731A29',
  Red: '#C92327',
  'Burnt-Orange': '#DC5527',
  Mango: '#EF7E2E',
  Rust: '#9B4827',
  Toffee: '#705037',
  Taupe: '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  Yellow: '#E8B629',
  Lime: '#C6B937',
  Green: '#4A813C',
  'Forest-Green': '#034123',
  Mint: '#78AB93',
  'Ice-Blue': '#BEE3E9',
  Denim: '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  Blue: '#1466B2',
  Grey: '#95979E',
  'Slate-Grey': '#63727E',
  Black: '#000000',
  White: '#FFFFFF'
};

export const locations = {
  Top: 'Top',
  Side: 'Side', 
  Back: 'Back'
};


const priorities = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];


export const FAULT_REASONS = [
  { value: 'crack', label: 'Crack' },
  { value: 'zip', label: 'Zip' },
  { value: 'lock', label: 'Lock' },
  { value: 'unable_to_repair', label: 'Unable to Repair' },
  { value: 'stitching', label: 'Stitching' },
  { value: 'other', label: 'Other' }
];





const createWarrantyTicket = async (data) => {
  try {
    
    const response = await superagent
      .post(`${backendUrl}/warranty-tickets`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .send(data);

    if (response.status >= 200 && response.status < 300) {
      toast.success('Warranty ticket created successfully');
    } else {
      toast.error('Failed to create warranty ticket');
    }
  } catch (error) {
    toast.error(`Error: ${error.message}`);
  }
};

export default function WarrantyClaim({ initialOrder=undefined, isForm = false, onModalChange, isWizard = false }) {
  
  const [linkToOrder, setLinkToOrder] = useState(true);
  
  const showOldOrderCheckbox = !isForm && !isWizard;  
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    
    defaultValues: {
      customerName: '',
      originalOrderDate: '',
      order: initialOrder,
      toBeShipped: true,
      orderNumber: '',
      fulfilled: false,
      replacementSkus: [],
      selectedItems: [],
      monogramData: [],
      storeLocation: '',
      pickupLocation: undefined,
      replacementIssuedDate: '',
      faultReason: '',
      customerRequest: '',
      hasOrder: !!initialOrder,
    }
  });

  


  const [loading, setLoading] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  
  const toBeShipped = watch('toBeShipped');
  const order = watch('order');
  const selectedItems = watch('selectedItems');

  const getVariant = useProductData();
  const [data] = useQuery({ url: `/product`, refresh: false });
  const products = data?.data;

  const [monogramData, setMonogramData] = useState([]);
  
  

  const [retailStores] = useQuery({
    url: '/inventory/getRetailStore',
  });


  const [replacementSkus, setReplacementSkus] = useState([]);

  

  

  
  useEffect(() => {
    if (initialOrder) {
      setValue('customerName', initialOrder.customer?.first_name + ' ' + initialOrder.customer?.last_name);
      setValue('originalOrderDate', moment(initialOrder.createdAt).format('YYYY-MM-DD'));
      setValue('order', initialOrder);
      
      // Prefill selected items từ line_items của order
      const itemsToSelect = initialOrder.line_items.map(item => ({
        sku: item.sku,
        quantity: item.quantity
      }));
      setValue('selectedItems', itemsToSelect);
    }
  }, [initialOrder, setValue]);

  useEffect(() => {
    
    if (initialOrder === undefined && order?.line_items && linkToOrder) {
      setIsSelectModalOpen(true);
    }
  }, [order, linkToOrder]);

  const isPersonalizedSKU = (sku) => {
    return sku?.split('-')
      .filter((val) => val !== 'P' && val !== 'LE')
      .join('-') !== sku;
  };

  
  const handleMonogramDataChange = (index, field, value) => {
    setMonogramData(prev => {
      const newData = [...prev];
      if (!newData[index]) {
        newData[index] = {};
      }
      newData[index][field] = typeof value === 'function' ? value(newData[index][field]) : value;
      return newData;
    });
    setValue(`monogramData.${index}.${field}`, value);
  };

  const handleVariantSelect = (itemIndex, variant) => {
    setSelectedVariants(prev => {
      const newVariants = [...prev];
      newVariants[itemIndex] = variant;
      return newVariants;
    });

    if (variant) {
      setValue(`replacementSkus.${itemIndex}`, variant.sku);
      clearErrors('replacementSkus');

      // Reset monogram data if not personalized
      if (!isPersonalizedSKU(variant.sku)) {
        setMonogramData(prev => {
          const newData = [...prev];
          newData[itemIndex] = null;
          return newData;
        });
        setValue(`monogramData.${itemIndex}`, null);
      } else if (!monogramData[itemIndex]) {
        // Initialize monogram data if personalized
        setMonogramData(prev => {
          const newData = [...prev];
          newData[itemIndex] = {
            type: '',
            text: '',
            font: '',
            color: '',
            location: '',
            shadowColor: ''
          };
          return newData;
        });
      }
    } else {
      setValue(`replacementSkus.${itemIndex}`, '');
      setMonogramData(prev => {
        const newData = [...prev];
        newData[itemIndex] = null;
        return newData;
      });
      setValue(`monogramData.${itemIndex}`, null);
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await superagent
        .get(`${backendUrl}/orders/${orderId}`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json');
      
      
      return response.body;
    } catch (error) {
      console.error('Error fetching customer info:', error);
    }
  };

  const handleModalItemSelect = async (items) => {
    
    setValue('selectedItems', items);
    const orderDetails = await fetchOrderDetails(order._id);
    setValue('originalOrderDate', moment(orderDetails.orderCreated).format('YYYY-MM-DD'));
    setValue('customerName', orderDetails.customer.first_name + ' ' + orderDetails.customer.last_name);
    setIsSelectModalOpen(false);
  };

  const renderSelectedItems = () => {
    const handleRemoveItem = (index) => {
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(index, 1);
      setValue('selectedItems', newSelectedItems);
      clearErrors('replacementSkus');
      const newSelectedVariants = [...selectedVariants];
      newSelectedVariants.splice(index, 1);
      setSelectedVariants(newSelectedVariants);
    };
  
    return (
      <div className="space-y-6">
        {selectedItems?.map((item, index) => (
          <div key={index} className="border rounded-lg overflow-hidden bg-white relative group">
            {/* Delete Button */}
            <button
              onClick={() => handleRemoveItem(index)}
              className="absolute top-3 right-3 p-2 rounded-full hover:bg-gray-100 transition-colors"
              title="Remove item"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="20" 
                height="20" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                className="text-gray-500 hover:text-red-500 transition-colors"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
  
            <div className="bg-gray-50 p-4 border-b">
              <h3 className="font-medium">Warranty Item #{index + 1}</h3>
            </div>
            


      
            <div className="p-6">
              <div className="grid grid-cols-1 gap-8">
      
                <div className="flex flex-col h-full">
                  <Label className="text-sm text-gray-500 mb-3">Original Product</Label>
                  <div className="bg-white border rounded-lg p-4 flex-1">
                    <div className="flex flex-col h-full">
                      <div className="flex gap-4 mb-4">
                        <div className="w-24 h-24 flex-shrink-0">
                          <img 
                            src={getVariant(item.sku)?.image} 
                            alt="" 
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div>
                          <h4 className="font-medium mb-1">{getVariant(item.sku)?.name}</h4>
                          <p className="text-sm text-gray-600">SKU: {item.sku}</p>
                          <p className="text-sm text-gray-600 mt-1">Quantity: {item.quantity}</p>
                        </div>
                      </div>

                      
                      {isPersonalizedSKU(item.sku) && item.monogram && (
                        <div className="border-t mt-4 pt-4">
                          <h5 className="font-medium mb-2">Personalisation Details</h5>
                          <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm text-gray-600">
                            <p className="flex items-center"><span className="font-medium mr-2">Type:</span> {item.pet ? 'Pets' : 'Text'}</p>
                            <p className="flex items-center"><span className="font-medium mr-2">Text:</span> {item.monogram_text}</p>
                            <p className="flex items-center"><span className="font-medium mr-2">Font:</span> {fonts[item.monogram_font] || item.monogram_font}</p>
                            <p className="flex items-center"><span className="font-medium mr-2">Color:</span> {item.monogram_color}</p>
                            <p className="flex items-center"><span className="font-medium mr-2">Location:</span> {locations[item.monogram_position] || item.monogram_position}</p>
                            {item.monogrammed_by && (
                              <p className="flex items-center"><span className="font-medium mr-2">Monogrammed by:</span> {item.monogrammed_by}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
  
                {/* Replacement Product */}
                <div className="flex flex-col h-full">
                  <Label className="text-sm text-gray-500 mb-3">Replacement Product</Label>
                  <div className="space-y-4 flex-1">
                    <ProductVariantSelector
                      products={products}
                      setSelectedVariant={(variant) => handleVariantSelect(index, variant)}
                      selectedVariant={selectedVariants[index]}
                      className="mb-4"
                    />
                    
                    <div className="bg-white border rounded-lg p-4 flex-1">
                      {selectedVariants[index] ? (
                        <>
                          <div className="flex gap-4">
                            <div className="w-24 h-24 flex-shrink-0">
                              <img 
                                src={selectedVariants[index].image} 
                                alt="" 
                                className="w-full h-full object-contain"
                              />
                            </div>
                            <div>
                              <h4 className="font-medium mb-1">{selectedVariants[index].name}</h4>
                              <p className="text-sm text-gray-600">SKU: {selectedVariants[index].sku}</p>
                            </div>
                          </div>

                          {selectedVariants[index] && 
                            isPersonalizedSKU(selectedVariants[index].sku) && (
                            <div className="border rounded-lg p-4 space-y-4 mt-4">
                              <h6 className="font-medium">Monogram Data</h6>
                              
                              <div className="space-y-4">
                                {/* Monogram Type */}
                                <div>
                                  <Label>Monogram Type</Label>
                                  <Select
                                    value={monogramData[index]?.type || ''}
                                    onValueChange={(value) => handleMonogramDataChange(index, 'type', value)}
                                  >
                                    <SelectTrigger>
                                      <SelectValue placeholder="Select type" />
                                    </SelectTrigger>
                                    <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                                      {Object.entries(monogramTypes).map(([value, label]) => (
                                        <SelectItem key={value} value={value}>
                                          {label}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                </div>

                                {monogramData[index]?.type === 'text' ? (
                                  <>
                                    {/* Text Input */}
                                    <div>
                                      <Label>Text</Label>
                                      <Input
                                        value={monogramData[index]?.text || ''}
                                        onChange={(e) => handleMonogramDataChange(index, 'text', e.target.value)}
                                        placeholder="Enter monogram text"
                                      />
                                    </div>

                                    {/* Font Selection */}
                                    <div>
                                      <Label>Font</Label>
                                      <Select
                                        value={monogramData[index]?.font || ''}
                                        onValueChange={(value) => handleMonogramDataChange(index, 'font', value)}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select font" />
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                                          {Object.entries(fonts).map(([value, label]) => (
                                            <SelectItem key={value} value={value}>
                                              {label}
                                            </SelectItem>
                                          ))}
                                        </SelectContent>
                                      </Select>
                                    </div>

                                    {/* Color Selection */}
                                    <div>
                                      <Label>Color</Label>
                                      <Select
                                        value={monogramData[index]?.color || ''}
                                        onValueChange={(value) => handleMonogramDataChange(index, 'color', value)}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select color" />
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                                          {Object.entries(newColors).map(([value, color]) => (
                                            <SelectItem key={value} value={value}>
                                              <div className="flex items-center gap-2">
                                                <div 
                                                  className="w-4 h-4 rounded-full" 
                                                  style={{ backgroundColor: color }}
                                                />
                                                <span>{value.replace(/-/g, ' ')}</span>
                                              </div>
                                            </SelectItem>
                                          ))}
                                        </SelectContent>
                                      </Select>
                                    </div>

                                    
                                    {['block', 'college', 'heritage', 'decorative'].includes(monogramData[index]?.font) && (
                                      <div>
                                        <Label>Shadow Color</Label>
                                        <Select
                                          value={monogramData[index]?.shadowColor || ''}
                                          onValueChange={(value) => handleMonogramDataChange(index, 'shadowColor', value)}
                                        >
                                          <SelectTrigger>
                                            <SelectValue placeholder="Select shadow color" />
                                          </SelectTrigger>
                                          <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                                            {Object.entries(newColors).map(([value, color]) => (
                                              <SelectItem key={value} value={value}>
                                                <div className="flex items-center gap-2">
                                                  <div 
                                                    className="w-4 h-4 rounded-full" 
                                                    style={{ backgroundColor: color }}
                                                  />
                                                  <span>{value.replace(/-/g, ' ')}</span>
                                                </div>
                                              </SelectItem>
                                            ))}
                                          </SelectContent>
                                        </Select>
                                      </div>
                                    )}

                                    {/* Location Selection */}
                                    <div>
                                      <Label>Location</Label>
                                      <Select
                                        value={monogramData[index]?.location || ''}
                                        onValueChange={(value) => handleMonogramDataChange(index, 'location', value)}
                                      >
                                        <SelectTrigger>
                                          <SelectValue placeholder="Select location" />
                                        </SelectTrigger>
                                        <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                                          {Object.entries(locations).map(([value, label]) => (
                                            <SelectItem key={value} value={value}>
                                              {label}
                                            </SelectItem>
                                          ))}
                                        </SelectContent>
                                      </Select>
                                    </div>
                                  </>
                                ) : monogramData[index]?.type === 'pets' && (
                                  <PetSection 
                                    key={`pet-section-${index}`}
                                    index={index}
                                    order={order}
                                    monogramData={monogramData}
                                    onPetSelect={(data) => handlePetDataChange(index, data)}
                                    onLocationChange={(location) => handleMonogramDataChange(index, 'location', location)}

                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="h-24 flex items-center justify-center text-gray-400">
                          No replacement product selected 
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const createReplacementItem = (originalItem, replacementSku, monogramData = null) => {
    if (!monogramData) return {
      originalSku: originalItem.sku,
      replacementSku,
      monogram: null
    };

    
    if (monogramData.type === 'pets') {
      return {
        originalSku: originalItem.sku,
        replacementSku,
        monogram: {
          type: 'pets',
          location: monogramData.location,
          airtableLines: monogramData.airtableLines,
          pets: monogramData.pets
        }
      };
    }

    return {
      originalSku: originalItem.sku,
      replacementSku,
      monogram: {
        type: monogramData.type,
        text: monogramData.text,
        font: monogramData.font,
        color: monogramData.color,
        location: monogramData.location,
        shadowColor: ['block', 'college', 'heritage', 'decorative'].includes(monogramData.font) ? monogramData.shadowColor : undefined
      }
    };
  };

  const onSubmit = async (data) => {
    if (linkToOrder && !data.order) {
      setError('order', {
        type: 'manual',
        message: 'Order is required when not skipped'
      });
      return;
    }

    setLoading(true);
    const hasEmptyReplacementSku = data.replacementSkus.some(sku => !sku);
    const hasInvalidReplacementCount = data.replacementSkus.length !== data.selectedItems.length;
    
    if (hasEmptyReplacementSku || hasInvalidReplacementCount) {
      setError('replacementSkus', {
        type: 'manual',
        message: "Please select all replacement products",
      });
      setLoading(false);
      return;
    }
    try {
      if (data['files']) {
        for (const file of data['files']) {
          const fileUploaded = await uploadFileS3Bucket(
            file,
            `attachment_${Date.now()}`
          );
          data['attachments'] = [...(data['attachments'] || []), fileUploaded];
        }
      }
      
      

      
      const formData = {
        ...data,
        selectedItems: data.selectedItems.map((item) => ({
          ...item,
          quantity: item.quantity.toString(),
        })),
        replacementSkus: 
        linkToOrder ? data.replacementSkus.map((sku, index) => {
          return createReplacementItem(
            data.selectedItems[index],
            sku,
            data.monogramData[index]
          );
        }) : undefined,
      };
      
      if (!linkToOrder) {
        
        formData.replacementSkus = replacementSkus;
      }
      
      await createWarrantyTicket(formData);
    
      onModalChange?.(false);
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add error handling for form
  const onError = (errors) => {
    console.log("Form errors:", errors);
    // Show first error message
  };

  const handlePetDataChange = useCallback((index, petData) => {
    setMonogramData(prev => {
      const newData = [...prev];
      if (!newData[index]) {
        newData[index] = {};
      }
      newData[index] = {
        ...newData[index],
        ...petData
      };
      return newData;
    });
    
    setValue(`monogramData.${index}`, petData);
  }, [setValue]);

  
  



  

  

  return (
    <div>
      <div className={`${isForm ? '' : 'page-content'}`}>
        <div className={`${isForm ? '' : 'container mx-auto px-4 lg:max-w-[85%]'}`}>
          <Card className={`${isForm ? '' : 'max-w-lg mx-auto my-10 p-6'}`}>
            {!isForm && (
              <CardHeader className="text-2xl font-semibold">
                Retail Warranty Submission
              </CardHeader>
            )}
            {isWizard && <WizardNav steps={['Create Warranty Ticket']} activeTabProgress={1} />}
            <CardContent className={`${isForm ? 'pt-6' : ''}`}>
              <form onSubmit={handleSubmit(onSubmit, onError)} className="space-y-6">
               
                <div className="mb-4">
                  <Label htmlFor="retailMemberName">Retail Member Name*</Label>
                  <Input
                    id="retailMemberName"
                    {...register('retailMemberName', { required: true })}
                    placeholder="Enter your name"
                  />
                  {errors.retailMemberName && (
                    <p className="text-red-500">This field is required</p>
                  )}
                </div>

                {/* State */}
                <div className="mb-4">
                  <Label htmlFor="state">State*</Label>
                  <Select
                    onValueChange={(val) => setValue('state', val, {shouldValidate: true})}
                    {...register('state', { required: true })}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select state" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                      {states.map((state) => (
                        <SelectItem key={state.value} value={state.value}>
                          {state.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {errors.state && (
                    <p className="text-red-500">This field is required</p>
                  )}
                </div>

                {/* Order Selection comman*/}
                <div className="mb-4">
                  

                 
                  <Label htmlFor="order">Order #*</Label>
                  <CommandSearch 
                    orderName={initialOrder?.name} 
                    setValue={setValue}
                    linkToOrder={linkToOrder}
                  
                  />
                  {showOldOrderCheckbox &&
                  <div className="flex items-center space-x-2 my-2 mt-4">
                    <Checkbox
                      id="skipOrder"
                      checked={!linkToOrder}
                      onCheckedChange={(checked) => {
                        setLinkToOrder(!checked);
                        if (checked) {
                
                          setValue('order', null);
                          setValue('originalOrderDate', '');
                          setValue('selectedItems', []);
                          setValue('replacementSkus', []);
                          clearErrors('order');
                        }
                      }}
                    />
                    <Label className="mb-0" htmlFor="skipOrder">Old Order (Pre-2020)</Label>
                    </div>
}

                  {linkToOrder && errors.order && (
                    <p className="text-red-500 text-sm mt-1">This field is required</p>
                  )}


                </div>

                {(selectedItems.length > 0 ) && (
                  <div className="mb-4">
                    <Label>Selected Items for Warranty</Label>
                    {errors.replacementSkus && (
      <p className="text-red-500 mb-2">{errors.replacementSkus.message}</p>
    )}
                    {renderSelectedItems()}

                    
                  </div>
                )}


                {/* Original Order Date */}
                <div className="mb-4">
                  <Label htmlFor="originalOrderDate">Original Order Date</Label>
                  <Input
                    type="date"
                    id="originalOrderDate"
                    {...register('originalOrderDate')}
                  />
                </div>

                {/* Replacement Issued Date */}
                <div className="mb-4">
                  <Label htmlFor="replacementIssuedDate">Replacement Issued Date*</Label>
                  <Input
                    type="date"
                    id="replacementIssuedDate"
                    {...register('replacementIssuedDate', { required: 'This field is required' })}
                  />
                  {errors.replacementIssuedDate && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.replacementIssuedDate.message}
                    </p>
                  )}
                </div>

  
                <div className="mb-4">
                  <Label htmlFor="storeLocation">Store Location</Label>
                  <Select
                    onValueChange={(value) => setValue('storeLocation', value, {shouldValidate: true})}
                    value={watch('storeLocation')}
                    {...register('storeLocation', { required: true })}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select store location" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                      {retailStores?.map((store) => (
                        <SelectItem key={store._id} value={store.name}>
                          {store.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {errors.storeLocation && (
                    <p className="text-sm text-red-500 mt-1">
                      Please select a store location
                    </p>
                  )}
                </div>
                  {/* Customer Name */}
                <div className="mb-4">
                  <Label htmlFor="customerName">Customer Name*</Label>
                  <Input
                    id="customerName"
                    {...register('customerName', { required: true })}
                    placeholder="Enter customer name"
                  />
                  {errors.customerName && (
                    <p className="text-red-500">This field is required</p>
                  )}
                </div>

                {/* Priority */}
                <div className="mb-4">
                  <Label htmlFor="priority">Priority</Label>
                  <Select
                    onValueChange={(val) => setValue('priority', val)}
                    {...register('priority')}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select priority" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                      {priorities.map((priority) => (
                        <SelectItem key={priority.value} value={priority.value}>
                          {priority.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                {/* Add Modal */}
                <SelectItemsModal
                  isOpen={isSelectModalOpen}
                  onClose={() => setIsSelectModalOpen(false)}
                  orderItems={order?.line_items}
                  onConfirm={handleModalItemSelect}
                  getVariant={getVariant}
                />

                {/* Shipping Option */}
                <div className="mb-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="toBeShipped"
                      checked={toBeShipped}
                      onCheckedChange={(checked) => {
                        setValue('toBeShipped', checked);
                        // Reset pickup location when switching to shipping
                        if (checked) {
                          setValue('pickupLocation', null);
                        }
                      }}
                    />
                    <Label className="mb-0" htmlFor="toBeShipped">Ship to Customer</Label>
                  
                  </div>
                </div>

                {/* Pickup Location (only visible when not shipping) */}
                {!toBeShipped && (
                  <div className="mb-4">
                    <Label htmlFor="pickupLocation">Pickup Location</Label>
                    <Select
                      onValueChange={(value) => setValue('pickupLocation', value)}
                      value={watch('pickupLocation')}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select pickup location" />
                      </SelectTrigger>
                      <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                        {retailStores?.map((location) => (
                          <SelectItem key={location._id} value={location._id}>
                            {location.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.pickupLocation && (
                      <p className="text-sm text-red-500 mt-1">
                        Please select a pickup location
                      </p>
                    )}
                  </div>
                )}

                {/* Fault Reason */}
                <div className="mb-4">
                  <Label htmlFor="faultReason">Fault / Reason for Submission*</Label>
                  <Select
                    onValueChange={(value) => setValue('faultReason', value)}
                    value={watch('faultReason')}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select reason" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                      {FAULT_REASONS.map((reason) => (
                        <SelectItem key={reason.value} value={reason.value}>
                          {reason.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {errors.faultReason && (
                    <p className="text-red-500">This field is required</p>
                  )}
                </div>

                <div className="space-y-4">
                      <div>
                        <Label className="text-sm font-medium mb-1 block">
                          Action Required - Customer Request*
                        </Label>
                        <Textarea
                          {...register('customerRequest', { required: 'This field is required' })}
                          className="min-h-[120px] focus-visible:ring-2 focus-visible:ring-blue-500"
                          placeholder="Describe customer's specific request..."
                        />
                        {errors.customerRequest && (
                          <p className="text-red-500 text-sm mt-1">
                            {errors.customerRequest.message}
                          </p>
                        )}
                      </div>

                    
                    </div>

                {/* Evidence Upload */}
                <div className="mb-4">
                  <Label htmlFor="files">Evidence for Warranty Submission*</Label>
                  <Input
                    type="file"
                    multiple
                    id="files"
                    {...register('files', { required: true })}
                    accept="image/*"
                  />
                  {errors.files && (
                    <p className="text-red-500">Evidence files are required</p>
                  )}
                </div>

                {/* Fulfilled */}
                <div className="mb-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="fulfilled"
                      checked={watch('fulfilled')}
                      onCheckedChange={(checked) => setValue('fulfilled', checked)}
                    />
                    <Label className="mb-0" htmlFor="fulfilled">Mark as Fulfilled</Label>
                  </div>
                </div>

            

                <div className="flex justify-end space-x-4">
                  <Button 
                    type="button" 
                    variant="outline" 
                    onClick={() => onModalChange?.(false)}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button 
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Submitting...' : 'Submit Warranty Claim'}
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        toastClassName="border shadow-lg"
        progressStyle={{ backgroundColor: 'var(--primary)' }}
      />
    </div>
  );
}
