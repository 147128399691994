import { useState } from 'react';


import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@shadcn/components/ui/dialog';
import  RepairTicket from './repair-ticket';
import { ToastContainer } from 'react-toastify';


export default function RepairTicketForm({order}) {
  const [open, setOpen] = useState(false);
  return (
   
      <>
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <p className='px-2 py-[6px] font-[14px]'>
              Create Repair Ticket
            </p>
          </DialogTrigger>
          <DialogContent className="z-[1500] max-h-[80vh] overflow-y-auto min-w-[60vw]">
            <DialogHeader>
              <DialogTitle>Create Repair Ticket</DialogTitle>
            </DialogHeader>
          <RepairTicket
          initialOrder={order}
          onModalChange={() => setOpen(false)}
          isForm={true}></RepairTicket>
          </DialogContent>
        </Dialog>
        <ToastContainer />
      </>
    
  );
}
