import { Button } from "@shadcn/components/ui/button";
import { X } from "lucide-react";
import superagent from "superagent";
import { backendUrl } from '@helpers/consts';
import { toast } from "react-toastify";

export const CloseTicketButton = ({ ticketId, ticketType, onSuccess }) => {
  
  
  const handleClose = async () => {
    try {
      const response = await superagent
        .put(`${backendUrl}/${ticketType}-tickets/${ticketId}/toggleClose`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`);

      if (response.status === 200) {
        toast.success('Ticket deleted successfully');
        onSuccess?.();
      }
    } catch (error) {
      toast.error('Failed to delete ticket');
      console.error('Error deleting ticket:', error);
    }
  };

  return (
    <Button
      variant="destructive"
      size="sm"
      onClick={handleClose}
      className="gap-2"
    >
    
      Delete Ticket
    </Button>
  );
}; 
