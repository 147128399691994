import React, { useState, useEffect } from 'react';
import { Label } from '@shadcn/components/ui/label';
import { Input } from '@shadcn/components/ui/input';
import { Button } from '@shadcn/components/ui/button';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select';
import { locations } from '../../orders';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';

const PetSection = ({ 
  index, 
  order,
  monogramData,
  onPetSelect,
  onLocationChange,
  
}) => {
  const [edittingPet, setEdittingPet] = useState();
  const [imageData, setImageData] = useState();
  const [uploadedAirtableID, setUploadedAirtableID] = useState('');
  const [newPetName, setNewPetName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allUniquePets, setAllUniquePets] = useState({});
  const [monogramAirtableLines, setMonogramAirtableLines] = useState(
    monogramData[index]?.airtableLines || []
  );
  const [monogramPets, setMonogramPets] = useState(
    monogramData[index]?.pets || ''
  );

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    let file = files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function (event) {
      setImageData({
        base64: `data:${file.type};base64,${btoa(event.target.result)}`,
        filename: file.name,
      });
    };
  };

  const uploadToS3 = () => {
    const uploadData = {
      petName: newPetName,
      base64Image: imageData.base64,
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    };

    options.body = JSON.stringify(uploadData);

    return new Promise((resolve, reject) => {
      fetch(
        'https://az25kdsm7h.execute-api.ap-southeast-2.amazonaws.com/production/uploadPetImagesToS3',
        options
      )
        .then((response) => response.json())
        .then((response) => {
          let base64ToString = Buffer.from(response.url, 'base64').toString();
          resolve(base64ToString);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const createAirtable = (printJobID) => {
    const uploadData = {
      printJobID: printJobID,
      OrderNumber: order.name,
      email: order.email,
    };

    return new Promise((resolve, reject) => {
      superagent
        .post(`${backendUrl}/orders/createPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            resolve(response.body);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchAirtable = (airtableId) => {
    return superagent
      .post(`${backendUrl}/orders/fetchPetAirtable/`)
      .send({ airtableId })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json');
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const savePet = async () => {
    setIsLoading(true);
    try {
      let airtableID;
      if (imageData !== undefined) {
        let printjobID = await uploadToS3();
        airtableID = (await createAirtable(printjobID)).body.id;
        await sleep(2000);
      } else if (uploadedAirtableID !== '') {
        airtableID = uploadedAirtableID;
      } else {
        return;
      }

      let airtableRecord = await fetchAirtable(airtableID);
      let uniquePet = {
        ...airtableRecord.body.fields,
        name: newPetName,
      };

      setAllUniquePets(prev => ({
        ...prev,
        [airtableID]: uniquePet
      }));

      setEdittingPet(undefined);
      setImageData(undefined);
      setNewPetName('');
      setUploadedAirtableID('');
    } catch (error) {
      console.error('Error saving pet:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePetSelection = (petID, pet) => {
    const selected = monogramAirtableLines.includes(petID);
    
    if (selected) {
      setMonogramAirtableLines(prev => prev.filter(val => val !== petID));
      setMonogramPets(prev => 
        btoa(JSON.stringify(
          JSON.parse(atob(prev || btoa('[]'))).filter(val => val.signedUrl !== pet.printJobID)
        ))
      );
    } else {
      setMonogramAirtableLines(prev => [...prev, petID]);
      setMonogramPets(prev => 
        btoa(JSON.stringify([
          ...(prev ? JSON.parse(atob(prev)) : []),
          {
            signedUrl: pet.printJobID,
            name: pet.name || pet.OrderNumber
          }
        ]))
      );
    }

    onPetSelect({
      type: 'pets',
      location: monogramData[index]?.location || '',
      pets: monogramPets,
      airtableLines: monogramAirtableLines
    });
  };

  return (
    <>
      <div className="flex flex-col mb-4">
        <Label>Location</Label>
        <Select
          value={monogramData[index]?.location || ''}
          onValueChange={onLocationChange}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select location" />
          </SelectTrigger>
          <SelectContent className='z-[1600]'>
            {Object.entries(locations).map(([value, label]) => (
              <SelectItem key={value} value={value}>
                {label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Pet Upload Form */}
      <div className="mb-4">
        {console.log("test image url", imageData)}
      { imageData === undefined ? (
        <Input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          className="mb-2"
        />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={() => setImageData()}
          >
            <img
              src={imageData?.base64}
              alt="Pet"
              className="mb-2 w-24 h-24 object-contain"
            />
          </div>
        )}
        <Input
          placeholder="Pet name"
          type="text"
          value={newPetName}
          onChange={(e) => setNewPetName(e.target.value)}
          className="mb-2"
        />
         {imageData === undefined && (
        <Input
          placeholder="Airtable ID (optional)"
          type="text"
          value={uploadedAirtableID}
          onChange={(e) => setUploadedAirtableID(e.target.value)}
          className="mb-2"
          />
        )}
        <Button 
          onClick={savePet}
          disabled={isLoading || (!imageData && !uploadedAirtableID)}
        >
          {isLoading ? 'Saving...' : 'Save Pet'}
        </Button>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {Object.entries(allUniquePets).map(([petID, pet]) => {
          const isSelected = monogramAirtableLines.includes(petID);
          return (
            <div
              key={petID}
              className="border rounded-lg p-4 cursor-pointer hover:bg-gray-50"
            >
              <div className="relative">
                <div className="absolute top-2 right-2">
                  <Checkbox 
                    checked={isSelected}
                    onCheckedChange={() => handlePetSelection(petID, pet)}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={pet['Provided Image'][0].url}
                    alt={pet.name || pet.OrderNumber}
                    className="w-24 h-24 object-contain mb-2"
                  />
                  <h5 className="font-medium">{pet.name || pet.OrderNumber}</h5>
                  <p className="text-sm text-gray-500">{pet.Status}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PetSection; 
