import React, { useState, useEffect } from 'react';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { X } from 'lucide-react';

const ProductVariantMultiSelector = ({
    products,
    setSelectedVariant,
    setSelectedProduct,
    selectedProduct,
    selectedVariant,
    customContainerStyle,
    hidePersonalisationOptions,
    isMulti = false,
    showSku = false
}) => {
    const [localSelectedProduct, setLocalSelectedProduct] = useState();
    const setSelectedProductCurrent = setSelectedProduct
      ? setSelectedProduct
      : setLocalSelectedProduct;
    const selectedProductCurrent = selectedProduct
      ? selectedProduct
      : localSelectedProduct;
  
      
    const setSelectedVariantCurrent = setSelectedVariant
      ? setSelectedVariant
      : () => {};
    const selectedVariantCurrent = selectedVariant || undefined;
  
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedVariant, setExpandedVariant] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const [selectedVariants, setSelectedVariants] = useState([]);
  
    const searchListOfProducts = searchTerm
      ? products.filter(
          (val) =>
            val.name.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
            val.variants?.some((variant) =>
              variant?.sku?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
              variant?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
            )
        )
      : products;


    const findProductNameById = (selectedProduct) => {
      const product = products?.find(option =>
          option?._id?.toLowerCase() === selectedProduct
      )
      if (product) {
          return product?.name
      }
      return ''
  };
  
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
      setIsDropdownOpen(true);
      
      if (isMulti) {
        setSelectedVariantCurrent(selectedVariants);
      } else {
        setSelectedVariantCurrent(null);
        setSelectedProductCurrent(null);
      }
    };
  
    const ensureSinglePersonalization = (sku) => {
      
      const baseSku = sku.replace(/-P+$/, '');
      
      return baseSku + '-P';
    };
  
    const handleVariantSelect = (product, variant, persSKU) => {
      if (!product || !variant) return;
      
      if (isMulti) {
        let newSelectedVariants;
        const variantWithSku = {
          ...variant,
          sku: persSKU ? ensureSinglePersonalization(variant.sku) : variant.sku.replace(/-P+$/, ''),
          name: variant.name,
          product: product.name
        };

        if (selectedVariants.some(v => v?.sku === variantWithSku.sku)) {
          newSelectedVariants = selectedVariants.filter(v => v?.sku !== variantWithSku.sku);
        } else {
          newSelectedVariants = [...(selectedVariants || []), variantWithSku];
        }

        setSelectedVariants(newSelectedVariants);
        setSelectedVariantCurrent(newSelectedVariants);
      } else {
        if (persSKU) {
          variant.sku = ensureSinglePersonalization(variant.sku);
        } else {
          variant.sku = variant.sku.replace(/-P+$/, '');
        }
        setSelectedProductCurrent(product);
        setSelectedVariantCurrent(variant);
        setSearchTerm('');
        setIsDropdownOpen(false);
      }
    };
  
    const handleProductSelect = (data) => {
      setSelectedProductCurrent(data);
      setSearchTerm('');
      setIsDropdownOpen(false);
    };
  
    const toggleVariant = (name) => {
      setExpandedVariant(expandedVariant === name ? null : name);
    };
  
    const placeholderValue =
      setSelectedProduct === undefined
        ? 'Search for a variant...'
        : 'Search for a product...';
  
    const containerStyle = {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
    };
  
    const getDisplayValue = () => {
      if (isMulti) {
        if (Array.isArray(selectedVariant) && selectedVariant.length > 0) {
          return selectedVariant
            .filter(Boolean)
            .map(v => showSku ? (v?.sku || '') : (v?.name || v?.sku || ''))
            .filter(Boolean)
            .join(', ');
        }
        return '';
      } else {
        if (setSelectedProduct === undefined) {
          return selectedVariantCurrent ? (showSku ? selectedVariantCurrent.sku : selectedVariantCurrent.name) : searchTerm;
        }
        return selectedProductCurrent?.name || 
               (selectedProduct !== null ? findProductNameById(selectedProduct) : searchTerm);
      }
    };
  
    const isVariantSelected = (variant) => {
      if (!isMulti || !variant) return false;
      return selectedVariants?.some(v => v?.sku === variant.sku);
    };
  
    useEffect(() => {
        if (isMulti && Array.isArray(selectedVariant)) {
            setSelectedVariants(selectedVariant);
        }
    }, [selectedVariant, isMulti]);

    useEffect(() => {
      setIsDropdownOpen(false);
  }, []);

  
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isDropdownOpen && !event.target.closest(`#variant-selector-${selectedProduct}`)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen, selectedProduct]);
  
    const handleClearAll = () => {
        setSelectedVariants([]);
        setSelectedVariantCurrent([]);
        setSearchTerm('');
    };
  
    const handleClearItem = (skuToRemove) => {
        const newSelectedVariants = selectedVariants.filter(v => v?.sku !== skuToRemove);
        setSelectedVariants(newSelectedVariants);
        setSelectedVariantCurrent(newSelectedVariants);
    };
  
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
  
    return (
      <div 
        id={`variant-selector-${selectedProduct}`}
        style={customContainerStyle ? customContainerStyle : containerStyle}
        className="relative"
      >
        <div className="relative">
            {isMulti ? (
                <div 
                    className="min-h-[38px] w-full border rounded-md p-1 flex flex-wrap gap-1 bg-white cursor-pointer"
                    onClick={toggleDropdown}
                >
                    {Array.isArray(selectedVariant) && selectedVariant.length > 0 ? (
                        <>
                            {selectedVariant.map((v, index) => (
                                <div 
                                    key={v?.sku || index}
                                    className="flex items-center gap-1 bg-gray-100 px-2 py-1 rounded-md"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <span className="text-sm">
                                        {showSku ? v?.sku : (v?.name || v?.sku)}
                                    </span>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClearItem(v?.sku);
                                        }}
                                        className="hover:bg-gray-200 rounded-full p-1"
                                    >
                                        <X className="h-3 w-3" />
                                    </button>
                                </div>
                            ))}
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClearAll();
                                }}
                                className="text-gray-500 hover:text-gray-700 text-sm ml-1"
                            >
                                Clear all
                            </button>
                        </>
                    ) : (
                        <span className="text-gray-500 text-sm p-1">
                            {placeholderValue}
                        </span>
                    )}
                </div>
            ) : (
                <Input
                    type="search"
                    placeholder={placeholderValue}
                    value={getDisplayValue()}
                    onChange={handleSearchChange}
                    onClick={() => setIsDropdownOpen(true)}
                />
            )}
        </div>
        {isDropdownOpen && (
          <div className="absolute top-full left-0 w-full bg-white border rounded-md shadow-lg mt-1 z-[2000] max-h-[200px] overflow-y-auto">
            {isMulti && (
                <div className="p-2 border-b">
                    <Input
                        type="search"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="mb-2"
                        onClick={(e) => e.stopPropagation()}
                    />
                    <Button 
                        onClick={() => setIsDropdownOpen(false)}
                        className="w-full"
                    >
                        Done ({selectedVariants.length} selected)
                    </Button>
                </div>
            )}
            {searchListOfProducts.map((product) => (
              <div key={product.sku || product.name}>
                <div
                  className="flex items-center justify-between p-2 cursor-pointer border-b"
                  onClick={() => toggleVariant(product.name)}
                >
                  {product.name}
                  {expandedVariant === product.name ? (
                    <i className="fa fa-chevron-up" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  )}
                </div>
                {expandedVariant === product.name && (
                  <div className="pl-4">
                    {product.variants?.map((variant) => (
                      <div key={variant.sku} className="pl-4">
                        <div className="flex items-center justify-between p-2 border-b">
                          <span className="flex items-center gap-2">
                            {isMulti && (
                              <Checkbox
                                checked={isVariantSelected(variant)}
                                onCheckedChange={() => handleVariantSelect(product, variant, false)}
                              />
                            )}
                            {variant.name} / Default
                          </span>
                          {!isMulti && (
                            <Button
                              onClick={() => handleVariantSelect(product, variant, false)}
                            >
                              Select
                            </Button>
                          )}
                        </div>
                        {/* Personalised variant selection (Select 131415) */}
                        {(['COP', 'CHK', 'PLS', 'CME', 'BKP', 'BPK', 'WKD', 'TOT', 'PRO', 'LGT', 'BOT', 'IPC', 'TRK', 'NBK', 'TAG', 'SET', 'TEC', 'COE', 'EXP', 'EVE', 'DBK', 'SLG', 'JET', 'COM'].includes(
                          variant?.sku?.slice(0, 3)
                        ) ||
                          ['CAR-DPK', 'CAR-TOT', 'CAR-BPK', 'CAR-WKD'].includes(
                            variant?.sku?.slice(0, 7)
                          ) ||
                          ['CAR-WKDP'].includes(variant?.sku?.slice(0, 8))) &&
                          !hidePersonalisationOptions && (
                            <div className="flex items-center justify-between p-2">
                              <span>
                                {variant.name} / <strong>Personalised</strong>
                              </span>
                              <Button
                                onClick={() =>
                                  handleVariantSelect(product, variant, true)
                                }
                              >
                                Select
                              </Button>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  export default ProductVariantMultiSelector;
