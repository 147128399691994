import React, { useState, useMemo, useEffect } from 'react';
import {  Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import {  Col } from '@shadcn/components/ui/col';
import {  Row } from '@shadcn/components/ui/row';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { backendUrl } from '@helpers/consts';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import superagent from 'superagent';


ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StockoutReportDashboard = () => {
  const [selectedLocation, setSelectedLocation] = useState('All Locations');
  const [stockoutData, setStockoutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const locationOptions = useMemo(() => {
    if(!stockoutData?.locations) return ['All Locations'];
    return ['All Locations', ...stockoutData.locations.map(loc => loc.location)];
  }, [stockoutData]);
  
  const filteredData = useMemo(() => {
    if (!stockoutData) return { total: 0, outOfStock: 0, inStock: 0, outOfStockPercentage: 0, inStockPercentage: 0, locations: [] };
    if (!stockoutData?.locations || stockoutData?.locations?.length === 0) return stockoutData;
    
    if (selectedLocation === 'All Locations') {
      return stockoutData;
    }
    
    const locationData = stockoutData.locations.find(loc => loc.location === selectedLocation);
    
    if (!locationData) return stockoutData;
    
    return {
      total: locationData.total,
      outOfStock: locationData.outOfStock,
      inStock: locationData.inStock,
      outOfStockPercentage: locationData.outOfStockPercentage,
      inStockPercentage: locationData.inStockPercentage,
      locations: [locationData]
    };
  }, [selectedLocation, stockoutData]);

  const doughnutData = useMemo(() => {
    return {
      labels: ['Out of Stock', 'In Stock'],
      datasets: [
        {
          data: [
            filteredData?.outOfStock || 0, 
            filteredData?.inStock || 0, 
          ],
          backgroundColor: ['#f46a6a', '#34c38f'],
          hoverBackgroundColor: ['#ef5350', '#26a69a'],
          borderWidth: 1,
        },
      ],
    };
  }, [filteredData]);

  const barChartData = useMemo(() => {
    if (!stockoutData?.locations || stockoutData.locations.length === 0) {
      return {
        labels: [],
        datasets: [
          {
            label: 'In Stock Percentage (%)',
            backgroundColor: '#34c38f',
            borderColor: '#34c38f',
            borderWidth: 1,
            hoverBackgroundColor: '#26a69a',
            hoverBorderColor: '#26a69a',
            data: [],
          },
        ],
      };
    }
    
    const top10Locations = [...stockoutData.locations]
      .sort((a, b) => b.inStockPercentage - a.inStockPercentage)
      .slice(0, 10);

    return {
      labels: top10Locations.map(loc => loc.location),
      datasets: [
        {
          label: 'In Stock Percentage (%)',
          backgroundColor: '#34c38f',
          borderColor: '#34c38f',
          borderWidth: 1,
          hoverBackgroundColor: '#26a69a',
          hoverBorderColor: '#26a69a',
          data: top10Locations.map(loc => loc.inStockPercentage.toFixed(2)),
        },
      ],
    };
  }, [stockoutData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await superagent
          .post(`${backendUrl}/product/stockOutReport`)
          .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
          .set('Accept', 'application/json');
        
        setStockoutData(response.body);
        setError(null);
      } catch (err) {
        setError(err.message || 'Failed to fetch stock status data');
        setStockoutData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if(loading) {
    return (
      <div className="page-content">
        <div className="container-fluid px-2 sm:px-4">
          <Breadcrumb title="Dashboard" breadcrumbItem="Stock Status Report" />
          <div className="flex justify-center items-center h-64">
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mt-2">Loading stock status data...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="page-content">
        <div className="container-fluid px-2 sm:px-4">
          <Breadcrumb title="Dashboard" breadcrumbItem="Stock Status Report" />
          <div className="text-center p-5">
            <h4>Error loading data</h4>
            <p className="text-danger">{error}</p>
            <button 
              className="btn btn-primary mt-3"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!stockoutData) {
    return (
      <div className="page-content">
        <div className="container-fluid px-2 sm:px-4">
          <Breadcrumb title="Dashboard" breadcrumbItem="Stock Status Report" />
          <div className="text-center p-5">
            <h4>No data available</h4>
            <p>Unable to load stock status data. Please try again later.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid px-2 sm:px-4">
          {/* Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Stock Status Report" />
          
          <Row className="mb-4">
            <Col className="w-full">
              <Card>
                <CardBody>
                  <div className="flex flex-col sm:flex-row justify-end items-start sm:items-center mb-4 mt-4">
                    <div className="flex items-center w-full sm:w-auto">
                      <h1 className="font-bold text-[15px] me-2 whitespace-nowrap">Filter by location:</h1>
                      <select 
                        className="form-select w-full" 
                        value={selectedLocation} 
                        onChange={(e) => setSelectedLocation(e.target.value)}
                      >
                        {locationOptions.map(location => (
                          <option key={location} value={location}>
                            {location}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div>
                      <Card className="mini-stats-wid bg-light h-full">
                        <CardBody className="h-full">
                          <div className="flex h-full">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Total Products</p>
                              <h4 className="mb-0 text-xl font-semibold">{filteredData?.total || 0}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i className="bx bx-package font-size-24"></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div>
                      <Card className="mini-stats-wid bg-light h-full">
                        <CardBody className="h-full">
                          <div className="flex h-full">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">Out of Stock</p>
                              <h4 className="mb-0 text-xl font-semibold">{filteredData?.outOfStock || 0}</h4>
                              <p className="text-danger mt-2 mb-0">
                                {(filteredData?.outOfStockPercentage || 0).toFixed(2)}%
                              </p>
                            </div>
                            <div className="avatar-sm rounded-circle bg-danger align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-danger">
                                <i className="bx bx-x-circle font-size-24"></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div>
                      <Card className="mini-stats-wid bg-light h-full">
                        <CardBody className="h-full">
                          <div className="flex h-full">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">In Stock</p>
                              <h4 className="mb-0 text-xl font-semibold">{filteredData?.inStock || 0}</h4>
                              <p className="text-success mt-2 mb-0">
                                {(filteredData?.inStockPercentage || 0).toFixed(2)}%
                              </p>
                            </div>
                            <div className="avatar-sm rounded-circle bg-success align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-success">
                                <i className="bx bx-check-circle font-size-24"></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-4">
            <div className="lg:col-span-5">
              <Card>
                <CardBody>
                  <h4 className="card-title my-4">Inventory Status Distribution</h4>
                  <div className="flex justify-center">
                    <div className="w-[250px] h-[250px] sm:w-[300px] sm:h-[300px]">
                      <Doughnut data={doughnutData} options={{ maintainAspectRatio: false }} />
                    </div>
                  </div>
                  <div className="chart-legend text-center mt-4">
                    <div className="flex flex-wrap justify-center">
                      <div className="me-4 mb-2">
                        <i className="mdi mdi-circle text-danger me-1"></i> Out of Stock
                      </div>
                      <div className="me-4 mb-2">
                        <i className="mdi mdi-circle text-success me-1"></i> In Stock
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="lg:col-span-7">
              <Card>
                <CardBody>
                  <h4 className="card-title my-4">Top 10 Locations with Highest In Stock Percentage</h4>
                  <div className="h-[300px] sm:h-[350px]">
                    <Bar 
                      data={barChartData} 
                      options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                          y: {
                            beginAtZero: true,
                            max: 100,
                            title: {
                              display: true,
                              text: 'In Stock Percentage (%)'
                            }
                          },
                          x: {
                            title: {
                              display: true,
                              text: 'Location'
                            }
                          }
                        }
                      }} 
                    />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

          
          <div className="grid grid-cols-1 mb-4">
            <div>
              <Card>
                <CardBody>
                  <h4 className="card-title my-4">
                    Location Details
                    {selectedLocation !== 'All Locations' && ` - ${selectedLocation}`}
                  </h4>
                  <div className="overflow-x-auto">
                    <table className="table table-centered table-nowrap mb-0 min-w-full">
                      <thead className="table-light">
                        <tr>
                          <th className="py-3">Location</th>
                          <th className="py-3">Total Products</th>
                          <th className="py-3">Out of Stock</th>
                          <th className="py-3">In Stock</th>
                          <th className="py-3">Out of Stock %</th>
                          <th className="py-3">In Stock %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData?.locations?.map(location => (
                          <tr key={location.location}>
                            <td className="py-3">{location.location}</td>
                            <td className="py-3">{location.total}</td>
                            <td className="py-3">
                              <span className="text-danger">{location.outOfStock}</span>
                            </td>
                            <td className="py-3">
                              <span className="text-success">{location.inStock}</span>
                            </td>
                            <td className="py-3">
                              <div className="progress" style={{ height: '8px' }}>
                                <div
                                  className="progress-bar bg-danger"
                                  role="progressbar"
                                  style={{ width: `${location.outOfStockPercentage}%` }}
                                  aria-valuenow={location.outOfStockPercentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="mt-1 d-block text-danger">
                                {location.outOfStockPercentage.toFixed(2)}%
                              </span>
                            </td>
                            <td className="py-3">
                              <div className="progress" style={{ height: '8px' }}>
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{ width: `${location.inStockPercentage}%` }}
                                  aria-valuenow={location.inStockPercentage}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <span className="mt-1 d-block text-success">
                                {location.inStockPercentage.toFixed(2)}%
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StockoutReportDashboard;
