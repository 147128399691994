
import SupplyChainNav from '../../../components/supply-chain/nav.js';


import ExternalPers from '../personalisation-batch/external-pers/index.js';
import ExternalPersAirbox from '../personalisation-batch/external-pers-airbox/index.js';
import ExternalPersEWE from '../personalisation-batch/external-pers-ewe/index.js';
import ExternalPersEWESYD from '../personalisation-batch/external-pers-ewe-syd/index.js';
import ExternalPersLaunch from '../personalisation-batch/external-pers-launch/index.js';
import { Card ,CardContent as CardBody} from '@shadcn/components/ui/card'

import { useHistory } from 'react-router-dom';



const FulFillmentLocationPage = () => {
 
  const user = JSON.parse(localStorage.getItem("authUser"))


  const history = useHistory();
 

  return (
    <SupplyChainNav menuType="fulfillment-location">
         <Card className="pt-6 mb-10">
          <CardBody>
        {history?.location?.pathname?.includes('launch') && (
          <ExternalPersLaunch></ExternalPersLaunch>
        )}
        {history?.location?.pathname?.includes('sydewe') ? (
          <ExternalPersEWESYD></ExternalPersEWESYD>
        ) : history?.location?.pathname?.includes('ewe') && (
          <ExternalPersEWE></ExternalPersEWE>
        )}

        {history?.location?.pathname?.includes('airbox') && (
          <ExternalPersAirbox></ExternalPersAirbox>
        )}

        {history?.location?.pathname?.includes('unis') && (
          <ExternalPers></ExternalPers>
        )}
        </CardBody>
      </Card>
    </SupplyChainNav>
  );
};

export default FulFillmentLocationPage;
