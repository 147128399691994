import React, { useState, useEffect, useRef } from 'react';

import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';

import { Input } from '@shadcn/components/ui/input';

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from '@shadcn/components/ui/dialog';
import FeedBackForm from '../order-ticket/FeedBackForm';

import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
  SelectGroup,
  SelectLabel,
} from '@shadcn/components/ui/select';

import ReactSelect from '@shadcn/components/ui/react-select';


import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@shadcn/components/ui/dropdown-menu';



//Import Breadcrumb

import '../datatables.scss';
import useQuery from '../../../helpers/JIFI/useQuery';

import SelectMultiple from '@shadcn/components/ui/react-select';


import { useReactToPrint } from 'react-to-print';
import PrintPage from '../monogram/PrintPage';
import PrintPageDeliveries from '../deliveries/delivery-label';

import useProductData from './useProductData';

import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';

import { Country, State } from 'country-state-city';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import FulfillmentModal from './FulfillmentModule';

import AsyncSelect from 'react-select/async';
import OrderWizardComponent from './Wizard/index.js';

import SKUToolTip from '../helpers/SKUToolTip.js';
import useSKULevels from '../helpers/useSKULevels.js';
import useSKUStatus from '../helpers/useSKUStatus.js';
import useUpcomingStock from '../helpers/useUpcomingStock.js';
import ProductVariantSelector from '../helpers/ProductVariantSelector.js';
import useUserStore from '../../../zustand/useUserStore.js';
import {
  Card,
  CardContent as CardBody,
  CardTitle,
} from '@shadcn/components/ui/card';
import { Button } from '@shadcn/components/ui/button';
import { Badge } from '@shadcn/components/ui/badge';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@shadcn/components/ui/tabs';
import { StatusBadge } from './components/StatusBadge';
import { LabelsSection } from './components/Section/LabelsSection';
import { OrderDetailsSection } from './components/Section/OrderDetailsSection';
import { CustomerInfoSection } from './components/Section/CustomerInfoSection';
import { NotesSection } from './components/Section/NotesSection';
import WarrantyClaimForm from '../warranty-claim/warranty-claim-form';
import RepairTicketForm from '../repair-ticket/repair-ticket-form';
import OrderViewersFab from './components/OrderViewersFab';

const fulfillmentToBadge = (fulfillment) => {
  if (fulfillment === 'unfulfilled') return 'warning';
  if (fulfillment === 'awaiting-stock') return 'secondary';
  const conversion = {
    fulfilled: 'success',
    partially_fulfilled: 'error',
  };
  return conversion[fulfillment];
};



class PackingSlip extends React.Component {
  render() {
    if (!this.props.order) return <></>;
    return (
      <div style={{ width: '100%', height: '100vh', padding: '128px 64px' }}>
        <h1 style={{ marginBottom: 48 }}>
          {' '}
          {this.props.order.name} - PACKING SLIPS{' '}
        </h1>
        <table style={{ fontSize: 24 }}>
          <thead>
            <th style={{ padding: 8 }}>QTY</th>
            <th style={{ padding: 8 }}>SKU</th>
           
          </thead>
          <tbody>
            {this.props.order?.line_items?.map((val) => {
              return (
                <tr>
                  <td style={{ padding: 8 }}>{val.quantity}</td>
                  <td style={{ padding: 8 }}>{val.sku}</td>
              
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

const UnfulfilledLineItems = ({
  shipping_address,
  printDeliveryLabels,
  lineItems,
  orderID,
  order_source,
  refresh,
  shipping_type,
  contains_monogram,
  orderName,
  getVariant,
  distanceFromHQ,
  findSKULevel,
  findUpcomingStock,
  findSKUStatus,
  pick_status,
  postcode,
}) => {
  const [selectedItems, setSelectedItems] = useState(
    lineItems?.map((val) => true) ?? []
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setSelectedCount(
      selectedItems.reduce(function (n, val) {
        return n + (val === true);
      }, 0)
    );
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems(
      lineItems?.map((val) => val.fulfillment_status !== 'deleted') ?? []
    );
  }, [lineItems]);

  const handleFulfillClicked = () => {
    setModalOpen(true);
  };

  return (
    <>
      {lineItems?.map((lineItem, key) => {
        const handleSelected = () => {
          setSelectedItems((prev) => {
            let newItems = [...prev];
            newItems[key] = !newItems[key];
            return newItems;
          });
        };
        const isSelected = selectedItems[key] === true;
        return (
          <>
            <Row>
              <Col className="sm:w-1/12">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {lineItem.fulfillment_status !== 'deleted' && (
                    <input
                      className="form-check-input w-[13px] h-[13px]"
                      type="checkbox"
                      onChange={handleSelected}
                      defaultChecked={true}
                      id="defaultCheck1"
                    />
                  )}
                </div>
              </Col>
              <Col className="sm:w-2/12">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={getVariant(lineItem.sku)?.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col className="p-3 sm:w-5/12 text-black font-medium">
                <h6>{getVariant(lineItem.sku)?.name}</h6>
                <div>
                  <StatusBadge
                    status={lineItem.fulfillment_status}
                    type="fulfillment"
                  />
                </div>
                <div>
                  SKU:{' '}
                  <SKUToolTip
                    sku={lineItem.sku}
                    findSKULevel={findSKULevel}
                    findUpcomingStock={findUpcomingStock}
                    findSKUStatus={findSKUStatus}
                  />
                </div>
              </Col>
              <Col  className="p-3 sm:w-2/12">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
              <Col  className="sm:w-2/12 p-3 flex flex-col items-end font-medium">
                {lineItem.quantity}x ${lineItem.price}
              </Col>
              
            </Row>
          </>
        );
      })}
      <Row>
        {selectedCount > 0 && (
          <Button
            onClick={handleFulfillClicked}
            variant="success"
            className="text-white mt-3"
            size="sm"
            type="submit"
          >
            {' '}
            Fulfill Selected
          </Button>
        )}
      </Row>
      <Dialog
        size="lg"
        open={modalOpen}
        onOpenChange={() => setModalOpen((prev) => !prev)}
        
        
      >
       
        <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
        <DialogHeader className="modal-header">
          <h5 className="text-[15px] font-medium mt-0">Fulfillment method</h5>
         
        </DialogHeader>
        <FulfillmentModal
          shipping_address={shipping_address}
          postcode={postcode}
          pick_status={pick_status}
          printDeliveryLabels={printDeliveryLabels}
          distanceFromHQ={distanceFromHQ}
          orderName={orderName}
          contains_monogram={contains_monogram}
          refresh={refresh}
          lineItems={lineItems}
          orderID={orderID}
          shipping_type={shipping_type}
          order_source={order_source}
          selectedItems={selectedItems}
          setModalOpen={setModalOpen}
        />
        </DialogContent>
      </Dialog>
    </>
  );
};

const SplitLineItems = ({
  lineItems,
  orderID,
  
  refresh,
  
  
  orderName,
  getVariant,
  
  findSKULevel,
  findSKUStatus,
  findUpcomingStock,
}) => {
  const [selectedItems, setSelectedItems] = useState(
    lineItems.map((val) => true)
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedCount(
      selectedItems.reduce(function (n, val) {
        return n + (val === true);
      }, 0)
    );
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems(
      lineItems.map((val) => val.fulfillment_status !== 'deleted')
    );
  }, [lineItems]);

  const handleFulfillClicked = () => {
    setModalOpen(true);
  };

  const [manualName, setManualName] = useState(orderName);
  useEffect(() => {
    setManualName(orderName);
  }, [orderName]);
  const handleManualNameChange = (e) => {
    setManualName(e.target.value);
  };

  const handleCreateSplit = () => {
    setLoading(true);
    const sendLineItems = selectedItems
      .map((val, key) => (val ? lineItems[key] : null))
      .filter((el) => el != null);

    superagent
      .post(`${backendUrl}/orders/splitOrder`)
      .send({
        line_items: sendLineItems,
        orderId: orderID,
        updatedName: manualName,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          setLoading(false);
          setModalOpen(false);
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {lineItems.map((lineItem, key) => {
        const handleSelected = () => {
          setSelectedItems((prev) => {
            let newItems = [...prev];
            newItems[key] = !newItems[key];
            return newItems;
          });
        };
        const isSelected = selectedItems[key] === true;
        return (
          <>
            <Row>
              <Col className="sm:w-1/12">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {lineItem.fulfillment_status !== 'deleted' && (
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleSelected}
                      checked={isSelected}
                      id="defaultCheck1"
                    />
                  )}
                </div>
              </Col>
              <Col  className="sm:w-2/12">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={getVariant(lineItem.sku)?.image}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col className="p-3 sm:w-5/12">
                <h6 className="text-black font-medium">{getVariant(lineItem.sku)?.name}</h6>
                <div>
                  <StatusBadge
                    status={lineItem.fulfillment_status}
                    type="fulfillment"
                  />

                 
                  
                </div>
                <div>
                  SKU:{' '}
                  <SKUToolTip
                    sku={lineItem.sku}
                    findSKULevel={findSKULevel}
                    findUpcomingStock={findUpcomingStock}
                    findSKUStatus={findSKUStatus}
                  />
                </div>
              </Col>
              <Col  className="p-3 sm:w-2/12">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
              <Col  className="p-3 sm:w-2/12 flex flex-col items-end font-medium">
                QUANTITY: {lineItem.quantity}
              </Col>
            </Row>
          </>
        );
      })}
      <Row>
        {selectedCount > 0 && (
          <Button onClick={handleFulfillClicked} type="submit" variant="success">
            {' '}
            Split Order
          </Button>
        )}
      </Row>
      <Dialog open={modalOpen} onOpenChange={setModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Split Order</DialogTitle>
          </DialogHeader>
          <div className="my-3">
            {lineItems
              .filter((val, key) => selectedItems[key] === true)
              .map((val, key) => (
                <div className="my-1" key={key}>
                  {val.sku} x {val.quantity}
                </div>
              ))}
          </div>
          <DialogFooter>
            {loading ? (
              <div className="flex justify-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Button variant="destructive" onClick={handleCreateSplit}>
                Create Split
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

const MonogramLineItems = ({
  order,
  refresh,
  getVariant,
  airtableData,
  findSKULevel,
  findUpcomingStock,
  findSKUStatus,
}) => {
  const handleUpdateItem = (id, machine) => {
    superagent
      .post(`${backendUrl}/orders/updateMonogramLineItem`)
      .send({ id, order_id: order._id, monogrammed_by: machine })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    order?.line_items &&
    order.line_items
      .filter(
        (val) =>
          val.monogram === true &&
          val.fulfillment_status !== 'unfulfilled' &&
          val.fulfillment_status !== 'fulfilled'
      )
      .map((lineItem) => {
        return (
          <Row>
            <Col  className="sm:w-2/12">
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={getVariant(lineItem.sku)?.image}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col className="p-3 sm:w-5/12">
              <h6>{getVariant(lineItem.sku)?.name} </h6>
              <h6 className="text-slateGray">
                {' '}
                {lineItem.monogrammed_by &&
                  `Monogrammed by ${lineItem.monogrammed_by
                    .replace('iron-man', 'Iron Man')
                    .replace('green-machine', 'Green Machine')
                    .replace('pitt-st', 'Pitt St')}`}
              </h6>
              <div>
                <Badge
                  className={
                    'font-size-12 badge-soft-' +
                    fulfillmentToBadge(lineItem.fulfillment_status)
                  }
                  color={fulfillmentToBadge(lineItem.fulfillment_status)}
                  pill
                >
                  {lineItem.fulfillment_status
                    ? lineItem.fulfillment_status
                        .replace('_', ' ')
                        .toUpperCase()
                    : 'UN-FULFILLED'}
                </Badge>
              </div>
              <div>
                SKU:{' '}
                <SKUToolTip
                  sku={lineItem.sku}
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock}
                  findSKUStatus={findSKUStatus}
                />
              </div>
            </Col>
            <Col className="p-3 sm:w-2/12">
              {lineItem.monogram && !lineItem.pet && (
                <a
                  href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                    lineItem.monogram_text
                  )}-${lineItem.monogram_color}-${
                    lineItem.monogram_shadow || ''
                  }-undefined${
                    lineItem.sku.startsWith('IPC')
                      ? `-IPC-${lineItem.monogram_position}`
                      : ''
                  }${
                    lineItem.sku.startsWith('BOT') &&
                    lineItem.monogram_position === 'Top'
                      ? '-Lid'
                      : ''
                  }.pdf?avoidTheCaches=1`}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>TEXT: {lineItem.monogram_text}</div>
                    <div>FONT: {lineItem.monogram_font}</div>
                    <div>COLOUR: {lineItem.monogram_color}</div>
                    {(lineItem.monogram_font === 'block' ||
                      lineItem.monogram_font === 'college' ||
                      lineItem.monogram_font === 'heritage' ||
                      lineItem.monogram_font === 'decorative') && (
                      <div>SHADOW: {lineItem.monogram_shadow}</div>
                    )}
                    <div>POSITION: {lineItem.monogram_position}</div>
                  </div>
                </a>
              )}
              {lineItem.pet &&
                lineItem.monogram_airtable_line &&
                airtableData &&
                lineItem.monogram_pets &&
                lineItem.monogram_airtable_line.map((val, key) => {
                  const currentAirtableFind = airtableData.find(
                    (airtablerecord) => airtablerecord.id === val
                  );
                  if (!currentAirtableFind) return <></>;
                  const currentAirtable = currentAirtableFind['fields'];
                  return (
                    <div>
                      <a
                        href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div>POSITION: {lineItem.monogram_position}</div>
                        <div>
                          {JSON.parse(atob(lineItem.monogram_pets))[key].name}{' '}
                          {currentAirtable['Status']}
                        </div>
                        <div>
                          <img
                            src={
                              currentAirtable['Completed illustration'] ===
                              undefined
                                ? currentAirtable['Provided Image'][0][
                                    'thumbnails'
                                  ]['small']['url']
                                : currentAirtable['Completed illustration'][0][
                                    'thumbnails'
                                  ]['small']['url']
                            }
                          />
                        </div>
                      </a>
                    </div>
                  );
                })}
            </Col>
            <Col  className="p-3 sm:w-2/12">
              {lineItem.quantity}x ${lineItem.price}
            </Col>
            <Col  className="p-3 sm:w-1/12">
              <DropdownMenu>
                <DropdownMenuTrigger href="#" className="card-drop" tag="i">
                  
                  <i className="mdi mdi-dots-horizontal font-size-18"></i>
                </DropdownMenuTrigger>
                <DropdownMenuContent right>
                  {lineItem.fulfillment_status === 'to-be-monogrammed' && (
                    <>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'green-machine');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Green Machine ){' '}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'iron-man');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Iron-Man){' '}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'julie');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for embroidery ( Julie ){' '}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'white-machine');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( White Machine ){' '}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'grey-machine');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Grey Machine ){' '}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'pitt-st');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Pitt St ){' '}
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          handleUpdateItem(lineItem._id, 'emoss');
                        }}
                        href="#"
                      >
                        {' '}
                        Mark in for monogramming ( Embossing ){' '}
                      </DropdownMenuItem>
                    </>
                  )}
                  {lineItem.fulfillment_status === 'in-for-monogram' && (
                    <DropdownMenuItem
                      onClick={() => {
                        handleUpdateItem(lineItem._id);
                      }}
                      href="#"
                    >
                      {' '}
                      Mark ready to ship{' '}
                    </DropdownMenuItem>
                  )}

                  {lineItem.pet === true && (
                    <DropdownMenuItem
                      onClick={() => {
                        alert('NEED TO IMPLEMENT COPY EMAIL');
                      }}
                      href="#"
                    >
                      {' '}
                      Contact customer{' '}
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </Col>
          </Row>
        );
      })
  );
};

const Header = ({
  order,
  handleOpenPopup,
  shopifyUrl,
  toggleCancelOpen,
  handleManuallyFulfill,
  handleSendLaunchLabel,
  handleFlag,
  handleContacted,
  handleRefreshFulfillment,
  togglemodalReturnOld,
  togglemodalReturn,
  handleRefreshMonogramFiles,
  cancelOrderFulfillment,
  handleUnpick,
  handleMispick,
  handleFixLaunch,
  handleToggleAssignedUser,
  user
}) => {
  return (
    <Row className="mb-6 flex flex-col sm:flex-row items-start sm:items-center gap-4">
      {/* Left side - Order info */}
      <Col className="w-full sm:w-1/2">
        <div className="flex items-center gap-3">
          <CardTitle className="text-2xl sm:text-3xl font-medium mb-2">
            {order?.name}
          </CardTitle>
          <OrderViewersFab />
        </div>
        
        <p className="text-gray-600 text-sm sm:text-base">
          Placed on {new Date(order.orderCreated).toLocaleString()}
        </p>
        <div>
          {order.flagged && (
            <Badge className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-50 text-red-700 border-red-100">
              Flagged
            </Badge>
          )}
          {!order.ready_to_ship && (
            <Badge className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-50 text-red-700 border-red-100">
              Not ready to ship
            </Badge>
          )}
        </div>

      </Col>

      {/* Right side - Actions */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 w-full sm:w-1/2 sm:justify-end">
        {/* Order Wizard Button */}
        <Button
          variant="primary"
          size="sm"
          className="flex items-center gap-2 px-3 sm:px-4 py-2 bg-black text-white hover:bg-gray-800 w-full sm:w-auto text-sm"
          onClick={handleOpenPopup}
        >
          <svg 
            className="w-4 h-4" 
            viewBox="0 0 24 24" 
            fill="none"
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" 
            />
          </svg>
          <span className="hidden sm:inline">Order Wizard</span>
        </Button>

        {/* Shopify Button */}
        {shopifyUrl && (
          <a
            target="_blank"
            href={`${shopifyUrl}admin/orders/${order.shopify_id}?orderListBeta=true`}
            rel="noreferrer"
            className="w-full sm:w-auto"
          >
            <Button 
              variant="primary"
              className="flex items-center gap-2 px-3 sm:px-4 py-2 text-white w-full sm:w-auto text-sm"
            >
              <svg 
                className="w-4 h-4" 
                viewBox="0 0 24 24" 
                fill="currentColor"
              >
                <path d="M19.1328 5.11645C19.1168 5.05091 19.0644 5.00372 19.0004 4.99574C18.9364 4.98775 18.8752 5.02006 18.8401 5.07992L18.0732 6.24827C18.0732 6.24827 16.8274 5.93261 16.8114 5.93261C16.7954 5.93261 15.1381 5.93261 15.1381 5.93261L13.8923 4.35355C13.8603 4.30636 13.8123 4.27405 13.7563 4.26607L13.2524 4.21888C13.2444 4.15334 13.2284 4.0878 13.2124 4.02226C12.9766 2.93935 12.4247 2.19503 11.6098 1.82655C11.5138 1.77936 11.4098 1.74705 11.3058 1.70676C10.8979 1.5756 10.4341 1.5756 9.99815 1.70676C9.89415 1.74705 9.79015 1.77936 9.69415 1.82655C8.87923 2.19503 8.32731 2.93935 8.09154 4.02226C8.07554 4.0878 8.05954 4.15334 8.05154 4.21888L7.54762 4.26607C7.49162 4.27405 7.44362 4.30636 7.41162 4.35355L6.16585 5.93261C6.16585 5.93261 4.50854 5.93261 4.49254 5.93261C4.47654 5.93261 3.23077 6.24827 3.23077 6.24827L2.46385 5.07992C2.42885 5.02006 2.36485 4.98775 2.30085 4.99574C2.23685 5.00372 2.18485 5.05091 2.16885 5.11645L0.0403845 16.6772C-0.0476155 17.0618 0.0403845 17.4624 0.280154 17.7833C0.519923 18.1041 0.887692 18.2836 1.28746 18.2836H20.0163C20.4161 18.2836 20.7838 18.1041 21.0236 17.7833C21.2634 17.4624 21.3514 17.0618 21.2634 16.6772L19.1328 5.11645ZM10.9061 2.77503C11.0341 2.72784 11.1701 2.72784 11.2981 2.77503C11.8341 2.98678 12.2178 3.48708 12.3938 4.21888L12.4498 4.45898H9.75015L9.80615 4.21888C9.98215 3.48708 10.3658 2.98678 10.9061 2.77503ZM4.77231 6.71921L6.42962 6.40355L7.75938 4.74013L8.11115 4.70782L8.06315 4.91159C8.05154 4.97713 8.03554 5.04267 8.02754 5.10821L7.82777 6.40355L4.77231 6.71921ZM14.4752 6.40355L14.2754 5.10821C14.2674 5.04267 14.2514 4.97713 14.2402 4.91159L14.1922 4.70782L14.544 4.74013L15.8737 6.40355L17.531 6.71921L14.4752 6.40355Z"/>
                <path d="M20.0166 19.0117H1.28777C0.68777 19.0117 0.167769 19.5281 0.167769 20.1481C0.167769 20.7681 0.68777 21.2845 1.28777 21.2845H20.0166C20.6166 21.2845 21.1366 20.7681 21.1366 20.1481C21.1366 19.5281 20.6166 19.0117 20.0166 19.0117Z"/>
              </svg>
              <span className="hidden sm:inline">View in Shopify</span>
            </Button>
          </a>
        )}

        <OrderDropdownMenu
          order={order}
          handleMispick={handleMispick}
          toggleCancelOpen={toggleCancelOpen}
          handleManuallyFulfill={handleManuallyFulfill}
          handleSendLaunchLabel={handleSendLaunchLabel}
          handleFlag={handleFlag}
          handleContacted={handleContacted}
          handleRefreshFulfillment={handleRefreshFulfillment}
          togglemodalReturnOld={togglemodalReturnOld}
          togglemodalReturn={togglemodalReturn}
          handleRefreshMonogramFiles={handleRefreshMonogramFiles}
          cancelOrderFulfillment={cancelOrderFulfillment}
          handleUnpick={handleUnpick}
          handleFixLaunch={handleFixLaunch}
          handleToggleAssignedUser={handleToggleAssignedUser}
          user={user}
        />
      </div>
    </Row>
  );
};

const LineItemRow = ({ lineItem, getVariant, findSKULevel, findUpcomingStock, findSKUStatus, handleEditLineItem, handleDeleteLineItem, handleRemoveAwaitingStock, handleAwaitingStock }) => {
  return (
    <Row>
      <Col className="sm:w-2/12">
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
        </div>
      </Col>
      <Col className="w-5/12 p-3">
        <h6 className="text-black font-medium">{getVariant(lineItem.sku)?.name}</h6>
        <div>
          <StatusBadge
            status={lineItem.fulfillment_status}
            type="fulfillment"
          />
            
            
            
        </div>
        <div className="text-gray-500">
          SKU:{' '}
          <SKUToolTip
            sku={lineItem.sku}
            findSKULevel={findSKULevel}
            findUpcomingStock={findUpcomingStock}
            findSKUStatus={findSKUStatus}
          />
        </div>
        <div>Allocated Warehouse: {lineItem.allocatedWarehouse}</div>
      </Col>
      <Col  className="p-3 sm:w-2/12">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
     
        <Col className="p-3 sm:w-2/12 flex flex-col items-end">
         <p className="text-black font-medium">{lineItem.quantity}x ${lineItem.price}</p> 
       
      </Col>
      <Col className="p-3 sm:w-1/12 flex flex-col items-end">
      {((lineItem.fulfillment_status === 'unfulfilled' && (lineItem.monogram == false || lineItem.monogram == undefined)) ||
        (lineItem.fulfillment_status === 'to-be-monogrammed' && lineItem.monogram === true) ||
        lineItem.fulfillment_status !== 'deleted') && (
          <DropdownMenu>
            <DropdownMenuTrigger href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18"></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent right>
              {((lineItem.fulfillment_status === 'unfulfilled' && (lineItem.monogram == false || lineItem.monogram == undefined)) ||
                (lineItem.fulfillment_status === 'to-be-monogrammed' && lineItem.monogram === true) ||
                lineItem.fulfillment_status === 'awaiting-stock') && (
                <DropdownMenuItem onClick={() => handleEditLineItem(lineItem)} href="#">
                  Edit
                </DropdownMenuItem>
              )}
              {lineItem.fulfillment_status !== 'deleted' && (
                <DropdownMenuItem onClick={() => handleDeleteLineItem(lineItem._id)} href="#">
                  Delete
                </DropdownMenuItem>
              )}
              {lineItem.fulfillment_status === 'awaiting-stock' && lineItem.bypass_awaiting_stock !== true && (
                <DropdownMenuItem onClick={() => handleRemoveAwaitingStock(lineItem._id)} href="#">
                  Remove from awaiting stock
                </DropdownMenuItem>
              )}
              {lineItem.bypass_awaiting_stock === true && (
                <DropdownMenuItem onClick={() => handleAwaitingStock(lineItem._id)} href="#">
                  Remove from bypass awaiting stock
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Col>
    </Row>
  );
};


const FulfilledLineItems = ({ lineItems, getVariant, findSKULevel, findUpcomingStock, findSKUStatus }) => {
  return (
    <>
      {lineItems?.length > 0 && lineItems.map((lineItem) => (
        <Row>
          <Col className="sm:w-2/12">
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col className="p-3 sm:w-5/12">
            <h6>{getVariant(lineItem.sku)?.name}</h6>
            <div>
                <StatusBadge
                    status={lineItem.fulfillment_status}
                    type="fulfillment"
                />
            </div>
            <div>
              SKU:{' '}
              <SKUToolTip
                sku={lineItem.sku}
                findSKULevel={findSKULevel}
                findUpcomingStock={findUpcomingStock}
                findSKUStatus={findSKUStatus}
              />
            </div>
          </Col>
          <Col  className="p-3 sm:w-2/12">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
          <Col className="sm:w-2/12 p-3">
            {lineItem.quantity}x ${lineItem.price}
          </Col>
        </Row>
      ))}
    </>
  );
};



const OrderDropdownMenu = ({
  order,
  toggleCancelOpen,
  handleManuallyFulfill,
  handleSendLaunchLabel,
  handleFlag,
  handleContacted,
  handleRefreshFulfillment,
  togglemodalReturnOld,
  togglemodalReturn,
  handleRefreshMonogramFiles,
  cancelOrderFulfillment,
  handleUnpick,
  handleMispick,
  handleFixLaunch,
  handleToggleAssignedUser,
  user
}) => {
  return (
    <DropdownMenu style={{ display: 'inline-block', padding: '0 16px' }}>
      <DropdownMenuTrigger href="#" className="ml-2 h-9 px-3 py-auto bg-white" tag="i">
        
        <i className="mdi mdi-dots-horizontal font-size-18"></i>
        
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" side="left">
        {(order.fulfillment_status === 'unfulfilled' ||
          order.fulfillment_status === 'awaiting-stock') && (
          <DropdownMenuItem onClick={toggleCancelOpen} href="#">
            {' '}
            Cancel{' '}
          </DropdownMenuItem>
        )}
        {user.email === 'lachi@july.com' && (
          <DropdownMenuItem onClick={handleManuallyFulfill} href="#">
            {' '}
            Manually Fulfill Without Picking{' '}
          </DropdownMenuItem>
        )}
        {order.pick_status === 'LAUNCH-MONO' && (
          <DropdownMenuItem onClick={handleSendLaunchLabel} href="#">
            {' '}
            Resend Launch Labels{' '}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={(e) => handleFlag(e)} href="#">
          {' '}
          {order.flagged ? 'Unflag' : 'Flag'}{' '}
        </DropdownMenuItem>
        {order.contacted === false && (
          <DropdownMenuItem onClick={handleContacted} href="#">
            {' '}
            Contacted customer{' '}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem onClick={handleRefreshFulfillment} href="#">
          {' '}
          Refresh Fulfillment Status{' '}
        </DropdownMenuItem>

        <DropdownMenuItem onClick={togglemodalReturnOld} href="#">
          {' '}
          Create return in spreadsheet
        </DropdownMenuItem>
        <DropdownMenuItem onClick={togglemodalReturn} href="#">
          {' '}
          Create repair in JIFI
        </DropdownMenuItem>
        {order.contains_monogram === true && (
          <DropdownMenuItem onClick={handleRefreshMonogramFiles} href="#">
            {' '}
            Refresh all Monograms{' '}
          </DropdownMenuItem>
        )}
        {order.fulfillment_status === 'fulfilled' && (
          <DropdownMenuItem onClick={cancelOrderFulfillment} href="#">
            {' '}
            Unfulfill{' '}
          </DropdownMenuItem>
        )}
        {order.fulfillment_status === 'unfulfilled' && (
          <DropdownMenuItem onClick={handleFixLaunch} href="#">
            {' '}
            Fix launch duplicate issue{' '}
          </DropdownMenuItem>
        )}


           <FeedBackForm
                              order={order}
                            />
                            <WarrantyClaimForm
                            order={order}
                            />
                            <RepairTicketForm
                            order={order}
                            />
        {order.pick_status !== 'none' && (
          <DropdownMenuItem onClick={handleUnpick} href="#">
            {' '}
            Unpick{' '}
          </DropdownMenuItem>
        )}
        <DropdownMenuItem 
        onClick={handleMispick}
        >
          {order.mispick_at ? 'Remove Mispick' : 'Mark as Mispicked'}
          </DropdownMenuItem>
        {
          <DropdownMenuItem onClick={handleToggleAssignedUser} href="#">
            Assigned User
          </DropdownMenuItem>
        }
      </DropdownMenuContent>
    </DropdownMenu>
  );
};


const LineItemsSection = ({ order, orderID, refreshData, findSKULevel, findUpcomingStock, findSKUStatus, getVariant, printDeliveryLabels, handleEditLineItem, handleDeleteLineItem, handleRemoveAwaitingStock, handleAwaitingStock }) => {
  return (
    <Col className="w-full">
      <Card className="my-6">
        <CardBody>
          <CardTitle className="mt-4 text-xl font-medium">Line Items</CardTitle>
          <Tabs className="mt-4" defaultValue="1">
            <TabsList>
              <TabsTrigger value="1">All</TabsTrigger>
              <TabsTrigger value="2">Unfulfilled</TabsTrigger>
              <TabsTrigger value="3">Monogramming</TabsTrigger>
              <TabsTrigger value="4">Fulfilled</TabsTrigger>
              <TabsTrigger value="5">Split</TabsTrigger>
              
            </TabsList>

            <TabsContent value="1">
              <Row className="flex-col align-items-start">
                <Col className="w-full">
                  {order?.line_items && order.line_items
                    .filter((val) => val.sku !== undefined)
                    .map((lineItem) => (
                      <LineItemRow 
                        lineItem={{...lineItem, name: getVariant(lineItem.sku)?.name}}
                        getVariant={getVariant}
                        findSKULevel={findSKULevel}
                        findUpcomingStock={findUpcomingStock}
                        findSKUStatus={findSKUStatus}
                        handleEditLineItem={handleEditLineItem}
                        handleDeleteLineItem={handleDeleteLineItem}
                        handleRemoveAwaitingStock={handleRemoveAwaitingStock}
                        handleAwaitingStock={handleAwaitingStock}
                      />
                    ))}
                </Col>
                <AddModal orderID={orderID} refresh={refreshData} />
              </Row>
            </TabsContent>

            <TabsContent value="2">
              {order.line_items
                .filter(
                  (val) =>
                    val.fulfillment_status === 'unfulfilled' ||
                    (val.fulfillment_status === 'awaiting-stock' &&
                      val.monogram === false)
                )
                .filter((val) => val.sku !== undefined)
                .length > 0 ? (
                <UnfulfilledLineItems 
                  shipping_address={order.shipping_address}
                  postcode={order.shipping_address.zip}
                  pick_status={order.pick_status}
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock}
                  findSKUStatus={findSKUStatus}
                  printDeliveryLabels={printDeliveryLabels}
                  getVariant={getVariant}
                  distanceFromHQ={order.distanceFromHQ}
                  contains_monogram={order.contains_monogram}
                  refresh={refreshData}
                  shipping_type={order?.shipping_type}
                  order_source={order?.order_source}
                  orderName={order.name}
                  orderID={order._id}
                  lineItems={order.line_items
                    .filter(
                      (val) =>
                        val.fulfillment_status === 'unfulfilled' ||
                        (val.fulfillment_status === 'awaiting-stock' &&
                          val.monogram === false)
                    )
                    .filter((val) => val.sku !== undefined)}
                />
              ) : (
                <div className="text-center py-4">No unfulfilled items</div>
              )}
            </TabsContent>

            <TabsContent value="3">
              {order.line_items
                .filter(
                  (val) =>
                    val.monogram === true &&
                    val.fulfillment_status !== 'unfulfilled' &&
                    val.fulfillment_status !== 'fulfilled'
                )
                .length > 0 ? (
                <MonogramLineItems
                  order={order} 
                  getVariant={getVariant}
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock}
                  findSKUStatus={findSKUStatus}
                  refresh={refreshData}
                />
              ) : (
                <div className="text-center py-4">No monogramming items</div>
              )}
            </TabsContent>

            <TabsContent value="4">
              {order.line_items
                .filter((val) => val.sku !== undefined)
                .filter(
                  (val) =>
                    val.fulfillment_status === 'fulfilled'
                )
                .length > 0 ? (
                <FulfilledLineItems
                  order={order}
                  lineItems={order.line_items
                    .filter((val) => val.sku !== undefined)
                    .filter(
                      (val) =>
                        val.fulfillment_status === 'fulfilled'
                    )}
                  getVariant={getVariant} 
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock}
                  findSKUStatus={findSKUStatus}
                />
              ) : (
                <div className="text-center py-4">No fulfilled items</div>
              )}
            </TabsContent>

            <TabsContent value="5">
              <SplitLineItems
                printDeliveryLabels={printDeliveryLabels}
                getVariant={getVariant}
                distanceFromHQ={order.distanceFromHQ}
                contains_monogram={order.contains_monogram}
                refresh={refreshData}
                shipping_type={order?.shipping_type}
                order_source={order?.order_source}
                orderName={order.name}
                orderID={order._id}
                lineItems={order.line_items.filter(
                  (val) => val.sku !== undefined
                )}
                findSKULevel={findSKULevel}
                findUpcomingStock={findUpcomingStock}
                findSKUStatus={findSKUStatus}
              />
            </TabsContent>

            
          </Tabs>
        </CardBody>
      </Card>
    </Col>
  );
};




// start order page
const OrderPage = ({ match: { params }, google }) => {
  const { orderID } = params;
  const [refresh, setRefresh] = useState(true);
  const [modalEdit, setModalEdit] = useState();
  const [modalFlag, setModalFlag] = useState();
  const [modalAssignedUser, setModalAssignedUser] = useState();
  const [returnModal, setModalReturn] = useState();
  const [returnModalOld, setModalReturnOld] = useState();
  const [shippingModalEdit, setShippingModalEdit] = useState();
  const [addModalEdit, setAddModalEdit] = useState();
  const [editLineItem, setEditLineItem] = useState();
  const [flagLineItem, setFlagLineItem] = useState();
  const [cancelOpen, setCancelOpen] = useState();

  const userList = useUserStore((state) => state.userList);

  
  const getVariant = useProductData();

  const refreshData = () => {
    setRefresh((prev) => !prev);
  };

  const [data, state] = useQuery({
    url: `/orders/${orderID}`,
    refresh,
  });

  const [airtableData, airtableDataState] = useQuery({
    url: `/orders/fetchAllPetAirtableFromOrder/${orderID}`,
    refresh,
  });

  const findSKULevel = useSKULevels();
  const findUpcomingStock = useUpcomingStock();
  const findSKUStatus = useSKUStatus();

  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const packingSlipRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => packingSlipRef.current,
  });

  const packingLabelsRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingLabelsRef.current,
  });
  const handlePrintLabel = () => {
    printLabels();
  };

  const nonpackingLabelsRef = useRef();
  const nonprintLabels = useReactToPrint({
    content: () => nonpackingLabelsRef.current,
  });
  const nonhandlePrintLabel = () => {
    nonprintLabels();
  };

  const deliveryLabels = useRef();
  const printDeliveryLabels = useReactToPrint({
    content: () => deliveryLabels.current,
  });

  const [customActiveTab, setCustomActiveTab] = useState('1');

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const handleEditLineItem = (id) => {
    setEditLineItem(id);
    togglemodalEdit();
  };

  const handleFlagLineItem = (id) => {
    setFlagLineItem(id);
    togglemodalFlag();
  };

  const handleRemoveAwaitingStock = (id) => {
    superagent
      .post(`${backendUrl}/orders/removeAwaitingStock/${id}`)
      .send({
        orderID: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAwaitingStock = (id) => {
    superagent
      .post(`${backendUrl}/orders/removeBypassAwaitingStock/${id}`)
      .send({
        orderID: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAssignedUser = (id) => {
    superagent
      .post(`${backendUrl}/orders/assignedUser`)
      .send({
        orderID,
        userID: id,
        url: window.location.href,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
          handleToggleAssignedUser();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteLineItem = (id) => {
    superagent
      .post(`${backendUrl}/orders/cancelLineItem/${id}`)
      .send({
        orderID: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const order = data ? data : {};

  useEffect(() => {
    if (data?.name) {
      document.title = data.name + ' | JIFI';
    }
  }, [data?.name]);

  const [allUniquePets, setAllUniquePets] = useState({});
  const addUniquePet = (pet, airtableID) => {
    setAllUniquePets((prev) => {
      let all = { ...prev };
      all[airtableID] = pet;
      return all;
    });
  };
  useEffect(() => {
    if (order && airtableData && order?.line_items) {
      order.line_items.map((lineItem) => {
        if (lineItem.pet === true) {
          let petData = JSON.parse(atob(lineItem.monogram_pets));
          petData.map((pet, petKey) => {
            let airtableID = lineItem.monogram_airtable_line[petKey];
            const currentAirtableFind = airtableData.find(
              (airtablerecord) => airtablerecord.id === airtableID
            );

            if (currentAirtableFind !== undefined) {
              const currentAirtable = currentAirtableFind['fields'];

              allUniquePets[airtableID] = {
                ...pet,
                ...currentAirtable,
              };
            }
          });
        }
      });
    }
  }, [airtableData, order]);

  if (!data) {
    return <div></div>;
  }

  const labelData = order.line_items
    .filter((val) => val.monogram && val.fulfillment_status !== 'deleted')
    .map((data) => ({
      monogramId: data.monogramId,
      location: data.monogram_position,
      letters: data.monogram_text,
      font: data.monogram_font,
      mainCol: data.monogram_color,
      backCol: data.monogram_shadow,
      created_at: order.orderCreated,
      shopifyId: order.name,
      country: order.shipping_address?.country,
      lineItems: order.line_items
        .filter(
          (val) => val._id !== data._id && val.fulfillment_status !== 'deleted'
        )
        .map((val) => val.sku),
      sku: data.sku,
      quantity: data.quantity,
      embroided: data.embroided,

      pet: data.pet,
      monogram_pets: data.monogram_pets && JSON.parse(atob(data.monogram_pets)),
      monogram_airtable_line: data.monogram_airtable_line,
      completedThumbnails: order.completedThumbnails,
    }));

  const nonLabelData = order.line_items
    .filter((val) => !val.monogram && val.fulfillment_status !== 'deleted')
    .map((data) => ({
      monogramId: data.monogramId,
      location: data.monogram_position,
      letters: data.monogram_text,
      font: data.monogram_font,
      mainCol: data.monogram_color,
      backCol: data.monogram_shadow,
      created_at: order.orderCreated,
      shopifyId: order.name,
      country: order?.shipping_address?.country,
      lineItems: order.line_items
        .filter(
          (val) => val._id !== data._id && val.fulfillment_status !== 'deleted'
        )
        .map((val) => val.sku),
      sku: data.sku,
      quantity: data.quantity,
    }));

  const handleCancel = (e) => {
    e.preventDefault();
    toggleCancelOpen();
    superagent
      .get(`${backendUrl}/orders/${orderID}/cancel`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFlag = (e) => {
    e.preventDefault();
    if (order.flagged === true) {
      superagent
        .get(`${backendUrl}/orders/${orderID}/getFlagged`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response.body);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            console.log(response.body);
            refreshData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      handleFlagLineItem(orderID);
    }
  };

  const handleRefreshMonogramFiles = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/createAllMonograms`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleContacted = (e) => {
    e.preventDefault();
    superagent
      .get(`${backendUrl}/orders/${orderID}/contacted`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSendLaunchLabel = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/resendLaunchLabels/${orderID}`)
      .send({})
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleManuallyFulfill = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/manualFulfillNoPick`)
      .send({
        orderId: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRefreshFulfillment = (e) => {
    e.preventDefault();
    superagent
      .get(`${backendUrl}/orders/${orderID}/refreshFulfillmentStatus`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggleAssignedUser = () => {
    setModalAssignedUser(!modalAssignedUser);
  };

  const handleFixLaunch = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/fix_double_send_launch`)
      .send({
        orderId: orderID,
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }

  const handleMispick = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/mispick`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUnpick = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/unpick`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOrderFulfillment = (e) => {
    e.preventDefault();
    superagent
      .post(`${backendUrl}/orders/${orderID}/cancelFulfillments`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const togglemodalEdit = () => {
    setModalEdit((prev) => !prev);
    // printLabels()
  };

  const togglemodalReturn = () => {
    setModalReturn((prev) => !prev);
    // printLabels()
  };

  const togglemodalReturnOld = () => {
    setModalReturnOld((prev) => !prev);
    // printLabels()
  };

  const toggleAddModalEdit = () => {
    setAddModalEdit((prev) => !prev);
    // printLabels()
  };

  const toggleShippingNodalEdit = () => {
    setShippingModalEdit((prev) => !prev);
    // printLabels()
  };

  const toggleCancelOpen = () => {
    setCancelOpen((prev) => !prev);
    // printLabels()
  };

  const togglemodalFlag = () => {
    setModalFlag((prev) => !prev);
    // printLabels()
  };

  const handleEditShipping = (e) => {
    e.preventDefault();
    toggleShippingNodalEdit();
  };

  const toggleWizardComponent = () => {
    setPopupOpen((prev) => !prev);
  };

  const orderSourceToShopify = {
    shopify_au: 'AU',
    qv_store: 'AU',
    galeries_store: 'AU',
    bondi: 'AU',
    '80_collins': 'AU',
    hq_store: 'AU',
    pitt_st: 'AU',
    pacfair: 'AU',
    james_st: 'AU',
    parramatta: 'AU',

    rosebery: 'AU',
    castle_towers: 'AU',
    highpoint: 'AU',
    emporium: 'AU',

    chadstone: 'AU',
    greene: 'US',

    shopify_nz: 'NZ',
    shopify_us: 'US',
    shopify_uk: 'UK',
    shopify_ca: 'CA',
  };
  const shopifyLocale = orderSourceToShopify[order.order_source];
  const shopifyToUrl = {
    AU: 'https://july-travel.myshopify.com/',
    US: 'https://july-us.myshopify.com/',
    NZ: 'https://july-nz-store.myshopify.com/',
    UK: 'https://july-uk.myshopify.com/',
    CA: 'https://july-ca.myshopify.com/',
  };

  
  
  let shopifyUrl;
  if (shopifyLocale) {
    shopifyUrl = shopifyToUrl[shopifyLocale];
  }

  const user = JSON.parse(localStorage.getItem('authUser'));

  return (
    <div className="page-content">
      <div className="mx-auto px-4 xl:max-w-[85%]">
        <Header 
         order={order}
         handleOpenPopup={handleOpenPopup}
         shopifyUrl={shopifyUrl}
         toggleCancelOpen={toggleCancelOpen}
         handleManuallyFulfill={handleManuallyFulfill}
         handleSendLaunchLabel={handleSendLaunchLabel}
         handleFlag={handleFlag}
         handleContacted={handleContacted}
         handleRefreshFulfillment={handleRefreshFulfillment}
         togglemodalReturnOld={togglemodalReturnOld}
         togglemodalReturn={togglemodalReturn}
         handleRefreshMonogramFiles={handleRefreshMonogramFiles}
         cancelOrderFulfillment={cancelOrderFulfillment}
         handleUnpick={handleUnpick}
         handleMispick={handleMispick}
         handleFixLaunch={handleFixLaunch}
         handleToggleAssignedUser={handleToggleAssignedUser}
         user={user}
        />
        <Row className="flex flex-wrap -mx-3">
          <Col className="w-full md:w-8/12 px-3">
            <Row>
              <Col className="w-full">
              
                <OrderDetailsSection 
                  order={order}
                  refreshData={refreshData}
                  EditModalShipping={EditModalShipping}
                />
              </Col>
            </Row>

            <Row className="flex-col">
              <Col className="w-full">
                <LineItemsSection 
                  order={order}
                  orderID={orderID}
                  refreshData={refreshData}
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock} 
                  findSKUStatus={findSKUStatus}
                  handleEditLineItem={handleEditLineItem}
                  handleDeleteLineItem={handleDeleteLineItem}
                  handleRemoveAwaitingStock={handleRemoveAwaitingStock}
                  handleAwaitingStock={handleAwaitingStock}
                  getVariant={getVariant}
                  printDeliveryLabels={printDeliveryLabels}
                  airtableData={airtableData}
                />
              </Col>
            </Row>

            <Row>
              <Col className="w-full">
                <LabelsSection
                  handlePrint={handlePrint}
                  order={order}
                  nonpackingLabelsRef={nonpackingLabelsRef}
                  packingLabelsRef={packingLabelsRef}
                  deliveryLabels={deliveryLabels}
                  nonhandlePrintLabel={nonhandlePrintLabel}
                  handlePrintLabel={handlePrintLabel}
                  printDeliveryLabels={printDeliveryLabels}
                />
              </Col>
            </Row>

          </Col>

          <Col className="md:w-4/12 px-3">
            <div style={{ position: 'relative', height: '100%' }}>
              <div id="stickyTest" className='mb-4' style={{ position: 'sticky', top: 100 }}>
                
              <CustomerInfoSection 
                  order={order}
                  orderID={orderID}
                />
                
                <NotesSection
                  refresh={refresh}
                  order={order}
                />

               

              </div>
            </div>
          </Col>
        </Row>
        <OrderWizardComponent
        findSKULevel={findSKULevel}
        refreshOrder={refreshData}
        orderID={orderID}
        open={isPopupOpen}
        togglemodal={toggleWizardComponent}
        order={order}
        handleFlag={handleFlag}
      />

      </div>

      <div style={{ display: 'none' }}>
        <PackingSlip order={order} ref={packingSlipRef} />
      </div>

      {order.contains_monogram && (
        <div style={{ display: 'none' }}>
          <PrintPage
            ref={packingLabelsRef}
            monogramRecords={labelData}
            monogram={true}
          />
        </div>
      )}

      <div style={{ display: 'none' }}>
        <PrintPage
          ref={nonpackingLabelsRef}
          monogramRecords={nonLabelData}
          monogram={false}
        />
      </div>

      <div style={{ display: 'none' }}>
        <PrintPageDeliveries
          ref={deliveryLabels}
          labels={order.line_items.map((val) => ({ quantity: val.quantity }))}
          name={order?.shipping_address?.name}
          address1={order?.shipping_address?.address1}
          city={order?.shipping_address?.city}
          zip={order?.shipping_address?.zip}
          phone={order?.shipping_address?.phone}
        />
      </div>

      {editLineItem && (
        <EditModal
          addUniquePet={addUniquePet}
          order={order}
          allUniquePets={allUniquePets}
          orderID={orderID}
          editLineItem={editLineItem}
          refresh={refreshData}
          togglemodal={togglemodalEdit}
          open={modalEdit}
        />
      )}

      <AssignedUserModal
        users={userList}
        togglemodal={handleToggleAssignedUser}
        isOpen={modalAssignedUser}
        defaultChecked={data?.assigned_user?._id}
        handleAssignedUser={handleAssignedUser}
      />

     
      {returnModal && (
        <ReturnsModal
          locale={['shopify_us', 'goop'].includes(order.order_source) ? 'US' : 'AU'}
          orderID={orderID}
          order={order}
          refresh={refreshData}
          togglemodal={togglemodalReturn}
          isOpen={returnModal}
        />
      )}

      <ReturnsModalOld
        locale={['shopify_us', 'goop'].includes(order.order_source) ? 'US' : 'AU'}
        orderID={orderID}
        order={order}
        refresh={refreshData}
        isOpen={returnModalOld}
        togglemodal={togglemodalReturnOld}
      />

      <FlaggedModal
        isOpen={modalFlag}
        orderID={orderID}
        flagLineItem={flagLineItem}
        refresh={refreshData}
        togglemodal={togglemodalFlag}
      />

      <CancelOrderDialog
        cancelOpen={cancelOpen}
        toggleCancelOpen={toggleCancelOpen}
        handleCancel={handleCancel}
      />

    </div>
  );
};

// end order page

const returnTypes = ['REPAIR', 'REPLACEMENT', 'RETURN', 'EXCHANGE'];

const defaultReasons = {
  REPAIR: [
    'TSA_LOCK',
    'HANDLE_TELESCOPIC',
    'HANDLE_CARRY',
    'TRUNK_LOCK',
    'SNAPSLEEVE_FIDLOCK',
    'TRUNK_CLIP',
    'LUGGAGE_FEET',
    'MONOGRAM_REPRINT',
    'ZIP',
  ],
  EXCHANGE: ['Change size', 'Change product', 'Change colour', 'Other'],
  RETURN: [
    'Change of Mind',
    'Too small',
    'Too Large',
    'Colour',
    'Quality',
    'Warranty',
    'Other',
  ],
  REPLACEMENT: [
    'Wheels',
    'Handle',
    'Zip',
    'Shell',
    'Lining',
    'Snapsleeve',
    'Strap',
    'Power bank',
    'Other',
  ],
};

const ReturnsModal = ({
  togglemodal,
  refresh,
  order,
  orderID,
  locale = 'US',
  isOpen = false,
}) => {
  const initParcel = {
    lineItems: [],
    lineItemsFlat: undefined,
    product: undefined,
    productData: undefined,
    parcelSize: undefined,
    quantity: 1,
  };

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [parcelData, setParcelData] = useState([initParcel]);
  const [returnItems, setReturnItems] = useState([]);
  const [refundAmount, setRefundAmount] = useState(0);
  const [returnType, setReturnType] = useState({
    label: returnTypes[0],
    value: returnTypes[0],
  });
  const [reason, setReason] = useState({
    label: defaultReasons[returnTypes[0]][0],
    value: defaultReasons[returnTypes[0]][0],
  });
  const [description, setDescription] = useState();
  useEffect(() => {
    setReason({
      label: defaultReasons[returnType.value][0],
      value: defaultReasons[returnType.value][0],
    });
    setDescription('');
  }, [returnType]);

  const [data, state] = useQuery({
    url: `/product`,
    refresh,
    runQuery: isOpen,
  });
  const productData = data && data.data;

  const [dataParcel, stateParcel] = useQuery({
    url: `/orders/fetchAllParcels`,
  });
  const parcels = dataParcel;

  useEffect(() => {
    setParcelData((prev) => {
      const tempReturnItems = selectedLineItems
        .map((lineItem) =>
          Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem.id,
            dupeId: `${lineItem.id}-${i}`,
            selected: undefined,
          }))
        )
        .flat();

      if (!productData || !parcels) {
        return [initParcel];
      }

      let flatItems = tempReturnItems.map((item) => {
        const rootSku = item.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-');
        const filteredProducts = productData
          .map((product, productId) => {
            const filteredVariants = product.variants.filter(
              (variant) => variant.sku === rootSku
            );
            if (filteredVariants.length === 0) {
              return undefined;
            }
            return { id: productId, variant: filteredVariants };
          })
          .filter((val) => val !== undefined);
        let p, pd;
        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id];
          p = selectedProduct && selectedProduct._id;
          pd = { label: selectedProduct.name, value: selectedProduct._id };
        }

        let filteredParcelSize = parcels.filter(
          (parcel) => `${parcel.product._id}` == `${p}`
        );
        let parcelSize, parcelSizeData, cubic;

        let parcel =
          filteredParcelSize.length > 0 ? filteredParcelSize[0] : parcels[0];
        parcelSize = parcel._id;
        parcelSizeData = {
          label: `${parcel.product.name} - ${parcel.length}x${parcel.width}x${parcel.depth} ${parcel.weight}kg`,
          value: parcel._id,
        };
        cubic = parcel.length * parcel.width * parcel.depth;

        return {
          lineItems: [item.dupeId],
          lineItemsFlat: [{ label: item.sku, value: item.dupeId }],
          product: p,
          productData: pd,
          parcelSize: parcelSize,
          parcelSizeData: parcelSizeData,
          quantity: 1,
          cubic,
          sku: item.sku,
        };
      });

      return flatItems;
    });
    setReturnItems(
      selectedLineItems
        .map((lineItem) =>
          Array.from(Array(lineItem.quantity)).map((_, i) => ({
            sku: lineItem.sku,
            quantity: 1,
            id: lineItem.id,
            dupeId: `${lineItem.id}-${i}`,
            selected: undefined,
          }))
        )
        .flat()
    );
  }, [selectedLineItems, productData]);

  useEffect(() => {
    setSelectedLineItems(
      order.line_items.map((lineItem) => ({
        sku: lineItem.sku.split('-P').join(''),
        quantity: lineItem.quantity,
        id: lineItem._id,
      }))
    );
  }, [order, parcels]);

  useEffect(() => {
    if (returnItems) {
      setReturnItems((prev) => {
        return prev.map((returnItem, rID) => {
          let parcelReduced = parcelData.map((parcel, parcelId) => ({
            id: parcelId,
            lineItem: parcel.lineItems.find((val) => val === returnItem.dupeId),
          }));
          parcelReduced = parcelReduced.filter(
            (val) => val.lineItem !== undefined
          );
          let updatedReturnItem = { ...returnItem };
          if (parcelReduced.length > 0) {
            updatedReturnItem.selected = parcelReduced[0].id;
          } else {
            updatedReturnItem.selected = undefined;
          }
          return updatedReturnItem;
        });
      });
    }
  }, [parcelData, step]);

  const addParcel = () => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines.push(initParcel);
      return updatedLines;
    });
  };

  const deleteParcel = (id) => {
    setParcelData((prev) => {
      return prev.filter((val, parcelId) => parcelId !== id);
    });
  };

  const handleSelectProduct = (selectedProduct, id) => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines[id].parcelSize = selectedProduct && selectedProduct.value;
      updatedLines[id].parcelSizeData = selectedProduct;
      return updatedLines;
    });
  };

  const handleSelectSkus = (selectedSkus, id) => {
    setParcelData((prev) => {
      let updatedLines = [...prev];
      updatedLines[id].lineItems =
        (Array.isArray(selectedSkus) && selectedSkus.map((a) => a.value)) || [];
      updatedLines[id].lineItemsFlat = selectedSkus;

      if (updatedLines[id].lineItems.length === 1) {
        const selectedLineId = updatedLines[id].lineItems[0];
        const selectedLine = returnItems.find(
          (val) => val.dupeId === selectedLineId
        );
        const rootSku = selectedLine.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-');
        const filteredProducts = productData
          .map((product, productId) => {
            const filteredVariants = product.variants.filter(
              (variant) => variant.sku === rootSku
            );
            if (filteredVariants.length === 0) {
              return undefined;
            }
            return { id: productId, variant: filteredVariants };
          })
          .filter((val) => val !== undefined);

        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id];
          updatedLines[id].product = selectedProduct && selectedProduct._id;
          updatedLines[id].productData = {
            label: selectedProduct.name,
            value: selectedProduct._id,
          };
        }
      }
      return updatedLines;
    });
  };

  const alterQuantity = (index, quantity) => {
    if (
      index >= 0 &&
      index <= selectedLineItems.length - 1 &&
      Number.isInteger(quantity) &&
      quantity <= order.line_items[index].quantity &&
      quantity >= 0
    ) {
      setSelectedLineItems((prev) => {
        let updatedLines = [...prev];
        updatedLines[index].quantity = quantity;
        return updatedLines;
      });
    }
  };

  const handleSave = () => {
    const proccessedParcels = parcelData.map((parcel) => {
      const lineItems = parcel.lineItems.map((lineID) =>
        returnItems.find((val) => val.dupeId === lineID)
      );
      const parcelSize = parcel.parcelSize;
      return {
        lineItems,
        parcelSize,
        quantity: 1,
      };
    });

    const exportData = {
      orderID: orderID,
      proccessedParcels: proccessedParcels,
      reason: reason.value,
      description,
      refundAmount,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/returns/createReturnFromOrder/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Dialog  open={isOpen} onOpenChange={togglemodal}>
      {loading === true ? (
        <DialogContent >
          <DialogHeader>
            <DialogTitle>Loading...</DialogTitle>
          </DialogHeader>
        </DialogContent>
      ) : (
        <DialogContent className="max-h-[80vh]  min-w-[60vw]">
          <DialogHeader>
            <DialogTitle>
              {locale} Return -{' '}
              {step === 0 ? 'Select Line Items' : 'Parcel Sizes'}
            </DialogTitle>
          </DialogHeader>
          <div className="text-slateGray mb-2">
            {step === 0
              ? 'Please select which SKUs and quantities you would like to proccess for the return.'
              : 'Please select which parcel sizes you would like for each sku (if you are nesting put them in the same parcel).'}
          </div>
          <div>
            {step === 0 ? (
              <>
                <div>
                  {selectedLineItems.map((lineItem, id) => (
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-4 col-form-label"
                      >
                        {' '}
                        {lineItem.sku}{' '}
                      </label>
                      <div className="col-md-8">
                        <Input
                          id={`line-item-${lineItem.sku}-${id}`}
                          className="mb-2"
                          type="number"
                          step="1"
                          value={lineItem.quantity}
                          onChange={(e) => {
                            alterQuantity(id, Number(e.target.value));
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pt-3 mt-3 border-top">
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-4 col-form-label"
                    >
                      {' '}
                      Reason{' '}
                    </label>
                    <div className="col-md-8">
                      <ReactSelect
                     
                        value={reason}
                   
                        onChange={(val) => {
                          setReason(val);
                        }}
                        options={defaultReasons[returnType.value].map(
                          (val) => ({ label: val, value: val })
                        )}
                        
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-md-4 col-form-label"
                    >
                      {' '}
                      Description{' '}
                    </label>
                    <div className="col-md-8">
                      <Input
                        placeholder="Reason Description"
                        className="mb-2"
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-4">SKUS</div>
                  <div className="col-md-5">PARCEL SIZE</div>
                  <div className="col-md-1"></div>
                  <div className="col-md-1"></div>
                </div>

                {parcelData.map((parcel, id) => {
                  return (
                    <div className="form-group row">
                      <div className="col-md-4">
                        <SelectMultiple
                         
                          value={parcel.lineItemsFlat}
                          onChange={(val) => {
                            handleSelectSkus(val, id);
                          }}
                          options={returnItems
                            .filter(
                              (val) =>
                                val.selected === id ||
                                val.selected === undefined
                            )
                            .map((val, id) => ({
                              label: val.sku,
                              value: val.dupeId,
                            }))}
                          isMulti
                        />
                      </div>
                      <div className="col-md-5">
                        {parcels && (
                          <SelectMultiple
                            
                            value={parcel.parcelSizeData}
                            onChange={(val) => {
                              handleSelectProduct(val, id);
                            }}
                            options={parcels.map((val, id) => ({
                              label: `${val.product.name} - ${val.length}x${val.width}x${val.depth} ${val.weight}kg`,
                              value: val._id,
                            }))}
                          />
                        )}
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-1">
                        <Button variant="destructive" onClick={() => deleteParcel(id)}>
                          {' '}
                          X{' '}
                        </Button>
                      </div>
                    </div>
                  );
                })}
                {returnItems.filter((val) => val.selected === undefined)
                  .length > 0 && (
                  <Button variant="primary" onClick={addParcel}>
                    {' '}
                    Add Line +{' '}
                  </Button>
                )}
              </>
            )}
          </div>
          <DialogFooter>
            {step === 0 ? (
              <Button variant="success" onClick={() => setStep(1)}>
                {' '}
                Next{' '}
              </Button>
            ) : (
              <>
                <Button variant="secondary" onClick={() => setStep(0)}>
                  {' '}
                  Back{' '}
                </Button>
                <Button
                  variant="success"
                  disabled={
                    returnItems.filter((val) => val.selected === undefined)
                      .length > 0
                  }
                  onClick={handleSave}
                >
                  {' '}
                  Send{' '}
                </Button>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
};




const ReturnsModalOld = React.memo(
  ({ togglemodal, refresh, order, orderID, isOpen = false, locale = 'US' }) => {
    const initParcel = {
      lineItems: [],
      lineItemsFlat: undefined,
      product: undefined,
      productData: undefined,
      parcelSize: undefined,
      quantity: 1,
    };

    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [selectedLineItems, setSelectedLineItems] = useState([]);
    const [parcelData, setParcelData] = useState([initParcel]);
    const [returnItems, setReturnItems] = useState([]);

    const [data, state] = useQuery({
      url: `/product`,
      refresh,
      runQuery: isOpen,
    });
    const productData = data && data.data;

    const [dataParcel, stateParcel] = useQuery({
      url: `/orders/fetchAllParcels`,
    });
    const parcels = dataParcel;

    useEffect(() => {
      setParcelData((prev) => {
        const tempReturnItems = selectedLineItems
          .map((lineItem) =>
            Array.from(Array(lineItem.quantity)).map((_, i) => ({
              sku: lineItem.sku,
              quantity: 1,
              id: lineItem.id,
              dupeId: `${lineItem.id}-${i}`,
              selected: undefined,
            }))
          )
          .flat();

        if (!productData || !parcels) {
          return [initParcel];
        }

        let flatItems = tempReturnItems.map((item) => {
          const rootSku = item.sku
            .split('-')
            .filter((val) => {
              return val !== 'P' && val !== 'LE';
            })
            .join('-');
          const filteredProducts = productData
            .map((product, productId) => {
              const filteredVariants = product.variants.filter(
                (variant) => variant.sku === rootSku
              );
              if (filteredVariants.length === 0) {
                return undefined;
              }
              return { id: productId, variant: filteredVariants };
            })
            .filter((val) => val !== undefined);
          let p, pd;
          if (filteredProducts.length > 0) {
            const selectedProduct = productData[filteredProducts[0].id];
            p = selectedProduct && selectedProduct._id;
            pd = { label: selectedProduct.name, value: selectedProduct._id };
          }

          let filteredParcelSize = parcels.filter(
            (parcel) => `${parcel.product._id}` == `${p}`
          );
          let parcelSize, parcelSizeData, cubic;

          let parcel =
            filteredParcelSize.length > 0 ? filteredParcelSize[0] : parcels[0];
          parcelSize = parcel._id;
          parcelSizeData = {
            label: `${parcel.product.name} - ${parcel.length}x${parcel.width}x${parcel.depth} ${parcel.weight}kg`,
            value: parcel._id,
          };
          cubic = parcel.length * parcel.width * parcel.depth;

          return {
            lineItems: [item.dupeId],
            lineItemsFlat: [{ label: item.sku, value: item.dupeId }],
            product: p,
            productData: pd,
            parcelSize: parcelSize,
            parcelSizeData: parcelSizeData,
            quantity: 1,
            cubic,
            sku: item.sku,
          };
        });

        return flatItems;
      });
      setReturnItems(
        selectedLineItems
          .map((lineItem) =>
            Array.from(Array(lineItem.quantity)).map((_, i) => ({
              sku: lineItem.sku,
              quantity: 1,
              id: lineItem.id,
              dupeId: `${lineItem.id}-${i}`,
              selected: undefined,
            }))
          )
          .flat()
      );
    }, [selectedLineItems, productData]);

    useEffect(() => {
      setSelectedLineItems(
        order.line_items.map((lineItem) => ({
          sku: lineItem.sku,
          quantity: lineItem.quantity,
          id: lineItem._id,
        }))
      );
    }, [order, parcels]);

    useEffect(() => {
      if (returnItems) {
        setReturnItems((prev) => {
          return prev.map((returnItem, rID) => {
            let parcelReduced = parcelData.map((parcel, parcelId) => ({
              id: parcelId,
              lineItem: parcel.lineItems.find(
                (val) => val === returnItem.dupeId
              ),
            }));
            parcelReduced = parcelReduced.filter(
              (val) => val.lineItem !== undefined
            );
            let updatedReturnItem = { ...returnItem };
            if (parcelReduced.length > 0) {
              updatedReturnItem.selected = parcelReduced[0].id;
            } else {
              updatedReturnItem.selected = undefined;
            }
            return updatedReturnItem;
          });
        });
      }
    }, [parcelData, step]);

    const addParcel = () => {
      setParcelData((prev) => {
        let updatedLines = [...prev];
        updatedLines.push(initParcel);
        return updatedLines;
      });
    };

    const deleteParcel = (id) => {
      setParcelData((prev) => {
        return prev.filter((val, parcelId) => parcelId !== id);
      });
    };

    const handleSelectProduct = (selectedProduct, id) => {
      setParcelData((prev) => {
        let updatedLines = [...prev];
        updatedLines[id].parcelSize = selectedProduct && selectedProduct.value;
        updatedLines[id].parcelSizeData = selectedProduct;
        return updatedLines;
      });
    };

    const handleSelectSkus = (selectedSkus, id) => {
      setParcelData((prev) => {
        let updatedLines = [...prev];
        updatedLines[id].lineItems =
          (Array.isArray(selectedSkus) && selectedSkus.map((a) => a.value)) ||
          [];
        updatedLines[id].lineItemsFlat = selectedSkus;

        if (updatedLines[id].lineItems.length === 1) {
          const selectedLineId = updatedLines[id].lineItems[0];
          const selectedLine = returnItems.find(
            (val) => val.dupeId === selectedLineId
          );
          const rootSku = selectedLine.sku
            .split('-')
            .filter((val) => {
              return val !== 'P' && val !== 'LE';
            })
            .join('-');
          const filteredProducts = productData
            .map((product, productId) => {
              const filteredVariants = product.variants.filter(
                (variant) => variant.sku === rootSku
              );
              if (filteredVariants.length === 0) {
                return undefined;
              }
              return { id: productId, variant: filteredVariants };
            })
            .filter((val) => val !== undefined);

          if (filteredProducts.length > 0) {
            const selectedProduct = productData[filteredProducts[0].id];
            updatedLines[id].product = selectedProduct && selectedProduct._id;
            updatedLines[id].productData = {
              label: selectedProduct.name,
              value: selectedProduct._id,
            };
          }
        }
        return updatedLines;
      });
    };

    const alterQuantity = (index, quantity) => {
      if (
        index >= 0 &&
        index <= selectedLineItems.length - 1 &&
        Number.isInteger(quantity) &&
        quantity <= order.line_items[index].quantity &&
        quantity >= 0
      ) {
        setSelectedLineItems((prev) => {
          let updatedLines = [...prev];
          updatedLines[index].quantity = quantity;
          return updatedLines;
        });
      }
    };

    const handleSave = () => {
      const proccessedParcels = parcelData.map((parcel) => {
        const lineItems = parcel.lineItems.map((lineID) =>
          returnItems.find((val) => val.dupeId === lineID)
        );
        const parcelSize = parcel.parcelSize;
        return {
          lineItems,
          parcelSize,
          quantity: 1,
        };
      });

      const exportData = {
        orderID: orderID,
        proccessedParcels: proccessedParcels,
      };

      setLoading(true);
      superagent
        .post(`${backendUrl}/orders/create${locale}Return/`)
        .send(exportData)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          setLoading(false);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            refresh();
            togglemodal();
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };

    return (
      <Dialog open={isOpen} onOpenChange={togglemodal}>
        {loading === true ? (
          <DialogContent className="z-[1600]">
            <DialogHeader>
              <DialogTitle>{locale} Return</DialogTitle>
            </DialogHeader>
            <div className="flex justify-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </DialogContent>
        ) : (
          <DialogContent className="z-[1600] min-w-[60vw]">
            <DialogHeader>
              <DialogTitle>
                {locale} Return -{' '}
                {step === 0 ? 'Select Line Items' : 'Parcel Sizes'}
              </DialogTitle>
              <DialogDescription>
                {step === 0
                  ? 'Please select which SKUs and quantities you would like to process for the return.'
                  : 'Please select which parcel sizes you would like for each SKU (if you are nesting, put them in the same parcel).'}
              </DialogDescription>
            </DialogHeader>

            <div className="mb-4">
              {step === 0 ? (
                <>
                  {selectedLineItems.map((lineItem, id) => (
                    <div className="flex items-center mb-2" key={id}>
                      <label className="w-1/4">{lineItem.sku}</label>
                      <Input
                        id={`line-item-${lineItem.sku}-${id}`}
                        type="number"
                        step="1"
                        value={lineItem.quantity}
                        onChange={(e) =>
                          alterQuantity(id, Number(e.target.value))
                        }
                        className="w-3/4"
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="grid grid-cols-3 gap-4">
                    <div>SKUS</div>
                    <div>PARCEL SIZE</div>
                    <div></div>
                    <div></div>
                  </div>

                  {parcelData.map((parcel, id) => (
                    <div className="grid grid-cols-3 gap-4 mb-2" key={id}>
                      <SelectMultiple
                        value={parcel.lineItemsFlat}
                        onChange={(val) => handleSelectSkus(val, id)}
                        options={returnItems
                          .filter(
                            (val) =>
                              val.selected === id || val.selected === undefined
                          )
                          .map((val) => ({
                            label: val.sku,
                            value: val.dupeId,
                          }))}
                        isMulti
                      />
                      {parcels && (
                        <SelectMultiple
                          value={parcel.parcelSizeData}
                          onChange={(val) => handleSelectProduct(val, id)}
                          options={parcels.map((val) => ({
                            label: `${val.product.name} - ${val.length}x${val.width}x${val.depth} ${val.weight}kg`,
                            value: val._id,
                          }))}
                        />
                      )}
                  
                      <div className='sm:w-1/12'>
                      <Button variant="destructive" onClick={() => deleteParcel(id)}>
                        X
                      </Button>
                      </div>
                    </div>
                  ))}
                  {returnItems.filter((val) => val.selected === undefined)
                    .length > 0 && (
                    <Button variant="primary" onClick={addParcel}>
                      Add Line +
                    </Button>
                  )}
                </>
              )}
            </div>

            <DialogFooter>
              {step === 0 ? (
                <Button variant="success" onClick={() => setStep(1)}>
                  Next
                </Button>
              ) : (
                <>
                  <Button variant="secondary" onClick={() => setStep(0)}>
                    Back
                  </Button>
                  <Button
                    variant="success"
                    disabled={
                      returnItems.filter((val) => val.selected === undefined)
                        .length > 0
                    }
                    onClick={handleSave}
                  >
                    Send
                  </Button>
                </>
              )}
            </DialogFooter>
          </DialogContent>
        )}
      </Dialog>
    );
  }
);

export const AssignedUserModal = ({
  defaultChecked,
  users,
  togglemodal,
  isOpen,
  handleAssignedUser,
}) => {
  const [selectedUser, setSelectedUser] = useState(''); // State to hold the selected user

  const handleSelectChange = (e) => {
    setSelectedUser(e);
  };

  const handleButtonClick = () => {
    handleAssignedUser(selectedUser);
  };
  return (
    <Dialog open={isOpen} onOpenChange={togglemodal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Assigned User</DialogTitle>
        </DialogHeader>
        <div>
          <label>User</label>
          <Select
            onValueChange={handleSelectChange}
            defaultValue={defaultChecked}
          >
            <SelectTrigger>
              <SelectValue placeholder="Please select" />
            </SelectTrigger>
            <SelectContent>
              {users?.map((val) => (
                <SelectItem key={val._id} value={val._id}>
                  {val.userName}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <DialogFooter>
          <Button
            variant="primary"
            onClick={handleButtonClick}
            style={{ marginTop: '10px' }}
          >
            Assign User
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const EditModal = ({
  togglemodal,
  refresh,
  editLineItem,
  orderID,
  allUniquePets,
  order,
  addUniquePet,
  open,
}) => {
  const [monogramType, setMonogramType] = useState(
    editLineItem?.pet === true ? 'pets' : 'text'
  );

  const [monogramPets, setMonogramPets] = useState(editLineItem.monogram_pets);
  const [monogramAirtableLines, setMonogramAirtableLines] = useState(
    editLineItem.monogram_airtable_line
  );

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  useEffect(() => {
    setLoading(true);
    superagent
      .get(
        `${backendUrl}/product/variant/${editLineItem.sku
          .split('-')
          .filter((val) => {
            return val !== 'P' && val !== 'LE';
          })
          .join('-')}/find`
      )
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          setOptions(response.body);
          setSelectedOption(editLineItem);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    if (editLineItem.monogram === true) {
      setMonogramText(editLineItem.monogram_text);
      setMonogramFont(editLineItem.monogram_font);
      setMonogramColor(editLineItem.monogram_color);
      setMonogramShadow(editLineItem.monogram_shadow || 'None');
      setMonogramLocation(editLineItem.monogram_position);
    }
  }, [editLineItem]);

  const handleOptionChanged = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
  };

  const [monogramText, setMonogramText] = useState('');
  const [monogramFont, setMonogramFont] = useState();
  const [monogramColor, setMonogramColor] = useState();
  const [monogramShadow, setMonogramShadow] = useState();
  const [monogramLocation, setMonogramLocation] = useState();

  const [edittingPet, setEdittingPet] = useState();

  const updateLineItem = () => {
    let newLineItem = {
      _id: editLineItem._id,
      sku: selectedOption?.sku,
      orderID: orderID,
      ...(selectedOption?.sku
        .split('-')
        .filter((val) => val !== 'P' && val !== 'LE')
        .join('-') !== selectedOption?.sku
        ? monogramType === 'text'
          ? {
              monogram_text: encodeURIComponent(monogramText),
              monogram_font: monogramFont,
              monogram_color: monogramColor,
              monogram_position: monogramLocation,
              ...(monogramFont === 'block' ||
              monogramFont === 'college' ||
              monogramFont === 'heritage' ||
              monogramFont === 'decorative'
                ? { monogram_shadow: monogramShadow }
                : {}),
              monogram: true,
              pet: false,
            }
          : {
              monogram_position: monogramLocation,
              monogram: true,
              monogram_pets: monogramPets,
              monogram_airtable_line: monogramAirtableLines,
              pet: true,
            }
        : {
            monogram: false,
          }),
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${editLineItem._id}/editLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  let [comment_file, setCommentFile] = useState(null);
  let [imageData, setImageData] = useState();
  let [uploadedAirtableID, setUploadedAirtableID] = useState('');
  let [newPetName, setNewPetName] = useState('');

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    let file = files[0];
    setCommentFile(file);

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function (event) {
      setImageData({
        base64: `data:${file.type};base64,${btoa(event.target.result)}`,
        filename: file.name,
      });
      event.target.value = null;
      event.target.files = null;
      setCommentFile(null);
    };
  };

  const uploadToS3 = () => {
    const uploadData = {
      petName: newPetName,
      base64Image: imageData.base64,
    };

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    };

    options.body = JSON.stringify(uploadData);

    return new Promise((resolve, reject) => {
      fetch(
        'https://az25kdsm7h.execute-api.ap-southeast-2.amazonaws.com/production/uploadPetImagesToS3',
        options
      )
        .then((response) => response.json())
        .then((response) => {
          let base64ToString = Buffer.from(response.url, 'base64').toString();
          resolve(base64ToString);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const createAirtable = (printJobID) => {
    const uploadData = {
      printJobID: printJobID,
      OrderNumber: order.name,
      email: order.email,
    };

    return new Promise((resolve, reject) => {
      superagent
        .post(`${backendUrl}/orders/createPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            resolve(response.body);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const fetchAirtable = (airtableId) => {
    const uploadData = {
      airtableId,
    };

    return new Promise((resolve, reject) => {
      superagent
        .post(`${backendUrl}/orders/fetchPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response);
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
          } else {
            resolve(response.body);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const savePet = async () => {
    let airtableID;
    if (imageData !== undefined) {
      let printjobID = await uploadToS3();
      airtableID = (await createAirtable(printjobID)).id;
      await sleep(2000);
    } else if (uploadedAirtableID !== '') {
      airtableID = uploadedAirtableID;
    } else {
      return;
    }

    let airtableRecord = await fetchAirtable(airtableID);

    let uniquePet = {
      ...airtableRecord['fields'],
      name: newPetName,
    };

    addUniquePet(uniquePet, airtableID);
    setEdittingPet();
  };

  const colorType = selectedOption
    ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK', 'EVE'].includes(
        selectedOption?.sku.slice(0, 3)
      ) || ['EVETOT'].includes(selectedOption?.sku.slice(0, 6))
    : false;
  let colors = colorType ? newColorsBags : newColors;
  let fontsFor = colorType ? fontsBags : fonts;

  const isEmboss = selectedOption && selectedOption?.sku.includes('JETTE');
  if (isEmboss) {
    colors = embosColours;
    fontsFor = embosFonts;
  }
  const getLocationOptions = () => {
    if (editLineItem.sku.includes('NBK')) {
      return Object.keys(Notebooklocations).map((key) => ({
        label: Notebooklocations[key],
        value: Notebooklocations[key],
      }));
    } else if (
      editLineItem.sku.includes('TEC') ||
      editLineItem.sku.includes('CME')
    ) {
      return Object.keys(TecLocations).map((key) => ({
        label: TecLocations[key],
        value: TecLocations[key],
      }));
    } else if (editLineItem.sku.includes('TAG')) {
      return Object.keys(TagLocations).map((key) => ({
        label: TagLocations[key],
        value: TagLocations[key],
      }));
    } else {
      return Object.keys(locations).map((key) => ({
        label: locations[key],
        value: locations[key],
      }));
    }
  };

  const monogramTypes = {
    text: 'Text',
    pets: 'Pets',
  };

  return (
    <Dialog open={open} onOpenChange={togglemodal}>
      {loading === true ? (
        <DialogContent className="max-h-[80vh] overflow-y-auto  min-w-[60vw]">
          <DialogHeader>
            <DialogTitle>Loading...</DialogTitle>
          </DialogHeader>
        </DialogContent>
      ) : (
        <DialogContent className="max-h-[80vh] z-[1500] min-w-[60vw] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>
              {edittingPet ? 'New pet upload' : 'Edit Line Item'}
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-4">
            {edittingPet !== undefined ? (
              <>
                {edittingPet.airtableId === undefined ? (
                  <>
                    {uploadedAirtableID === '' &&
                      (imageData === undefined ? (
                        <div className="mb-4">
                          <Col
                            className="w-full d-flex align-items-center custome-input"
                          >
                            <Input
                              accept="image/*"
                              type="file"
                              id="comment_file"
                              name="comment_file"
                              label={comment_file || 'Add image'}
                              onChange={(e) => handleFileChange(e)}
                            />
                           
                          </Col>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => setImageData()}
                        >
                          <img
                            style={{ height: 100, width: 100 }}
                            src={imageData.base64}
                          />
                        </div>
                      ))}
                    {imageData === undefined && (
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          {' '}
                          Airtable ID{' '}
                        </label>
                        <div className="col-md-10">
                          <Input
                            placeholder="Airtable ID"
                            className="mb-2"
                            type="text"
                            value={uploadedAirtableID}
                            onChange={(e) =>
                              setUploadedAirtableID(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        {' '}
                        Pet name{' '}
                      </label>
                      <div className="col-md-10">
                        <Input
                          placeholder="Pet name"
                          className="mb-2"
                          type="text"
                          value={newPetName}
                          onChange={(e) => setNewPetName(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <> {JSON.stringify(edittingPet)} </>
                )}
              </>
            ) : (
              <>
                <ProductVariantSelector
                  products={options}
                  setSelectedVariant={setSelectedOption}
                  selectedVariant={selectedOption}
                />
                <br />
                {selectedOption &&
                  selectedOption?.sku
                    .split('-')
                    .filter((val) => val !== 'P' && val !== 'LE')
                    .join('-') !== selectedOption?.sku && (
                    <div>
                      <br />
                      <h6> Monogram Data</h6>
                      <br />
                      <div className="form-group row">
                        <div className="col-md-12">
                          <Select
                            onValueChange={setMonogramType}
                            value={monogramType}
                          >
                            <SelectTrigger className="mb-2">
                              <SelectValue placeholder="-- select an option --" />
                            </SelectTrigger>
                            <SelectContent className="z-[1600]">
                              {Object.keys(monogramTypes).map((val) => (
                                <SelectItem key={val} value={val}>
                                  {monogramTypes[val]}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      <br />
                      {monogramType === 'text' && (
                        <>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              {' '}
                              Text{' '}
                            </label>
                            <div className="col-md-10">
                              <Input
                                placeholder="Monogram text"
                                className="mb-2"
                                type="text"
                                value={monogramText}
                                onChange={(e) =>
                                  setMonogramText(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              {' '}
                              Font{' '}
                            </label>
                            <div className="col-md-10">
                              <Select
                                onValueChange={setMonogramFont}
                                value={monogramFont}
                              >
                                <SelectTrigger className="mb-2">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {Object.keys(fontsFor).map((val) => (
                                    <SelectItem key={val} value={val}>
                                      {fontsFor[val]}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Main Colour
                            </label>
                            <div className="col-md-10">
                              <Select
                                onValueChange={setMonogramColor}
                                value={monogramColor}
                              >
                                <SelectTrigger className="mb-2">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {Object.keys(colors).map((val) => (
                                    <SelectItem
                                      key={val}
                                      value={val}
                                      style={{ color: colors[val] }}
                                    >
                                      <span style={{ color: colors[val] }}>
                                        {val}
                                      </span>
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                          {(monogramFont === 'block' ||
                            monogramFont === 'college' ||
                            monogramFont === 'heritage' ||
                            monogramFont === 'decorative') && (
                            <div className="form-group row">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Shadow Colour
                              </label>
                              <div className="col-md-10">
                                <Select
                                  onValueChange={setMonogramShadow}
                                  value={monogramShadow}
                                >
                                  <SelectTrigger className="mb-2">
                                    <SelectValue placeholder="-- select an option --" />
                                  </SelectTrigger>
                                  <SelectContent className="z-[1600]">
                                    {Object.keys(newColors).map((val) => (
                                      <SelectItem key={val} value={val}>
                                        <span style={{ color: newColors[val] }}>
                                          {val}
                                        </span>
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                              </div>
                            </div>
                          )}
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Location
                            </label>
                            <div className="col-md-10">
                              <Select
                                onValueChange={(value) =>
                                  setMonogramLocation(value)
                                }
                                value={monogramLocation || ''}
                              >
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {getLocationOptions()?.map((option) => (
                                    <SelectItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                        </>
                      )}
                      {monogramType === 'pets' && (
                        <>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-2 col-form-label"
                            >
                              Location
                            </label>
                            <div className="col-md-10">
                              <Select
                                onValueChange={(value) =>
                                  setMonogramLocation(value)
                                }
                                value={monogramLocation || ''}
                              >
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {getLocationOptions()?.map((option) => (
                                    <SelectItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </div>
                          </div>
                          <Row>
                            {Object.keys(allUniquePets).map((petID, key) => {
                              let pet = allUniquePets[petID];
                              let selected =
                                monogramAirtableLines.includes(petID);
                              const handleTogglePetSelect = () => {
                                if (selected) {
                                  setMonogramAirtableLines((prev) =>
                                    prev.filter((val) => val !== petID)
                                  );
                                  setMonogramPets((prev) =>
                                    btoa(
                                      JSON.stringify(
                                        JSON.parse(atob(prev)).filter(
                                          (val) =>
                                            val.signedUrl !== pet.printJobID
                                        )
                                      )
                                    )
                                  );
                                } else {
                                  setMonogramAirtableLines((prev) => [
                                    ...prev,
                                    petID,
                                  ]);
                                  setMonogramPets((prev) =>
                                    btoa(
                                      JSON.stringify([
                                        ...(prev ? JSON.parse(atob(prev)) : []),
                                        {
                                          signedUrl: pet.printJobID,
                                          name: pet.name,
                                        },
                                      ])
                                    )
                                  );
                                }
                              };

                              return (
                                <Col  className="sm:w-6/12 xl:w-3/12" key={'_pet_' + key}>
                                  <Card
                                    style={{ background: '#d5d5d5' }}
                                    onClick={handleTogglePetSelect}
                                  >
                                    <CardBody style={{ position: 'relative' }}>
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: 8,
                                          right: 8,
                                          fontSize: 24,
                                        }}
                                      >
                                        <i
                                          className={
                                            selected
                                              ? 'bx bx-check-square'
                                              : 'bx bx-square'
                                          }
                                        />
                                      </div>
                                      <h5>{pet.name}</h5>
                                      <h6>{pet.Status}</h6>
                                      <img
                                        src={pet['Provided Image'][0]['url']}
                                        style={{
                                          width: 100,
                                          height: 100,
                                          objectFit: 'contain',
                                        }}
                                      />
                                      <br />
                                      <br />
                                      {/* <Button color="warning" onClick={(e) => {e.stopPropagation(); setEdittingPet({...pet, airtableId: petID})}}> Edit. </Button> */}
                                    </CardBody>
                                  </Card>
                                </Col>
                              );
                            })}
                            <Col className="sm:w-6/12 xl:w-3/12" key={'_pet_add'}>
                              <Card style={{ background: '#d5d5d5' }}>
                                <CardBody>
                                  <Button
                                    variant="success"
                                    onClick={() =>
                                      setEdittingPet({ airtableId: undefined })
                                    }
                                  >
                                    {' '}
                                    Add new pet.{' '}
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  )}
              </>
            )}
          </div>
          <DialogFooter>
            {edittingPet !== undefined && (
              <>
                <Button variant="warning" onClick={() => setEdittingPet()}>
                  {' '}
                  Back{' '}
                </Button>
                <Button
                  variant="success"
                  disabled={
                    !(imageData !== undefined || uploadedAirtableID !== '')
                  }
                  onClick={savePet}
                >
                  {' '}
                  Save{' '}
                </Button>
              </>
            )}
            {edittingPet === undefined &&
              (selectedOption?.sku !== editLineItem.sku ||
                (selectedOption &&
                  selectedOption?.sku
                    .split('-')
                    .filter((val) => val !== 'P' && val !== 'LE')
                    .join('-') !== selectedOption?.sku)) && (
                <Button variant="success" onClick={updateLineItem}>
                  {' '}
                  Save{' '}
                </Button>
              )}
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
};

const EditModalShipping = ({  refresh, order }) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
  const [open, setOpen] = useState(false)
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: apiKey,
    options: {
      fields: ['address_component', 'types', 'geocode'],
      types: ['geocode'],
    },
  });

  useEffect(() => {
    const loaded = new CustomEvent('loadedPlaced', {
      detail: placePredictions.map((placeLine) => ({
        label: placeLine.description,
        value: placeLine,
      })),
    });
    document.dispatchEvent(loaded);
  }, [placePredictions]);

  const [selected, setSelected] = useState(null);

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(
        document.getElementById('test-map'),
        { zoom: 15 }
      );
      var request = {
        placeId: placeID,
        fields: ['address_components'],
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej(place);
        }
      }
    });
  };

  const loadOptions = (inputValue, callback) => {
    getPlacePredictions({ input: inputValue });
    document.addEventListener('loadedPlaced', function (e) {
      callback(e.detail);
    });
  };

  const [loading, setLoading] = useState(false);

  const [shippingType, setShippingType] = useState(order.shipping_type);
  const handleShippingTypeChange = (e) => {
    setShippingType(e);
  };



  const [name, setName] = useState(order.shipping_address?.name);
  const [email, setEmail] = useState(order.email);
  const [company, setCompany] = useState(order.shipping_address?.company);
  const [address1, setAddress1] = useState(order.shipping_address?.address1);
  const [address2, setAddress2] = useState(order.shipping_address?.address2);
  const [city, setCity] = useState(order.shipping_address?.city);
  const [zip, setZip] = useState(order.shipping_address?.zip);
  const [phone, setPhone] = useState(order.shipping_address?.phone);

  const [selectedState, setState] = useState(
    State.getStatesOfCountry(
      Country.getAllCountries().find(
        (val) => val.name === order.shipping_address?.country
      )?.isoCode
    )?.find((val) => val.name === order.shipping_address?.province)?.isoCode
  );
  const [selectedCountry, setCountry] = useState(
    Country.getAllCountries().find(
      (val) => val.name === order.shipping_address?.country
    )?.isoCode
  );

  const handleCountryChange = (e) => {
    setCountry(e);
    let newState;
    try {
      newState = State.getStatesOfCountry(e)[0].isoCode;
    } catch {}
    setState(newState);
  };

  useEffect(() => {
    if (selected) {
      getPlaceData(selected.value.place_id).then((res) => {
        let items = res.address_components;
        let streetNumberGoogleAPI,
          streetAddressGoogleAPI,
          countryGoogleAPI,
          stateGoogleAPI,
          unitNoGoogleAPI,
          suburnGoogleAPI,
          postcodeGoogleAPI;

        items.map((item) => {
          if (item.types.includes('subpremise')) {
            unitNoGoogleAPI = item.long_name;
          }
          if (item.types.includes('street_number')) {
            streetNumberGoogleAPI = item.long_name;
          }
          if (item.types.includes('route')) {
            streetAddressGoogleAPI = item.long_name;
          }
          if (item.types.includes('postal_code')) {
            postcodeGoogleAPI = item.long_name;
          }
          if (item.types.includes('locality')) {
            suburnGoogleAPI = item.long_name;
          }
          if (item.types.includes('administrative_area_level_1')) {
            stateGoogleAPI = item.long_name;
          }
          if (item.types.includes('country')) {
            countryGoogleAPI = item.long_name;
          }
        });

        setCountry(
          Country.getAllCountries().find((val) => val.name === countryGoogleAPI)
            ?.isoCode
        );
        setState(
          State.getStatesOfCountry(
            Country.getAllCountries().find(
              (val) => val.name === countryGoogleAPI
            )?.isoCode
          )?.find((val) => val.name === stateGoogleAPI)?.isoCode
        );
        setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI);
        setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI);
        setAddress2('');
        if (streetAddressGoogleAPI) {
          if (streetNumberGoogleAPI) {
            if (unitNoGoogleAPI) {
              setAddress1(
                unitNoGoogleAPI +
                  '/' +
                  streetNumberGoogleAPI +
                  ' ' +
                  streetAddressGoogleAPI
              );
            } else {
              setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI);
            }
          } else {
            setAddress1(streetAddressGoogleAPI);
          }
        } else {
          setAddress1('');
        }
        setSelected('');
      });
    }
  }, [selected]);

  const handleSave = () => {
    const exportData = {
      shippingType,
      name,
      email,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      province: State.getStateByCodeAndCountry(selectedState, selectedCountry)
        .name,
      country: Country.getCountryByCode(selectedCountry).name,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${order._id}/editShipping/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
          setOpen(false)
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const makeUnqiue = () => {
    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${order._id}/makeShippingUnique/`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refresh();
       
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="modal-content">
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }


  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <div style={{ display: 'none' }}>
        <div id="test-map" />
      </div>
      <DialogTrigger>
        <p className='text-black font-medium'>Edit</p>
      </DialogTrigger>

      <DialogContent className="max-h-[80vh] overflow-y-auto z-[1500] min-w-[60vw]">
        <DialogHeader>
          <DialogTitle className="text-left text-[16px] font-medium pb-4">Edit Shipping Details</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          {/* Shipping Type Selection */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Shipping Type</label>
            <div className="col-span-9">
              <Select
                onValueChange={handleShippingTypeChange}
                value={shippingType}
              >
                <SelectTrigger className="w-full rounded-md">
                  <SelectValue placeholder="Select shipping type" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  <SelectGroup>
                    <SelectItem value="standard">Standard</SelectItem>
                    <SelectItem value="express">Express</SelectItem>
                    <SelectItem value="same-day">Same Day</SelectItem>
                    <SelectItem value="next-day">Next Day</SelectItem>
                    <SelectItem value="pickup">QV Pickup</SelectItem>
                    <SelectItem value="pickup_hq">HQ Pickup</SelectItem>
                    <SelectItem value="Uber">Uber</SelectItem>
                    <SelectItem value="overnight">Overnight</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Name Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Name</label>
            <div className="col-span-9">
              <Input
                type="text"
                defaultValue={order?.shipping_address?.name}
                onChange={(e) => setName(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Email Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Email</label>
            <div className="col-span-9">
              <Input
                type="email"
                defaultValue={order?.email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Phone Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Phone</label>
            <div className="col-span-9">
              <Input
                type="text"
                defaultValue={order?.shipping_address?.phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Company Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Company</label>
            <div className="col-span-9">
              <Input
                type="text"
                defaultValue={order?.shipping_address?.company}
                onChange={(e) => setCompany(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Address Search with AsyncSelect */}
          <div className="grid grid-cols-12 gap-6 items-center">
            <label className="col-span-3">Search Address</label>
            <div className="col-span-9">
              <AsyncSelect
                styles={ {
                  control: (provided) => ({
                    ...provided,
                    padding: "0px 8px",
                    border:"1px solid black",
                    paddingRight: 0,
                  }),
                  
                }}
                onClick={setSelected}
                onChange={setSelected}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
              />
            </div>
          </div>

          {/* Country Selection */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Country</label>
            <div className="col-span-9">
              <Select
                defaultValue={selectedCountry}
                onValueChange={handleCountryChange}
                value={selectedCountry}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a country" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {Country.getAllCountries().map((country) => (
                    <SelectItem value={country.isoCode} key={country.isoCode}>
                      {country.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* State Selection */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">State</label>
            <div className="col-span-9">
              <Select
                defaultValue={selectedState}
                onValueChange={setState}
                value={selectedState}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a state" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {State.getStatesOfCountry(selectedCountry).map((state) => (
                    <SelectItem value={state.isoCode} key={state.isoCode}>
                      {state.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          {/* Address Inputs */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Address</label>
            <div className="col-span-9">
              <Input
                type="text"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Suburb Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Suburb</label>
            <div className="col-span-9">
              <Input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Postcode Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">Postcode</label>
            <div className="col-span-9">
              <Input
                type="text"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                className="w-full"
              />
            </div>
          </div>

          {/* Apartment/Optional Address Input */}
          <div className="grid grid-cols-12 gap-4 items-center">
            <label className="col-span-3">
              Apartment, suite, etc. (optional)
            </label>
            <div className="col-span-9">
              <Input
                type="text"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                className="w-full"
              />
            </div>
          </div>
        </div>

        {/* Footer Actions */}
        <DialogFooter className="flex justify-end space-x-2">
          <Button variant="destructive" onClick={makeUnqiue}>
            Make Unique
          </Button>
          <Button variant="success" onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const CancelOrderDialog = ({ cancelOpen, toggleCancelOpen, handleCancel }) => {
  return (
    <Dialog open={cancelOpen} onOpenChange={toggleCancelOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>CANCELLING ORDER</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <p>
            Are you sure you want to cancel this order? (this can't be undone).
          </p>
        </div>
        <DialogFooter>
          <Button
            onClick={handleCancel}
            variant="destructive"
            className="btn-lg btn-rounded"
          >
            Confirm
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const AddModal = ({ refresh, orderID }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedVariant, setSelectedVariant] = useState();

  useEffect(() => {
    if(!open) {
      return
    }
    setLoading(true);

    superagent
      .get(`${backendUrl}/product`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          setOptions(response.body.data);
          setSelectedProduct(response.body.data[0]._id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [open]);

  const handleProductChanged = (e) => {
    const option = e.target.value;
    setSelectedProduct(option);
  };
  const handleVariantChanged = (e) => {
    const option = e.target.value;
    setSelectedVariant(option);
  };

  const [monogramText, setMonogramText] = useState('');
  const [monogramFont, setMonogramFont] = useState();
  const [monogramColor, setMonogramColor] = useState();
  const [monogramShadow, setMonogramShadow] = useState();
  const [monogramLocation, setMonogramLocation] = useState();

  const productObject =
    selectedProduct && options.find((val) => val._id === selectedProduct);

  const getLocationOptions = (selected) => {
    if (selected?.sku?.includes('NBK')) {
      return Object.keys(Notebooklocations).map((key) => ({
        label: Notebooklocations[key],
        value: Notebooklocations[key],
      }));
    } else if (
      selected?.sku?.includes('TEC') ||
      selected?.sku?.includes('CME')
    ) {
      return Object.keys(TecLocations).map((key) => ({
        label: TecLocations[key],
        value: TecLocations[key],
      }));
    } else if (selected?.sku?.includes('TAG')) {
      return Object.keys(TagLocations).map((key) => ({
        label: TagLocations[key],
        value: TagLocations[key],
      }));
    } else {
      return Object.keys(locations).map((key) => ({
        label: locations[key],
        value: locations[key],
      }));
    }
  };

  const updateLineItem = () => {
    let newLineItem = {
      product: selectedProduct,
      variant: selectedVariant?.sku,
      orderID: orderID,
      ...(selectedVariant?.sku
        .split('-')
        .filter((val) => val !== 'P' && val !== 'LE')
        .join('-') !== selectedVariant?.sku
        ? {
            monogram_text: encodeURIComponent(monogramText),
            monogram_font: monogramFont,
            monogram_color: monogramColor,
            monogram_position: monogramLocation,
            ...(monogramFont === 'block' ||
            monogramFont === 'college' ||
            monogramFont === 'heritage' ||
            monogramFont === 'decorative'
              ? { monogram_shadow: monogramShadow }
              : {}),
            monogram: true,
          }
        : {
            monogram: false,
          }),
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${orderID}/addLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          setOpen(false);
        } else {
          console.log(response.body);
          refresh();
          setLoading(false);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setOpen(false);
      });
  };

  const colorType = selectedVariant
    ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK', 'EVE'].includes(
        selectedVariant?.sku.slice(0, 3)
      ) || ['EVETOT'].includes(selectedVariant?.sku.slice(0, 6))
    : false;
  let colors = colorType ? newColorsBags : newColors;
  let fontsFor = colorType ? fontsBags : fonts;

  const isEmboss = selectedVariant && selectedVariant?.sku.includes('JETTE');

  if (isEmboss) {
    colors = embosColours;
    fontsFor = embosFonts;
  }

  const monogramTypes = {
    text: 'Text',
    pets: 'Pets',
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className='mt-8'>
        <Button>+ Add Line Item</Button>
      </DialogTrigger>

      {loading === true ? (
        <DialogContent className="max-h-[80vh] overflow-y-auto  min-w-[60vw]">
          <DialogHeader>
            <DialogTitle>Loading...</DialogTitle>
          </DialogHeader>
        </DialogContent>
      ) : 
      (<DialogContent className="z-[1600] min-w-[60vw]">
        <DialogHeader>
          <DialogTitle>Add Line Item</DialogTitle>
        </DialogHeader>
        <ProductVariantSelector
          products={options}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={selectedVariant}
        />
        
        {selectedVariant &&
          selectedVariant?.sku
            .split('-')
            .filter((val) => val !== 'P' && val !== 'LE')
            .join('-') !== selectedVariant?.sku && (
            <div>
              <h6 className='mb-4 font-medium'> Monogram Data </h6>
              <Input
                placeholder="Monogram text"
                className="mb-2"
                type="text"
                value={monogramText}
                onChange={(e) => setMonogramText(e.target.value)}
              />
              <Select onValueChange={setMonogramFont} value={monogramFont}>
                <SelectTrigger className="mb-2">
                  <SelectValue placeholder="-- select an option --" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {Object.keys(fontsFor).map((val) => (
                    <SelectItem key={val} value={val}>
                      {fontsFor[val]}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
                    
              <Select onValueChange={setMonogramColor} value={monogramColor}>
                <SelectTrigger className="mb-2">
                  <SelectValue placeholder="-- select an option --" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {Object.keys(colors).map((val) => (
                    <SelectItem key={val} value={val} >
                      <span style={{ color: colors[val]  }}>
                   {val}
                   </span>
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {(monogramFont === 'block' ||
                monogramFont === 'college' ||
                monogramFont === 'heritage' ||
                monogramFont === 'decorative') && (
                <Select
                  onValueChange={setMonogramShadow}
                  value={monogramShadow}
                >
                  <SelectTrigger className="mb-2">
                    <SelectValue placeholder="-- select an option --" />
                  </SelectTrigger>
                  <SelectContent className="z-[1600]">
                    {Object.keys(newColors).map((val) => (
                      <SelectItem key={val} value={val} >
                          <span style={{ color: newColors[val]  }}>
                        {val}
                        </span>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}

              <Select
                onValueChange={setMonogramLocation}
                value={monogramLocation}
              >
                <SelectTrigger className="mb-2">
                  <SelectValue placeholder="-- select an option --" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {getLocationOptions(selectedVariant)?.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
        <DialogFooter>
          <Button variant="success" onClick={updateLineItem}>
            {' '}
            Add{' '}
          </Button>
        </DialogFooter>
      </DialogContent>
      )}
    </Dialog>
  );
};

const FlaggedModal = ({ togglemodal, refresh, orderID, isOpen }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFlagType, setSelectedFlagType] = useState();

  useEffect(() => {
    setLoading(true);

    superagent
      .get(`${backendUrl}/orders/getFlagTypes`)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log('response.body', response.body);
          setOptions(response.body);
          setSelectedFlagType(response.body.data?._id);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleFlagLineItemChanged = (e) => {
    setSelectedFlagType(e);
  };

  const updateLineItem = () => {
    let newLineItem = {
      orderID: orderID,
      flagTypeId:
        selectedFlagType !== undefined ? selectedFlagType : options[0]?._id,
    };

    setLoading(true);
    superagent
      .post(`${backendUrl}/orders/${orderID}/setFlagged`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          refresh();
          togglemodal();
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={togglemodal}>
      {loading === true ? (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Choose Flag Type</DialogTitle>
          </DialogHeader>
          <div className="flex justify-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Choose Flag Type</DialogTitle>
          </DialogHeader>
          <div>
            <Select onValueChange={(value) => handleFlagLineItemChanged(value)}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Options</SelectLabel>
                  {options.map((option) => (
                    <SelectItem
                      key={option?._id}
                      value={
                        option?._id === undefined
                          ? options[0]?._id
                          : option?._id
                      }
                    >
                      {option?.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>

            <br />
          </div>
          <DialogFooter>
            <Button onClick={updateLineItem}>Add</Button>
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
};

const newColorsBags = {
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  Mint: '#D4EECC',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  'French-Navy': '#182D6D',
  Red: '#C63531',
  Plum: '#AC3856',
  Purple: '#64258E',
  Lavender: '#957EA3',
  'Forest-Green': '#1C4125',
  Green: '#6A9A52',
  Yellow: '#E8BE47',
  Orange: '#E96737',
  White: '#ffffff',
  Grey: '#97999D',
  Black: '#000',
};

export const newColors = {
  Lavender: '#90759B',
  Lilac: '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  Grenaine: '#9E5360',
  'Blush-Pink': '#E77472',
  Magenta: '#B12D52',
  Maroon: '#731A29',
  Red: '#C92327',
  'Burnt-Orange': '#DC5527',
  Mango: '#EF7E2E',
  Rust: '#9B4827',
  Toffee: '#705037',
  Taupe: '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  Yellow: '#E8B629',
  Lime: '#C6B937',
  Green: '#4A813C',
  'Forest-Green': '#034123',
  Mint: '#78AB93',
  'Ice-Blue': '#BEE3E9',
  Denim: '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  Blue: '#1466B2',
  Grey: '#95979E',
  'Slate-Grey': '#63727E',
  Black: '#000000',
  White: '#FFFFFF',
};

export const fonts = {
  heritage: 'Heritage',
  'bold-new': 'Bold (new)',
  bubble: 'Bubble',
  'retro-serif': 'Retro Serif',
  graphic: 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',
  'love-letter': 'Love Letter',

  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif',
  'zh-ser': 'Chinese Serif',
  'zh-san': 'Chinese Sans Serif',
  'zh-block': 'Chinese Block',
  'zh-script': 'Chinese Script',
  'zh-casual': 'Chinese Casual',
};

export const fontsBags = {
  'all-sans': 'Carry All Sans Serif',
  'all-serif': 'Carry All Serif',
};

export const embosFonts = {
  'sans-serif-embos': 'Sans Serif Emboss',
  'serif-embos': 'Serif Emboss',
};

export const embosColours = {
  Gold: '#FFD700',
  Silver: '#c0c0c0',
  Blind: '#212a2c',
};

export const locations = {
  Top: 'Top',
  Side: 'Side',
  Back: 'Back',
};

export const TecLocations = {
  Left: 'Left',
  Right: 'Right',
};

export const TagLocations = {
  Horizontal: 'Horizontal',
  Vertical: 'Vertical',
};

export const Notebooklocations = {
  'center-landscape': 'center-landscape',
  'center-portrait': 'center-portrait',
  'bottom-left': 'bottom-left',
};

export default OrderPage;
