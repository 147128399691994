import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { Link } from "react-router-dom";
import useQuery from '../../../helpers/JIFI/useQuery';
import { ToastContainer, toast } from 'react-toastify';
import { useReactTable, getCoreRowModel, flexRender, createColumnHelper } from '@tanstack/react-table';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '@shadcn/components/ui/table';
import { Button } from '@shadcn/components/ui/button';
import { Input } from '@shadcn/components/ui/input';
import { Col } from '@shadcn/components/ui/col';
import { Row } from '@shadcn/components/ui/row';
import { Card, CardContent as CardBody } from '@shadcn/components/ui/card';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import superagent from 'superagent';

import { TableSkeleton } from '@shadcn/components/ui/table';
import { backendUrl } from '@helpers/consts';
import CreateMappingModal from './components/CreateMappingModal';
import { Checkbox } from '@shadcn/components/ui/checkbox';

const columnHelper = createColumnHelper();
const SkuListPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [skus, skuState] = useQuery({
    url: `/sku-mapping/list`,
    refresh
  });

  const triggerRefresh = useCallback(() => {
    setRefresh(prev => !prev);
  }, []);

  const filteredData = useMemo(() => {
    if (!searchTerm) return skus || [];
    return skus?.filter(item => 
      item.sku.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.setSkus.some(sku => sku.toLowerCase().includes(searchTerm.toLowerCase())) ||
      item.doubleSkus.some(sku => sku.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [skus, searchTerm]);

  const handleCheckboxChange = useCallback((id) => {
    setSelectedIds(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }
      return [...prev, id];
    });
  }, []);

  const handleSelectAll = useCallback((checked) => {
    if (checked) {
      setSelectedIds(filteredData.map(item => item._id));
    } else {
      setSelectedIds([]);
    }
  }, [filteredData]);

  const handleBulkDelete = async () => {
    if (!selectedIds.length) return;
    
    try {
      setIsDeleting(true);
      await superagent
        .post(`${backendUrl}/sku-mapping/bulk-delete`)
        .send({ ids: selectedIds })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`);
      
      toast.success(`${selectedIds.length} mappings deleted successfully`);
      setSelectedIds([]);
      triggerRefresh();
    } catch (err) {
      toast.error('Failed to delete mappings');
    } finally {
      setIsDeleting(false);
    }
  };

  const hasEditPermission = useMemo(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    if (!user) return false;

    if (user.permissions.includes('EditSkuMapping')) {
      return true;
    }

    return user.roles?.some(role => 
      role.permissions.includes('EditSkuMapping')
    );
  }, []);

  const skuColumns = useMemo(
    () => [
      ...(hasEditPermission ? [
        columnHelper.accessor('select', {
          header: ({ table }) => (
            <Checkbox
              checked={selectedIds.length === filteredData.length && filteredData.length > 0}
              onCheckedChange={handleSelectAll}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={selectedIds.includes(row.original._id)}
              onCheckedChange={() => handleCheckboxChange(row.original._id)}
            />
          )
        })
      ] : []),
      columnHelper.accessor('sku', {
        header: () => 'SKU',
        cell: ({ row }) => <div>{row.original.sku}</div>,
      }),
      columnHelper.accessor('setSkus', {
        header: () => 'Set SKUs',
        cell: ({ row }) => (
          <div className="whitespace-pre-line">
            {row.original.setSkus?.join('\n') || '-'}
          </div>
        ),
      }),
      columnHelper.accessor('doubleSkus', {
        header: () => 'Double SKUs',
        cell: ({ row }) => (
          <div className="whitespace-pre-line">
            {row.original.doubleSkus?.join('\n') || '-'}
          </div>
        ),
      }),
      ...(hasEditPermission ? [
        columnHelper.accessor('actions', {
          header: 'Actions',
          cell: ({ row }) => (
            <div className="flex gap-2">
              <Button
                variant="destructive"
                size="sm"
                onClick={() => handleDelete(row.original._id)}
              >
                Delete
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => handleEdit(row.original)}
              >
                Edit
              </Button>
            </div>
          ),
        })
      ] : []),
    ],
    [selectedIds, filteredData.length, handleCheckboxChange, handleSelectAll, hasEditPermission]
  );

  const handleEdit = async (sku) => {
    setInitialValues([sku]);
    setIsCreateModalOpen(true);
  }
  
  const handleDelete = async (id) => {
    try {
      await superagent
        .delete(`${backendUrl}/sku-mapping/${id}`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`);
      toast.success('SKU mapping deleted successfully');
      triggerRefresh();
    } catch (err) {
      toast.error('Failed to delete SKU mapping');
    }
  };

  const skuTable = useReactTable({
    data: filteredData,
    columns: skuColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%] mb-6">
          <Breadcrumbs title="Inventory" breadcrumbItem="SKU Mappings" />
          
          <Col className="w-full">
            <Card className="mb-10">
              <CardBody className="pt-4">
                <Row className="justify-between mb-4">
                  <Col className="w-3/12">
                    <Input
                      type="text"
                      placeholder="Search SKUs..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Col>
                  {hasEditPermission && (
                    <Col className="ml-2 flex gap-2">
                      <Button
                        onClick={handleBulkDelete}
                        disabled={selectedIds.length === 0}
                        variant="destructive"
                      >
                        Delete Selected
                      </Button>
                      <Button onClick={() => setIsCreateModalOpen(true)}>
                        Create New Mapping
                      </Button>
                    </Col>
                  )}
                </Row>

                <div className="table-responsive">
                  <Table>
                    <TableHeader>
                      {skuTable.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <TableHead key={header.id}>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </TableHead>
                          ))}
                        </TableRow>
                      ))}
                    </TableHeader>
                    {skuState === 'success' ? (
                      <TableBody>
                        {skuTable.getRowModel().rows.map((row) => (
                          <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableSkeleton
                        columns={skuColumns.length}
                        perPage={10}
                      />
                    )}
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <CreateMappingModal 
        initialValues={initialValues}
        open={isCreateModalOpen}
        onClose={() => {
          setInitialValues([]);
          setIsCreateModalOpen(false);
        }}
        onSuccess={() => {
          triggerRefresh();
          setIsCreateModalOpen(false);
        }}
      />
      <ToastContainer />
    </>
  );
};

export default SkuListPage;
