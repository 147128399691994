
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { useDndContext } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useMemo, useState } from 'react';
import { TaskCard } from './TaskCard';
import { cva } from 'class-variance-authority';
import { Card, CardContent, CardHeader } from '@shadcn/components/ui/card';
import { Button } from '@shadcn/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { GripHorizontal, GripVertical } from 'lucide-react';
import { ScrollArea, ScrollBar } from '@shadcn/components/ui/scroll-area';


export function BoardColumn({ column, tasks, isOverlay, addColumn, renameColumn, removeColumn }) {
  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const [isRenameColumn, setIsRenameColumn] = useState(false);
  const [newTitle, setNewTitle] = useState(column.title);
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: {
      type: 'Column',
      column,
    },
    attributes: {
      roleDescription: `Column: ${column.title}`,
    },
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const variants = cva(
    'h-[calc(100vh-240px)] w-[350px] max-w-full bg-primary-foreground flex flex-col flex-shrink-0 snap-center',
    {
      variants: {
        dragging: {
          default: 'border-2 border-transparent',
          over: 'ring-2 opacity-30',
          overlay: 'ring-2 ring-primary',
        },
      },
    }
  );


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
    
      renameColumn(column.id, newTitle, !isRenameColumn);
      setIsRenameColumn(false)
    }
  };


  return (
    <Card
      ref={setNodeRef}
      style={style}
      className={variants({
        dragging: isOverlay ? 'overlay' : isDragging ? 'over' : undefined,
      })}
    >
      <CardHeader className="p-4 font-semibold border-b-2 text-left flex flex-row justify-between items-center">
        <Button
          variant={'ghost'}
          {...attributes}
          {...listeners}
          className=" p-1 text-primary/50 -ml-2 h-auto cursor-grab relative"
        >
          <span className="sr-only">{`Move column: ${column.title}`}</span>
          <GripVertical />
        </Button>

        {column.rename || isRenameColumn? 
             <input
             type="text"
             value={newTitle}
             onChange={(e) => setNewTitle(e.target.value)}
             
             onKeyDown={handleKeyDown}
             className="border border-gray-300 rounded p-1 w-full"
             autoFocus
           />
         :  <span> {column.title}</span>}
        
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button
              variant={'ghost'}
              {...attributes}
              {...listeners}
              className=" p-1 text-primary/50 -ml-2 h-auto cursor-grab relative"
            >
              <span className="sr-only">{`Move column: ${column.title}`}</span>
              <GripHorizontal />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => addColumn(column.id, 'left')}>Add Left Column</DropdownMenuItem>
            <DropdownMenuItem onClick={() => addColumn(column.id, 'right')}>Add Right Column</DropdownMenuItem>
            <DropdownMenuItem onClick={()=> removeColumn(column.id)}>Delete Column</DropdownMenuItem>
            <DropdownMenuItem onClick={()=> setIsRenameColumn(true)}>Rename Column</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardHeader>

      <ScrollArea>
        <CardContent className="flex flex-grow flex-col gap-2 p-2">
          <SortableContext items={tasksIds}>
            {tasks.map((task) => (
              <TaskCard key={task.id} task={task} />
            ))}
          </SortableContext>
        </CardContent>
      </ScrollArea>
    </Card>
  );
}

export function BoardContainer({ children }) {
  const dndContext = useDndContext();

  const variations = cva('px-2 md:px-0 flex lg:justify-center pb-4', {
    variants: {
      dragging: {
        default: 'snap-x snap-mandatory',
        active: 'snap-none',
      },
    },
  });

  return (
    <ScrollArea
      className={variations({
        dragging: dndContext.active ? 'active' : 'default',
      })}
    >
      <div className="flex gap-4 flex-row justify-between">{children}</div>

      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
}
