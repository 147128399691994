import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { memo } from "react";

import { Card, CardContent as CardBody, CardTitle} from "@shadcn/components/ui/card"
import { Row } from "@shadcn/components/ui/row"
import { Col } from "@shadcn/components/ui/col"
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
  SelectGroup,

} from '@shadcn/components/ui/select';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@shadcn/components/ui/tabs';

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  
} from '@shadcn/components/ui/dropdown-menu';



import { Link } from "react-router-dom";
import "../datatables.scss";
import useQuery from '../../../helpers/JIFI/useQuery';

import SelectMultiple from '@shadcn/components/ui/react-select'

import CommentHistory from "../../Skote/Dashboard/CommentHistory";
import { useReactToPrint } from 'react-to-print';
import PrintPage from '../monogram/PrintPage'
import PrintPageDeliveries from '../deliveries/delivery-label'

import useProductData from '../orders/useProductData'
import FulfillmentModal from "../orders/FulfillmentModule";
import superagent from 'superagent'
import { backendUrl } from '../../../helpers/consts.js'
import moment from 'moment'
import AsyncSelect from 'react-select/async';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Country, State } from 'country-state-city';
import { AssignedUserModal, embosColours, embosFonts } from "../orders/index.js";
import ProductVariantSelector from "../helpers/ProductVariantSelector.js";
import SKUToolTip from "../helpers/SKUToolTip.js";
import useSKULevels from "../helpers/useSKULevels.js";
import useSKUStatus from "../helpers/useSKUStatus.js";
import useUpcomingStock from "../helpers/useUpcomingStock.js";
import useUserStore from "../../../zustand/useUserStore.js";
import { Button } from "@shadcn/components/ui/button";
import { Badge } from "@shadcn/components/ui/badge";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@shadcn/components/ui/dialog";
import { Input } from "@shadcn/components/ui/input";
import { StatusBadge } from "../orders/components/StatusBadge";
import { LabelsSection } from "../orders/components/Section/LabelsSection";
import { OrderDetailsSection } from "../orders/components/Section/OrderDetailsSection";
import { CustomerInfoSection } from "../orders/components/Section/CustomerInfoSection";
import { NotesSection } from "../orders/components/Section/NotesSection";

const postcodeArray = [
  3000,
  3002,
  3003,
  3004,
  3004,
  3004,
  3005,
  3006,
  3006,
  3008,
  3011,
  3011,
  3011,
  3012,
  3012,
  3012,
  3012,
  3012,
  3013,
  3013,
  3015,
  3015,
  3015,
  3016,
  3016,
  3018,
  3018,
  3019,
  3019,
  3020,
  3020,
  3020,
  3020,
  3020,
  3021,
  3021,
  3021,
  3021,
  3022,
  3022,
  3023,
  3023,
  3023,
  3023,
  3023,
  3023,
  3023,
  3024,
  3024,
  3024,
  3024,
  3025,
  3025,
  3025,
  3026,
  3026,
  3027,
  3028,
  3028,
  3028,
  3029,
  3029,
  3029,
  3030,
  3030,
  3030,
  3030,
  3030,
  3030,
  3031,
  3031,
  3032,
  3032,
  3032,
  3032,
  3033,
  3034,
  3036,
  3036,
  3037,
  3037,
  3037,
  3037,
  3037,
  3038,
  3038,
  3038,
  3038,
  3039,
  3040,
  3040,
  3040,
  3041,
  3041,
  3041,
  3041,
  3041,
  3042,
  3042,
  3042,
  3042,
  3043,
  3043,
  3043,
  3044,
  3044,
  3045,
  3046,
  3046,
  3046,
  3047,
  3047,
  3047,
  3048,
  3048,
  3049,
  3049,
  3051,
  3051,
  3052,
  3052,
  3053,
  3053,
  3054,
  3054,
  3055,
  3055,
  3055,
  3055,
  3056,
  3056,
  3056,
  3057,
  3057,
  3058,
  3058,
  3058,
  3058,
  3058,
  3059,
  3060,
  3061,
  3062,
  3063,
  3063,
  3064,
  3064,
  3065,
  3066,
  3066,
  3067,
  3068,
  3068,
  3070,
  3070,
  3071,
  3072,
  3072,
  3072,
  3072,
  3072,
  3072,
  3072,
  3073,
  3074,
  3075,
  3076,
  3078,
  3078,
  3079,
  3079,
  3079,
  3081,
  3081,
  3081,
  3081,
  3082,
  3083,
  3083,
  3083,
  3084,
  3084,
  3084,
  3084,
  3084,
  3085,
  3085,
  3085,
  3086,
  3087,
  3087,
  3088,
  3088,
  3088,
  3094,
  3095,
  3095,
  3101,
  3101,
  3102,
  3103,
  3103,
  3103,
  3103,
  3104,
  3104,
  3105,
  3106,
  3107,
  3108,
  3109,
  3109,
  3111,
  3113,
  3116,
  3121,
  3121,
  3121,
  3121,
  3121,
  3121,
  3121,
  3122,
  3122,
  3122,
  3122,
  3122,
  3123,
  3123,
  3124,
  3124,
  3124,
  3124,
  3124,
  3124,
  3125,
  3125,
  3125,
  3126,
  3126,
  3127,
  3127,
  3127,
  3128,
  3128,
  3128,
  3128,
  3128,
  3129,
  3129,
  3129,
  3130,
  3130,
  3130,
  3130,
  3131,
  3131,
  3131,
  3132,
  3132,
  3132,
  3133,
  3133,
  3134,
  3134,
  3134,
  3134,
  3134,
  3135,
  3135,
  3135,
  3136,
  3136,
  3136,
  3136,
  3137,
  3138,
  3141,
  3142,
  3142,
  3143,
  3143,
  3144,
  3144,
  3144,
  3145,
  3145,
  3145,
  3145,
  3145,
  3145,
  3146,
  3146,
  3147,
  3147,
  3148,
  3148,
  3148,
  3148,
  3149,
  3149,
  3149,
  3150,
  3150,
  3150,
  3151,
  3151,
  3152,
  3152,
  3152,
  3152,
  3153,
  3153,
  3155,
  3156,
  3156,
  3156,
  3156,
  3160,
  3160,
  3161,
  3161,
  3162,
  3162,
  3162,
  3163,
  3163,
  3163,
  3163,
  3165,
  3166,
  3166,
  3166,
  3166,
  3167,
  3168,
  3168,
  3169,
  3169,
  3170,
  3170,
  3171,
  3171,
  3172,
  3172,
  3173,
  3174,
  3174,
  3175,
  3175,
  3175,
  3175,
  3175,
  3175,
  3177,
  3177,
  3178,
  3179,
  3180,
  3181,
  3181,
  3181,
  3182,
  3182,
  3182,
  3183,
  3183,
  3184,
  3184,
  3185,
  3185,
  3185,
  3186,
  3186,
  3186,
  3186,
  3187,
  3187,
  3188,
  3188,
  3188,
  3189,
  3189,
  3189,
  3190,
  3191,
  3192,
  3192,
  3192,
  3193,
  3193,
  3193,
  3193,
  3194,
  3194,
  3194,
  3195,
  3195,
  3195,
  3195,
  3195,
  3195,
  3196,
  3196,
  3196,
  3196,
  3197,
  3197,
  3198,
  3199,
  3199,
  3199,
  3199,
  3199,
  3199,
  3200,
  3200,
  3201,
  3202,
  3204,
  3204,
  3204,
  3204,
  3205,
  3206,
  3206,
  3207,
  3207,
  3337,
  3337,
  3338,
  3800,
  3802,
  3803,
  3805,
  3805,
  3975,
  3976
]


const fulfillmentToBadge =  (fulfillment) => {
  const conversion = {
    'unfulfilled': 'warning',
    'awaiting-stock': 'secondary',
    'fulfilled': 'success',
    'partially_fulfilled': 'error',
  };
  return conversion[fulfillment] || 'warning';
}

const pickToName = (pick) => {
  const conversion = {
    'none': 'UNPICKED',
    'monogram': 'TO-MONOGRAM',
    'fulfillment': 'TO-SHIP',
    'EWE': 'EWE',
    'QV': 'QV',
    'EWESYD': 'EWE Sydney',
    'B360': 'B360',
    'UNIS': 'UNIS',
    'EWE-MONO': 'EWE-MONO',
    'AIRBOX': 'Airbox',
    'AIRBOX-MONO': 'Airbox Monogram',
  }
  return conversion[pick] || pick
}

const pickToBadge = (pick) => {
  const conversion = {
    'none': 'warning',
    'monogram': 'info',
    'fulfillment': 'success',
    'EWE': 'success',
    'QV': 'success',
    'B360': 'success',
    'EWESYD': 'success',
    'UNIS': 'success',
    'EWE-MONO': 'info',
    'AIRBOX': 'success',
    'AIRBOX-MONO': 'info',
  }
  return conversion[pick] || 'success'
}

const PreviousOrders = ({ orderID }) => {
  const [refresh, setRefresh] = useState(true)
  const refreshData = () => {
    setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({
    url: `/orders/${orderID}/customerOrders`,
    refresh
  });


  if (!data || data.length === 0) return <> </>

  const filteredOrders = data.filter(val => orderID !== `${val._id}`)

  return <div className="mt-2 pt-3 border-top border-light">
    <h5 className="mb-2"> {filteredOrders.length} Previous Orders </h5>
    <br />
    {
      filteredOrders.map(order => {
        return <div>
          <Link to={`/orders/${order._id}`}> <h6> {order.name} - ${order.total_price}</h6> </Link>
          <div className="mb-3">
            {
              order.line_items.map(lineItem => <div> {lineItem.quantity} x {lineItem.sku} </div>)
            }
          </div>
        </div>
      })
    }
  </div>
}


class PackingSlip extends React.Component {
  render() {
    if (!this.props.order) return <></>

    return (
      <div style={{ width: '100%', height: '100vh', padding: '128px 64px' }}>
        <h1 style={{ marginBottom: 48 }}> {this.props.order.name} - PACKING SLIPS </h1>
        <table style={{ fontSize: 24 }}>
          <thead>
            <th style={{ padding: 8 }}>QTY</th>
            <th style={{ padding: 8 }}>SKU</th>
            {/* <th style={{padding: 8}}>NAME</th> */}
          </thead>
          <tbody >
            {
              this.props.order?.line_items?.map(val => {
                return <tr>
                  <td style={{ padding: 8 }}>{val.quantity}</td>
                  <td style={{ padding: 8 }}>{val.sku}</td>
                  {/* <td></td> */}
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

const UnfulfilledLineItems = ({ shipping_address, printDeliveryLabels, pick_status, lineItems, orderID, order_source, refresh, shipping_type, contains_monogram, orderName, getVariant, distanceFromHQ, postcode, findSKULevel, findSKUStatus, findUpcomingStock }) => {
  const [selectedItems, setSelectedItems] = useState(lineItems.map(val => true))
  const [selectedCount, setSelectedCount] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    setSelectedCount(selectedItems.reduce(function (n, val) {
      return n + (val === true);
    }, 0))
  }, [selectedItems])

  useEffect(() => {
    setSelectedItems(lineItems.map(val => val.fulfillment_status !== 'deleted'))
  }, [lineItems])

  const handleFulfillClicked = () => {
    setModalOpen(true)
  }


  return (
    <>
      {
        lineItems.map((lineItem, key) => {
          const handleSelected = () => {
            setSelectedItems(prev => {
              let newItems = [...prev]
              newItems[key] = !newItems[key]
              return newItems
            })
          }
          const isSelected = selectedItems[key] === true
          return (
            <>
              <Row>
                <Col  className="sm:w-1/12">
                  <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                      lineItem.fulfillment_status !== 'deleted' && (
                        <Input className="form-check-input  w-[13px] h-[13px]" type="checkbox" onChange={handleSelected} defaultChecked={true} id="defaultCheck1" />
                      )
                    }
                  </div>
                </Col>
                <Col  className="sm:w-2/12">
                  <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col  className="p-3 sm:w-5/12">
                  <h6>{getVariant(lineItem.sku)?.name}</h6>
                  <div>
                   <StatusBadge
                    status={lineItem.fulfillment_status}
                    type="fulfillment"/>
                  </div>
                  <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                </Col>
                <Col  className="p-3 sm:w-2/12">
                  {
                    lineItem.monogram && !lineItem.pet && (
                      <a href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(lineItem.monogram_text)}-${lineItem.monogram_color}-${lineItem.monogram_shadow || ''}-undefined${lineItem.sku.startsWith('IPC') ? `-IPC-${lineItem.monogram_position}` : ''}${lineItem.sku.startsWith('BOT') && lineItem.monogram_position === 'Top' ? '-Lid' : ''}.pdf?avoidTheCaches=1`}`} target="_blank" rel="noreferrer">
                        <div style={{ opactity: 0.8, fontSize: 10 }}>
                          <div>
                            TEXT: {lineItem.monogram_text}
                          </div>
                          <div>
                            FONT: {lineItem.monogram_font}
                          </div>
                          <div>
                            COLOUR: {lineItem.monogram_color}
                          </div>
                          {
                            (lineItem.monogram_font === 'block' || lineItem.monogram_font === 'college' || lineItem.monogram_font === 'heritage' || lineItem.monogram_font === 'decorative') && (
                              <div>
                                SHADOW: {lineItem.monogram_shadow}
                              </div>
                            )
                          }
                          <div>
                            POSITION: {lineItem.monogram_position}
                          </div>
                        </div>
                      </a>
                    )
                  }
                  {
                    lineItem.pet && lineItem.monogram_airtable_line && lineItem.monogram_airtable_line.map((val, key) =>
                      <a href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`} target="_blank" rel="noreferrer">
                        PET {key}
                      </a>
                    )
                  }

                  {
                    lineItem.message && (
                      <div style={{ opactity: 0.8, fontSize: 10 }}>
                        <div>
                          message: {lineItem.message}
                        </div>
                      </div>
                    )
                  }
                </Col>
                <Col className="p-3 sm:w-2/12 flex flex-col items-end font-medium">
                  {lineItem.quantity}x ${lineItem.price}
                </Col>
              
              </Row>
            </>
          )
        })
      }
      <Row>
        {
          selectedCount > 0 && <Button onClick={handleFulfillClicked} type="submit" variant="success"> Fulfill Selected</Button>
        }
      </Row>
      <Dialog
        size='lg'
        open={modalOpen}
        onOpenChange={() => setModalOpen(prev => !prev)}
        
      >
        <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
        <div className="modal-header">
        <h5 className="text-[15px] font-medium mt-0">Fulfillment method</h5>
         
        </div>
        <FulfillmentModal shipping_address={shipping_address} postcode={postcode} pick_status={pick_status} printDeliveryLabels={printDeliveryLabels} distanceFromHQ={distanceFromHQ} orderName={orderName} contains_monogram={contains_monogram} refresh={refresh} lineItems={lineItems} orderID={orderID} shipping_type={shipping_type} order_source={order_source} selectedItems={selectedItems} setModalOpen={setModalOpen} />
        </DialogContent>
      </Dialog>
    </>
  )
}


const SplitLineItems = ({ lineItems, orderID, order_source, refresh, shipping_type, contains_monogram, orderName, getVariant, distanceFromHQ, findSKULevel, findSKUStatus, findUpcomingStock }) => {
  const [selectedItems, setSelectedItems] = useState(lineItems.map(val => true))
  const [selectedCount, setSelectedCount] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSelectedCount(selectedItems.reduce(function (n, val) {
      return n + (val === true);
    }, 0))
  }, [selectedItems])

  useEffect(() => {
    setSelectedItems(lineItems.map(val => val.fulfillment_status !== 'deleted'))
  }, [lineItems])

  const handleFulfillClicked = () => {
    setModalOpen(true)
  }

  const [manualName, setManualName] = useState(orderName)
  useEffect(() => {
    setManualName(orderName)
  }, [orderName])
  const handleManualNameChange = (e) => {
    setManualName(e.target.value)
  }

  const handleCreateSplit = () => {
    setLoading(true)
    const sendLineItems = selectedItems
      .map((val, key) => val ? lineItems[key] : null)
      .filter(el => el != null);

    superagent.post(`${backendUrl}/orders/splitCorpOrder`)
      .send({
        line_items: sendLineItems,
        orderId: orderID,
        updatedName: manualName
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          setLoading(false)
          setModalOpen(false)
          refresh()
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });

  }

  return (
    <>
      {
        lineItems.map((lineItem, key) => {
          const handleSelected = () => {
            setSelectedItems(prev => {
              let newItems = [...prev]
              newItems[key] = !newItems[key]
              return newItems
            })
          }
          const isSelected = selectedItems[key] === true
          return (
            <>
              <Row>
                <Col  className="sm:w-1/12">
                  <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {
                      lineItem.fulfillment_status !== 'deleted' && (
                        <Input className="form-check-input  w-[13px] h-[13px]" type="checkbox" onChange={handleSelected} checked={isSelected} id="defaultCheck1" />
                      )
                    }
                  </div>
                </Col>
                <Col  className="sm:w-2/12">
                  <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
                  </div>
                </Col>
                <Col  className="p-3 sm:w-5/12">
                  <h6>{getVariant(lineItem.sku)?.name}</h6>
                  <div>
                    
                    <StatusBadge
                    status={lineItem.fulfillment_status}
                    type="fulfillment"/>
                  </div>
                  <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
                </Col>
                <Col sm="2" className="p-3 sm:w-2/12">
                  {
                    lineItem.monogram && !lineItem.pet && (
                      <a href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(lineItem.monogram_text)}-${lineItem.monogram_color}-${lineItem.monogram_shadow || ''}-undefined${lineItem.sku.startsWith('IPC') ? `-IPC-${lineItem.monogram_position}` : ''}${lineItem.sku.startsWith('BOT') && lineItem.monogram_position === 'Top' ? '-Lid' : ''}.pdf?avoidTheCaches=1`}`} target="_blank" rel="noreferrer">
                        <div style={{ opactity: 0.8, fontSize: 10 }}>
                          <div>
                            TEXT: {lineItem.monogram_text}
                          </div>
                          <div>
                            FONT: {lineItem.monogram_font}
                          </div>
                          <div>
                            COLOUR: {lineItem.monogram_color}
                          </div>
                          {
                            (lineItem.monogram_font === 'block' || lineItem.monogram_font === 'college' || lineItem.monogram_font === 'heritage' || lineItem.monogram_font === 'decorative') && (
                              <div>
                                SHADOW: {lineItem.monogram_shadow}
                              </div>
                            )
                          }
                          <div>
                            POSITION: {lineItem.monogram_position}
                          </div>
                        </div>
                      </a>
                    )
                  }
                  {
                    lineItem.pet && lineItem.monogram_airtable_line && lineItem.monogram_airtable_line.map((val, key) =>
                      <a href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`} target="_blank" rel="noreferrer">
                        PET {key}
                      </a>
                    )
                  }

                  {
                    lineItem.message && (
                      <div style={{ opactity: 0.8, fontSize: 10 }}>
                        <div>
                          message: {lineItem.message}
                        </div>
                      </div>
                    )
                  }
                </Col>
                <Col  className="p-3 sm:w-2/12 flex flex-col items-end font-medium">
                  QUANTITY: {lineItem.quantity}
                </Col>
              </Row>
            </>
          )
        })
      }
      <Row>
        {
          selectedCount > 0 && <Button onClick={handleFulfillClicked} type="submit" variant="success"> Split Order</Button>
        }
      </Row>
      <Dialog
        size='lg'
        open={modalOpen}
        onOpenChange={() => setModalOpen(prev => !prev)}
        centered={true}
      >
        <DialogContent>
        <DialogHeader>
        <DialogTitle>Split Order</DialogTitle>
        </DialogHeader>
       
        <DialogDescription>
          <Input onChange={handleManualNameChange} value={manualName} placeholder="Tracking Url" />
          <div className="my-3">
            {
              lineItems.filter((val, key) => selectedItems[key] === true).map((val, key) => <div className="my-1"> {val.sku} x {val.quantity} </div>)
            }
          </div>
        </DialogDescription>
        <DialogFooter>
          {
            loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <Button variant='destructive' onClick={handleCreateSplit}> Create Split </Button>
            )
          }
        </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}


const MonogramLineItems = ({ order, refresh, getVariant, findSKULevel, findSKUStatus, findUpcomingStock }) => {
  const handleUpdateItem = (id, machine) => {
    superagent.post(`${backendUrl}/orders/updateMonogramLineItem`)
      .send({ id, order_id: order._id, monogrammed_by: machine })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refresh()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  return order?.line_items && order.line_items.filter(val => val.monogram === true && val.fulfillment_status !== 'unfulfilled' && val.fulfillment_status !== 'fulfilled').map(lineItem => {
    return (
      <Row>
        <Col sm="2" className="sm:w-2/12">
          <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
          </div>
        </Col>
        <Col sm="5" className="p-3 sm:w-5/12">
          <h6>{getVariant(lineItem.sku)?.name} </h6>
          <h6 className='text-slateGray'> {lineItem.monogrammed_by && `Monogrammed by ${lineItem.monogrammed_by.replace('iron-man', 'Iron Man').replace('green-machine', 'Green Machine')}`}</h6>
          <div>
            <StatusBadge status={lineItem.fulfillment_status} type='fulfillment' />
            
          </div>
          <div>SKU: <SKUToolTip sku={lineItem.sku} findSKULevel={findSKULevel} findUpcomingStock={findUpcomingStock} findSKUStatus={findSKUStatus} /></div>
        </Col>
        <Col sm="2" className="p-3 sm:w-2/12">
          {
            lineItem.monogram && !lineItem.pet && (
              <a href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(lineItem.monogram_text)}-${lineItem.monogram_color}-${lineItem.monogram_shadow || ''}-undefined${lineItem.sku.startsWith('IPC') ? `-IPC-${lineItem.monogram_position}` : ''}${lineItem.sku.startsWith('BOT') && lineItem.monogram_position === 'Top' ? '-Lid' : ''}.pdf?avoidTheCaches=1`}`} target="_blank" rel="noreferrer">
                <div style={{ opactity: 0.8, fontSize: 10 }}>
                  <div>
                    TEXT: {lineItem.monogram_text}
                  </div>
                  <div>
                    FONT: {lineItem.monogram_font}
                  </div>
                  <div>
                    COLOUR: {lineItem.monogram_color}
                  </div>
                  {
                    (lineItem.monogram_font === 'block' || lineItem.monogram_font === 'college' || lineItem.monogram_font === 'heritage' || lineItem.monogram_font === 'decorative') && (
                      <div>
                        SHADOW: {lineItem.monogram_shadow}
                      </div>
                    )
                  }
                  <div>
                    POSITION: {lineItem.monogram_position}
                  </div>
                </div>
              </a>
            )
          }
          {
            lineItem.pet && lineItem.monogram_airtable_line && lineItem.monogram_airtable_line.map((val, key) =>
              <a href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`} target="_blank" rel="noreferrer">
                PET {key}
              </a>
            )
          }
        </Col>
        <Col sm="2" className="p-3 sm:w-2/12 flex flex-col items-end">
          {lineItem.quantity}x ${lineItem.price}
        </Col>
        <Col sm="1" className="p-3 sm:w-1/12">
        <DropdownMenu style={{ display: 'inline-block', padding: '0 16px' }}>
          <DropdownMenuTrigger href="#" className="card-drop p-3" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18"></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start" side="start">
            {lineItem.fulfillment_status === 'to-be-monogrammed' ? (
              <>
                <DropdownMenuItem onClick={() => handleUpdateItem(lineItem._id, 'green-machine')} href="#">
                  Mark in for monogramming ( Green Machine )
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleUpdateItem(lineItem._id, 'iron-man')} href="#">
                  Mark in for monogramming ( Iron-Man )
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleUpdateItem(lineItem._id, 'julie')} href="#">
                  Mark in for embroidery ( Julie )
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleUpdateItem(lineItem._id, 'white-machine')} href="#">
                  Mark in for monogramming ( White Machine )
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => handleUpdateItem(lineItem._id, 'grey-machine')} href="#">
                  Mark in for monogramming ( Grey Machine )
                </DropdownMenuItem>
              </>
            ) : (
              <DropdownMenuItem onClick={() => handleUpdateItem(lineItem._id)} href="#">
                Mark ready to ship
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
        </Col>
      </Row>
    )
  })
}

const OrderPage = ({
  match: {
    params
  },
  google
}) => {
  const { orderID } = params
  const [refresh, setRefresh] = useState(true)
  const [modalEdit, setModalEdit] = useState();
  const [modalAssignedUser, setModalAssignedUser] = useState();
  const [modalFlag, setModalFlag] = useState();
  const [returnModal, setModalReturn] = useState();
  const [shippingModalEdit, setShippingModalEdit] = useState();
  const [addModalEdit, setAddModalEdit] = useState();
  const [updateModal, setUpdateModal] = useState()
  const [editLineItem, setEditLineItem] = useState();
  const [flagLineItem, setFlagLineItem] = useState();
  const [cancelOpen, setCancelOpen] = useState();

  
  const userList = useUserStore((state) => state.userList);

  const getVariant = useProductData()

  const findSKULevel = useSKULevels()
  const findUpcomingStock = useUpcomingStock()
  const findSKUStatus = useSKUStatus()

  const refreshData = () => {
    setRefresh(prev => !prev)
  }
  const [data, state] = useQuery({
    url: `/orders/corp/${orderID}`,
    refresh
  });

  const [airtableData, airtableDataState] = useQuery({
    url: `/orders/fetchAllPetAirtableFromOrder/${orderID}`,
    refresh
  });

  const handleAssignedUser = (id) => {
    superagent
      .post(`${backendUrl}/orders/assignedCorpOrder`)
      .send({
        orderID,
        userID: id,
        url: window.location.href
      })
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response);
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
        } else {
          console.log(response.body);
          refreshData();
          handleToggleAssignedUser()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const packingSlipRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => packingSlipRef.current,
  });

  const packingLabelsRef = useRef();
  const printLabels = useReactToPrint({
    content: () => packingLabelsRef.current,
  });

  const handlePrintLabel = () => {
    printLabels()
  }

  const nonpackingLabelsRef = useRef();
  const nonprintLabels = useReactToPrint({
    content: () => nonpackingLabelsRef.current,
  });
  const nonhandlePrintLabel = () => {
    nonprintLabels()
  }

  const deliveryLabels = useRef();
  const printDeliveryLabels = useReactToPrint({
    content: () => deliveryLabels.current,
  });

  const [customActiveTab, setCustomActiveTab] = useState("1")

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  const handleToggleAssignedUser = () => {
    setModalAssignedUser(!modalAssignedUser);
  };

  const handleEditLineItem = (id) => {
    setEditLineItem(id)
    togglemodalEdit()
  }

  const handleFlagLineItem = (id) => {
    setFlagLineItem(id)
    togglemodalFlag()
  }

  const handleRemoveAwaitingStock = (id) => {
    superagent.post(`${backendUrl}/orders/removeAwaitingStock/${id}`)
      .send({
        orderID: orderID
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const handleDeleteLineItem = (id) => {
    superagent.post(`${backendUrl}/orders/cancelLineItem/${id}`)
      .send({
        orderID: orderID
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const order = data ? data : {}

  useEffect(() => {
    if (data?.name) {
      document.title =
        data.name + " | JIFI";
    }
  }, [data?.name])


  const [allUniquePets, setAllUniquePets] = useState({})
  const addUniquePet = (pet, airtableID) => {
    setAllUniquePets(prev => {
      let all = { ...prev }
      all[airtableID] = pet
      return all
    })
  }
  useEffect(() => {
    if (order && airtableData && order?.line_items) {
      order.line_items.map(lineItem => {
        if (lineItem.pet === true) {
          let petData = JSON.parse(atob(lineItem.monogram_pets))
          petData.map((pet, petKey) => {
            let airtableID = lineItem.monogram_airtable_line[petKey]
            const currentAirtableFind = airtableData.find(airtablerecord => airtablerecord.id === airtableID)

            if (currentAirtableFind !== undefined) {
              const currentAirtable = currentAirtableFind['fields']

              allUniquePets[airtableID] = {
                ...pet,
                ...currentAirtable
              }
            }
          })
        }
      })
    }
  }, [airtableData, order])


  if (!data) {
    return <div></div>
  }

  const labelData = order.line_items.filter(val => val.monogram && val.fulfillment_status !== 'deleted').map(data => ({
    monogramId: data.monogramId,
    location: data.monogram_position,
    letters: data.monogram_text,
    font: data.monogram_font,
    mainCol: data.monogram_color,
    backCol: data.monogram_shadow,
    created_at: order.orderCreated,
    country: order.shipping_address.country,
    shopifyId: order.name,
    lineItems: order.line_items.filter(val => val._id !== data._id && val.fulfillment_status !== 'deleted').map(val => val.sku),
    sku: data.sku,
    quantity: data.quantity,
    embroided: data.embroided,

    pet: data.pet,
    monogram_pets: data.monogram_pets && JSON.parse(atob(data.monogram_pets)),
    monogram_airtable_line: data.monogram_airtable_line,
    completedThumbnails: order.completedThumbnails
  }))

  const nonLabelData = order.line_items.filter(val => !val.monogram && val.fulfillment_status !== 'deleted').map(data => ({
    monogramId: data.monogramId,
    location: data.monogram_position,
    letters: data.monogram_text,
    font: data.monogram_font,
    mainCol: data.monogram_color,
    backCol: data.monogram_shadow,
    created_at: order.orderCreated,
    country: order?.shipping_address?.country,
    shopifyId: order.name,
    lineItems: order.line_items.filter(val => val._id !== data._id && val.fulfillment_status !== 'deleted').map(val => val.sku),
    sku: data.sku,
    quantity: data.quantity,
  }))

  const handleCancel = (e) => {
    e.preventDefault()
    toggleCancelOpen()
    superagent.get(`${backendUrl}/orders/${orderID}/cancel`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const setPickStatus = (monogram) => {
    superagent.post(`${backendUrl}/orders/setPickStatusCorp`)
      .send({
        orderId: orderID,
        status: monogram ? 'monogram' : 'fulfillment'
      })
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const handleFlag = (e) => {
    e.preventDefault()
    if (order.flagged === true) {
      superagent.get(`${backendUrl}/orders/${orderID}/getFlagged`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          console.log(response.body)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            console.log(response.body)
            refreshData()
          }
        }).catch(err => {
          console.log(err)
        });
    } else {
      handleFlagLineItem(orderID)
    }
  }

  const handleRefreshMonogramFiles = (e) => {
    e.preventDefault()
    superagent.post(`${backendUrl}/orders/${orderID}/createAllMonograms`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const handleRefreshFulfillment = (e) => {
    e.preventDefault()
    superagent.get(`${backendUrl}/orders/${orderID}/refreshFulfillmentStatus`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }


  const handleUnpick = (e) => {
    e.preventDefault()
    superagent.post(`${backendUrl}/orders/${orderID}/unpick`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const removeDraft = (e) => {
    e.preventDefault()
    superagent.post(`${backendUrl}/orders/${orderID}/removeDraft`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }


  const cancelOrderFulfillment = (e) => {
    e.preventDefault()
    superagent.post(`${backendUrl}/orders/${orderID}/cancelFulfillments`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        console.log(response)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refreshData()
        }
      }).catch(err => {
        console.log(err)
      });
  }

  const togglemodalEdit = () => {
    setModalEdit(prev => !prev)
    // printLabels()
  }

  const togglemodalReturn = () => {
    setModalReturn(prev => !prev)
    // printLabels()
  }

  const toggleAddModalEdit = () => {
    setAddModalEdit(prev => !prev)
    // printLabels()
  }

  const toggleUpdateModal = () => {
    setUpdateModal(prev => !prev)
    // printLabels()
  }

  const toggleShippingNodalEdit = () => {
    setShippingModalEdit(prev => !prev)
    // printLabels()
  }

  const toggleCancelOpen = () => {
    setCancelOpen(prev => !prev)
    // printLabels()
  }

  const handleEditShipping = (e) => {
    e.preventDefault()
    toggleShippingNodalEdit()
  }

  const togglemodalFlag = () => {
    setModalFlag(prev => !prev)
    // printLabels()
  }

  return (
    <div className="page-content">
      <div className="w-full px-3 mx-auto xl:max-w-[85%]">
        <Header
        order={order}
        toggleUpdateModal={toggleUpdateModal}
        removeDraft={removeDraft}
        toggleCancelOpen={toggleCancelOpen}
        handleUnpick={handleUnpick}
        handleRefreshMonogramFiles={handleRefreshMonogramFiles}
        handleFlag={handleFlag}
        cancelOrderFulfillment={cancelOrderFulfillment}
        handleRefreshFulfillment={handleRefreshFulfillment}
        setPickStatus={setPickStatus}
        togglemodalReturn={togglemodalReturn}
        handleToggleAssignedUser={handleToggleAssignedUser}
        ></Header>
        <Row className="flex flex-wrap -mx-3">
          <Col className="w-full md:w-8/12 px-3">
            <Row>
              <Col className="w-full">
              
                <OrderDetailsSection 
                  order={order}
                  refreshData={refreshData}
                  EditModalShipping={EditModalShipping}
                />
              </Col>
            </Row>

            <Row className="flex-col">
              <Col className="w-full">
                <LineItemsSection 
                  order={order}
                  orderID={orderID}
                  refreshData={refreshData}
                  findSKULevel={findSKULevel}
                  findUpcomingStock={findUpcomingStock} 
                  findSKUStatus={findSKUStatus}
                  handleEditLineItem={handleEditLineItem}
                  handleDeleteLineItem={handleDeleteLineItem}
                  handleRemoveAwaitingStock={handleRemoveAwaitingStock}
                  
                  getVariant={getVariant}
                  printDeliveryLabels={printDeliveryLabels}
                  airtableData={airtableData}
                />
              </Col>
            </Row>

            <Row>
              <Col className="w-full">
                <LabelsSection
                  handlePrint={handlePrint}
                  order={order}
                  nonpackingLabelsRef={nonpackingLabelsRef}
                  packingLabelsRef={packingLabelsRef}
                  deliveryLabels={deliveryLabels}
                  handlePrintLabel={handlePrintLabel}
                  nonhandlePrintLabel={nonhandlePrintLabel}
                  printDeliveryLabels={printDeliveryLabels}
                />
              </Col>
            </Row>

          </Col>

          <Col className="md:w-4/12 px-3">
            <div style={{ position: 'relative', height: '100%' }}>
              <div id="stickyTest" className='mb-4' style={{ position: 'sticky', top: 100 }}>
                
              <CustomerInfoSection 
                  order={order}
                  handleEditShipping={handleEditShipping}
                  toggleShippingNodalEdit={toggleShippingNodalEdit}
                  refreshData={refreshData}
                  orderID={orderID}
                />
                
                <NotesSection
                  refresh={refresh}
                  order={order}
                />

               

              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ display: 'none' }}>
        <PackingSlip order={order} ref={packingSlipRef} />
      </div>
      {
        order.contains_monogram && (
          <div style={{ display: 'none' }}>
            <PrintPage
              ref={packingLabelsRef}
              monogramRecords={labelData}
              monogram={true}
            />
          </div>
        )
      }
      <div style={{ display: 'none' }}>
        <PrintPage
          ref={nonpackingLabelsRef}
          monogramRecords={nonLabelData}
          monogram={false}
        />
      </div>
      <div style={{ display: 'none' }}>
        <PrintPageDeliveries
          ref={deliveryLabels}
          labels={order.line_items.map(val => ({ quantity: val.quantity }))}
          name={order.shipping_address.name}
          address1={order.shipping_address.address1}
          city={order.shipping_address.city}
          zip={order.shipping_address.zip}
          phone={order.shipping_address.phone}
        />
      </div>
        {editLineItem && (
        <EditModal open={modalEdit} addUniquePet={addUniquePet} allUniquePets={allUniquePets} orderID={orderID} order={order} editLineItem={editLineItem} refresh={refreshData} togglemodal={togglemodalEdit} />
      )}

      
        {shippingModalEdit && 
        <EditModalShipping open={shippingModalEdit} orderID={orderID} order={order} refresh={refreshData} togglemodal={toggleShippingNodalEdit} />
        }



      
        {returnModal &&   
        <ReturnsModal isOpen={returnModal} orderID={orderID} order={order} refresh={refreshData} togglemodal={togglemodalReturn} />
      
      
        }
     
      
     

        {
          updateModal === true && (
            <CorpEditModal open={updateModal} orderID={orderID} editLineItem={editLineItem} priority={order.priority} deadline={order.deadline} refresh={refreshData} togglemodal={toggleUpdateModal} />
          )
        }
      {modalFlag &&
        <FlaggedModal orderID={orderID} flagLineItem={flagLineItem} refresh={refreshData} open={modalFlag} togglemodal={togglemodalFlag} />
}
        <AssignedUserModal 
        users={userList}
        togglemodal={handleToggleAssignedUser}
        isOpen={modalAssignedUser}
        defaultChecked={data.assigned_user?.id}
        handleAssignedUser={handleAssignedUser}  
        />
      

      <Dialog open={cancelOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" size="lg" onOpenChange={toggleCancelOpen}>
        <DialogContent>
          <DialogTitle>
          <h3>CANCELLING ORDER</h3>
          </DialogTitle>
        <DialogDescription>
          Are you sure you want to cancel this order? (this can't be undone).
        </DialogDescription>
        <DialogFooter>
          <Button onClick={handleCancel} variant="destructive" className="btn-lg btn-rounded">
            Confirm
          </Button>
        </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}





const OrderDropdownMenu = ({
  order,
  toggleCancelOpen,
  handleFlag,
  handleRefreshFulfillment,
  removeDraft,
  toggleUpdateModal,
  setPickStatus,
  handleRefreshMonogramFiles,
  cancelOrderFulfillment,
  handleUnpick,
  handleToggleAssignedUser,
  
}) => {
  return (
    <DropdownMenu style={{ display: 'inline-block', padding: '0 16px' }}>
                        <DropdownMenuTrigger href="#" className="card-drop p-3" tag="i">
                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" side="left">
                          <DropdownMenuItem onClick={(e) => handleFlag(e)} href="#">
                            {order.flagged ? 'Unflag' : 'Flag'}
                          </DropdownMenuItem>

                          <DropdownMenuItem onClick={(e) => toggleUpdateModal(e)} href="#">
                            Update corp options
                          </DropdownMenuItem>

                          {(order.fulfillment_status === 'unfulfilled' || order.fulfillment_status === 'awaiting-stock') && (
                            <DropdownMenuItem onClick={toggleCancelOpen} href="#">
                              Cancel
                            </DropdownMenuItem>
                          )}

                          {order.contains_monogram === true && (
                            <DropdownMenuItem onClick={handleRefreshMonogramFiles} href="#">
                              Refresh all Monograms
                            </DropdownMenuItem>
                          )}

                          {order.fulfillment_status === 'fulfilled' && (
                            <DropdownMenuItem onClick={cancelOrderFulfillment} href="#">
                              Unfulfill
                            </DropdownMenuItem>
                          )}

                          {order.fulfillment_status === 'draft' && (
                            <DropdownMenuItem onClick={removeDraft} href="#">
                              Remove from draft
                            </DropdownMenuItem>
                          )}

                          {order.fulfillment_status === 'unfulfilled' && (
                            <>
                              {order.pick_status === 'none' && order.contains_monogram === true && (
                                <DropdownMenuItem onClick={() => setPickStatus(true)} href="#">
                                  Pick For Monogram
                                </DropdownMenuItem>
                              )}

                              {(order.pick_status === 'none' && order.contains_monogram === false) ||
                                (order.pick_status === 'monogram' && order.contains_monogram === true) ? (
                                <DropdownMenuItem onClick={() => setPickStatus(false)} href="#">
                                  Pick For Fulfillment
                                </DropdownMenuItem>
                              ) : null}
                            </>
                          )}

                          <DropdownMenuItem onClick={handleRefreshFulfillment} href="#">
                            Refresh Fulfillment Status
                          </DropdownMenuItem>

                          {order.pick_status !== 'none' && (
                            <DropdownMenuItem onClick={handleUnpick} href="#">
                              Unpick
                            </DropdownMenuItem>
                          )}

                          <DropdownMenuItem onClick={handleToggleAssignedUser} href="#">
                            Assigned User
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
  );
};

const LineItemsSection = memo(({ 
  order, 
  orderID, 
  refreshData, 
  findSKULevel, 
  findUpcomingStock, 
  findSKUStatus, 
  getVariant, 
  printDeliveryLabels, 
  handleEditLineItem,
  handleDeleteLineItem, 
  handleRemoveAwaitingStock 
}) => {
  const [activeTab, setActiveTab] = useState("1");

  const filteredLineItems = useMemo(() => {
    return {
      all: order?.line_items?.filter(val => val.sku !== undefined) || [],
      unfulfilled: order?.line_items?.filter(
        val => 
          (val.fulfillment_status === 'unfulfilled' || 
           (val.fulfillment_status === 'awaiting-stock' && val.monogram === false)) && 
          val.sku !== undefined
      ) || [],
      monogram: order?.line_items?.filter(
        val => val.monogram === true && 
               val.fulfillment_status !== 'unfulfilled' && 
               val.fulfillment_status !== 'fulfilled'
      ) || [],
      fulfilled: order?.line_items?.filter(
        val => val.sku !== undefined && val.fulfillment_status === 'fulfilled'
      ) || [],
      split: order?.line_items?.filter(val => val.sku !== undefined) || []
    };
  }, [order?.line_items]);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  return (
    <Col className="w-full">
      <Card className="my-6">
        <CardBody>
          <CardTitle className="mt-4 text-xl font-medium">Line Items</CardTitle>
          <Tabs 
            className="mt-4" 
            defaultValue="1" 
            value={activeTab} 
            onValueChange={handleTabChange}
          >
            <TabsList>
            <TabsTrigger value="1">All</TabsTrigger>
              <TabsTrigger value="2">Unfulfilled</TabsTrigger>
              <TabsTrigger value="3">Monogramming</TabsTrigger>
              <TabsTrigger value="4">Fulfilled</TabsTrigger>
              <TabsTrigger value="5">Split</TabsTrigger>
            </TabsList>

            <TabsContent value="1">
              <Row className="flex-col align-items-start">
                <Col className="w-full">
                  {filteredLineItems.all.map((lineItem) => (
                    <LineItemRow 
                      key={lineItem._id}
                      lineItem={{...lineItem, name: getVariant(lineItem.sku)?.name}}
                      getVariant={getVariant}
                      findSKULevel={findSKULevel}
                      findUpcomingStock={findUpcomingStock}
                      findSKUStatus={findSKUStatus}
                      handleEditLineItem={handleEditLineItem}
                      handleDeleteLineItem={handleDeleteLineItem}
                      handleRemoveAwaitingStock={handleRemoveAwaitingStock}
                    />
                  ))}
                </Col>
                {(order.fulfillment_status === 'unfulfilled' || order.fulfillment_status === 'awaiting-stock') && order.pick_status === 'none' && ( 
                <AddModal orderID={orderID} refresh={refreshData} />
                )}
              </Row>
            </TabsContent>

            <TabsContent value="2">
              <UnfulfilledLineItems 
                shipping_address={order.shipping_address}
                postcode={order.shipping_address.zip}
                pick_status={order.pick_status}
                findSKULevel={findSKULevel}
                findUpcomingStock={findUpcomingStock}
                findSKUStatus={findSKUStatus}
                printDeliveryLabels={printDeliveryLabels}
                getVariant={getVariant}
                distanceFromHQ={order.distanceFromHQ}
                contains_monogram={order.contains_monogram}
                refresh={refreshData}
                shipping_type={order?.shipping_type}
                order_source={order?.order_source}
                orderName={order.name}
                orderID={order._id}
                lineItems={order.line_items
                  .filter(
                    (val) =>
                      val.fulfillment_status ===
                        'unfulfilled' ||
                      (val.fulfillment_status ===
                        'awaiting-stock' &&
                        val.monogram === false)
                  )
                  .filter((val) => val.sku !== undefined)}
              />
            </TabsContent>

            <TabsContent value="3">
              <MonogramLineItems
                order={order} 
                getVariant={getVariant}
                findSKULevel={findSKULevel}
                findUpcomingStock={findUpcomingStock}
                findSKUStatus={findSKUStatus}
                refresh={refreshData}
              />
            </TabsContent>

            <TabsContent value="4">
              <FulfilledLineItems
                order={order}
                lineItems={order.line_items
                  .filter((val) => val.sku !== undefined)
                  .filter(
                    (val) =>
                      val.fulfillment_status === 'fulfilled'
                  )}
                getVariant={getVariant} 
                findSKULevel={findSKULevel}
                findUpcomingStock={findUpcomingStock}
                findSKUStatus={findSKUStatus}
              />
            </TabsContent>

            <TabsContent value="5">
            <SplitLineItems
                    printDeliveryLabels={printDeliveryLabels}
                    getVariant={getVariant}
                    distanceFromHQ={order.distanceFromHQ}
                    contains_monogram={order.contains_monogram}
                    refresh={refreshData}
                    shipping_type={order?.shipping_type}
                    order_source={order?.order_source}
                    orderName={order.name}
                    orderID={order._id}
                              lineItems={order.line_items.filter(
                                (val) => val.sku !== undefined
                              )}
                              findSKULevel={findSKULevel}
                              findUpcomingStock={findUpcomingStock}
                              findSKUStatus={findSKUStatus}
                            />
             
            </TabsContent>
          </Tabs>
        </CardBody>
      </Card>
    </Col>
  );
});

const LineItemRow = memo(({ 
  lineItem, 
  getVariant, 
  findSKULevel, 
  findUpcomingStock, 
  findSKUStatus, 
  handleEditLineItem, 
  handleDeleteLineItem, 
  handleRemoveAwaitingStock 
}) => {
  const variant = useMemo(() => getVariant(lineItem.sku), [lineItem.sku, getVariant]);

  const canEdit = useMemo(() => 
    ((lineItem.fulfillment_status === 'unfulfilled' && (lineItem.monogram == false || lineItem.monogram == undefined)) ||
    (lineItem.fulfillment_status === 'to-be-monogrammed' && lineItem.monogram === true) ||
    lineItem.fulfillment_status === 'awaiting-stock'), 
    [lineItem.fulfillment_status, lineItem.monogram]
  );

  const handleEdit = useCallback(() => handleEditLineItem(lineItem), [handleEditLineItem, lineItem]);
  const handleDelete = useCallback(() => handleDeleteLineItem(lineItem._id), [handleDeleteLineItem, lineItem._id]);
  const handleRemoveStock = useCallback(() => handleRemoveAwaitingStock(lineItem._id), [handleRemoveAwaitingStock, lineItem._id]);

  return (
    <Row>
      <Col className="sm:w-2/12">
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={variant?.image} alt="" className="img-fluid" />
        </div>
      </Col>
      <Col className="w-5/12 p-3">
        <h6 className="text-black font-medium">{variant?.name}</h6>
        <div>
          <StatusBadge
            status={lineItem.fulfillment_status}
            type="fulfillment"
          />
        </div>
        <div className="text-gray-500">
          SKU:{' '}
          <SKUToolTip
            sku={lineItem.sku}
            findSKULevel={findSKULevel}
            findUpcomingStock={findUpcomingStock}
            findSKUStatus={findSKUStatus}
          />
        </div>
        <div>Allocated Warehouse: {lineItem.allocatedWarehouse}</div>
      </Col>
      <Col  className="p-3 sm:w-2/12">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
     
      <Col className="p-3 sm:w-2/12 flex flex-col items-end">
        <p className="text-black font-medium">{lineItem.quantity}x ${lineItem.price}</p> 
        
      </Col>

      <Col className="p-3 sm:w-1/12 flex flex-col items-end">
      {canEdit && (
          <DropdownMenu>
            <DropdownMenuTrigger href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18"></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent right>
              {canEdit && (
                <DropdownMenuItem onClick={handleEdit} href="#">
                  Edit
                </DropdownMenuItem>
              )}
              {lineItem.fulfillment_status !== 'deleted' && (
                <DropdownMenuItem onClick={handleDelete} href="#">
                  Delete
                </DropdownMenuItem>
              )}
              {lineItem.fulfillment_status === 'awaiting-stock' && lineItem.bypass_awaiting_stock !== true && (
                <DropdownMenuItem onClick={handleRemoveStock} href="#">
                  Remove from awaiting stock
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </Col>
    </Row>
  );
});

const FulfilledLineItems = ({ lineItems, getVariant, findSKULevel, findUpcomingStock, findSKUStatus }) => {
  return (
    <>
      {lineItems?.length > 0 && lineItems.map((lineItem) => (
        <Row>
          <Col className="sm:w-2/12">
            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={getVariant(lineItem.sku)?.image} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col className="p-3 sm:w-5/12">
            <h6>{getVariant(lineItem.sku)?.name}</h6>
            <div>
              <StatusBadge
                status={lineItem.fulfillment_status}
                type="fulfillment"
              />
              
            </div>
            <div>
              SKU:{' '}
              <SKUToolTip
                sku={lineItem.sku}
                findSKULevel={findSKULevel}
                findUpcomingStock={findUpcomingStock}
                findSKUStatus={findSKUStatus}
              />
            </div>
          </Col>
          <Col  className="p-3 sm:w-2/12">
                {lineItem.monogram && !lineItem.pet && (
                  <a
                    href={`https://storage.googleapis.com/july-monogramming-management.appspot.com/${`${lineItem.monogram_font.toLowerCase()}-${encodeURIComponent(
                      lineItem.monogram_text
                    )}-${lineItem.monogram_color}-${
                      lineItem.monogram_shadow || ''
                    }-undefined${
                      lineItem.sku.startsWith('IPC')
                        ? `-IPC-${lineItem.monogram_position}`
                        : ''
                    }${
                      lineItem.sku.startsWith('BOT') &&
                      lineItem.monogram_position === 'Top'
                        ? '-Lid'
                        : ''
                    }.pdf?avoidTheCaches=1`}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ opactity: 0.8, fontSize: 10 }}>
                      <div>TEXT: {lineItem.monogram_text}</div>
                      <div>FONT: {lineItem.monogram_font}</div>
                      <div>COLOUR: {lineItem.monogram_color}</div>
                      {(lineItem.monogram_font === 'block' ||
                        lineItem.monogram_font === 'college' ||
                        lineItem.monogram_font === 'heritage' ||
                        lineItem.monogram_font === 'decorative') && (
                        <div>SHADOW: {lineItem.monogram_shadow}</div>
                      )}
                      <div>POSITION: {lineItem.monogram_position}</div>
                    </div>
                  </a>
                )}
                {lineItem.pet &&
                  lineItem.monogram_airtable_line &&
                  lineItem.monogram_airtable_line.map((val, key) => (
                    <a
                      href={`https://airtable.com/appoz4KxpjUnhvi36/tblNYa0W7gSLllwFB/viwIQ8UsoqhlIyiGt/${val}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      PET {key}
                    </a>
                  ))}

                {lineItem.message && (
                  <div style={{ opactity: 0.8, fontSize: 10 }}>
                    <div>message: {lineItem.message}</div>
                  </div>
                )}
              </Col>
          <Col className="sm:w-2/12 p-3">
            {lineItem.quantity}x ${lineItem.price}
          </Col>
        </Row>
      ))}
    </>
  );
};


const Header = ({
  order,
  handleOpenPopup,
  toggleUpdateModal,
  toggleCancelOpen,
  handleManuallyFulfill,
  removeDraft,
  handleSendLaunchLabel,
  handleFlag,
  handleContacted,
  handleRefreshFulfillment,
  togglemodalReturnOld,
  togglemodalReturn,
  handleRefreshMonogramFiles,
  cancelOrderFulfillment,
  handleUnpick,
  handleToggleAssignedUser,
  user,
  setPickStatus
}) => {
  return (
    <Row className="mb-6 flex flex-col sm:flex-row items-start sm:items-center gap-4">
      {/* Left side - Order info */}
      <Col className="w-full sm:w-1/2">
        <CardTitle className="text-2xl sm:text-3xl font-medium mb-2">{order?.name}</CardTitle>
        <p className="text-gray-600 text-sm sm:text-base">
          Placed on {new Date(order.orderCreated).toLocaleString()}
        </p>
      </Col>

      {/* Right side - Actions */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 w-full sm:w-1/2 sm:justify-end">
      

        <OrderDropdownMenu
          order={order}
          toggleUpdateModal={toggleUpdateModal}
          toggleCancelOpen={toggleCancelOpen}
          handleManuallyFulfill={handleManuallyFulfill}
          handleSendLaunchLabel={handleSendLaunchLabel}
          handleOpenPopup={handleOpenPopup}
          removeDraft={removeDraft}
          handleFlag={handleFlag}
          handleContacted={handleContacted}
          handleRefreshFulfillment={handleRefreshFulfillment}
          togglemodalReturnOld={togglemodalReturnOld}
          togglemodalReturn={togglemodalReturn}
          handleRefreshMonogramFiles={handleRefreshMonogramFiles}
          cancelOrderFulfillment={cancelOrderFulfillment}
          handleUnpick={handleUnpick}
          handleToggleAssignedUser={handleToggleAssignedUser}
          user={user}
          setPickStatus={setPickStatus}
        />
      </div>
    </Row>
  );
};


const ReturnsModal = ({ togglemodal, refresh, order, orderID, isOpen=false }) => {
  const initParcel = {
    lineItems: [],
    lineItemsFlat: undefined,
    product: undefined,
    productData: undefined,
    parcelSize: undefined,
    quantity: 1
  }

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [selectedLineItems, setSelectedLineItems] = useState([])
  const [parcelData, setParcelData] = useState([initParcel])
  const [returnItems, setReturnItems] = useState([])

  const [data, state] = useQuery({
    url: `/product`,
    refresh,
    runQuery: isOpen,
  });
  const productData = data && data.data

  useEffect(() => {
    setParcelData(prev => {
      const tempReturnItems = selectedLineItems.map(lineItem => Array.from(Array(lineItem.quantity)).map((_, i) => ({
        sku: lineItem.sku,
        quantity: 1,
        id: lineItem.id,
        dupeId: `${lineItem.id}-${i}`,
        selected: undefined
      }))).flat()

      if (!productData) {
        return [initParcel]
      }

      return tempReturnItems.map(item => {
        const rootSku = item.sku?.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
        const filteredProducts = productData.map((product, productId) => {
          const filteredVariants = product.variants.filter(variant => variant.sku === rootSku)
          if (filteredVariants.length === 0) {
            return undefined
          }
          return { id: productId, variant: filteredVariants }
        }).filter(val => val !== undefined)
        let p, pd
        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id]
          p = selectedProduct && selectedProduct._id
          pd = { label: selectedProduct.name, value: selectedProduct._id }
        }

        return ({
          lineItems: [item.dupeId],
          lineItemsFlat: [{ label: item.sku, value: item.dupeId }],
          product: p,
          productData: pd,
          parcelSize: undefined,
          quantity: 1
        })
      })
    })
    setReturnItems(selectedLineItems.map(lineItem => Array.from(Array(lineItem.quantity)).map((_, i) => ({
      sku: lineItem.sku,
      quantity: 1,
      id: lineItem.id,
      dupeId: `${lineItem.id}-${i}`,
      selected: undefined
    }))).flat())
  }, [selectedLineItems, productData])

  useEffect(() => {
    setSelectedLineItems(order.line_items.map((lineItem) => ({
      sku: lineItem.sku,
      quantity: lineItem.quantity,
      id: lineItem._id
    })))
  }, [order])

  useEffect(() => {
    if (returnItems) {
      setReturnItems(prev => {
        return prev.map((returnItem, rID) => {
          let parcelReduced = parcelData.map((parcel, parcelId) => ({ id: parcelId, lineItem: parcel.lineItems.find(val => val === returnItem.dupeId) }))
          parcelReduced = parcelReduced.filter(val => val.lineItem !== undefined)
          let updatedReturnItem = { ...returnItem }
          if (parcelReduced.length > 0) {
            updatedReturnItem.selected = parcelReduced[0].id
          } else {
            updatedReturnItem.selected = undefined
          }
          return updatedReturnItem
        })
      })

    }
  }, [parcelData, step])

  const addParcel = () => {
    setParcelData(prev => {
      let updatedLines = [...prev]
      updatedLines.push(initParcel)
      return updatedLines
    })
  }

  const deleteParcel = (id) => {
    setParcelData(prev => {
      return prev.filter((val, parcelId) => parcelId !== id)
    })
  }

  const handleSelectProduct = (selectedProduct, id) => {
    setParcelData(prev => {
      let updatedLines = [...prev]
      updatedLines[id].product = selectedProduct && selectedProduct.value
      updatedLines[id].productData = selectedProduct
      return updatedLines
    })
  }

  const handleSelectSkus = (selectedSkus, id) => {
    setParcelData(prev => {
      let updatedLines = [...prev]
      updatedLines[id].lineItems = (Array.isArray(selectedSkus) && selectedSkus.map(a => a.value)) || []
      updatedLines[id].lineItemsFlat = selectedSkus

      if (updatedLines[id].lineItems.length === 1) {
        const selectedLineId = updatedLines[id].lineItems[0]
        const selectedLine = returnItems.find(val => val.dupeId === selectedLineId)
        const rootSku = selectedLine.sku?.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')
        const filteredProducts = productData.map((product, productId) => {
          const filteredVariants = product.variants.filter(variant => variant.sku === rootSku)
          if (filteredVariants.length === 0) {
            return undefined
          }
          return { id: productId, variant: filteredVariants }
        }).filter(val => val !== undefined)

        if (filteredProducts.length > 0) {
          const selectedProduct = productData[filteredProducts[0].id]
          updatedLines[id].product = selectedProduct && selectedProduct._id
          updatedLines[id].productData = { label: selectedProduct.name, value: selectedProduct._id }
        }
      }
      return updatedLines
    })
  };

  const alterQuantity = (index, quantity) => {
    if (index >= 0 && index <= selectedLineItems.length - 1 && Number.isInteger(quantity) && quantity <= order.line_items[index].quantity && quantity >= 0) {
      setSelectedLineItems(prev => {
        let updatedLines = [...prev]
        updatedLines[index].quantity = quantity
        return updatedLines
      })
    }
  }

  const handleSave = () => {
    const proccessedParcels = parcelData.map(parcel => {
      const lineItems = parcel.lineItems.map(lineID => returnItems.find(val => val.dupeId === lineID))
      const parcelSize = productData.find(val => val._id === parcel.product).variants[0].sku
      return {
        lineItems,
        parcelSize,
        quantity: 1
      }
    })

    const exportData = {
      orderID: orderID,
      proccessedParcels: proccessedParcels
    }

    setLoading(true)
    superagent.post(`${backendUrl}/orders/createUSReturn/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          refresh()
          togglemodal()
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  

  return (
    <Dialog open={isOpen} onOpenChange={togglemodal}>
     {loading === true ? (
     <DialogContent className="z-[1600]">
      
     <DialogHeader>
       <DialogTitle> US Return</DialogTitle>
     </DialogHeader>
     <DialogDescription>
     <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
     </DialogDescription>
   </DialogContent>): (
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
          US Return - {step === 0 ? 'Select Line Items' : 'Parcel Sizes'}
          </DialogTitle>
        </DialogHeader >
        <div>
          <div className="text-slateGray mb-2">
            {step === 0 ? 'Please select which SKUs and quantities you would like to proccess for the return.' : 'Please select which parcel sizes you would like for each sku (if you are nesting put them in the same parcel).'}
          </div>
          <div className="">
            {
              step === 0 ? (
                <>
                  {
                    selectedLineItems.map((lineItem, id) => (
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-md-4 col-form-label"> {lineItem.sku} </label>
                        <div className="col-md-8">
                          <Input
                            id={`line-item-${lineItem.sku}-${id}`}
                            className="mb-2"
                            type="number"
                            step="1"
                            value={lineItem.quantity}
                            onChange={(e) => { alterQuantity(id, Number(e.target.value)) }} />
                        </div>
                      </div>
                    ))
                  }
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-4">
                      SKUS
                    </div>
                    <div className="col-md-5">
                      PARCEL SIZE
                    </div>
                    <div className="col-md-1">
                    </div>
                    <div className="col-md-1">

                    </div>
                  </div>

                  {
                    parcelData.map((parcel, id) => {
                      return (
                        <div className="form-group row">
                          <div className="col-md-4">
                            <SelectMultiple
                            
                              value={parcel.lineItemsFlat}
                              onChange={(val => { handleSelectSkus(val, id) })}
                              options={returnItems.filter(val => (val.selected === id || val.selected === undefined)).map((val, id) => ({ label: val.sku, value: val.dupeId }))}
                              isMulti
                            />
                          </div>
                          <div className="col-md-5">
                            {
                              productData && (
                                <SelectMultiple
                                 
                                  value={parcel.productData}
                                  onChange={(val => { handleSelectProduct(val, id) })}
                                  options={productData.map((val, id) => ({ label: val.name, value: val._id }))}
                                />
                              )
                            }
                          </div>
                          <div className="col-md-1">
                          </div>
                          <div className="col-md-1">
                            <Button variant="destructive" onClick={() => deleteParcel(id)}> X </Button>
                          </div>
                        </div>
                      )
                    })
                  }
                  {
                    returnItems.filter(val => val.selected === undefined).length > 0 && (
                      <Button variant="primary" onClick={addParcel}> Add Line + </Button>
                    )
                  }
                </>
              )
            }
          </div>
        </div>
        <DialogFooter>
          {
            step === 0 ? (
              <Button variant="success" onClick={() => setStep(1)}> Next </Button>
            ) : (
              <>
                <Button variant="secondary" onClick={() => setStep(0)}> Back </Button>
                <Button variant="success" disabled={returnItems.filter(val => val.selected === undefined).length > 0} onClick={handleSave}> Send </Button>
              </>
            )
          }

        </DialogFooter>
      </DialogContent>)}
    </Dialog>
  )
}
const EditModal = ({ togglemodal, refresh, editLineItem, orderID, allUniquePets, order, addUniquePet, open }) => {
  const [monogramType, setMonogramType] = useState(editLineItem.pet === true ? 'pets' : 'text')

  const [monogramPets, setMonogramPets] = useState(editLineItem.monogram_pets)
  const [monogramAirtableLines, setMonogramAirtableLines] = useState(editLineItem.monogram_airtable_line)

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState()
  const [quantity, setQuantity] = useState(editLineItem.quantity)

  useEffect(() => {
    setLoading(true)
    superagent.get(`${backendUrl}/product/variant/${editLineItem.sku?.split('-').filter((val) => { return (val !== 'P' && val !== 'LE') }).join('-')}/find`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          setOptions(response.body)
          setSelectedOption(editLineItem)
          setLoading(false)
        }
      }).catch(err => {
        setLoading(false)
      });

    if (editLineItem.monogram === true) {
      setMonogramText(editLineItem.monogram_text)
      setMonogramFont(editLineItem.monogram_font)
      setMonogramColor(editLineItem.monogram_color)
      setMonogramShadow(editLineItem.monogram_shadow || 'None')
      setMonogramLocation(editLineItem.monogram_position)
    }
  }, [editLineItem])

  const handleOptionChanged = (e) => {
    const option = e.target.value
    setSelectedOption(option)
  }

  const [monogramText, setMonogramText] = useState('')
  const [monogramFont, setMonogramFont] = useState()
  const [monogramColor, setMonogramColor] = useState()
  const [monogramShadow, setMonogramShadow] = useState()
  const [monogramLocation, setMonogramLocation] = useState()

  const [edittingPet, setEdittingPet] = useState()

  const updateLineItem = () => {
    let newLineItem = {
      _id: editLineItem._id,
      sku: selectedOption?.sku,
      orderID: orderID,
      quantity: quantity,
      ...(
        (selectedOption?.sku?.split('-').filter(val => val !== 'P' && val !== 'LE').join('-')) !== selectedOption?.sku ? (
          monogramType === 'text' ? (
            {
              monogram_text: encodeURIComponent(monogramText),
              monogram_font: monogramFont,
              monogram_color: monogramColor,
              monogram_position: monogramLocation,
              ...(
                (monogramFont === 'block' || monogramFont === 'college' || monogramFont === 'heritage' || monogramFont === 'decorative') ?
                  { monogram_shadow: monogramShadow, } : {}
              ),
              monogram: true,
              pet: false
            }
          ) : (
            {
              monogram_position: monogramLocation,
              monogram: true,
              monogram_pets: monogramPets,
              monogram_airtable_line: monogramAirtableLines,
              pet: true
            }
          )
        ) : {
          monogram: false
        }
      )
    }

    setLoading(true)
    superagent.post(`${backendUrl}/orders/${editLineItem._id}/editLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refresh()
          togglemodal()
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  let [comment_file, setCommentFile] = useState(null)
  let [imageData, setImageData] = useState()
  let [uploadedAirtableID, setUploadedAirtableID] = useState('')
  let [newPetName, setNewPetName] = useState('')

  const handleFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    let file = files[0]
    setCommentFile(file)

    const reader = new FileReader()
    reader.readAsBinaryString(file)

    reader.onload = function (event) {
      setImageData({
        base64: `data:${file.type};base64,${btoa(event.target.result)}`,
        filename: file.name,
      })
      event.target.value = null
      event.target.files = null
      setCommentFile(null)
    }
  }

  const uploadToS3 = () => {
    const uploadData = {
      "petName": newPetName,
      "base64Image": imageData.base64
    }

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    }

    options.body = JSON.stringify(uploadData)

    return new Promise((resolve, reject) => {
      fetch(
        'https://az25kdsm7h.execute-api.ap-southeast-2.amazonaws.com/production/uploadPetImagesToS3',
        options
      )
        .then((response) => response.json())
        .then((response) => {
          let base64ToString = Buffer.from(response.url, "base64").toString();
          resolve(base64ToString)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const createAirtable = (printJobID) => {
    const uploadData = {
      printJobID: printJobID,
      OrderNumber: order.name,
      email: order.email
    }

    return new Promise((resolve, reject) => {
      superagent.post(`${backendUrl}/orders/createPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve(response.body)
          }
        }).catch(err => {
          reject(err)
        });
    })
  }

  const fetchAirtable = (airtableId) => {
    const uploadData = {
      airtableId
    }

    return new Promise((resolve, reject) => {
      superagent.post(`${backendUrl}/orders/fetchPetAirtable/`)
        .send(uploadData)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')
        .on('error', (response) => {
          reject(response)
        })
        .then(response => {
          const code = response.status
          if (code > 400) {
          } else {
            resolve(response.body)
          }
        }).catch(err => {
          reject(err)
        });
    })
  }

  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  const savePet = async () => {
    let airtableID
    if (imageData !== undefined) {
      let printjobID = await uploadToS3()
      airtableID = (await createAirtable(printjobID)).id
      await sleep(2000)
    } else if (uploadedAirtableID !== '') {
      airtableID = uploadedAirtableID
    } else {
      return
    }

    let airtableRecord = await fetchAirtable(airtableID)

    let uniquePet = {
      ...airtableRecord['fields'],
      name: newPetName
    }

    addUniquePet(uniquePet, airtableID)
    setEdittingPet()
  }




  const colorType = selectedOption ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR'].includes(selectedOption?.sku.slice(0, 3)) || ['EVETOT'].includes(selectedOption?.sku.slice(0, 6)) : false
  const isEmboss = selectedOption && selectedOption?.sku.includes('JETTE')
  let colors = colorType ? newColorsBags : newColors
  let fontsFor = colorType ? fontsBags : fonts
  if (isEmboss) {
    colors = embosColours
    fontsFor = embosFonts
  }
  const monogramTypes = {
    'text': 'Text',
    'pets': 'Pets',
  }

  return (
    <Dialog open={open} onOpenChange={togglemodal}>
      {loading === true ? (
        <DialogContent className="max-h-[80vh] overflow-y-auto  min-w-[50vw]">
        <DialogHeader>
          <DialogTitle>Loading...</DialogTitle>
        </DialogHeader>
      </DialogContent>
      ) : (
      <DialogContent className="max-h-[80vh] z-[1500] min-w-[60vw]">
        <DialogHeader toggle={togglemodal}>
          <DialogTitle>
          {
            edittingPet !== undefined ? ('New pet upload') : ('Edit Line Item')
          }
          </DialogTitle>
        </DialogHeader>
        <div>
          {
            edittingPet !== undefined ? (
              <>
                {
                  edittingPet.airtableId === undefined ? (
                    <>
                      {
                        uploadedAirtableID === '' && (
                          imageData === undefined ? (
                            <div className="flex flex-row mb-4" row>
                              <Col  className="d-flex align-items-center custome-input">
                                <Input
                                  accept="image/*"
                                  className=" ml-2"
                                  type="file"
                                  id="comment_file"
                                  name="comment_file"
                                  label={comment_file || 'Add image'}
                                  onChange={(e) => handleFileChange(e)}
                                />
                              </Col>
                            </div>
                          ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => setImageData()}>
                              <img style={{ height: 100, width: 100 }} src={imageData.base64} />
                            </div>
                          )
                        )
                      }
                      {
                        imageData === undefined && (
                          <div className="form-group row">
                            <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Airtable ID </label>
                            <div className="col-md-10">
                              <Input placeholder="Airtable ID" className="mb-2" type="text" value={uploadedAirtableID} onChange={(e) => setUploadedAirtableID(e.target.value)} />
                            </div>
                          </div>
                        )
                      }
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Pet name </label>
                        <div className="col-md-10">
                          <Input placeholder="Pet name" className="mb-2" type="text" value={newPetName} onChange={(e) => setNewPetName(e.target.value)} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <> {JSON.stringify(edittingPet)} </>
                  )
                }
              </>
            ) : (
              <>
                <ProductVariantSelector
                  products={options}
                  setSelectedVariant={setSelectedOption}
                  selectedVariant={selectedOption}
                />
                <Input className="mt-2" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                <br />
                {
                  selectedOption && (selectedOption?.sku?.split('-').filter(val => val !== 'P' && val !== 'LE').join('-') !== selectedOption?.sku) && (
                    <div>
                      <br />
                      <h6> Monogram Data</h6>
                      <br />
                      <div className="form-group row">
                        <div className="col-md-12">
                        <Select
                            onValueChange={setMonogramType}
                            value={monogramType}
                          >
                            <SelectTrigger className="mb-2">
                              <SelectValue placeholder="-- select an option --" />
                            </SelectTrigger>
                            <SelectContent className="z-[1600]">
                              {Object.keys(monogramTypes).map((val) => (
                                <SelectItem key={val} value={val}>
                                  {monogramTypes[val]}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      <br />
                      {
                        monogramType === 'text' && (
                          <>
                            <div className="form-group row">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Text </label>
                              <div className="col-md-10">
                                <Input placeholder="Monogram text" className="mb-2" type="text" value={monogramText} onChange={(e) => setMonogramText(e.target.value)} />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label"> Font </label>
                              <div className="col-md-10">
                              <Select onValueChange={setMonogramFont} value={monogramFont}>
                                <SelectTrigger className="mb-2">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {Object.keys(fontsFor).map((val) => (
                                    <SelectItem key={val} value={val}>
                                      {fontsFor[val]}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Main Colour</label>
                              <div className="col-md-10">
                              <Select onValueChange={setMonogramColor} value={monogramColor}>
                                <SelectTrigger className="mb-2">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {Object.keys(colors).map((val) => (
                                    <SelectItem key={val} value={val} style={{ color: colors[val] }}>
                                      {val}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>

                              </div>
                            </div>
                            {
                              (monogramFont === 'block' || monogramFont === 'college' || monogramFont === 'heritage' || monogramFont === 'decorative') && (
                                <div className="form-group row">
                                  <label htmlFor="example-text-input" className="col-md-2 col-form-label">Shadow Colour</label>
                                  <div className="col-md-10">
                                  <Select onValueChange={setMonogramShadow} value={monogramShadow}>
                                    <SelectTrigger className="mb-2">
                                      <SelectValue placeholder="-- select an option --" />
                                    </SelectTrigger>
                                    <SelectContent className="z-[1600]">
                                      {Object.keys(newColors).map((val) => (
                                        <SelectItem key={val} value={val} style={{ color: newColors[val] }}>
                                          {val}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  </div>
                                </div>
                              )
                            }
                            <div className="form-group row">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Location</label>
                              <div className="col-md-10">
                              <Select onValueChange={setMonogramLocation} value={monogramLocation}>
                                <SelectTrigger className="mb-2">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {editLineItem.sku.includes('NBK') ? (
                                    Object.keys(Notebooklocations).map((val) => (
                                      <SelectItem key={val} value={Notebooklocations[val]}>
                                        {Notebooklocations[val]}
                                      </SelectItem>
                                    ))
                                  ) : editLineItem.sku.includes('TEC') || editLineItem.sku.includes('CME') ? (
                                    Object.keys(TecLocations).map((val) => (
                                      <SelectItem key={val} value={TecLocations[val]}>
                                        {TecLocations[val]}
                                      </SelectItem>
                                    ))
                                  ) : editLineItem.sku.includes('TAG') ? (
                                    Object.keys(TagLocations).map((val) => (
                                      <SelectItem key={val} value={TagLocations[val]}>
                                        {TagLocations[val]}
                                      </SelectItem>
                                    ))
                                  ) : (
                                    Object.keys(locations).map((val) => (
                                      <SelectItem key={val} value={locations[val]}>
                                        {locations[val]}
                                      </SelectItem>
                                    ))
                                  )}
                                </SelectContent>
                              </Select>

                              </div>
                            </div>

                          </>
                        )
                      }
                      {
                        monogramType === 'pets' && (
                          <>
                            <div className="form-group row">
                              <label htmlFor="example-text-input" className="col-md-2 col-form-label">Location</label>
                              <div className="col-md-10">
                              <Select onValueChange={setMonogramLocation} value={monogramLocation}>
                                <SelectTrigger className="mb-2">
                                  <SelectValue placeholder="-- select an option --" />
                                </SelectTrigger>
                                <SelectContent className="z-[1600]">
                                  {editLineItem.sku.includes('NBK') ? (
                                    Object.keys(Notebooklocations).map((val) => (
                                      <SelectItem key={val} value={Notebooklocations[val]}>
                                        {Notebooklocations[val]}
                                      </SelectItem>
                                    ))
                                  ) : editLineItem.sku.includes('TEC') || editLineItem.sku.includes('CME') ? (
                                    Object.keys(TecLocations).map((val) => (
                                      <SelectItem key={val} value={TecLocations[val]}>
                                        {TecLocations[val]}
                                      </SelectItem>
                                    ))
                                  ) : editLineItem.sku.includes('TAG') ? (
                                    Object.keys(TagLocations).map((val) => (
                                      <SelectItem key={val} value={TagLocations[val]}>
                                        {TagLocations[val]}
                                      </SelectItem>
                                    ))
                                  ) : (
                                    Object.keys(locations).map((val) => (
                                      <SelectItem key={val} value={locations[val]}>
                                        {locations[val]}
                                      </SelectItem>
                                    ))
                                  )}
                                </SelectContent>
                              </Select>

                              </div>
                            </div>
                            <Row>
                              {
                                Object.keys(allUniquePets).map((petID, key) => {
                                  let pet = allUniquePets[petID]
                                  let selected = monogramAirtableLines.includes(petID)
                                  const handleTogglePetSelect = () => {
                                    if (selected) {
                                      setMonogramAirtableLines(prev => prev.filter(val => val !== petID))
                                      setMonogramPets(prev => btoa(JSON.stringify(JSON.parse(atob(prev)).filter(val => val.signedUrl !== pet.printJobID))))
                                    } else {
                                      setMonogramAirtableLines(prev => [...prev, petID])
                                      setMonogramPets(prev => btoa(JSON.stringify([...(prev ? JSON.parse(atob(prev)) : []), { signedUrl: pet.printJobID, name: pet.name }])))
                                    }
                                  }

                                  return (
                                    <Col  className="sm:w-6/12 xl:w-3/12" key={"_pet_" + key} >
                                      <Card style={{ background: '#d5d5d5' }} onClick={handleTogglePetSelect}>
                                        <CardBody style={{ position: 'relative' }}>
                                          <div style={{ position: 'absolute', top: 8, right: 8, fontSize: 24 }}>
                                            <i className={selected ? 'bx bx-check-square' : 'bx bx-square'} />
                                          </div>
                                          <h5>{pet.name}</h5>
                                          <h6>{pet.Status}</h6>
                                          <img src={pet['Provided Image'][0]['url']} style={{ width: 100, height: 100, objectFit: 'contain' }} />
                                          <br />
                                          <br />
                                          {/* <Button color="warning" onClick={(e) => {e.stopPropagation(); setEdittingPet({...pet, airtableId: petID})}}> Edit. </Button> */}
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  )
                                })
                              }
                              <Col  className="sm:w-6/12 xl:w-3/12" key={"_pet_add"} >
                                <Card style={{ background: '#d5d5d5' }}>
                                  <CardBody>
                                    <Button variant="success" onClick={() => setEdittingPet({ airtableId: undefined })}> Add new pet. </Button>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </>
                        )
                      }

                    </div>
                  )
                }

              </>
            )
          }
        </div>
        <DialogFooter>
          {
            edittingPet !== undefined && (
              <>
                <Button variant="warning" onClick={() => setEdittingPet()}> Back </Button>
                <Button variant="success" disabled={!(imageData !== undefined || uploadedAirtableID !== '')} onClick={savePet}> Save </Button>
              </>
            )
          }
          {
            edittingPet === undefined && (selectedOption?.sku !== editLineItem.sku || (selectedOption?.sku && (selectedOption?.sku?.split('-').filter(val => val !== 'P' && val !== 'LE').join('-') !== selectedOption?.sku)) || quantity !== editLineItem.quantity) && (
              <Button variant="success" onClick={updateLineItem}> Save </Button>
            )
          }
        </DialogFooter>
      </DialogContent>
      )}
    </Dialog>
  )
}
const EditModalShipping = ({  refresh, order }) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI'
  const {
    placePredictions,
    getPlacePredictions,
  } = useGoogle({
    apiKey: apiKey,
    options: {
      fields: ["address_component", "types", "geocode"],
      types: ["geocode"]
    }
  });
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const loaded = new CustomEvent('loadedPlaced', { 'detail': placePredictions.map(placeLine => ({ label: placeLine.description, value: placeLine })) });
    document.dispatchEvent(loaded)
  }, [placePredictions])

  const [selected, setSelected] = useState(null);

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(document.getElementById('test-map'), { zoom: 15 });
      var request = {
        placeId: placeID,
        fields: ['address_components']
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place)
        } else {
          rej(place)
        }
      }
    })
  }

  const loadOptions = (
    inputValue,
    callback
  ) => {
    getPlacePredictions({ input: inputValue })
    document.addEventListener("loadedPlaced", function (e) {
      callback(e.detail)
    });
  };

  const [loading, setLoading] = useState(false)

  const [shippingType, setShippingType] = useState(order.shipping_type)
  const handleShippingTypeChange = (e) => {
    setShippingType(e)
  }

  const [name, setName] = useState(order.shipping_address.name)
  const [email, setEmail] = useState(order.email)
  const [company, setCompany] = useState(order.shipping_address.company)
  const [address1, setAddress1] = useState(order.shipping_address.address1)
  const [address2, setAddress2] = useState(order.shipping_address.address2)
  const [city, setCity] = useState(order.shipping_address.city)
  const [zip, setZip] = useState(order.shipping_address.zip)
  const [phone, setPhone] = useState(order.shipping_address.phone)

  const [selectedState, setState] = useState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === order.shipping_address.country)?.isoCode)?.find(val => val.name === order.shipping_address.province)?.isoCode)
  const [selectedCountry, setCountry] = useState(Country.getAllCountries().find(val => val.name === order.shipping_address.country)?.isoCode)

  const handleStateChange = (e) => {
    setState(e.target.value)
  }

  const handleCountryChange = (e) => {
    setCountry(e)
    let newState
    try {
      newState = State.getStatesOfCountry(e)[0].isoCode
    } catch { }
    setState(newState)
  }

  useEffect(() => {
    if (selected) {
      getPlaceData(selected.value.place_id).then(res => {
        let items = res.address_components
        let streetNumberGoogleAPI, streetAddressGoogleAPI, countryGoogleAPI,
          stateGoogleAPI, unitNoGoogleAPI, suburnGoogleAPI, postcodeGoogleAPI

        items.map((item) => {
          if (item.types.includes('subpremise')) {
            unitNoGoogleAPI = item.long_name
          }
          if (item.types.includes('street_number')) {
            streetNumberGoogleAPI = item.long_name
          }
          if (item.types.includes('route')) {
            streetAddressGoogleAPI = item.long_name
          }
          if (item.types.includes('postal_code')) {
            postcodeGoogleAPI = item.long_name
          }
          if (item.types.includes('locality')) {
            suburnGoogleAPI = item.long_name
          }
          if (item.types.includes('administrative_area_level_1')) {
            stateGoogleAPI = item.long_name
          }
          if (item.types.includes('country')) {
            countryGoogleAPI = item.long_name
          }
        })

        setCountry(Country.getAllCountries().find(val => val.name === countryGoogleAPI)?.isoCode)
        setState(State.getStatesOfCountry(Country.getAllCountries().find(val => val.name === countryGoogleAPI)?.isoCode)?.find(val => val.name === stateGoogleAPI)?.isoCode)
        setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI)
        setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI)
        setAddress2('')
        if (streetAddressGoogleAPI) {
          if (streetNumberGoogleAPI) {
            if (unitNoGoogleAPI) {
              setAddress1(unitNoGoogleAPI + '/' + streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI)
            } else {
              setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI)
            }
          } else {
            setAddress1(streetAddressGoogleAPI)
          }
        } else {
          setAddress1('')
        }
        setSelected('')
      })
    }
  }, [selected])

  const handleSave = () => {
    const exportData = {
      shippingType,
      name,
      email,
      company,
      address1,
      address2,
      city,
      zip,
      phone,
      "province": State.getStateByCodeAndCountry(selectedState, selectedCountry).name,
      "country": Country.getCountryByCode(selectedCountry).name,
    }

    setLoading(true)
    superagent.post(`${backendUrl}/orders/${order._id}/editShipping/`)
      .send(exportData)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refresh()
          setOpen(false)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  const makeUnqiue = () => {
    setLoading(true)
    superagent.post(`${backendUrl}/orders/${order._id}/makeShippingUnique/`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refresh()
          setOpen(false)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
      <p className='text-black font-medium'>Edit</p>
      </DialogTrigger>
      {
      loading === true ? (
        
        <DialogContent className="max-h-[80vh] overflow-y-auto  min-w-[60vw]">
          <DialogHeader>
            <DialogTitle>Loading...</DialogTitle>
          </DialogHeader>
        </DialogContent>
      ): (
        <DialogContent className="max-h-[80vh] overflow-y-auto z-[1500] min-w-[60vw] xl:min-w-[53vw]">
        <div style={{ display: 'none' }}><div id="test-map" /></div>
        <DialogHeader>
          <DialogTitle className="pb-4">
          Edit Shipping Details
          </DialogTitle>
        </DialogHeader >
        <div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Shipping Type</label>
            <div className="col-md-10">
            <Select
                onValueChange={handleShippingTypeChange}
                value={shippingType}
              >
                <SelectTrigger className="w-full rounded-md">
                  <SelectValue placeholder="Select shipping type" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  <SelectGroup>
                    <SelectItem value="standard">Standard</SelectItem>
                    <SelectItem value="express">Express</SelectItem>
                    <SelectItem value="pickup">QV Pickup</SelectItem>
                    <SelectItem value="pickup_hq">HQ Pickup</SelectItem>
                    <SelectItem value="Uber">Uber</SelectItem>
                    <SelectItem value="overnight">Overnight</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Name</label>
            <div className="col-md-10">
              <Input onChange={e => setName(e.target.value)} className="form-control" type="text" defaultValue={order.shipping_address.name} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Email</label>
            <div className="col-md-10">
              <Input onChange={e => setEmail(e.target.value)} className="form-control" type="text" defaultValue={order.email} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Phone</label>
            <div className="col-md-10">
              <Input onChange={e => setPhone(e.target.value)} className="form-control" type="text" defaultValue={order.shipping_address.phone} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Company</label>
            <div className="col-md-10">
              <Input onChange={e => setCompany(e.target.value)} className="form-control" type="text" defaultValue={order.shipping_address.company} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Search Address</label>
            <div className="col-md-10">
              <AsyncSelect 
              styles={ {
                control: (provided) => ({
                  ...provided,
                  padding: "0px 8px",
                  border:"1px solid black",
                  paddingRight: 0,
                }),
              }}
              onClick={setSelected} onChange={setSelected} cacheOptions loadOptions={loadOptions} defaultOptions />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Country</label>
            <div className="col-md-10">
              {/* <select onChange={e => handleCountryChange(e)} type="select" name="select" id="exampleSelect">
                {
                  Country.getAllCountries().map(country => <option value={country.isoCode} selected={selectedCountry === country.isoCode} > {country.name} </option>)
                }
              </select> */}

              <Select
                defaultValue={selectedCountry}
                onValueChange={handleCountryChange}
                value={selectedCountry}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a country" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {Country.getAllCountries().map((country) => (
                    <SelectItem value={country.isoCode} key={country.isoCode}>
                      {country.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">State</label>
            <div className="col-md-10">  
                <Select
                defaultValue={selectedState}
                onValueChange={setState}
                value={selectedState}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a state" />
                </SelectTrigger>
                <SelectContent className="z-[1600]">
                  {State.getStatesOfCountry(selectedCountry).map((state) => (
                    <SelectItem value={state.isoCode} key={state.isoCode}>
                      {state.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Address</label>
            <div className="col-md-10">
              <Input onChange={e => setAddress1(e.target.value)} className="form-control" type="text" value={address1} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Suburb</label>
            <div className="col-md-10">
              <Input onChange={e => setCity(e.target.value)} className="form-control" type="text" value={city} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Postcode</label>
            <div className="col-md-10">
              <Input onChange={e => setZip(e.target.value)} className="form-control" type="text" value={zip} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="example-text-input" className="col-md-2 col-form-label">Apartment, suite, etc. (optional)</label>
            <div className="col-md-10">
              <Input onChange={e => setAddress2(e.target.value)} className="form-control" type="text" value={address2} />
            </div>
          </div>
          </div>
        
        <DialogFooter>

          <Button variant="destructive" onClick={makeUnqiue}> Make Unique </Button>
          <Button variant="success" onClick={handleSave}> Save </Button>
        </DialogFooter>
        </DialogContent>
      ) }
      
    </Dialog>
  )
}
const AddModal = ({  refresh, orderID }) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState()
  const [selectedVariant, setSelectedVariant] = useState()
  const [quantity, setQuantity] = useState(1)

  useEffect(() => {
    setLoading(true)
    superagent.get(`${backendUrl}/product`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          setOptions(response.body.data)
          setSelectedProduct(response.body.data[0]._id)
          setSelectedVariant(response.body.data[0].variants[0]?.sku)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }, [])

  const handleProductChanged = (e) => {
    const option = e.target.value
    setSelectedProduct(option)
  }
  const handleVariantChanged = (e) => {
    const option = e.target.value
    setSelectedVariant(option)
  }

  const [monogramText, setMonogramText] = useState('')
  const [monogramFont, setMonogramFont] = useState()
  const [monogramColor, setMonogramColor] = useState()
  const [monogramShadow, setMonogramShadow] = useState()
  const [monogramLocation, setMonogramLocation] = useState()

  const productObject = selectedProduct && options.find(val => val._id === selectedProduct)

  const updateLineItem = () => {
    let newLineItem = {
      product: selectedProduct,
      variant: selectedVariant?.sku,
      quantity: quantity,
      orderID: orderID,
      ...(
        selectedVariant?.sku?.split('-').filter(val => val !== 'P' && val !== 'LE').join('-') !== selectedVariant?.sku ? {
          monogram_text: encodeURIComponent(monogramText),
          monogram_font: monogramFont,
          monogram_color: monogramColor,
          monogram_position: monogramLocation,
          ...(
            (monogramFont === 'block' || monogramFont === 'college' || monogramFont === 'heritage' || monogramFont === 'decorative') ?
              { monogram_shadow: monogramShadow, } :
              {}
          ),
          monogram: true
        } : {
          monogram: false
        }
      )
    }

    setLoading(true)
    superagent.post(`${backendUrl}/orders/${orderID}/addLineItem`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log(response.body)
          refresh()
          setOpen(false)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  

  const colorType = productObject ? ['BKP', 'BPK', 'WKD', 'TOT', 'CAR', 'DBK'].includes(productObject.name.slice(0, 3)) || ['EVETOT'].includes(productObject.name.slice(0, 6)) : false
  let colors = colorType ? newColorsBags : newColors
  let fontsFor = colorType ? fontsBags : fonts

  const isEmboss = productObject && productObject.name.includes('JETTE')
  if (isEmboss) {
    colors = embosColours
    fontsFor = embosFonts
  }

  const monogramTypes = {
    'text': 'Text',
    'pets': 'Pets',
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
    <DialogTrigger className='mt-8'>
        <Button>+ Add Line Item</Button>
      </DialogTrigger>

      {loading === true ? (
          <DialogContent className="max-h-[80vh] overflow-y-auto  min-w-[60vw]">
          <DialogHeader>
            <DialogTitle>Loading...</DialogTitle>
          </DialogHeader>
        </DialogContent>
      ): (
        <DialogContent className="z-[1600] min-w-[60vw]">
       
        <DialogHeader>

          <DialogTitle>
          Add Line Item
          </DialogTitle>
        </DialogHeader >
        
          <ProductVariantSelector
            products={options}
            setSelectedVariant={setSelectedVariant}
            selectedVariant={selectedVariant}
          />
          
          <Input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
         
          {
            selectedVariant && selectedVariant?.sku?.split('-').filter(val => val !== 'P' && val !== 'LE').join('-') !== selectedVariant?.sku && (
              <div>
                <h6 className="mb-2"> Monogram Data </h6>
                <Input placeholder="Monogram text" className="mb-2" type="text" value={monogramText} onChange={(e) => setMonogramText(e.target.value)} />
                <Select onValueChange={setMonogramFont} value={monogramFont}>
                  <SelectTrigger className="mb-2">
                    <SelectValue placeholder="-- select an option --" />
                  </SelectTrigger>
                  <SelectContent className="z-[1600]">
                    {Object.keys(fontsFor).map((val) => (
                      <SelectItem key={val} value={val}>
                        {fontsFor[val]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <Select onValueChange={setMonogramColor} value={monogramColor}>
                  <SelectTrigger className="mb-2">
                    <SelectValue placeholder="-- select an option --" />
                  </SelectTrigger>
                  <SelectContent className="z-[1600]">
                    {Object.keys(colors).map((val) => (
                      <SelectItem 
                        key={val} 
                        value={val}
                        style={{ color: colors[val] }} 
                      >
                        {val}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {
                  ((monogramFont === 'block' || monogramFont === 'college' || monogramFont === 'heritage' || monogramFont === 'decorative')) && (
                    <Select onValueChange={setMonogramShadow} value={monogramShadow}>
                      <SelectTrigger className="mb-2">
                        <SelectValue placeholder="-- select an option --" />
                      </SelectTrigger>
                      <SelectContent className="z-[1600]">
                        {Object.keys(newColors).map((val) => (
                          <SelectItem key={val} value={val} style={{ color: newColors[val] }}>
                            {val}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                  )
                }
                <Select onValueChange={setMonogramLocation} value={monogramLocation}>
                  <SelectTrigger className="mb-2">
                    <SelectValue placeholder="-- select an option --" />
                  </SelectTrigger>
                  <SelectContent className="z-[1600]">
                    {selectedVariant?.sku.includes('NBK') ? (
                      Object.keys(Notebooklocations).map((val) => (
                        <SelectItem key={val} value={Notebooklocations[val]}>
                          {Notebooklocations[val]}
                        </SelectItem>
                      ))
                    ) : selectedVariant?.sku.includes('TEC') || selectedVariant?.sku.includes('CME') ? (
                      Object.keys(TecLocations).map((val) => (
                        <SelectItem key={val} value={TecLocations[val]}>
                          {TecLocations[val]}
                        </SelectItem>
                      ))
                    ) : selectedVariant?.sku.includes('TAG') ? (
                      Object.keys(TagLocations).map((val) => (
                        <SelectItem key={val} value={TagLocations[val]}>
                          {TagLocations[val]}
                        </SelectItem>
                      ))
                    ) : (
                      Object.keys(locations).map((val) => (
                        <SelectItem key={val} value={locations[val]}>
                          {locations[val]}
                        </SelectItem>
                      ))
                    )}
                  </SelectContent>
                </Select>

              </div>
            )
          }
       
        <DialogFooter>
          <Button variant="success" onClick={updateLineItem}> Add </Button>
        </DialogFooter>
    
        </DialogContent>
      )}
      
    </Dialog>
  )
}
const FlaggedModal = ({ togglemodal, refresh, orderID, open }) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedFlagType, setSelectedFlagType] = useState()

  useEffect(() => {
    setLoading(true)
    superagent.get(`${backendUrl}/orders/getFlagTypes`)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          console.log("response.body", response.body)
          setOptions(response.body)
          setSelectedFlagType(response.body.data._id)
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }, [])

  const handleFlagLineItemChanged = (e) => {
    const option = e.target.value
    setSelectedFlagType(option)
  }

  

  const updateLineItem = () => {
    let newLineItem = {
      orderID: orderID,
      flagTypeId: selectedFlagType !== undefined ? selectedFlagType : options[0]?._id,
    }

    setLoading(true)
    superagent.post(`${backendUrl}/orders/${orderID}/setFlagged`)
      .send(newLineItem)
      .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
      .set('accept', 'json')
      .on('error', (response) => {
        setLoading(false)
      })
      .then(response => {
        const code = response.status
        if (code > 400) {
        } else {
          refresh()
          togglemodal()
          setLoading(false)
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
      });
  }

  

  return (
    <Dialog open={open} onOpenChange={togglemodal}>
      {loading === true ? (
         <DialogContent>
         <DialogHeader>
           <DialogTitle>Choose Flag Type</DialogTitle>
         </DialogHeader>
         <div className="flex justify-center">
           <div className="spinner-border text-primary m-1" role="status">
             <span className="sr-only">Loading...</span>
           </div>
         </div>
       </DialogContent>
      ): (
        <DialogContent>
       
          <DialogHeader>
            <DialogTitle>
            Choose Flag Type
            </DialogTitle>
          </DialogHeader >
          <div>
            {/* {JSON.stringify(options)} */}
            <Select onValueChange={(value) => handleFlagLineItemChanged({ target: { value } })} value={selectedFlagType}>
                <SelectTrigger>
                  <SelectValue placeholder="-- select an option --" />
                </SelectTrigger>
                <SelectContent>
                  {options.map((option) => (
                    <SelectItem
                      key={option?._id ?? options[0]?._id} 
                      value={option?._id ?? options[0]?._id}
                    >
                      {option?.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

            <br />
          </div>
          <DialogFooter>
            <Button variant="success" onClick={updateLineItem}> Add </Button>
          </DialogFooter>
        
        </DialogContent>
      )}
      
    </Dialog>
  )
}

const CorpEditModal = ({ togglemodal, refresh, orderID, priority, deadline, open }) => {
  const priorityOptions = [{ label: 'Low', value: 'low' }, { label: 'Medium', value: 'medium' }, { label: 'High', value: 'high' }]

  const [loading, setLoading] = useState(false)
  const [updatedDeadline, setUpdatedDeadline] = useState(moment(deadline).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'))
  const [updatedPriority, setUpdatedPriority] = useState(priority || 'low')

  const save = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await superagent.post(`${backendUrl}/orders/updateCorp`, {
        id: orderID,
        priority: updatedPriority,
        deadline: updatedDeadline

      }).set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .set('accept', 'json')

      setLoading(false)
      refresh()
      togglemodal()
      console.log(res)
    } catch (e) {
      console.log(e)
    }   
  }


  return (
    <Dialog open={open} onOpenChange={togglemodal}>
      {loading === true ? (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Corp Details</DialogTitle>
          </DialogHeader>
          <div className="flex justify-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </DialogContent>
      ): 
      <DialogContent>

        <DialogHeader toggle={togglemodal}>
          <DialogTitle> 
          Edit Corp Details
          </DialogTitle>
        </DialogHeader >
        <div>
          <Row className="flex-wrap">
            <Col className="w-full">
              <div className="select2-container">
                <label className="control-label">Deadline</label>
                <Input className="form-control" name="orderStartDate" value={updatedDeadline} onChange={e => setUpdatedDeadline(e.target.value)} type="date" defaultValue={moment().format('YYYY-MM-DD')} id="example-date-input" />
              </div>
            </Col>
            <Col className="w-full mt-6">
              <div className="select2-container">
                <label className="control-label">Priority</label>
                <SelectMultiple  value={priorityOptions.find(val => val.value === updatedPriority)} onChange={e => setUpdatedPriority(e.value)} placeholder="All" title="Priority" options={priorityOptions} />
              </div>
            </Col>
          </Row>
        </div>
        <DialogFooter>
          <Button variant="success" active={!loading} onClick={save}> Save </Button>
        </DialogFooter>
     
      </DialogContent>
      }
      
    </Dialog>
  )
}

const newColorsBags = {
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  Mint: '#D4EECC',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  'French-Navy': '#182D6D',
  Red: '#C63531',
  Plum: '#AC3856',
  Purple: '#64258E',
  Lavender: '#957EA3',
  'Forest-Green': '#1C4125',
  Green: '#6A9A52',
  Yellow: '#E8BE47',
  Orange: '#E96737',
  White: '#ffffff',
  Grey: '#97999D',
  Black: '#000',
}
export const newColors = {
  'Lavender': '#90759B',
  'Lilac': '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  'Grenaine': '#9E5360',
  'Blush-Pink': '#E77472',
  'Magenta': '#B12D52',
  'Maroon': '#731A29',
  'Red': '#C92327',
  'Burnt-Orange': '#DC5527',
  'Mango': '#EF7E2E',
  'Rust': '#9B4827',
  'Toffee': '#705037',
  'Taupe': '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  'Yellow': '#E8B629',
  'Lime': '#C6B937',
  'Green': '#4A813C',
  'Forest-Green': '#034123',
  'Mint': '#78AB93',
  'Ice-Blue': '#BEE3E9',
  'Denim': '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  'Blue': '#1466B2',
  'Grey': '#95979E',
  'Slate-Grey': '#63727E',
  'Black': '#000000',
  White: '#FFFFFF',
}
export const fonts = {
  'heritage': 'Heritage',
  'bold-new': 'Bold (new)',
  'bubble': 'Bubble',
  'retro-serif': 'Retro Serif',
  'graphic': 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',

  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif',
  'zh-ser': 'Chinese Serif',
  'zh-san': 'Chinese Sans Serif',
  'zh-block': 'Chinese Block',
  'zh-script': 'Chinese Script',
  'zh-casual': 'Chinese Casual',
}

export const fontsBags = {
  'all-sans': 'Carry All Sans Serif',
  'all-serif': 'Carry All Serif',
}

export const locations = {
  'Top': 'Top',
  'Side': 'Side',
  'Back': 'Back',
}

export const TecLocations = {
  'Left': 'Left',
  'Right': 'Right',
}

export const TagLocations = {
  'Horizontal': 'Horizontal',
  'Vertical': 'Vertical',
}

export const Notebooklocations = {
  'center-landscape': 'center-landscape',
  'center-portrait': 'center-portrait',
  'bottom-left': 'bottom-left'
}

export default OrderPage

// const LoadingContainer = props => <>Loading...</>;

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
//     LoadingContainer: LoadingContainer,
//     v: "3"
//   })(OrderPage)
// );
