import { useForm } from 'react-hook-form'; 
import { Input } from '@shadcn/components/ui/input'; 
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select'; 

import { DialogFooter } from '@shadcn/components/ui/dialog';
import { Textarea } from '@shadcn/components/ui/textarea'; 
import { Button } from '@shadcn/components/ui/button';
import { Label } from '@shadcn/components/ui/label';
import { Card, CardContent, CardHeader } from '@shadcn/components/ui/card';
import {
  Command,
  CommandInput,
  CommandList,
  CommandItem,
} from '@shadcn/components/ui/command';

import { debounce } from 'lodash'; 

import superagent from 'superagent';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { cn } from '@shadcn/lib/utils'
import { useEffect, useState } from 'react';
import { backendUrl } from '@helpers/consts';
import { uploadFileS3Bucket } from '@utilities/uploadFileS3Bucket';
import useQuery from '@helpers/JIFI/useQuery';

import WizardNav from '@pages/JIFI/orders/Wizard/WizardNav';
import { Checkbox } from '@shadcn/components/ui/checkbox';
import { Store, Truck } from 'lucide-react';


const department = [{ value: 'Operations' }, { value: 'Customer Experience' }];




export default function FeedBack({
  isWizard = false,
  isForm = false, onModalChange, initialOrder

}) {

  
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors
  } = useForm({
    defaultValues: {
      order: initialOrder,
      toBeShipped: false
    }
  });


  const showOldOrderCheckbox = !isForm && !isWizard;

  const [loading, setLoading]  = useState(false)
  const [data, state] = useQuery({
    url: '/categories', 
  });

  const [retailStores, stateRetailStore] = useQuery({
    url: `/inventory/getRetailStore`,
  });

  const [linkToOrder, setLinkToOrder] = useState(true);
  

  const onSubmit = async (data) => {
    setLoading(true)
    if (data['files']) {
      for (const file of data['files']) {
        const fileUploaded = await uploadFileS3Bucket(
          file,
          `attachment_${Date.now()}`
        );

        data['attachments'] = [...(data['attachments'] || []), fileUploaded];
      }
    }

    superagent
      .post(`${backendUrl}/order-tickets`)
      .send(data)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
       
        toast.error(response?.response?.body?.message ?? "Internal server errors");
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
       
          toast.error(response?.response?.body?.message ?? "Internal server errors");
        } else {
          if(onModalChange) {
            onModalChange()
          }
          else {
            toast.success('Ticket submitted successfully');
          }
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  };



  return (
    <div>
     {isWizard && <WizardNav steps={['Create Order Ticket']} activeTabProgress={1} />}
    <div className={`${isForm ? '' : 'page-content'}`}>
      <div className={`${isForm ? '' : 'container mx-auto px-4 lg:max-w-[85%]'}`}>
        <Card className={`${isForm ? '' : 'max-w-lg mx-auto my-10 p-6'}`}>
          {!isForm && <CardHeader className="text-2xl font-semibold">
            Retail Order Notes - Ops & CX
          </CardHeader>}
          <CardContent className={`${isForm ? 'pt-6' : ''}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <Label htmlFor="store">Store*</Label>
                <Select
                  onValueChange={(val) =>
                    setValue('store', val, { shouldValidate: true })
                  }
                  {...register('store', { required: true })}
                  id="store"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {retailStores?.map((option) => (
                      <SelectItem key={option._id} value={option._id}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.store && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Department Selection */}
              <div className="mb-4">
                <Label htmlFor="department">Department*</Label>
                <Select
                  onValueChange={(val) =>
                    setValue('department', val, { shouldValidate: true })
                  }
                  {...register('department', { required: true })}
                  id="department"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {department.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.department && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Category */}
              <div className="mb-4">
                <Label htmlFor="category">Category*</Label>
                <Select
                  onValueChange={(val) => {
                  
                    setValue('category', val, { shouldValidate: true })
                  }
                    
                  }
                  {...register('category', { required: true })}
                  id="category"
                  placeholder="Choose one..."
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Choose one..." />
                  </SelectTrigger>
                  <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                    {data?.map((option) => (
                      <SelectItem key={option._id} value={option._id}>
                        {option.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.category && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Customer Name */}
              <div className="mb-4">
                <Label htmlFor="customerName">Customer Name</Label>
                <Input
                  id="customerName"
                  className="text-black"
                  placeholder="Enter customer name"
                  {...register('customerName')}
                />
              </div>

              
              <div className="mb-4">
                <Label htmlFor="order">Order #*</Label>
                <CommandSearch 
                  orderName={initialOrder?.name} 
                  setValue={setValue}
                  linkToOrder={linkToOrder}
                 
                />
                {showOldOrderCheckbox && <div className="flex items-center space-x-2 my-2 mt-4">
                  <Checkbox
                    id="skipOrder"
                    checked={!linkToOrder}
                    onCheckedChange={(checked) => {
                      setLinkToOrder(!checked);
                      if (checked) {
                        setValue('order', null);
                        setValue('selectedItems', []);
                        clearErrors('order');
                      
                      }
                    }}
                  />
                  
                  <Label className="mb-0" htmlFor="skipOrder">Old Order (Pre-2020)</Label>
                </div>
}
                {linkToOrder && errors.order && (
                  <p className="text-red-500 text-sm mt-1">This field is required</p>
                )}
              </div>

              {/* Day Needed By */}
              <div className="mb-4">
                <Label htmlFor="dayNeededBy">Day Needed By</Label>
                <Input
                  type="date"
                  id="dayNeededBy"
                  {...register('dayNeededBy')}
                />
              </div>

              {/* Delivery Options */}
              <div className="mb-4 space-y-4">
                <Label>Delivery Options</Label>
                <div className="grid grid-cols-2 gap-4">
                  <div
                    className={`p-4 rounded-lg border-2 cursor-pointer transition-all ${
                      !watch('toBeShipped') 
                        ? 'border-primary bg-primary/5'
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                    onClick={() => {
                      setValue('toBeShipped', false);
                      setValue('pickupLocation', null);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Store className="h-5 w-5 text-primary" />
                      <div>
                        <p className="font-medium">Store Pickup</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`p-4 rounded-lg border-2 cursor-pointer transition-all ${
                      watch('toBeShipped')
                        ? 'border-primary bg-primary/5' 
                        : 'border-gray-200 hover:border-gray-300'
                    }`}
                    onClick={() => {
                      setValue('toBeShipped', true);
                      setValue('pickupLocation', null);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Truck className="h-5 w-5 text-primary" />
                      <div>
                        <p className="font-medium">To be Shipped</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Pickup Location */}
              {!watch('toBeShipped') && (
                <div className="mb-4">
                  <Label htmlFor="pickupLocation">Pickup Location</Label>
                  <Select
                    onValueChange={(val) =>
                      setValue('pickupLocation', val, { shouldValidate: true })
                    }
                    {...register('pickupLocation')}
                    id="pickupLocation"
                    placeholder="Choose one..."
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Choose one..." />
                    </SelectTrigger>
                    <SelectContent className={`${isForm ? 'z-[1600]' : ''}`}>
                      {retailStores?.map((option) => (
                        <SelectItem key={option._id} value={option._id}>
                          {option.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}

              {/* To Be Actioned */}
              <div className="mb-4">
                <Label htmlFor="actionDescription">To be actioned*</Label>
                <Textarea
                  className="border-black"
                  id="actionDescription"
                  placeholder="Please include only essential information"
                  {...register('actionDescription', { required: true })}
                />
                {errors.actionDescription && (
                  <p className="text-red-500">This field is required</p>
                )}
              </div>

              {/* Attachments */}
              <div className="mb-4">
                <Label htmlFor="files">Attachments</Label>
                <Input accept="image/*" type="file" multiple id="files" {...register('files')} />
              </div>

              {/* Submit Button */}
              <DialogFooter>
              <Button type="submit" className={`${!isForm ? 'w-full' : ''}`} disabled={loading}>
              {loading && <LoadingSpinner className="mr-2"/> } 
              Submit
              </Button>
              </DialogFooter>
            </form>
          </CardContent>
          <ToastContainer />
        </Card>
      </div>
    </div>
    </div>
  );
}

export function CommandSearch({ setValue, orderName, linkToOrder }) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(orderName ?? '');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  
  const handleValueChange = (value) => {
    setInputValue(value);
    setOpen(!!value);
 
    if (linkToOrder) {
      debouncedFetchOrders(value);
    }
    setValue('orderNumber', value);
    
    
  };

  const fetchOrders = async (searchTerm) => {
    setLoading(true);
    try {
      const response = await superagent
        .get(`${backendUrl}/orders`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .query({
          page: 1,
          perPage: 10,
          searchTerm: searchTerm,
        });
      setOrders(response.body);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchOrders = debounce((value) => {
    if (value.length > 3) {
      fetchOrders(value);
    }
  }, 500);

  const filteredCommands = Array.isArray(orders?.data)
    ? orders?.data?.filter((order) => {
      return order.name.toLowerCase().includes(inputValue?.toLowerCase())
    })
    : [];

  return (
    <Command className="rounded-lg border border-black">
      <CommandInput
        value={inputValue}
        placeholder={linkToOrder ? "Search for an order..." : "Enter order number"}
        onValueChange={handleValueChange}
      />
        <CommandList>
          {open &&
            filteredCommands.length > 0 &&
            filteredCommands.map((command) => (
              <CommandItem
                onSelect={() => { 
                  setInputValue(command.name);
                  setValue('order', command);
                  setOpen(false);
                  setValue('orderNumber', command.name);
                }}
                key={command._id}
              >
                {command.name}
              </CommandItem>
            ))}
        </CommandList>
    
    </Command>
  );
}

const LoadingSpinner = ({
  size = 24,
  className,
  ...props
}) => {
 return   <svg
 xmlns="http://www.w3.org/2000/svg"
 width={size}
height={size}
{...props}
 viewBox="0 0 24 24"
 fill="none"
 stroke="currentColor"
 strokeWidth="2"
 strokeLinecap="round"
 strokeLinejoin="round"
 className={cn("animate-spin", className)}
>
 <path d="M21 12a9 9 0 1 1-6.219-8.56" />
</svg>
}
