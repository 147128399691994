import React, { useState, useEffect, useCallback } from 'react';

import { FixedSizeList as List } from 'react-window';

import {
  Card,
  CardContent as CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
} from '@shadcn/components/ui/card';

import  MultiSelect  from '@shadcn/components/ui/react-select';
import TopupSchedule from './components/TopupSchedule';

import useShopifyLocationsStore from '@zustand/useShopifyLocationsStore';

import {
  SelectContent,
  SelectValue,
  SelectItem,
  Select,
  SelectTrigger,
} from '@shadcn/components/ui/select';
import useQuery from '../../../helpers/JIFI/useQuery';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Country, State } from 'country-state-city';
import AsyncSelect from 'react-select/async';

import superagent from 'superagent';
import { backendUrl } from '../../../helpers/consts.js';
import { useHistory } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';
import { Row } from '@shadcn/components/ui/row';
import { Col } from '@shadcn/components/ui/col';
import { Button } from '@shadcn/components/ui/button';
import { Input as InputShadcn } from '@shadcn/components/ui/input';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogHeader,
} from '@shadcn/components/ui/dialog';


import { Collapsible, CollapsibleContent } from '@shadcn/components/ui/collapsible';
const emptyAddress = {
  address1: '',
  address2: '',
  phone: '',
  city: '',
  zip: '',
  country: 'Australia',
  company: '',
  province: 'Victoria',
};

const Accordian = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div id="accordion">
        <Card className="mb-1  bg-[#f6f6f6]">
          <CardHeader
            onClick={() => setOpen((prev) => !prev)}
            className="p-3"
            id="headingOne"
          >
            <h6 className="m-0 text-[14px] font-medium">
              <span style={{ cursor: 'pointer' }} className={''}>
                {title}
              </span>
            </h6>
          </CardHeader>
          <Collapsible open={open} onValueChange={() => setOpen((prev) => !prev)}>
          <CollapsibleContent>
            <Card className='rounded-none'>
              <CardBody>{children}</CardBody>
            </Card>
            </CollapsibleContent>
          </Collapsible>
        </Card>
      </div>
      <br />
      <br />
    </>
  );
};


const RowItem = React.memo(
  ({
    index,
    data,
    style,
 
    
  }) => {
    const {products, editProductRow, editVariantRow, editQuantityRow,removeRow} = data
    const line = data.stock[index];
      const product = products.find((val) => val._id === line.product);
    const variant =
      product && product.variants.find((val) => val._id === line.variant);

    const [fulfillmentModalOpen, setFulfillmentModalOpen] = useState(false);
    return (
      <Row style={{ padding: '12px 0', gap: '10px', ...style }} key={index}>
        <Col className="sm:w-4/12">
        <div   className='border border-black rounded px-3 py-2'>
        <select
            disabled={true}
            value={line.product}
            onChange={(e) => editProductRow(index, e.target.value)}
            style={{ width: '100%', height: '100%' }}
          >
            <option value="" disabled>Select Product</option>
            {products.map((val) => (
              <option key={val._id} value={val._id}>
                {val.name}
              </option>
            ))}
          </select>
          </div>
        </Col>
        <Col className="sm:w-4/12">
        <div   className='border border-black rounded px-3 py-2'>
        <select
            disabled={true}
            value={line.variant}
            onChange={(e) => editVariantRow(index, e.target.value)}
            style={{ width: '100%', height: '100%' }}
          >
            <option value="" disabled>Select Variant</option>
            {product?.variants.map((val) => (
              <option
                key={val._id}
                value={val._id}
                disabled={val.selected !== -1 && val.selected !== index}
              >
                {val.name}
              </option>
            ))}
          </select>
          </div>
        </Col>
        <Col className="sm:w-3/12">
          <input
          disabled={true}
          key={index}
            style={{ width: '90%', height: '100%' }}
            type="number"
            className='border border-black rounded px-3 py-2'
            value={line.quantity}
            onChange={(e) =>

              { 
              
                editQuantityRow(index, e.target.value)}}
          />
        </Col>
      
      </Row>
    );
  }
);

export const StockInput = ({ stock, setStock, productData }) => {
  
  const listRef = React.useRef(null);
  const handleAddRow = useCallback(() => {
    const prefillProduct = stock[stock.length - 1]?.product;
    let prefillVariant;
    if (prefillProduct) {
      prefillVariant = productData.find((val) => prefillProduct === val._id)[
        'variants'
      ][0]._id;

    
    }

    const item = {
      product: prefillProduct || productData?.[0]._id,
      variant: prefillVariant || productData?.[0]['variants']?.[0]._id,
      quantity: 0,
    };
    
    setStock((prev) => [...prev ?? {}, item]);
    scrollToBottom()
  }, [stock, productData, setStock]);


  const scrollToBottom = () => {
    if (listRef.current) {
      setTimeout(() => listRef.current.scrollToItem(stock.length),500);
    }
  };


  const editProductRow = useCallback(
    (id, productId) => {
      setStock((prev) => {
        let newList = [...prev];
        newList[id]['product'] = productId;
        newList[id]['quantity'] = 0;
        const variant = productData.find(
          (val) => `${val._id}` === `${productId}`
        );
        newList[id]['variant'] = variant.variants[0]?._id;
        return newList;
      });
    },
    [productData, setStock]
  );

  const editVariantRow = useCallback(
    (id, val) => {
      setStock((prev) => {
        let newList = [...prev];
        newList[id]['variant'] = val;
        newList[id]['quantity'] = 0;
        return newList;
      });
    },
    [setStock]
  );

  const editQuantityRow = 
    (id, val) => {
      console.log("test id", id, val)
      setStock((prev) => {
        let newList = [...prev];
        let parsedInput = parseInt(val);
        if (parsedInput < 0) {
          parsedInput = 0;
        }
        newList[id]['quantity'] = parsedInput;
        return newList;
      });
    }
  

  const removeRow = useCallback(
    (id) => {
      setStock((prev) => {
        let newList = prev?.filter((item, key) => key !== id);
        return newList;
      });
    },
    [setStock]
  );

  
  const products = productData && Object.keys(stock).length > 0
    ? productData.map((product) => {
        const variants = product.variants.map((variant) => {
          return {
            _id: variant._id,
            name: variant.name,
            selected: stock?.findIndex((val) => val.variant === variant._id),
          };
        });

        return {
          name: product.name,
          _id: product._id,
          variants,
        };
      })
    : [];

  return (
    <>
      <Row className='mt-4'>
        <Col xs="4">Product</Col>
        <Col xs="4">Variant</Col>
        <Col xs="4">Quantity</Col>
      </Row>
      <br />
      <List
        height={
          stock.length === 0 ? 60 : stock.length < 10 ? 60 * stock.length : 600
        }
        ref={listRef}
        itemData={{stock, products, editProductRow, editVariantRow, editQuantityRow, removeRow}}
        itemCount={stock.length}
        itemSize={60}
        itemKey={(index) => index}
        width="100%"
      >
        {RowItem}
      </List>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 12,
        }}
      >
        <Button onClick={handleAddRow} variant="primary">
          Add
        </Button>
      </div> */}
    </>
  );
};

const LocationListPage = ({ match: { params } }) => {
  const history = useHistory();
  const { locationID } = params;

  const [locationName, setLocationName] = useState('');
  const [code, setCode] = useState('');
  const [locationAustralian, setLocationAustralian] = useState(false);

  const [factoryOptions, setFactoryOptions] = useState([]);
  const [locationFactory, setLocationFactory] = useState();

  const [region, setRegion] = useState('');
  const [locationLogisticsProvider, setLocationLogisticsProvider] =
    useState('');
  const [shopifyLinks, setShopifyLinks] = useState([]);
  const [isRetailStore, setIsRetailStore] = useState(false);
  const [addressEditModal, setAddressEditModal] = useState();
  const toggleAddressEditModal = () => {
    setAddressEditModal((prev) => !prev);
  };
  const [address, setAddress] = useState(emptyAddress);
  const [topupSchedule, setTopupSchedule] = useState(null);
  
  const [refresh, setRefresh] = useState(false);

  const [fulfillmentModalOpen, setFulfillmentModalOpen] = useState(false);

  const [regionOptions, regionOptionsState] = useQuery({
    url: `/inventory/getRegions`,
  });
  const refreshData = () => setRefresh((prev) => !prev);

  const [stockPull, setStockPull] = useState([]);
  const [data, productState] = useQuery({
    url: `/product`,
    refresh,
  });
  const productData = data && data.data;

  const [location, state] = useQuery({
    refresh,
    url: `/inventory/location/${locationID}`,
  });

  const [selectedTopupLocation, setSelectedTopupLocation]= useState({})
  const [selectedReplenLocations, setSelectedReplenLocations] = useState([]);
  const [replenLocations, replenLocationStates] = useQuery({
    refresh,
    url: `/inventory/getReplenishLocations`,
  });

  useEffect(() => {
    if (
      replenLocations !== undefined &&
      replenLocationStates !== 'loading' &&
      selectedReplenLocations?.filter((val) => val.label == undefined).length > 0
    ) {
      setSelectedReplenLocations((prev) => {
        return prev.map((replenLocation) => {
          return {
            value: replenLocation.value,
            label: replenLocations?.find(
              (val) => val._id === replenLocation.value
            ).name,
          };
        });
      });
    }
  }, [replenLocations, selectedReplenLocations]);

  console.log('topupSchedule', topupSchedule);
  useEffect(() => {
    if (location != undefined) {
      setLocationName(location.display_name);
      setCode(location.code);
      setLocationAustralian(location.australian);
      setLocationLogisticsProvider(location.logistics_provider);
      setShopifyLinks(location.shopify_links);
      setAddress(location.address || emptyAddress);
      setSelectedReplenLocations(
        (location.stockDropLocations || []).map((val) => ({ value: val })) ?? []
      );
      setIsRetailStore(location.isRetailStore);
      setRegion(location?.region);
      if (location.topupSchedule) {
        setTopupSchedule(location.topupSchedule);
      } else {
        setTopupSchedule(null);
      }

      if (
        factoryOptions.length > 0 &&
        location.factory !== '' &&
        location.factory !== undefined
      ) {
        let fac = factoryOptions.find(
          (val) => `${val._id}` === `${location.factory}`
        );
        if (fac !== undefined) {
          setLocationFactory({
            value: location.factory,
            label: fac.name,
          });
        }
      } else {
        setLocationFactory({ value: location.factory });
      }

      let items = [];
      for (let variantCount of location.topupLevel) {
        const item = {
          product: variantCount.variant.product,
          variant: variantCount.variant._id,
          quantity: variantCount.count.on_hand,
        };
        items.push(item);
      }
      setStockPull((prev) => items);
    }
  }, [location]);

  const updateLocation = (e) => {
    e.preventDefault();

    if (location == undefined) {
      superagent
        .post(`${backendUrl}/inventory/createLocation`)
        .send({
          name: locationName,
          code: code,
          australian: locationAustralian,
          
          logistics_provider: locationLogisticsProvider,
          isRetailStore,
          region,
          ...(locationFactory?.value != null
            ? { factory: locationFactory.value }
            : {}),
          ...(topupSchedule ? { topupSchedule: topupSchedule } : {})
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            history.push(`/location/edit/${response.body._id}`);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      
      superagent
        .put(`${backendUrl}/inventory/location/${locationID}`)
        .send({
          name: locationName,
          code: code,
          australian: locationAustralian,
        
          logistics_provider: locationLogisticsProvider,
          shopify_links: shopifyLinks.map(({ locationVariantIds, ...rest }) => ({
            ...rest,
          })),
          address: address,
          isRetailStore,
          region,
          ...(topupSchedule ? { topupSchedule: topupSchedule } : {}), 
          stockDropLocations: selectedReplenLocations?.map((val) => val.value) ?? [],
          ...(locationFactory?.value != null
            ? { factory: locationFactory.value }
            : {}),
         
        })
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json')
        .on('error', (response) => {
          const code = response.status;
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        })
        .then((response) => {
          const code = response.status;
          if (code > 400) {
            history.replace(history.location.pathname, {
              errorStatusCode: code,
            });
          } else {
            history.go(0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    superagent
      .post(`${backendUrl}/product/productFactories`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          setFactoryOptions(response.body);
          setLocationFactory((prev) => {
            console.log(prev);
            if (prev && prev !== '' && prev.value !== undefined) {
              return {
                value: prev.value,
                label: response.body.find((val) => `${val._id}` === prev.value)
                  ?.name,
              };
            }
            return prev;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const logisticOptions = [
    { value: 'EWE', label: 'EWE Melbourne' },
    { value: 'EWESYD', label: 'EWE Sydney' },
    { value: 'EWEBRS', label: 'EWE Brisbane' },
    { value: 'EWEPTH', label: 'EWE Perth' },
    { value: 'UNIS', label: 'Unis' },
    { value: 'Airbox', label: 'Airbox' },

    { value: 'LaunchUtah', label: 'Launch Utah' },
    { value: 'LaunchKentucky', label: 'Launch Kentucky' },
  ];

  let [shopifySyncModalOpen, setShopifySyncModalOpen] = useState(false);

  
  return (
    <>
      <div className="page-content">
        <div className="w-full px-3 mx-auto xl:max-w-[85%]">
          <Breadcrumbs
            title="Ecommerce"
            breadcrumbItem={
              locationID != undefined ? 'Edit Location' : 'New Location'
            }
          />
          
          <Row>
            <Col className="w-full">
              <Card className="pt-6 mb-6">
                <CardBody>
                  <Row className='flex flex-row gap-6 items-center'>
                  <CardTitle className='text-[15px]'>Location Information</CardTitle>
                  
                  </Row>
                  <br />
                  <div>
                    <Row className="flex-wrap">
                      <Col className="sm:w-6/12 pr-3">
                        <div className="mb-4">
                          <label htmlFor="locationname">Location Name</label>
                          <InputShadcn
                            id="locationname"
                            name="locationname"
                            type="text"
                            className="form-control"
                            value={locationName}
                            onChange={(e) => setLocationName(e.target.value)}
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="locationname">Code</label>
                          <InputShadcn
                            id="locationname"
                            name="locationname"
                            type="text"
                            className="form-control"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        <div className="mb-4">
                          <label htmlFor="locationname">
                            Logistics Provider
                          </label>
                          <Select
                            value={locationLogisticsProvider}
                            onValueChange={(value) =>
                              setLocationLogisticsProvider(value)
                            }
                          >
                            <SelectTrigger
                              style={{ width: '100%', height: '100%' }}
                            >
                              <SelectValue placeholder="None" />
                            </SelectTrigger>

                            <SelectContent className="z-[1500]">
                              {logisticOptions.map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="regionSelect">Select Region</label>
                          <Select
                            value={region}
                            onValueChange={(value) => setRegion(value)}
                            name="select"
                            id="regionSelect"
                          >
                            <SelectTrigger
                              style={{ width: '100%', height: '100%' }}
                            >
                              <SelectValue placeholder="Please select region" />
                            </SelectTrigger>

                            <SelectContent className="z-[1500]">
                              {regionOptions?.map((regionOption) => (
                                <SelectItem
                                  key={regionOption.id}
                                  value={regionOption.id}
                                  selected={regionOption.id === region}
                                >
                                  {regionOption.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </div>

                        <div className="mb-4" check>
                          <label check>
                            <input
                              type="checkbox"
                              checked={isRetailStore}
                              onChange={() => setIsRetailStore(!isRetailStore)}
                            />{' '}
                            Retail Store
                          </label>
                        </div>
                        <div
                          style={{
                            margin: '16px 0',
                            background: '#eee',
                            borderRadius: 17,
                            padding: 16,
                          }}
                        >
                          <h6 className='font-medium' style={{ marginBottom: 16 }}>
                            {' '}
                            Shipping Address -{' '}
                            <a href="#" onClick={toggleAddressEditModal} className='text-[#1f3dd0]'>
                              edit
                            </a>
                          </h6>
                          <div> {address?.name} </div>
                          <div> {address?.address1} </div>
                          <div> {address?.address2 || ''} </div>
                          <div>
                            {' '}
                            {`${address?.city || ''} ${
                              address?.province || ''
                            } ${address?.zip || ''}`}{' '}
                          </div>
                          <div> {address?.country || ''} </div>
                          <div> {address?.phone || ''} </div>
                        </div>
                        {locationLogisticsProvider !== '' && (
                          <div className="mb-4">
                            <label htmlFor="locationname">
                              Replenishment Locations
                            </label>
                            <MultiSelect
                              isMulti
                              options={replenLocations?.map((val) => ({
                                value: val._id,
                                label: val.name,
                              }))}
                              value={selectedReplenLocations}
                              onChange={setSelectedReplenLocations}
                            />
                          </div>
                        )}
                      </Col>
                      {location !== undefined && (
                        <Col className="sm:w-6/12 pl-3">
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'stretch',
                              flexDirection: 'column',
                            }}
                          >
                            {shopifyLinks?.map((val) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    border: '1px solid #ccc',
                                    borderRadius: 26,
                                    marginBottom: 16,
                                    padding: 16,
                                  }}
                                >
                                  <div>
                                    <b>{val.name}</b>
                                  </div>
                                  <div>
                                    <i className="bx bxl-shopify" /> {val.store}
                                    .myshopify.com
                                  </div>
                                  <div>Sync type: {val.sync_type}</div>
                                </div>
                              );
                            })}

                            
                             
                            <div
                              style={{
                                alignItems: 'center',
                                borderStyle: 'dashed',
                                borderWidth: '2px',
                                borderRadius: '20px',
                                borderColor: 'rgb(204, 204, 204)',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'center',
                                padding: '20px',
                                cursor: 'pointer',
                              }}
                              onClick={() => setShopifySyncModalOpen(true)}
                            >
                             
                              <i className="bx bxl-shopify" /> Link to Shopify
                            </div>
                          </div>
                        </Col>
                      )}
                      <Col className="w-full">
                    
                        <Accordian title="Top up amount">
                          <StockInput
                            stock={stockPull}
                            setStock={setStockPull}
                            productData={productData}
                          />
                        </Accordian>
                      </Col>
                    </Row>
                    <Button
                      onClick={updateLocation}
                      type="submit"
                      variant="success"
                      className="mr-1 waves-effect waves-light"
                    >
                      Save Changes
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ShopifySyncModal
        modal={shopifySyncModalOpen}
        togglemodal={() => setShopifySyncModalOpen((prev) => !prev)}
        location={location}
        history={history}
        shopifyLinks={shopifyLinks}
        setShopifyLinks={setShopifyLinks}
        address={address}
      />
      <EditAddressModal
        modal={addressEditModal}
        togglemodal={toggleAddressEditModal}
        address={address}
        setAddress={setAddress}
      />
    
      <ToastContainer />
    </>
  );
};

const ShopifySyncModal = ({
  modal,
  togglemodal,
  history,
  location,
  shopifyLinks,
  setShopifyLinks,
  address,
}) => {
  
  const [stage, setStage] = useState(0);
  const { locations, setLocations } = useShopifyLocationsStore()
  const [loading, setLoading] = useState(false);
  const [shopifyLocations, setShopifyLocation] = useState({});
  const [selectedLocation, setSelectedLocation] = useState();

  const [syncSettings, setSyncSetttings] = useState({
    type: 'NONE',
  });

  const stateCode = State.getStatesOfCountry(
    Country.getAllCountries().find((val) => val.name === address.country)
      ?.isoCode
  )?.find((val) => val.name === address.province)?.isoCode;

  const countryCode = Country.getAllCountries().find(
    (val) => val.name === address.country
  )?.isoCode;

  const currentLinkObject = {
    location_id: selectedLocation?.id,
    name: location?.name,
    store: selectedLocation?.locale,
    sync_type: syncSettings.type,
  };

  console.log(currentLinkObject);

  useEffect(() => {
    fetchShopifyLocations();
  }, [location]);

  const handleSave = () => {
    setShopifyLinks((prev) => [...prev, currentLinkObject]);
    togglemodal();
  };

  const fetchShopifyLocations = () => {
    superagent
      .post(`${backendUrl}/inventory/fetchShopifyLocations`)
      .send()
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .on('error', (response) => {
        const code = response.status;
        history.replace(history.location.pathname, {
          errorStatusCode: code,
        });
      })
      .then((response) => {
        const code = response.status;
        if (code > 400) {
          history.replace(history.location.pathname, {
            errorStatusCode: code,
          });
        } else {
          setLocations(response.body)
          setShopifyLocation(response.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateLocation = async (locale) => {
    setLoading(true);

    if (
      !location?.name ||
      !address.address1 ||
      !address.city ||
      !address.province ||
      !address.country ||
      !address.zip
    ) {
      toast.info('Please fill in all fields in address.');
      setLoading(false);
      return;
    }

    const apiEndpoint = `${backendUrl}/inventory/addShopifyLocations`;
    const apiPayload = {
      store: locale,
      name: location?.name,
      address1: address.address1,
      address2: address.address2,
      phone: address.phone,
      city: address.city,
      provinceCode: stateCode,
      countryCode: countryCode,
      zip: address.zip,
    };

    try {
      const response = await superagent
        .post(apiEndpoint)
        .send(apiPayload)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json');
      const error =
        response?.body?.data?.locationAdd?.userErrors[0]?.message ||
        response?.body?.errors?.[0]?.message;
      if (error) {
        toast.error(error);
        return;
      }
      toast.success('Location created successfully');
      fetchShopifyLocations();
    } catch (error) {
      console.error('Error adding location:', error);
    } finally {
      setLoading(false);
    }
  };

  const stageComponents = [
    <DialogContent hideClose className="z-[1500] max-h-[80vh] overflow-y-auto pt-0 min-w-[60vw]">
      <CardBody className="p-0 px-0">
        <h4 className="card-title mb-4 pt-6">Select Shopify Location</h4>

        <br />
        {Object.keys(shopifyLocations).map((locale) => {
          return (
            <div>
              <div className="flex flex-row items-center justify-start">
                <h3 className="me-2">{locale}.myshopify.com:</h3>
                <Button
                variant="success"
                  onClick={() => handleCreateLocation(locale)}
                  className="ml-2 mb-2"
                >
                  Create
                </Button>
              </div>

              <br />
              <Row className="flex-wrap gap-4">
                {shopifyLocations[locale].locations
                  ?.filter(
                    (val) =>
                      !shopifyLinks
                        .map((val) => val.location_id)
                        .includes(val.id)
                  )
                  .map((location, index) => (
                    <Col key={index} className="w-[23%]">
                      <Card className="flex flex-col h-full">
                        <CardHeader>
                          <h4>{location.name}</h4>
                        </CardHeader>
                        <CardBody className="flex-grow min-h-[150px]">
                          <div>{location.address1}</div>
                          <div>{location.city}</div>
                          <div>{location.province}</div>
                          <div>{location.country}</div>
                        </CardBody>
                        <CardFooter>
                          <Button
                            onClick={() => {
                              setSelectedLocation({
                                locale: locale,
                                id: location.id,
                              });
                            }}
                            variant={
                              selectedLocation?.id === location.id
                                ? 'success'
                                : 'primary'
                            }
                            className="mr-1 waves-effect waves-light"
                          >
                            {selectedLocation?.id === location.id
                              ? 'Selected'
                              : 'Select'}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                  ))}
              </Row>
              <br />
            </div>
          );
        })}
      </CardBody>
      <DialogFooter>
        {loading ? (
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <Button
            onClick={() =>
              selectedLocation !== undefined ? setStage(1) : () => {}
            }

            variant={selectedLocation !== undefined ? 'success' : 'secondary'}
            className="btn-lg btn-rounded"
          >
            Next
          </Button>
        )}
      </DialogFooter>
    </DialogContent>,
    <DialogContent>
      <CardBody className='p-0'>
        <h4 className="card-title mb-4 p-0">Select Shopify Location</h4>
        <br />
        <div className="mb-4">
          <label htmlFor="locationname">Stock Sync Type</label>
          <Select
            value={syncSettings.type}
            onValueChange={(value) =>
              setSyncSetttings({ ...syncSettings, type: value })
            }
          >
            <SelectTrigger style={{ width: '100%', height: '100%' }}>
              <SelectValue />
            </SelectTrigger>

            <SelectContent className="z-[1500]">
              {['NONE', 'PUSH', 'PULL'].map((val) => (
                <SelectItem key={val} value={val}>
                  {val}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </CardBody>
      <DialogFooter>
        {loading ? (
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <>
            <Button
              onClick={() => setStage(0)}
              color={'warning'}
              className="btn-lg btn-rounded"
            >
              Back
            </Button>
            <Button
              onClick={handleSave}
              variant={selectedLocation !== undefined ? 'success' : 'secondary'}
              className="btn-lg btn-rounded"
            >
              Save
            </Button>
          </>
        )}
      </DialogFooter>
    </DialogContent>,
  ];

  return (
    <Dialog
      open={modal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabindex="-1"
      size="lg"
      onOpenChange={togglemodal}
    >
      {stageComponents[stage]}
    </Dialog>
  );
};

const EditAddressModal = ({ modal, togglemodal, address, setAddress }) => {
  const apiKey = 'AIzaSyACNNxCG6Oc9WaFfFslSu3jv5qJApU9PpI';
  const { placePredictions, getPlacePredictions } = useGoogle({
    apiKey: apiKey,
    options: {
      fields: ['address_component', 'types', 'geocode'],
      types: ['geocode'],
    },
  });

  useEffect(() => {
    const loaded = new CustomEvent('loadedPlaced', {
      detail: placePredictions.map((placeLine) => ({
        label: placeLine.description,
        value: placeLine,
      })),
    });
    document.dispatchEvent(loaded);
  }, [placePredictions]);

  const [selected, setSelected] = useState(null);

  const getPlaceData = async (placeID) => {
    return await new Promise((res, rej) => {
      const map = new window.google.maps.Map(
        document.getElementById('test-map'),
        { zoom: 15 }
      );
      var request = {
        placeId: placeID,
        fields: ['address_components'],
      };

      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(request, callback);

      function callback(place, status) {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          res(place);
        } else {
          rej(place);
        }
      }
    });
  };

  const loadOptions = (inputValue, callback) => {
    getPlacePredictions({ input: inputValue });
    document.addEventListener('loadedPlaced', function (e) {
      callback(e.detail);
    });
  };
  const [loading, setLoading] = useState(false);

  const [fastFill, setFastFill] = useState('');
  useEffect(() => {
    if (fastFill !== '') {
      let split = fastFill.split('	');
      setName(split[0]);
      setPhone(split[2]);
      setAddress1(split[3]);
      setAddress2(split[4]);
      setCity(split[5]);
      setState(split[6]);
      setZip(split[7]);
      setCountry(split[8]);
      setFastFill('');
    }
  }, [fastFill]);

  // 0: "Lisa Polon"
  // 1: "lisapolon88@yahoo.com"
  // 2: "3108942862"
  // 3: "4809 Laurelgrove Ave"
  // 4: "103"
  // 5: "Valley Village"
  // 6: "CA"
  // 7: "91607"

  const [name, setName] = useState(address.name);
  const [company, setCompany] = useState(address.company);
  const [address1, setAddress1] = useState(address.address1);
  const [address2, setAddress2] = useState(address.address2);
  const [city, setCity] = useState(address.city);
  const [zip, setZip] = useState(address.zip);
  const [phone, setPhone] = useState(address.phone);

  const [selectedState, setState] = useState(
    State.getStatesOfCountry(
      Country.getAllCountries().find((val) => val.name === address.country)
        ?.isoCode
    )?.find((val) => val.name === address.province)?.isoCode
  );
  const [selectedCountry, setCountry] = useState(
    Country.getAllCountries().find((val) => val.name === address.country)
      ?.isoCode
  );

  useEffect(() => {
    setName(address.name);
    setCompany(address.company);
    setAddress1(address.address1);
    setAddress2(address.address2);
    setCity(address.city);
    setZip(address.zip);
    setPhone(address.phone);
    setState(
      State.getStatesOfCountry(
        Country.getAllCountries().find((val) => val.name === address.country)
          ?.isoCode
      )?.find((val) => val.name === address.province)?.isoCode
    );
    setCountry(
      Country.getAllCountries().find((val) => val.name === address.country)
        ?.isoCode
    );
  }, [address]);

  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    let newState;
    try {
      newState = State.getStatesOfCountry(e.target.value)[0].isoCode;
    } catch {}
    setState(newState);
  };

  useEffect(() => {
    if (selected) {
      getPlaceData(selected.value.place_id).then((res) => {
        let items = res.address_components;
        let streetNumberGoogleAPI,
          streetAddressGoogleAPI,
          countryGoogleAPI,
          stateGoogleAPI,
          unitNoGoogleAPI,
          suburnGoogleAPI,
          postcodeGoogleAPI;

        items.map((item) => {
          if (item.types.includes('subpremise')) {
            unitNoGoogleAPI = item.long_name;
          }
          if (item.types.includes('street_number')) {
            streetNumberGoogleAPI = item.long_name;
          }
          if (item.types.includes('route')) {
            streetAddressGoogleAPI = item.long_name;
          }
          if (item.types.includes('postal_code')) {
            postcodeGoogleAPI = item.long_name;
          }
          if (item.types.includes('locality')) {
            suburnGoogleAPI = item.long_name;
          }
          if (item.types.includes('administrative_area_level_1')) {
            stateGoogleAPI = item.long_name;
          }
          if (item.types.includes('country')) {
            countryGoogleAPI = item.long_name;
          }
        });

        setCountry(
          Country.getAllCountries().find((val) => val.name === countryGoogleAPI)
            ?.isoCode
        );
        setState(
          State.getStatesOfCountry(
            Country.getAllCountries().find(
              (val) => val.name === countryGoogleAPI
            )?.isoCode
          )?.find((val) => val.name === stateGoogleAPI)?.isoCode
        );
        setCity(suburnGoogleAPI === undefined ? '' : suburnGoogleAPI);
        setZip(postcodeGoogleAPI === undefined ? '' : postcodeGoogleAPI);
        setAddress2('');
        if (streetAddressGoogleAPI) {
          if (streetNumberGoogleAPI) {
            if (unitNoGoogleAPI) {
              setAddress1(
                unitNoGoogleAPI +
                  '/' +
                  streetNumberGoogleAPI +
                  ' ' +
                  streetAddressGoogleAPI
              );
            } else {
              setAddress1(streetNumberGoogleAPI + ' ' + streetAddressGoogleAPI);
            }
          } else {
            setAddress1(streetAddressGoogleAPI);
          }
        } else {
          setAddress1('');
        }
        setSelected('');
      });
    }
  }, [selected]);

  const handleSave = () => {
    const address = {
      name: name,
      address1: address1,
      address2: address2,
      company: company,
      phone: phone,
      city: city,
      zip: zip,
      province:
        State.getStateByCodeAndCountry(selectedState, selectedCountry)?.name ??
        'Victoria',
      country: Country.getCountryByCode(selectedCountry)?.name ?? 'Australia',
    };
    setAddress(address);
    togglemodal();
  };

  return (
    <Dialog
      open={modal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabindex="-1"
      size="lg"
      onOpenChange={togglemodal}
    >
      {loading === true ? (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Loading...</DialogTitle>
          </DialogHeader>
        </DialogContent>
      ) : (
        <DialogContent  className="max-h-[80vh]  z-[1500] min-w-[50vw] overflow-y-auto">
          <div>
            <div style={{ display: 'none' }}>
              <div id="test-map" />
            </div>
            <div>
              <DialogHeader>
                <DialogTitle toggle={togglemodal}>
                  Edit Shipping Details
                </DialogTitle>
              </DialogHeader>
              <div className="space-y-4 mt-4">
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Name
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      type="text"
                      value={name}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Phone
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-control"
                      type="text"
                      value={phone}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Company
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setCompany(e.target.value)}
                      className="form-control"
                      type="text"
                      value={company}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Search Address
                  </label>
                  <div className="col-md-10">
                    <AsyncSelect
                      onClick={setSelected}
                      onChange={setSelected}
                      cacheOptions
                      loadOptions={loadOptions}
                      defaultOptions
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Country
                  </label>
                  <div className="col-md-10">
                    <Select
                      value={selectedCountry}
                      onValueChange={(value) => {
                        setCountry(value);
                        const state = State.getStatesOfCountry(value);
                        if (state.length > 0) {
                          setState(state[0].isoCode);
                        }
                      }}
                    >
                      <SelectTrigger style={{ width: '100%', height: '100%' }}>
                        <SelectValue />
                      </SelectTrigger>

                      <SelectContent className="z-[1500]">
                        {Country.getAllCountries().map((country) => (
                          <SelectItem
                            key={country.isoCode}
                            value={country.isoCode}
                          >
                            {country.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    State
                  </label>
                  <div className="col-md-10">
                    <Select
                      onValueChange={(e) => setState(e)}
                      value={selectedState}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>

                      <SelectContent className="z-[1500]">
                        {State.getStatesOfCountry(selectedCountry).map(
                          (state) => (
                            <SelectItem
                              key={state.isoCode}
                              value={state.isoCode}
                            >
                              {state.name}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Address
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setAddress1(e.target.value)}
                      className="form-control"
                      type="text"
                      value={address1}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Suburb
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setCity(e.target.value)}
                      className="form-control"
                      type="text"
                      value={city}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Postcode
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setZip(e.target.value)}
                      className="form-control"
                      type="text"
                      value={zip}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="example-text-input"
                    className="col-md-2 col-form-label"
                  >
                    Apartment, suite, etc. (optional)
                  </label>
                  <div className="col-md-10">
                    <InputShadcn
                      onChange={(e) => setAddress2(e.target.value)}
                      className="form-control"
                      type="text"
                      value={address2}
                    />
                  </div>
                </div>
              </div>
              <DialogFooter>
                <Button variant="success" onClick={handleSave}>
                  {' '}
                  Save{' '}
                </Button>
              </DialogFooter>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default LocationListPage;
