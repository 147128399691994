import React, { useState, useMemo, useEffect, useCallback } from 'react';
import superagent from 'superagent';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,

  createColumnHelper,
  getPaginationRowModel
} from '@tanstack/react-table';
import { Card, CardContent } from '@shadcn/components/ui/card';

import { Button } from '@shadcn/components/ui/button';
import { 
  Dialog,
  DialogContent 
} from '@shadcn/components/ui/dialog';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@shadcn/components/ui/popover";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@shadcn/components/ui/tabs";
import { Calendar } from "@shadcn/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import moment from 'moment';
import { backendUrl } from '@helpers/consts';
import ProductVariantMultiSelector from '../helpers/ProductVariantMultiSelector';
import useQuery from '@helpers/JIFI/useQuery';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip as ChartTooltip,
  Legend as ChartLegend
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';

// Đăng ký ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  ChartTooltip,
  ChartLegend,
  Title
);



const InventoryAccuracyPage = ({ match: { params } }) => {
  const [data, setData] = useState([]);
  const [productAccuracy, setProductAccuracy] = useState([]);
  const [locationAccuracy, setLocationAccuracy] = useState([]);
  const [timeBasedReport, setTimeBasedReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("skuLevel");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    totalPages: 0
  });
  
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeChartType, setActiveChartType] = useState('bar');
  
  const [productData, productState] = useQuery({
    url: `/product`,
  });

  const [locationData, locationState] = useQuery({
    url: `/inventory/getApprovalLocations`,
  });

  
  const products = productData?.data ?? [];
  const locations = locationData ?? [];
  console.log('Location data:', locations);
  const columnHelper = createColumnHelper();

  
  useEffect(() => {
    if (!activeTab) {
      setActiveTab("skuLevel");
    }
  }, [activeTab]);

  const fetchInventoryAccuracyOverview = useCallback(() => {
    setLoading(true);
    
    const queryParams = {};
    
    
    if (selectedVariant) {
      if (Array.isArray(selectedVariant)) {
        queryParams.skus = selectedVariant.map(v => v.sku).join(',');
      } else {
        queryParams.sku = selectedVariant.sku;
      }
    } else if (selectedProduct) {
      queryParams.productId = selectedProduct._id;
    }
    
    
    if (selectedLocation) {
      queryParams.locationId = selectedLocation._id;
    }
    
    
    if (startDate) {
      queryParams.startDate = format(startDate, 'yyyy-MM-dd');
    }
    
    if (endDate) {
      queryParams.endDate = format(endDate, 'yyyy-MM-dd');
    }

    superagent
      .get(`${backendUrl}/inventory/inventoryAccuracyOverview`)
      .query(queryParams)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then(response => {
        const { 
          productAccuracy: newProductAccuracy, 
          locationAccuracy: newLocationAccuracy,
          timeBasedReport: newTimeBasedReport
        } = response.body;
        
        
        setProductAccuracy(newProductAccuracy || []);
        setLocationAccuracy(newLocationAccuracy || []);
        setTimeBasedReport(newTimeBasedReport || []);
        
    
      })
      .catch(error => {
        console.error('Error fetching inventory accuracy overview:', error);
      });
  }, [selectedProduct, selectedVariant, selectedLocation, startDate, endDate]);

  const fetchInventoryAccuracy = useCallback((isLoadMore = false) => {
    setLoading(true);
    
    const queryParams = {
      page: isLoadMore ? pagination.current + 1 : 1,
      limit: pagination.pageSize,
    };
    
    
    if (selectedVariant) {
      if (Array.isArray(selectedVariant)) {
        queryParams.skus = selectedVariant.map(v => v.sku).join(',');
      } else {
        queryParams.sku = selectedVariant.sku;
      }
    } else if (selectedProduct) {
      queryParams.productId = selectedProduct._id;
    }
    
    
    if (selectedLocation) {
      queryParams.locationId = selectedLocation._id;
    }
    
    
    if (startDate) {
      queryParams.startDate = format(startDate, 'yyyy-MM-dd');
    }
    
    if (endDate) {
      queryParams.endDate = format(endDate, 'yyyy-MM-dd');
    }

    superagent
      .get(`${backendUrl}/inventory/inventoryAccuracyTrends`)
      .query(queryParams)
      .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
      .set('accept', 'json')
      .then(response => {
        const { 
          data: newData,
          total, 
          page, 
          totalPages 
        } = response.body;
        
    
        console.log('Trends Response Data:', response.body);
        console.log('Current pagination:', pagination);
        console.log('Load more?', isLoadMore, 'Page:', page);
        
    
        if (!newData || newData.length === 0 || page >= totalPages) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }

  
        if (isLoadMore) {
          setData(prevData => [...prevData, ...newData]);
        } else {
          setData(newData || []);
        }
        
        setPagination({
          current: page,
          pageSize: pagination.pageSize,
          total: total || 0,
          totalPages: totalPages || 1
        });
        
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching inventory accuracy trends:', error);
        setLoading(false);
      });
  }, [pagination.pageSize, pagination.current, selectedProduct, selectedVariant, selectedLocation, startDate, endDate]);

  
  useEffect(() => {
    fetchInventoryAccuracy(false);
    fetchInventoryAccuracyOverview();
  }, [pagination.pageSize, selectedProduct, selectedVariant, selectedLocation, startDate, endDate]);

  
  const handleLoadMore = () => {
    fetchInventoryAccuracy(true);
  };

  
  const handleResetFilters = () => {
    setSelectedProduct(null);
    setSelectedVariant(null);
    setSelectedLocation(null);
    setStartDate(null);
    setEndDate(null);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('product', {
        header: 'Product',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('sku', {
        header: 'SKU',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('locationName', {
        header: 'Location',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('systemOnHand', {
        header: 'System Inventory',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('physicalCount', {
        header: 'Physical Count',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('accuracy', {
        header: 'Accuracy (%)',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('variance', {
        header: 'Variance',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('auditDate', {
        header: 'Audit Date',
        cell: info => moment(info.getValue()).format('DD/MM/YYYY HH:mm')
      })
    ],
    []
  );

  

  // Header component
  const TableHeader = React.memo(() => {
    return (
      <div className="flex border-b font-medium bg-gray-100">
        {columns.map(column => (
          <div 
            key={column.header} 
            className="flex-1 p-2"
            style={{ minWidth: column.header === 'Product' ? '200px' : 'auto' }}
          >
            {column.header}
          </div>
        ))}
      </div>
    );
  });

  // Row component for virtualization
  const VirtualRow = useCallback(({ index, style }) => {
    const row = data[index];
    if (!row) return null;
    
    return (
      <div 
        style={style} 
        className="flex border-b hover:bg-gray-100 cursor-pointer"
        onClick={() => setSelectedAudit(row)}
      >
        {columns.map(column => (
          <div 
            key={`${row.auditId}-${column.header}`} 
            className="flex-1 p-2 truncate"
            style={{ minWidth: column.header === 'Product' ? '200px' : 'auto' }}
          >
            {column.cell({ getValue: () => row[column.accessorKey] })}
          </div>
        ))}
      </div>
    );
  }, [data, columns]);

  // Audit details
  const renderAuditDetails = (audit) => {
    return (
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Audit Details</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p><strong>Product:</strong> {audit.product}</p>
            <p><strong>SKU:</strong> {audit.sku}</p>
            <p><strong>Location:</strong> {audit.locationName}</p>
            <p><strong>System Inventory:</strong> {audit.systemOnHand}</p>
          </div>
          <div>
            <p><strong>Physical Count:</strong> {audit.physicalCount}</p>
            <p><strong>Accuracy:</strong> {audit.accuracy}%</p>
            <p><strong>Variance:</strong> {audit.variance}</p>
            <p><strong>Audit Date:</strong> {moment(audit.auditDate).format('DD/MM/YYYY HH:mm')}</p>
          </div>
        </div>
      </div>
    );
  };

  // Product Accuracy Chart
  const ProductAccuracyChart = () => {
    if (!productAccuracy || productAccuracy.length === 0) {
      return (
        <div className="mt-4 p-4 text-center">
          <h3 className="text-lg font-semibold mb-2">Product Accuracy</h3>
          <p>No data</p>
        </div>
      );
    }

    
    const sortedData = [...productAccuracy].sort((a, b) => a.accuracy - b.accuracy);
    
    
    
    const minBarWidth = 60;
    const chartWidth = Math.max(800, sortedData.length * minBarWidth);
    
    
    const chartData = {
      labels: sortedData.map(item => item.product),
      datasets: [
        {
          label: 'Accuracy %',
          data: sortedData.map(item => item.accuracy),
          backgroundColor: '#0088FE',
          borderColor: '#0088FE',
          borderWidth: 1,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
        },
      ],
    };
    
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          title: {
            display: true,
            text: 'Accuracy (%)'
          }
        },
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45,
            font: {
              size: 12
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              
              return [
                `Accuracy: ${context.parsed.y.toFixed(2)}%`,
                
              ];
            }
          }
        },
        legend: {
          display: true,
        }
      }
    };

    return (
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Product Accuracy ({sortedData.length} Products)</h3>
        <div className="overflow-x-auto" style={{ position: 'relative' }}>
          <div style={{ minWidth: '100%', width: `${chartWidth}px`, height: '400px' }}>
            <Bar data={chartData} options={options} />
          </div>
          
          {sortedData.length > 10 && (
            <div className="text-center mt-2 text-sm text-gray-500">
              <span>Scroll horizontally to view all products →</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  // Location Accuracy Chart
  const LocationAccuracyChart = () => {
    if (!locationAccuracy || locationAccuracy.length === 0) {
      return (
        <div className="mt-4 p-4 text-center">
          <h3 className="text-lg font-semibold mb-2">Location Accuracy</h3>
          <p>No data</p>
        </div>
      );
    }

    
    const sortedData = [...locationAccuracy].sort((a, b) => a.accuracy - b.accuracy);
    console.log('Location Accuracy Chart Data:', sortedData);
    
    
    const chartData = {
      labels: sortedData.map(item => item.locationName),
      datasets: [
        {
          label: 'Accuracy %',
          data: sortedData.map(item => item.accuracy),
          backgroundColor: '#00C49F',
          borderColor: '#00C49F',
          borderWidth: 1,
        },
      ],
    };
    
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          title: {
            display: true,
            text: 'Accuracy (%)'
          }
        },
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45,
            font: {
              size: 12
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              return `Accuracy: ${context.parsed.y}%`;
            }
          }
        },
        legend: {
          display: true,
        }
      }
    };
    
    return (
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Location Accuracy ({sortedData.length} Locations)</h3>
        <div className="h-[400px]">
          <Bar data={chartData} options={options} />
        </div>
      </div>
    );
  };

  // Time Based Report Chart
  const TimeBasedReportChart = () => {
    if (!timeBasedReport || timeBasedReport.length === 0) {
      return (
        <div className="mt-4 p-4 text-center">
          <h3 className="text-lg font-semibold mb-2">Accuracy Trends Over Time</h3>
          <p>No data</p>
        </div>
      );
    }

    
    const sortedData = [...timeBasedReport].sort((a, b) => {
    
      if (a.intervalType === 'week') {
        return a.timeInterval.localeCompare(b.timeInterval);
      }
    
      return new Date(a.timeInterval) - new Date(b.timeInterval);
    });
    
    
    
    
    const formatLabels = (timeInterval, intervalType) => {
      if (intervalType === 'week') {
        const [year, week] = timeInterval.split('-W');
        return `Week ${week}, ${year}`;
      }
      return timeInterval;
    };
    
    
    const chartData = {
      labels: sortedData.map(item => formatLabels(item.timeInterval, item.intervalType)),
      datasets: [
        {
          label: 'Accuracy %',
          data: sortedData.map(item => item.overallAccuracy),
          backgroundColor: '#FFBB28',
          borderColor: '#FFBB28',
          borderWidth: activeChartType === 'line' ? 2 : 1,
          tension: activeChartType === 'line' ? 0.1 : undefined,
          pointRadius: activeChartType === 'line' ? 5 : undefined,
          fill: false,
        },
      ],
    };
    
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          title: {
            display: true,
            text: 'Accuracy (%)'
          }
        },
        x: {
          ticks: {
            autoSkip: false,
            maxRotation: 45,
            minRotation: 45,
            font: {
              size: 12
            }
          }
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              const dataPoint = sortedData[context.dataIndex];
              const skuCount = dataPoint.locationDetails?.reduce((sum, loc) => sum + loc.skuCount, 0) || 0;
              return [
                `Overall Accuracy: ${context.parsed.y.toFixed(2)}%`,
                `SKUs Counted: ${skuCount}`,
                `Locations: ${dataPoint.locationDetails?.length || 0}`
              ];
            }
          }
        },
        legend: {
          display: true
        }
      }
    };
    
    // Thêm biểu đồ hiển thị chi tiết theo location
   
    
    return (
      <div className="mt-4">
        <h3 className="text-lg font-semibold mb-2">Accuracy Trends Over Time ({sortedData.length} Periods)</h3>
        
        
        <div className="mb-4">
          <button 
            className="px-3 py-1 bg-blue-500 text-white rounded-l-md"
            onClick={() => setActiveChartType('bar')}
            style={{ backgroundColor: activeChartType === 'bar' ? '#2563eb' : '#93c5fd' }}
          >
          Bar Chart
          </button>
          <button 
            className="px-3 py-1 bg-blue-500 text-white rounded-r-md"
            onClick={() => setActiveChartType('line')}
            style={{ backgroundColor: activeChartType === 'line' ? '#2563eb' : '#93c5fd' }}
          >
            Line Chart
          </button>
        </div>
        
        <div className="h-[400px]">
          {activeChartType === 'bar' ? (
            <Bar data={chartData} options={options} />
          ) : (
            <Line data={chartData} options={options} />
          )}
        </div>
        
        
      </div>
    );
  };

 


  // Render Location selector
  const renderLocationSelector = () => {
    return (
      <div>
        <label className="block text-sm font-medium mb-1">Location</label>
        <select
          className="w-full p-2 border rounded-md"
          value={selectedLocation?._id || ""}
          onChange={(e) => {
            const locationId = e.target.value;
            console.log('Selected location ID:', locationId);
            console.log('Available locations:', locations);
            const location = locations.find(loc => loc._id === locationId);
            console.log('Found location:', location);
            setSelectedLocation(location || null);
          }}
        >
          <option value="">All Locations</option>
          {Array.isArray(locations) && locations.length > 0 ? (
            locations.map(location => (
              <option key={location._id} value={location._id}>
                {location.display_name || location.name}
              </option>
            ))
          ) : (
            <option disabled>No locations available</option>
          )}
        </select>
      </div>
    );
  };

  return (
    <div className="page-content">
    <div className="w-full h-full">
      <Card>
        <CardContent className="p-5">
          <div className="mb-5">
            <h1 className="text-xl font-bold">Inventory Accuracy Report</h1>
            <p className="text-gray-500">Monitor inventory accuracy and variances across locations and products</p>
          </div>
          
          <Tabs defaultValue="skuLevel" value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="mb-4">
              <TabsTrigger value="skuLevel">SKU Level</TabsTrigger>
              <TabsTrigger value="productLevel">Product Level</TabsTrigger>
              <TabsTrigger value="locationLevel">Location Level</TabsTrigger>
              <TabsTrigger value="timeLevel">Time Trends</TabsTrigger>
              <TabsTrigger value="dashboard">Dashboard</TabsTrigger>
            </TabsList>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div>
                <label className="block text-sm font-medium mb-1">Product/SKU</label>
                <ProductVariantMultiSelector
                  products={products}
                  hidePersonalisationOptions={true}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  selectedVariant={selectedVariant}
                  setSelectedVariant={setSelectedVariant}
                  isMulti={true}
                  showSku={true}
                />
              </div>
              
              {renderLocationSelector()}
              
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label className="block text-sm font-medium mb-1">From Date</label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-full justify-start text-left font-normal"
                      >
                        {startDate ? (
                          format(startDate, "dd/MM/yyyy")
                        ) : (
                          <span className="text-muted-foreground">Select date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={startDate}
                        onSelect={setStartDate}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                
                <div>
                  <label className="block text-sm font-medium mb-1">To Date</label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className="w-full justify-start text-left font-normal"
                      >
                        {endDate ? (
                          format(endDate, "dd/MM/yyyy")
                        ) : (
                          <span className="text-muted-foreground">Select date</span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={endDate}
                        onSelect={setEndDate}
                        initialFocus
                        disabled={(date) => startDate && date < startDate}
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </div>
            
            <div className="flex justify-between items-center mb-4">
              <div className="flex gap-2">
                <Button 
                  onClick={handleResetFilters}
                  variant="outline"
                >
                  Clear Filters
                </Button>
                
                <select
                  value={pagination.pageSize}
                  onChange={e => setPagination(prev => ({ ...prev, pageSize: Number(e.target.value) }))}
                  className="border rounded p-2">
                  {[50, 100, 200, 500].map(size => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
              
              <div>
                <Button 
                  onClick={() => {
                    const now = new Date();
                    const oneWeekAgo = new Date();
                    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
                    setStartDate(oneWeekAgo);
                    setEndDate(now);
                  }}
                  variant="outline"
                  className="mr-2"
                >
                  Last Week
                </Button>
                <Button 
                  onClick={() => {
                    const now = new Date();
                    const oneMonthAgo = new Date();
                    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
                    setStartDate(oneMonthAgo);
                    setEndDate(now);
                  }}
                  variant="outline"
                >
                  Last Month
                </Button>
              </div>
            </div>
            
            <TabsContent value="skuLevel">
              <div className="mb-2">
                <TableHeader />
              </div>
              
              <div style={{ height: 'calc(100vh - 350px)', width: '100%' }}>
                {loading && data.length === 0 ? (
                  <div className="flex justify-center items-center h-full">
                    <p>Loading data...</p>
                  </div>
                ) : data.length === 0 ? (
                  <div className="flex justify-center items-center h-full">
                    <p>No data available</p>
                  </div>
                ) : (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        height={height}
                        itemCount={data.length}
                        itemSize={50}
                        width={width}
                      >
                        {VirtualRow}
                      </List>
                    )}
                  </AutoSizer>
                )}
              </div>
              
              {hasMore && (
                <div className="flex justify-center mt-4">
                  <Button 
                    onClick={handleLoadMore} 
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : 'Load More'}
                  </Button>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="productLevel">
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <p>Loading product accuracy data...</p>
                </div>
              ) : productAccuracy.length === 0 ? (
                <div className="flex justify-center items-center h-64">
                  <p>No product accuracy data available</p>
                </div>
              ) : (
                <ProductAccuracyChart />
              )}
            </TabsContent>
            
            <TabsContent value="locationLevel">
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <p>Loading location accuracy data...</p>
                </div>
              ) : locationAccuracy.length === 0 ? (
                <div className="flex justify-center items-center h-64">
                  <p>No location accuracy data available</p>
                </div>
              ) : (
                <LocationAccuracyChart />
              )}
            </TabsContent>
            
            <TabsContent value="timeLevel">
              {loading ? (
                <div className="flex justify-center items-center h-64">
                  <p>Loading time-based report data...</p>
                </div>
              ) : timeBasedReport.length === 0 ? (
                <div className="flex justify-center items-center h-64">
                  <p>No time-based report data available</p>
                </div>
              ) : (
                <TimeBasedReportChart />
              )}
            </TabsContent>
            
            <TabsContent value="dashboard">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <Card>
                  <CardContent>
                    {productAccuracy.length > 0 ? (
                      <ProductAccuracyChart />
                    ) : (
                      <div className="h-64 flex justify-center items-center">
                        <p>No product accuracy data available</p>
                      </div>
                    )}
                  </CardContent>
                </Card>
                
                <Card>
                  <CardContent>
                    {locationAccuracy.length > 0 ? (
                      <LocationAccuracyChart />
                    ) : (
                      <div className="h-64 flex justify-center items-center">
                        <p>No location accuracy data available</p>
                      </div>
                    )}
                  </CardContent>
                </Card>
                
                <Card>
                  <CardContent>
                    {timeBasedReport.length > 0 ? (
                      <TimeBasedReportChart />
                    ) : (
                      <div className="h-64 flex justify-center items-center">
                        <p>No time-based report data available</p>
                      </div>
                    )}
                  </CardContent>
                </Card>
                
                {/* <Card>
                  <CardContent>
                    <TopIssuesPieChart />
                  </CardContent>
                </Card> */}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>

      <Dialog
        open={!!selectedAudit}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedAudit(null);
          }
        }}
        size="lg">
        <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
          {selectedAudit && renderAuditDetails(selectedAudit)}
        </DialogContent>
      </Dialog>
    </div>
    </div>
  );
};

export default InventoryAccuracyPage;
