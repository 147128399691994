import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@shadcn/components/ui/dialog";
import { Button } from "@shadcn/components/ui/button";
import { Checkbox } from "@shadcn/components/ui/checkbox";
import { useState } from "react";

export default function SelectItemsModal({ 
  isOpen, 
  onClose, 
  orderItems,
  onConfirm,
  getVariant 
}) {
  const [selectedItems, setSelectedItems] = useState([]);

  const handleItemSelect = (item, checked) => {
    if (checked) {
      setSelectedItems(prev => [...prev, item]);
    } else {
      setSelectedItems(prev => prev.filter(i => i.sku !== item.sku));
    }
  };

  const handleConfirm = () => {
    onConfirm(selectedItems);
    setSelectedItems([]); // Reset selected items
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[50vw] max-w-2xl">
        <DialogHeader>
          <DialogTitle>Select Items for Repair</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-4 my-4 max-h-[60vh] overflow-y-auto">
          {orderItems?.map((item, index) => (
            <div key={index} className="flex items-center space-x-4 p-4 border rounded">
              <Checkbox
                checked={selectedItems.some(selected => selected.sku === item.sku)}
                onCheckedChange={(checked) => handleItemSelect(item, checked)}
              />
              
              <div className="flex items-center gap-4 flex-1">
                <img 
                  src={getVariant(item.sku)?.image} 
                  alt="" 
                  className="w-16 h-16 object-contain"
                />
                <div>
                  <h4 className="font-medium">{getVariant(item.sku)?.name}</h4>
                  <p className="text-sm text-gray-600">SKU: {item.sku}</p>
                  <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-2 pt-4 border-t">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Confirm Selection</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
