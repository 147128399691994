import { useForm } from 'react-hook-form';
import { Input } from '@shadcn/components/ui/input';
import {
  Select,
  SelectItem,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from '@shadcn/components/ui/select';

import  WizardNav  from '@pages/JIFI/orders/Wizard/WizardNav';
import { Button } from '@shadcn/components/ui/button';
import { Label } from '@shadcn/components/ui/label';
import { Card, CardContent, CardHeader } from '@shadcn/components/ui/card';
import { Checkbox } from "@shadcn/components/ui/checkbox";
import { useState, useEffect, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import superagent from 'superagent';
import { backendUrl } from '@helpers/consts';
import { CommandSearch } from '../order-ticket/feedback';

import useProductData from '../orders/useProductData';
import SelectItemsModal from './SelectItemsModal';
import { Textarea } from '@shadcn/components/ui/textarea';
import { uploadFileS3Bucket } from '@utilities/uploadFileS3Bucket';
import moment from 'moment';

import { MultiSelect } from '@shadcn/components/ui/multi-select';
import useQuery from '@helpers/JIFI/useQuery';
import { Store, Truck } from 'lucide-react';

export const states = [
  { value: 'VIC', label: 'Victoria' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'WA', label: 'Western Australia' },

];


const monogramTypes = {
  text: 'Text',
  pets: 'Pets'
};

const fonts = {
  heritage: 'Heritage',
  'bold-new': 'Bold (new)', 
  bubble: 'Bubble',
  'retro-serif': 'Retro Serif',
  graphic: 'Graphic',
  'sans-serif': 'Sans Serif',
  block: 'Block',
  signature: 'Signature',
  retro: 'Retro',
  serif: 'Serif',
  decorative: 'Decorative',
  'love-letter': 'Love Letter',
  painted: 'Painted',
  college: 'College',
  script: 'Script',
  casual: 'Casual',
  wide: 'Wide',
  bold: 'Block Serif',
  cursive: 'Modern Script',
  modern: 'Modern Serif'
};

const newColors = {
  Lavender: '#90759B',
  Lilac: '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  Grenaine: '#9E5360',
  'Blush-Pink': '#E77472',
  Magenta: '#B12D52',
  Maroon: '#731A29',
  Red: '#C92327',
  'Burnt-Orange': '#DC5527',
  Mango: '#EF7E2E',
  Rust: '#9B4827',
  Toffee: '#705037',
  Taupe: '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  Yellow: '#E8B629',
  Lime: '#C6B937',
  Green: '#4A813C',
  'Forest-Green': '#034123',
  Mint: '#78AB93',
  'Ice-Blue': '#BEE3E9',
  Denim: '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  Blue: '#1466B2',
  Grey: '#95979E',
  'Slate-Grey': '#63727E',
  Black: '#000000',
  White: '#FFFFFF'
};

const locations = {
  Top: 'Top',
  Side: 'Side', 
  Back: 'Back'
};


const PICKUP_LOCATIONS = [
  { value: 'QV_PICKUP', label: 'QV Store' },
  { value: 'COLLINGWOOD_PICKUP', label: 'Collingwood Store' },
  { value: 'GALLERIES_PICKUP', label: 'Galleries Store' },
  { value: 'BONDI_PICKUP', label: 'Bondi Store' },
  { value: 'CHADSTONE_PICKUP', label: 'Chadstone Store' },
  { value: 'PITT_ST_PICKUP', label: 'Pitt St Store' }
];

const priorities = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];


export const FAULT_REASONS = [
  { value: 'crack', label: 'Crack' },
  { value: 'zip', label: 'Zip' },
  { value: 'lock', label: 'Lock' },
  { value: 'unable_to_repair', label: 'Unable to Repair' },
  { value: 'stitching', label: 'Stitching' },
  { value: 'other', label: 'Other' }
];

const STORES = [
  { value: 'COLLINGWOOD', label: 'Collingwood' },
  { value: 'QV', label: 'QV' },
  { value: 'CHADSTONE', label: 'Chadstone' },
  { value: 'EMPORIUM', label: 'Emporium' },
  { value: 'HIGHPOINT', label: 'Highpoint' },
  { value: 'GALERIES', label: 'Galeries' },
  { value: 'ROSEBERY', label: 'Rosebery' },
  { value: 'PARRAMATTA', label: 'Parramatta' },
  { value: 'CASTLE_TOWERS', label: 'Castle Towers' },
  { value: 'BONDI', label: 'Bondi' },
  { value: 'PITT_ST', label: 'Pitt St' },
  { value: 'JAMES_ST', label: 'James St' }
];

const REPAIR_TYPES = [
  { value: 'WHEEL', label: 'Wheel Repair' },
  { value: 'ZIP', label: 'Zip Repair' },
  { value: 'LOCK_CLASSICS', label: 'Lock Repair Classics' },
  { value: 'LOCK_TRUNK', label: 'Lock Repair Trunk' },
  { value: 'TELESCOPIC', label: 'Telescopic Handle Repair' },
  { value: 'CARRY_HANDLE', label: 'Carry Handle Repair' },
  { value: 'HINGE_PIN', label: 'Hinge Pin' },
  { value: 'HINGE_PIN_COVER', label: 'Hinge Pin Cover' },
  { value: 'FIDLOCK', label: 'Fidlock' },
  { value: 'POWER_BANK', label: 'Power Bank Cage' },
  { value: 'OTHER', label: 'Other' }
];



export default function RepairTicket({ initialOrder, onModalChange, isForm = false, isWizard = false }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      store: '',
      repairDate: moment().format('YYYY-MM-DD'),
      staffName: '',
      customerName: '',
      order: initialOrder,
      orderNumber: '',
      selectedItems: [],
      completed: false,
      dateNeeded: '',
      additionalNotes: '',
      attachments: []
    },
    resolver: async (values) => {
      const errors = {};
      
      // Required fields validation
      if (!values.store) errors.store = { message: 'Store is required' };
      if (!values.repairDate) errors.repairDate = { message: 'Date is required' };
      if (!values.staffName) errors.staffName = { message: 'Staff name is required' };
      if (!values.dateNeeded) errors.dateNeeded = { message: 'Date needed is required' };
      if (!values.customerName) errors.customerName = { message: 'Customer name is required' };
      if (!values.additionalNotes) errors.additionalNotes = { message: 'Additional notes are required' };
      

      // Order validation
      if (linkToOrder) {
        if (!values.order) {
          errors.order = { message: 'Order is required' };
        }
        if (!values.selectedItems?.length) {
          errors.selectedItems = { message: 'At least one item must be selected' };
        }
      } else {
        if (!values.orderNumber) {
          errors.orderNumber = { message: 'Order number is required' };
        }
      }

      return {
        values,
        errors
      };
    }
  });

  const showOldOrderCheckbox = !isForm && !isWizard;
  const [loading, setLoading] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [selectedItemRepairs, setSelectedItemRepairs] = useState({});
  const [selectedItemParts, setSelectedItemParts] = useState({});
  const [linkToOrder, setLinkToOrder] = useState(true);
  const [retailStores] = useQuery({
    url: '/inventory/getRetailStore',
  });

  const order = watch('order');
  const selectedItems = watch('selectedItems') || [];

  const getVariant = useProductData();

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await superagent
        .get(`${backendUrl}/orders/${orderId}`)
        .set('Authorization', `Bearer ${localStorage.getItem('authJWT')}`)
        .set('accept', 'json');
      
      
      return response.body;
    } catch (error) {
      console.error('Error fetching customer info:', error);
    }
  };
  
  useEffect(() => {
    if (initialOrder) {
      setValue('order', initialOrder);
      const itemsToSelect = initialOrder.line_items.map(item => ({
        sku: item.sku,
        quantity: item.quantity
      }));
      setValue('customerName', initialOrder.customer?.first_name + ' ' + initialOrder.customer?.last_name);
      setValue('selectedItems', itemsToSelect);
    }
  }, [initialOrder, setValue]);

  useEffect(() => {
    if (!initialOrder && order?.line_items) {
      setIsSelectModalOpen(true);
    }
  }, [order, initialOrder]);

  const handleModalItemSelect = async (items) => {
    setValue('selectedItems', items);
    const orderDetails = await fetchOrderDetails(order._id);
    setValue('customerName', orderDetails.customer.first_name + ' ' + orderDetails.customer.last_name);
    setIsSelectModalOpen(false);
  };



  const handlePartChange = (sku, index, field, value) => {
    setSelectedItemRepairs(prev => {
      const newParts = [...prev[sku].parts];
      newParts[index] = { ...newParts[index], [field]: value };
      return { 
        ...prev, 
        [sku]: {
          ...prev[sku],
          parts: newParts
        }
      };
    });
  };

  const removePart = (sku, index) => {
    setSelectedItemRepairs(prev => ({
      ...prev,
      [sku]: {
        ...prev[sku],
        parts: prev[sku].parts.filter((_, i) => i !== index)
      }
    }));
  };

  const addNewPart = (sku) => {
    setSelectedItemRepairs(prev => ({
      ...prev,
      [sku]: {
        ...prev[sku],
        parts: [...(prev[sku]?.parts || []), { name: '', quantity: '' }]
      }
    }));
  };

  const createRepairTicket = async (data) => {
    try {
      const response = await superagent
        .post(`${backendUrl}/repair-tickets`)
        .set('Authorization', `Bearer ${localStorage.getItem("authJWT")}`)
        .send(data);

      if (response.status >= 200 && response.status < 300) {
        toast.success('Repair ticket created successfully');
      } else {
        toast.error('Failed to create repair ticket');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
  

    try {
      if (data['files']) {
        for (const file of data['files']) {
          const fileUploaded = await uploadFileS3Bucket(
          file,
          `attachment_${Date.now()}`
        );
        data['attachments'] = [...(data['attachments'] || []), fileUploaded];
      }
    }

    const formData = {
      ...data,
      itemRepairs: Object.entries(selectedItemRepairs).map(([sku, item]) => ({
        sku,
        repairTypes: item.repairTypes || [],
        parts: item.parts || []
      }))
    };

    await createRepairTicket(formData);
    onModalChange?.(false);
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };


  const renderSelectedItems = () => (
    <div className="space-y-6">
      {selectedItems.map((item, index) => {
        const currentItem = selectedItemRepairs[item.sku] || { repairTypes: [], parts: [] };
        const variant = getVariant(item.sku);
        
        return (
          <div key={index} className="border rounded-lg p-4 bg-white relative">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                const newItems = selectedItems.filter(i => i.sku !== item.sku);
                setValue('selectedItems', newItems);
                const newItemRepairs = { ...selectedItemRepairs };
                delete newItemRepairs[item.sku];
                setSelectedItemRepairs(newItemRepairs);
              }}
              className="absolute -top-2 -right-2 w-5 h-5 bg-red-500 hover:bg-red-600 
                       rounded-full text-white flex items-center justify-center 
                       shadow-md transition-colors"
              title="Remove item"
            >
              ×
            </button>

            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-4">
                <img 
                  src={variant?.image}
                  alt=""
                  className="w-16 h-16 object-contain border rounded p-1"
                />
                <div>
                  <h4 className="font-medium">{variant?.name}</h4>
                  <p className="text-sm text-gray-600">SKU: {item.sku}</p>
                  <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
                </div>
              </div>
            </div>
            
            <div>
              <Label>Repair Types</Label>
              <MultiSelect
                options={REPAIR_TYPES}
                onValueChange={(values) => setSelectedItemRepairs(prev => ({
                  ...prev,
                  [item.sku]: {
                    ...prev[item.sku],
                    repairTypes: values
                  }
                }))}
                defaultValue={currentItem.repairTypes}
                placeholder="Select repair types..."
                variant="inverted"
                maxCount={2}
                maxLengthTruncation="120px"
              />
            </div>

            <div className="mt-4">
              <Label>Parts Required</Label>
              <div className="space-y-3">
                {currentItem?.parts?.length > 0 && currentItem?.parts?.map((part, partIndex) => (
                  <div key={partIndex} className="flex gap-2 items-end">
                    <div className="flex-1 grid gap-2 grid-cols-2">
                      <Input
                        placeholder="Part Name"
                        value={part.name}
                        onChange={(e) => handlePartChange(item.sku, partIndex, 'name', e.target.value)}
                      />
                      <Input
                        type="number"
                        placeholder="Quantity"
                        value={part.quantity}
                        onChange={(e) => handlePartChange(item.sku, partIndex, 'quantity', e.target.value)}
                      />
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={() => removePart(item.sku, partIndex)}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
                <Button
                  type="button"
                  variant="secondary"
                  size="sm"
                  onClick={() => addNewPart(item.sku)}
                >
                  Add Part
                </Button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
    
  
  return (
    <div className={`${isForm ? '' : 'page-content'}`}>
      <div className={`${isForm ? '' : 'container mx-auto px-4 lg:max-w-[85%]'}`}>
        <Card className={`${isForm ? '' : 'max-w-lg mx-auto my-10 p-6'}`}>
          { !isForm && <CardHeader className="text-2xl font-semibold">
            Repair Ticket
          </CardHeader>}
          {isWizard && <WizardNav steps={['Create Repair Ticket']} activeTabProgress={1} />}
          <CardContent className={`${isForm ? 'pt-6' : ''}`}>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              {/* Store Selection */}
              <div>
                <Label>Store*</Label>
                <Select 
                  onValueChange={(value) => setValue('store', value)}
                  value={watch('store')}
                >
                  <SelectTrigger className={errors.store ? 'border-destructive' : ''}>
                    <SelectValue placeholder="Select store" />
                  </SelectTrigger>
                  <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                    {STORES.map(store => (
                      <SelectItem key={store.value} value={store.value}>
                        {store.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                {errors.store && (
                  <p className="text-red-500 text-sm mt-1">{errors.store.message}</p>
                )}
              </div>

              {/* Date */}
              <div>
                <Label>Date*</Label>
                <Input
                  type="date"
                  {...register('repairDate', { required: 'Date is required' })}
                />
                {errors.repairDate && (
                  <p className="text-red-500 text-sm mt-1">{errors.repairDate.message}</p>
                )}
              </div>

              {/* Staff Name */}
              <div>
                <Label>Staff Name*</Label>
                <Input
                  {...register('staffName', { required: 'Staff name is required' })}
                  placeholder="Enter staff name"
                />
                {errors.staffName && (
                  <p className="text-red-500 text-sm mt-1">{errors.staffName.message}</p>
                )}
              </div>

               {/* Customer Name */}
               <div>
                <Label>Customer Name</Label>
                <Input
                  id="customerName"
                  className="text-black"
                  placeholder="Enter customer name"
                  {...register('customerName')}
                />
              </div>

              {/* Order Number with Skip Option */}
              <div>
                <Label>Order Number*</Label>
                <CommandSearch
                  orderName={initialOrder?.name}
                  setValue={setValue}

                  linkToOrder={linkToOrder}
                />
                {showOldOrderCheckbox &&
                <div className="flex items-center space-x-2 my-2 mt-4">
                  <Checkbox
                    id="skipOrder"
                    checked={!linkToOrder}
                    onCheckedChange={(checked) => {
                      setLinkToOrder(!checked);
                      if (checked) {
                        setValue('order', null);
                        setValue('selectedItems', []);
                        clearErrors('order');
                      }
                    }}
                  />
                  <Label className="mb-0" htmlFor="skipOrder">Old Order (Pre-2020)</Label>
                </div>
}
                {linkToOrder && errors.order && (
                  <p className="text-red-500 text-sm mt-1">{errors.order.message}</p>
                )}
                {!linkToOrder && errors.orderNumber && (
                  <p className="text-red-500 text-sm mt-1">{errors.orderNumber.message}</p>
                )}
              </div>

              {selectedItems.length > 0 && (
                <div>
                  <Label>Selected Items</Label>
                  {renderSelectedItems()}
                </div>
              )}
              {/* Selected Items Validation */}
              {errors.selectedItems && (
                <p className="text-red-500 text-sm mt-1">{errors.selectedItems.message}</p>
              )}

              {/* Item Repairs Validation */}
              {errors.itemRepairs && (
                <p className="text-red-500 text-sm mt-1">{errors.itemRepairs.message}</p>
              )}

              {/* Completion & Delivery Section */}
              <div className="space-y-4">
                <Label>Status & Delivery</Label>
                
                {/* Status Checkbox */}
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="completed"
                    checked={watch('completed')}
                    onCheckedChange={(checked) => setValue('completed', checked)}
                  />
                  <Label className="mb-0" htmlFor="completed">
                    {watch('completed') ? 'Completed' : 'To be Completed'}
                  </Label>
                </div>

                {/* Show delivery options when not completed */}
                {!watch('completed') && (
                  <div className="mt-4 space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div
                        className={`p-4 rounded-lg border-2 cursor-pointer transition-all ${
                          !watch('toBeShipped') 
                            ? 'border-primary bg-primary/5'
                            : 'border-gray-200 hover:border-gray-300'
                        }`}
                        onClick={() => {
                          setValue('toBeShipped', false);
                          setValue('pickupLocation', null); // Reset pickup location
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <Store className="h-5 w-5 text-primary" />
                          <div>
                            <p className="font-medium">Store Pickup</p>
                            
                          </div>
                        </div>
                      </div>

                      <div
                        className={`p-4 rounded-lg border-2 cursor-pointer transition-all ${
                          watch('toBeShipped')
                            ? 'border-primary bg-primary/5' 
                            : 'border-gray-200 hover:border-gray-300'
                        }`}
                        onClick={() => {
                          setValue('toBeShipped', true);
                          setValue('pickupLocation', null); // Clear pickup location
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <Truck className="h-5 w-5 text-primary" />
                          <div>
                            <p className="font-medium">To be Shipped</p>
                            
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Show Pickup Location when Store Pickup is selected */}
                    {!watch('toBeShipped') && (
                      <div className="mt-2">
                        <Label>Pickup Location*</Label>
                        <Select
                          onValueChange={(val) => setValue('pickupLocation', val)}
                          value={watch('pickupLocation')}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select pickup location" />
                          </SelectTrigger>
                          <SelectContent className="max-h-[200px] overflow-y-auto z-[1500]">
                            {retailStores?.map((option) => (
                              <SelectItem key={option._id} value={option._id}>
                                {option.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        {errors.pickupLocation && (
                          <p className="text-red-500 text-sm mt-1">{errors.pickupLocation.message}</p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Date Needed By */}
              <div>
                <Label>Date Needed By*</Label>
                <Input
                  type="date"
                  {...register('dateNeeded', { required: 'Date needed is required' })}
                />
                {errors.dateNeeded && (
                  <p className="text-red-500 text-sm mt-1">{errors.dateNeeded.message}</p>
                )}
              </div>

              {/* Additional Notes */}
              <div>
                <Label>Additional Notes / Part Shipment Request</Label>
                <Textarea
                  {...register('additionalNotes')}
                  placeholder="Enter any additional notes or part shipment requests"
                  className="min-h-[100px]"
                />
              </div>
              {errors.additionalNotes && (
                <p className="text-red-500 text-sm mt-1">{errors.additionalNotes.message}</p>
              )}

              {/* Attachments */}
              <div>
                <Label>Attachments</Label>
                <Input
                  type="file"
                  multiple
                  {...register('files')}
                  accept="image/*"
                />
              </div>

              

              {/* Submit Buttons */}
              <div className="flex justify-end space-x-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => onModalChange?.(false)}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Creating...' : 'Create Repair Ticket'}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>

      <SelectItemsModal
        isOpen={isSelectModalOpen}
        onClose={() => setIsSelectModalOpen(false)}
        orderItems={order?.line_items}
        onConfirm={handleModalItemSelect}
        getVariant={getVariant}
      />

      {(!isForm || isWizard) && <ToastContainer />}
    </div>
  );
}
