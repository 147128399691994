import  { useState, useMemo, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,

  flexRender,
  createColumnHelper
} from '@tanstack/react-table';
import { Card, CardContent  } from '@shadcn/components/ui/card';
import {   Dialog,
  DialogContent,

   } from '@shadcn/components/ui/dialog';
import moment from 'moment';  



import useQuery from '@helpers/JIFI/useQuery';


const columnHelper = createColumnHelper();

const TrackingUpdatesPage = ({ match: { params } }) => {

  const { orderID, trackingUpdateID } = params;
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  });

  const [data, isLoading] = useQuery({
    url: `/tracking-updates/${params.orderID}`,
    queryData: {
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize
    }
  });
  

  const trackingUpdates = data?.data || [];
  const paginationInfo = data?.pagination || {};
  
  const columns = useMemo(
    () => [
      
        columnHelper.accessor('orderName', {
          header: 'Order',
          cell: ({ row }) => (
          <a target="_blank" rel="noopener noreferrer">
            {row.original.orderName}
          </a>
          )
        }),
      
      
        columnHelper.accessor('changes', {
          header: 'Change Type',
          cell: ({ row }) => {
            const types = [];
          if (row.original.changes.order) types.push('Order');
          if (row.original.changes.line_items) types.push('Product');
          if (row.original.changes.customer) types.push('Customer');
          if (row.original.changes.shipping_address) types.push('Shipping Address');
          if (row.original.changes.billing_address) types.push('Billing Address');
            return types.join(', ');
        }
        }),
      
      
        columnHelper.accessor('shopifyUpdatedAt', {
          header: 'Shopify Update Time',
          cell: ({ row }) => moment(row.original.shopifyUpdatedAt).format('DD/MM/YYYY HH:mm:ss')
        }),
      
      
        columnHelper.accessor('processed', {
          header: 'Status',
          cell: ({ row   }) => (
          <span className={`badge badge-${row.original.processed ? 'success' : 'warning'}`}>
            {row.original.processed ? 'Processed' : 'Unprocessed'}
          </span>
          )
        })
    ],
    []
  );

  const table = useReactTable({
    data: trackingUpdates || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: paginationInfo?.totalPages || 0,
    state: {
      pagination
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  useEffect(() => {
    if (trackingUpdateID && data?.data) {
      const targetUpdate = data.data.find(update => 
        update.id === trackingUpdateID || update._id === trackingUpdateID
      );
      if (targetUpdate) setSelectedUpdate(targetUpdate);
    }
  }, [data, trackingUpdateID]);

  
  
  const renderChangeDetails = (changes) => {
    const renderTableContent = (data, type) => {
      
      if (data.shopify && !data.jifi && typeof data.shopify === 'object') {
        return (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Field</th>
                <th>New Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(data.shopify).map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }

      
      return (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Field</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(data)
              .filter(([key]) => key !== 'id')
              .map(([key, value]) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{value.jifi || ''}</td>
                  <td>{value.shopify || ''}</td>
                </tr>
              ))}
          </tbody>
        </table>
      );
    };

    return (
      <div className="space-y-4">
        {changes.order && (
          <div className="mb-4">
            <h5 className="text-lg mb-2">Order Information Changes:</h5>
            <div className="table-responsive">
              {renderTableContent(changes.order)}
            </div>
          </div>
        )}

        {changes.line_items && (
          <div className="mb-4">
            <h5 className="text-lg mb-2">Product Information Changes:</h5>
            {changes.line_items.map((item, index) => (
              <div key={index} className="mb-3">
                {item.deletedLine && <p className="text-base mb-2">Line item ID: {item.deletedLine} has been deleted</p>}
                {item.missingLine && (
                  <div>
                    <p className="text-base mb-2">Add new line item:</p>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Field</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(item.missingLine).map(([key, value]) => (
                            <tr key={key}>
                              <td>{key}</td>
                              <td>{value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {!item.deletedLine && !item.missingLine && (
                  <div className="table-responsive">
                    {renderTableContent(item)}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {changes.customer && (
          <div className="mb-4">
            <h5 className="text-lg mb-2">Customer Information Changes:</h5>
            <div className="table-responsive">
              {renderTableContent(changes.customer)}
            </div>
          </div>
        )}

        {changes.shipping_address && (
          <div className="mb-4">
            <h5 className="text-lg mb-2">Shipping Address Changes:</h5>
            <div className="table-responsive">
              {renderTableContent(changes.shipping_address)}
            </div>
          </div>
        )}

        {changes.billing_address && (
          <div className="mb-4">
            <h5 className="text-lg mb-2">Billing Address Changes:</h5>
            <div className="table-responsive">
              {renderTableContent(changes.billing_address)}
            </div>
          </div>
        )}
      </div>
    );
  };

  if (isLoading !== 'success') {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-content">
      <Card className="w-full px-3 mx-auto xl:max-w-[85%]">
        <CardContent>
          <h4 className="mt-6 mb-4 text-2xl font-semibold">Shopify Update History</h4>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              
              <tbody>
                {table.getRowModel().rows.map(row => (
                  
                  <tr 
                    key={row.id} 
                    onClick={() => setSelectedUpdate(row.original)}
                    style={{ cursor: 'pointer' }}
                    className={!!trackingUpdateID && row.original._id === trackingUpdateID ? 'bg-yellow-100' : ''}
                  >
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between mt-4">
            <div>
              <button
                className="btn btn-primary mr-2"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}>
                Previous
              </button>
              <button
                className="btn btn-primary"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}>
                Next
              </button>
            </div>
            <div>
              Page{' '}
              <strong>
                {pagination.pageIndex + 1} of {paginationInfo?.totalPages || 1}
              </strong>{' '}
              | Total: {paginationInfo?.total || 0} records
            </div>
            <select
              value={pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value));
              }}
              className="form-control"
              style={{ width: '120px' }}>
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </CardContent>
      </Card>

      <Dialog
        open={!!selectedUpdate}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedUpdate(null);
          }
        }}
        size="lg">
      
        <DialogContent className="z-[1600] max-h-[80vh] overflow-y-auto min-w-[60vw]">
          {selectedUpdate && renderChangeDetails(selectedUpdate.changes)}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TrackingUpdatesPage;
