import SupplyChainNav from '../../../components/supply-chain/nav.js';

import { Card ,CardContent as CardBody} from '@shadcn/components/ui/card'
import WarrantyKanbanBoard from "@pages/JIFI/warranty-claim";
import { useHistory } from 'react-router-dom';
import OrderTicketPage from '../order-ticket/index.js';
import RepairTicketPage from '../repair-ticket/index.js';
const TicketsPage = () => {
 
  const user = JSON.parse(localStorage.getItem("authUser"))

  const history = useHistory();
 

  return (
    <SupplyChainNav menuType="tickets">
         <Card className="pt-6 mb-10">
          <CardBody className="flex-1 min-h-0">
        {history?.location?.pathname?.includes('tickets/warranty') && (
          <WarrantyKanbanBoard></WarrantyKanbanBoard>
        )}
       

       {history?.location?.pathname?.includes('tickets/order') && (
          <OrderTicketPage></OrderTicketPage>
        )}

        {history?.location?.pathname?.includes('tickets/repair') && (
          <RepairTicketPage></RepairTicketPage>
        )}
        </CardBody>
      </Card>
    </SupplyChainNav>
  );
};

export default TicketsPage;
