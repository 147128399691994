import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardHeader, CardContent } from "@shadcn/components/ui/card";
import { cn } from "@shadcn/lib/utils";

import { cva } from "class-variance-authority"; 
import {  CalendarDays, AlertCircle, Truck, CheckCircle2, ArrowRight, Store, User } from "lucide-react"; 
import { Badge } from "@shadcn/components/ui/badge";

import { useTaskCard } from "@zustand/useTaskCard";
import { useEffect } from "react";
import { getLabel } from "@pages/JIFI/warranty-claim";
import { FAULT_REASONS } from "@pages/JIFI/warranty-claim/warranty-claim";

const statusTitle = {
    todo: 'To do',
    'in-progress': 'In Progress',
    complete: 'Complete',
  };

const statusColors = {
    todo: 'red',
    'in-progress': 'yellow',
    complete: 'green'
};

const priorityColors = {
    high: 'bg-red-100 text-red-800',
    medium: 'bg-yellow-100 text-yellow-800',
    low: 'bg-gray-100 text-gray-800'
};

export function TaskCard({ task, isOverlay }) {
    
    const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
        id: task.id, 
        data: {
            type: "Task",
            task, 
        },
        attributes: {
            roleDescription: "Task",
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 999 : 'auto'
    };


    const selectedCard = useTaskCard ((state) => state.selectedCard);
    const selected = useTaskCard((state => state.selected))
    const taskCard  = useTaskCard((state => state.task))
    const setSelected = useTaskCard((state => state.setSelected))
    useEffect(() => {
        if(task.selected) {
            if(selected === undefined) {
                selectedCard({...task})
                setSelected(true)
            }
        }
    },[])
    
    const cardClass = task?.id === taskCard?.id
        ? "border-2 border-black shadow-lg"
        : "bg-white border border-gray-300 shadow-sm";

    const variants = cva("", {
        variants: {
            dragging: {
                over: "ring-2 opacity-30", 
                overlay: "ring-2 ring-primary",
            },
        },
    });
    const formattedDate = task.replacementIssuedDate ? new Date(task.replacementIssuedDate).toLocaleDateString() : '';



    return (
        <Card
            onClick={() => {
                selectedCard(task)
                setSelected(true)
            }}
            ref={setNodeRef}
            style={style}
            className={`${cardClass} cursor-pointer ${variants({
                dragging: isOverlay ? "overlay" : isDragging ? "over" : undefined,
            })} transition-all duration-300 rounded-xl hover:shadow-lg border-l-4 border-${statusColors[task.status]}-500`}
        >
            <CardHeader 
                {...attributes}
                {...listeners}
                className="px-4 py-3 flex flex-row items-center justify-between border-b cursor-grab"
            >
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                    <User className="h-4 w-4 text-gray-400" />
                        <span className="font-medium text-sm text-gray-900">
                            
                            {task.customerName}
                        </span>
                     
                    </div>
                    <div className="flex items-start">
                        {task?.order?.name &&
                            <Badge variant="outline" className="text-xs py-0 px-2 border-gray-200 w-fit">
                                {task?.order?.name}
                            </Badge>
                        }
                           {!task?.hasOrder && (
                            <>
                            {task?.orderNumber && <Badge variant="outline" className="text-xs bg-gray-100 text-gray-800 mr-1">
                                {task?.orderNumber}
                            </Badge>}
                            <Badge variant="outline" className="text-xs bg-gray-100 text-gray-800">
                                Pre 2020
                            </Badge>
                            </>
                        )}
                    </div>
                    {task.pickupLocation && (
                        <div className="flex items-center gap-1.5 bg-purple-50 py-1 rounded-full">
                            <Store className="h-4 w-4 text-purple-600" />
                            <span className="text-xs text-purple-700 font-medium">
                                Pickup: {task?.pickupLocation?.display_name} ({task?.pickupLocation?.code})
                            </span>
                        </div>
                    )}
                    
                </div>
                <div className="flex items-center gap-2">
                    {task.toBeShipped && <Truck className="h-4 w-4 text-blue-500" />}
                    {task.fulfilled && <CheckCircle2 className="h-4 w-4 text-green-500" />}
                </div>
            </CardHeader>

            <CardContent className="p-4 space-y-3">
                <div className="flex items-center gap-2">
                    <Badge variant="destructive" className="text-xs">
                        {getLabel(FAULT_REASONS, task.faultReason)}
                    </Badge>
                </div>

                <div className="space-y-1">
                    {task?.replacementSkus?.map((item, index) => (
                        <div key={index} className="flex items-center gap-2 text-xs">
                            <span className="text-gray-500 line-through">{item?.originalSku}</span>
                            <ArrowRight className="h-3 w-3 text-gray-400" />
                            <span className="font-medium text-gray-900">
                                {item?.replacementSku}
                            </span>
                        </div>
                    ))}
                </div>

                <div className="flex items-center justify-between text-xs text-gray-500">
                    <div className="flex items-center gap-1">
                        <CalendarDays className="h-4 w-4" />
                        <span>{formattedDate}</span>
                    </div>
                    {task.priority && (
                        <div className={`flex items-center gap-1 px-2 py-1 rounded-full ${priorityColors[task.priority]}`}>
                            <AlertCircle className="h-4 w-4" />
                            <span className="capitalize">{task.priority}</span>
                        </div>
                    )}
                </div>
            </CardContent>
        </Card>
    );
}
